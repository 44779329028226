import React, { useState, useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import { SERVER_API } from "../env";
import { FaSort } from "react-icons/fa";

const Scorecard = ({ setScorecardExist, scorecard, setScorecard }) => {
  const [error, setError] = useState("");

  let GetScorecardApi =
    SERVER_API + `/api/scorecard/${localStorage.getItem("matchId")}`;
  let UpdateScorecardApi = SERVER_API + "/api/scorecard";

  const GetScorecard = async (queryValue) => {
    if (queryValue) {
      GetScorecardApi = GetScorecardApi + "?sort=" + queryValue;
    }

    const response = await fetch(GetScorecardApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });

    let result = await response.json();
    if (response.status !== 200) {
      setError(result.message);
      return;
    }

    setScorecardExist(true);
    setScorecard(result);
  };

  const UpdateScorecard = async (index, teamIndex) => {
    console.log(index, teamIndex);

    let value = { ...scorecard[teamIndex]["scorecard"][index] };

    delete value.strikeRate;
    delete value.economy;
    delete value.fantasyScore;
    delete value.credits;
    delete value.role;
    delete value.name;

    value.matchId = localStorage.getItem("matchId");

    let input = value;
    const response = await fetch(UpdateScorecardApi, {
      method: "PATCH",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(input),
    });
    let result = await response.json();
    if (response.status === 200) {
      setScorecard(result);
    } else {
      alert(result.message);
    }
  };

  const editScorecard = (teamIndex, index, key, value) => {
    let temp = [...scorecard];

    temp[teamIndex]["scorecard"][index][key] = value;
    setScorecard(temp);
  };

  useEffect(() => {
    GetScorecard();
  }, []);

  let teamIndex = -1;
  return (
    <div>
      <div>
        <div
          style={{
            width: "85vw",
            marginLeft: "1em",
            height: "70vh",
            overflowY: "scroll",
          }}
        >
          {!error &&
            scorecard.map((teams, name) => {
              teamIndex++;
              let thisTeamIndex = teamIndex;
              return (
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>{teams.teamName}</Accordion.Header>
                    <Accordion.Body>
                      <div
                        style={{
                          overflowY: "scroll",
                          height: "60vh",
                        }}
                      >
                        <table
                          style={{
                            width: "80vw",
                            marginLeft: "1em",
                          }}
                        >
                          <thead
                            id="thead"
                            style={{ position: "sticky", top: "0px" }}
                          >
                            <tr style={{ fontSize: ".8em" }}>
                              <th>CricBuzzPlayerId</th>
                              <th onClick={() => GetScorecard("name")}>
                                Name
                                <FaSort
                                  style={{ height: "2vh", width: "2vw" }}
                                />
                              </th>
                              <th>Fours</th>
                              <th>Sixes</th>
                              <th>Runs</th>
                              <th>Balls Played</th>
                              <th>Strike Rate</th>
                              <th>Wickets</th>
                              <th>Bowlds/Lbw</th>
                              <th>Midens</th>
                              <th>Runs Given</th>
                              <th>Over Bowled</th>
                              <th>Economy</th>
                              <th>Catches</th>
                              <th>Stumpings</th>
                              <th>RunOutsDirect</th>
                              <th>Run Outs</th>
                              <th onClick={() => GetScorecard("role")}>
                                Role
                                <FaSort
                                  style={{ height: "2vh", width: "2vw" }}
                                />
                              </th>
                              <th onClick={() => GetScorecard("fantasyScore")}>
                                Fantasy Score
                                <FaSort
                                  style={{ height: "2vh", width: "2vw" }}
                                />
                              </th>
                              <th>Credits</th>
                              <th>In Line Up</th>
                              <th>Sub</th>
                              <th>Sub Played</th>
                              <th>is out</th>
                              <th>Action</th>
                            </tr>
                          </thead>

                          <tbody className="t-body">
                            {teams.scorecard?.map((data, index) => {
                              return (
                                <tr
                                  style={{
                                    borderBottom: "1px solid lightgray",
                                    height: "6vh",
                                  }}
                                >
                                  <td>{data.cricBuzzPlayerId}</td>
                                  <td
                                    onBlur={(e) =>
                                      editScorecard(
                                        thisTeamIndex,
                                        index,
                                        "name",
                                        e.target.innerText
                                      )
                                    }
                                    contentEditable
                                  >
                                    {data.name}
                                  </td>
                                  <td
                                    onBlur={(e) =>
                                      editScorecard(
                                        thisTeamIndex,
                                        index,
                                        "fours",
                                        e.target.innerText
                                      )
                                    }
                                    contentEditable
                                  >
                                    {data.fours}
                                  </td>
                                  <td
                                    onBlur={(e) =>
                                      editScorecard(
                                        thisTeamIndex,
                                        index,
                                        "sixes",
                                        e.target.innerText
                                      )
                                    }
                                    contentEditable
                                  >
                                    {data.sixes}
                                  </td>
                                  <td
                                    onBlur={(e) =>
                                      editScorecard(
                                        thisTeamIndex,
                                        index,
                                        "runs",
                                        e.target.innerText
                                      )
                                    }
                                    contentEditable
                                  >
                                    {data.runs}
                                  </td>
                                  <td
                                    onBlur={(e) =>
                                      editScorecard(
                                        thisTeamIndex,
                                        index,
                                        "ballsPlayed",
                                        e.target.innerText
                                      )
                                    }
                                    contentEditable
                                  >
                                    {data.ballsPlayed}
                                  </td>
                                  <td>{data.strikeRate}</td>
                                  <td
                                    onBlur={(e) =>
                                      editScorecard(
                                        thisTeamIndex,
                                        index,
                                        "wickets",
                                        e.target.innerText
                                      )
                                    }
                                    contentEditable
                                  >
                                    {data.wickets}
                                  </td>
                                  <td
                                    onBlur={(e) =>
                                      editScorecard(
                                        thisTeamIndex,
                                        index,
                                        "bowledsOrLbw",
                                        e.target.innerText
                                      )
                                    }
                                    contentEditable
                                  >
                                    {data.bowledsOrLbw}
                                  </td>
                                  <td
                                    onBlur={(e) =>
                                      editScorecard(
                                        thisTeamIndex,
                                        index,
                                        "maidens",
                                        e.target.innerText
                                      )
                                    }
                                    contentEditable
                                  >
                                    {data.maidens}
                                  </td>
                                  <td
                                    onBlur={(e) =>
                                      editScorecard(
                                        thisTeamIndex,
                                        index,
                                        "runsGiven",
                                        e.target.innerText
                                      )
                                    }
                                    contentEditable
                                  >
                                    {data.runsGiven}
                                  </td>
                                  <td
                                    onBlur={(e) =>
                                      editScorecard(
                                        thisTeamIndex,
                                        index,
                                        "oversBowled",
                                        e.target.innerText
                                      )
                                    }
                                    contentEditable
                                  >
                                    {data.oversBowled}
                                  </td>
                                  <td>{data.economy}</td>
                                  <td
                                    onBlur={(e) =>
                                      editScorecard(
                                        thisTeamIndex,
                                        index,
                                        "catches",
                                        e.target.innerText
                                      )
                                    }
                                    contentEditable
                                  >
                                    {data.catches}
                                  </td>
                                  <td
                                    onBlur={(e) =>
                                      editScorecard(
                                        thisTeamIndex,
                                        index,
                                        "stumpings",
                                        e.target.innerText
                                      )
                                    }
                                    contentEditable
                                  >
                                    {data.stumpings}
                                  </td>
                                  <td
                                    onBlur={(e) =>
                                      editScorecard(
                                        thisTeamIndex,
                                        index,
                                        "runOutsDirect",
                                        e.target.innerText
                                      )
                                    }
                                    contentEditable
                                  >
                                    {data.runOutsDirect}
                                  </td>
                                  <td
                                    onBlur={(e) =>
                                      editScorecard(
                                        thisTeamIndex,
                                        index,
                                        "runOuts",
                                        e.target.innerText
                                      )
                                    }
                                    contentEditable
                                  >
                                    {data.runOuts}
                                  </td>
                                  <td>{data.role}</td>
                                  <td>{data.fantasyScore}</td>
                                  <td>{data.credits}</td>
                                  <td>
                                    <input
                                      style={{
                                        height: "1em",
                                        width: "1em",
                                      }}
                                      type="checkbox"
                                      id="myCheckbox"
                                      onChange={(e) =>
                                        editScorecard(
                                          thisTeamIndex,
                                          index,
                                          "inLineup",
                                          e.target.checked
                                        )
                                      }
                                      checked={data.inLineup}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      style={{
                                        height: "1em",
                                        width: "1em",
                                      }}
                                      type="checkbox"
                                      id="myCheckbox"
                                      onChange={(e) =>
                                        editScorecard(
                                          thisTeamIndex,
                                          index,
                                          "sub",
                                          e.target.checked
                                        )
                                      }
                                      checked={data.sub}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      style={{
                                        height: "1em",
                                        width: "1em",
                                      }}
                                      type="checkbox"
                                      id="myCheckbox"
                                      onChange={(e) =>
                                        editScorecard(
                                          thisTeamIndex,
                                          index,
                                          "subPlayed",
                                          e.target.checked
                                        )
                                      }
                                      checked={data.subPlayed}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      style={{
                                        height: "1em",
                                        width: "1em",
                                      }}
                                      type="checkbox"
                                      id="myCheckbox"
                                      onChange={(e) =>
                                        editScorecard(
                                          thisTeamIndex,
                                          index,
                                          "isOut",
                                          e.target.checked
                                        )
                                      }
                                      checked={data.isOut}
                                    />
                                  </td>
                                  <td>
                                    <button
                                      onClick={() => {
                                        UpdateScorecard(index, thisTeamIndex);
                                      }}
                                      style={{
                                        border: "1px solid red",
                                        borderRadius: "2px",
                                        backgroundColor: "white",
                                        color: "red",
                                      }}
                                    >
                                      Save
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              );
            })}
        </div>
        <p id="error" style={{ color: "gray", marginTop: "2em" }}>
          {error}
        </p>
      </div>
    </div>
  );
};

export default Scorecard;
