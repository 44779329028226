import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { PREDICTION_API } from "../env";
import { BsFillCheckCircleFill } from "react-icons/bs";

const SettleContest = ({ show, onHide, displayData }) => {
  const [error, setError] = useState("");

  let ForceCalApi =
    PREDICTION_API +
    `/api/prediction-contests/${localStorage.getItem(
      "predictionContestId"
    )}/rewards/force-settle`;

  const ForceCalLeaderboard = async () => {
    const response = await fetch(ForceCalApi, {
      method: "PATCH",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 200 || response.status === 201) {
      onHide();
    } else {
      setError(result.message);
    }
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={onHide}
        style={{
          backgroundColor: "black-transperant",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        size="md"
        centered
      >
        {displayData ? (
          <div>
            <Modal.Body
              style={{
                height: "25vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "2em",
              }}
            >
              <BsFillCheckCircleFill
                style={{ height: "6vh", width: "8vw", color: "green" }}
              />
              <h2>Successfully Settled</h2>
            </Modal.Body>
          </div>
        ) : (
          <Modal.Body
            style={{
              height: "26vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "2em",
            }}
          >
            <h5>Please Force Caluculate Leaderboad</h5>
            <button onClick={ForceCalLeaderboard} id="matches-btn">
              Force Cal.
            </button>
            <p id="error">{error}</p>
          </Modal.Body>
        )}
      </Modal>
    </div>
  );
};

export default SettleContest;
