import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { RxCross1 } from "react-icons/rx";
import { useState } from "react";
import CreateRewards from "./CreateRewards";
import { SERVER_API } from "../env";
import { useLocation } from "react-router-dom";

const CreateContests2 = ({ modalShow, setModalShow }) => {
  const location = useLocation();
  const [matchId, setMatchId] = useState("");
  const [commission, setCommission] = useState("");
  const [entryFee, setEntryFee] = useState("");
  const [maxSlots, setMaxSlots] = useState("");
  const [maxEntriesPerUser, setMaxEntriesPerUser] = useState("");
  const [total, setTotal] = useState(0);
  const [symbol, setSymbol] = useState("");
  const [entryType, setEntryType] = useState("");
  const [allMatches, setAllMatches] = useState([]);
  const [error, setError] = useState("");
  const [modalShow2, setModalShow2] = useState(false);

  let UpcomingMatchesApi = SERVER_API + "/api/matches?status=upcoming";

  const CheckUpcomingMatches = async () => {
    const response = await fetch(UpcomingMatchesApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    setAllMatches(result);
    setMatchId(result[0]?._id);
  };

  useEffect(() => {
    CheckUpcomingMatches();
  }, []);

  return (
    <div>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body
          style={{
            height: "55vh",
          }}
        >
          <div
            style={{
              height: "50vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h4>Create Prediction Contests</h4>
              <RxCross1 onClick={setModalShow} color="black" />
            </div>
            <div
              style={{
                height: "35vh",
                display: "flex",
                justifyContent: "space-around",
                marginTop: "1em",
              }}
            >
              <div
                style={
                  location.pathname === "/PredictionContest"
                    ? {
                        height: "35vh",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }
                    : entryType === "paid" || entryType === "free"
                    ? {
                        height: "25vh",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }
                    : {
                        height: "15vh",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }
                }
              >
                {location.pathname === "/PredictionContest" ? (
                  <div>
                    <p id="username">Match Id</p>
                    <select
                      id="select"
                      onChange={(E) => {
                        setMatchId(E.target.value);
                        console.log("e val", E.target.value);
                      }}
                      value={matchId}
                    >
                      {allMatches?.map((data) => {
                        return (
                          <option key={data._id} value={data._id}>
                            {data.series} - {data.teamA} vs {data.teamB} -
                            {data.matchNumber}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                ) : null}

                <div id="div-2">
                  <p id="username">Symbol</p>
                  <input
                    onChange={(E) => setSymbol(E.target.value)}
                    id="fst-input"
                    type="text"
                    value={symbol.toUpperCase()}
                  />
                </div>

                <div id="div-2">
                  <p id="username">Entry Type</p>
                  <select
                    onChange={(E) => setEntryType(E.target.value)}
                    id="fst-input"
                    type="text"
                    value={entryType}
                  >
                    <option value="select">Select</option>
                    <option value="paid">Paid</option>
                    <option value="free">Free</option>
                  </select>
                </div>
                {entryType === "paid" ? (
                  <div id="div-2">
                    <p id="username">Entry Fee</p>
                    <input
                      onChange={(E) => setEntryFee(E.target.value)}
                      id="fst-input"
                      type="text"
                      value={entryFee}
                    />
                  </div>
                ) : null}

                {entryType == "free" ? (
                  <div id="div-2">
                    <p id="username">Total Rewards</p>
                    <input
                      onChange={(E) => setTotal(E.target.value)}
                      id="fst-input"
                      type="text"
                      value={total}
                    />
                  </div>
                ) : null}
              </div>

              <div
                style={
                  entryType === "paid"
                    ? {
                        height: "25vh",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }
                    : {
                        height: "15.5vh",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }
                }
              >
                <div id="div-2">
                  <p id="username">Commission</p>
                  <input
                    onChange={(E) => setCommission(E.target.value)}
                    id="fst-input"
                    type="number"
                    min="1"
                    value={commission}
                  />
                </div>
                {entryType === "paid" ? (
                  <div id="div-2">
                    <p id="username">Max Slots</p>
                    <input
                      onChange={(E) => setMaxSlots(E.target.value)}
                      id="fst-input"
                      type="number"
                      value={maxSlots}
                    />
                  </div>
                ) : null}

                <div id="div-2">
                  <p id="username">Max Entries Per User</p>
                  <input
                    onChange={(E) => setMaxEntriesPerUser(E.target.value)}
                    id="fst-input"
                    type="number"
                    value={maxEntriesPerUser}
                  />
                </div>
              </div>
            </div>
            <p id="error">{error}</p>

            <div>
              <button
                onClick={() => {
                  setModalShow2(true);
                  setModalShow(true);
                }}
                id="update-btn2"
              >
                Create Reward
              </button>
              <button onClick={setModalShow} id="cancel-btn2">
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <CreateRewards
        matchId={matchId}
        entrySymbol={symbol}
        entryFee={entryFee}
        entryType={entryType}
        commission={commission}
        total={total}
        maxSlots={maxSlots}
        maxEntriesPerUser={maxEntriesPerUser}
        modalShow={modalShow2}
        setModalShow={() => setModalShow2(false)}
      />
    </div>
  );
};

export default CreateContests2;
