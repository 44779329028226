import React from "react";
import SideNav from "../SideNav";
import TopNav from "../TopNav";
import "./OGCricket.css";
import CreatePrediction from "./CreatePrediction";
import { useState } from "react";
import UpcomingPredictions from "./UpcomingPredictions";
import LivePreadictions from "./LivePreadictions";
import CompletedPredictions from "./CompletedPredictions";
import { getAdmin } from "../getAdmin";

const OGCricket = () => {
  let adminDetails = {};
  (async () => {
    adminDetails = await getAdmin();
  })();

  const [open, setOpen] = useState(false);
  const [toggle, setToggle] = useState(2);

  return (
    <div className="Cricket-div">
      <SideNav />
      <div style={{ width: "87svw" }}>
        <TopNav />
        <div id="tabs-div">
          <div
            style={{
              width: "25vw",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <button
              id="upcoming-btn"
              onClick={() => setToggle(2)}
              style={
                toggle === 2 ? { border: "1px solid red", color: "red" } : null
              }
            >
              Upcoming
            </button>
            <button
              id="live-btn"
              onClick={() => setToggle(3)}
              style={
                toggle === 3 ? { border: "1px solid red", color: "red" } : null
              }
            >
              Live
            </button>
            <button
              id="completed-btn"
              onClick={() => setToggle(4)}
              style={
                toggle === 4 ? { border: "1px solid red", color: "red" } : null
              }
            >
              Completed
            </button>
          </div>
          <div>
            <button onClick={() => setOpen(true)} id="matches-btn">
              Create Instance
            </button>
          </div>
        </div>
        {toggle === 2 ? <UpcomingPredictions /> : null}
        {toggle === 3 ? <LivePreadictions /> : null}
        {toggle === 4 ? <CompletedPredictions /> : null}
      </div>
      <CreatePrediction show={open} onHide={() => setOpen(false)} />
    </div>
  );
};

export default OGCricket;
