import React, { useState } from "react";
import "./Series.css";
import Modal from "react-bootstrap/Modal";
import { RxCross1 } from "react-icons/rx";
import CreateSeriesAuto from "./CreateSeriesAuto";

const EnterCrickBuzzId = ({ modalShow, setModalShow }) => {
  const [cricBuzzSeriesId, setCricBuzzSeriesId] = useState(null);
  const [modalShow2, setModalShow2] = useState(false);
  const [displayPopup, setDisplayPopup] = useState(false);

  return (
    <div>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body
          style={{
            height: "25vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <h4>Create Series</h4>
            <RxCross1
              style={{ color: "black", cursor: "pointer" }}
              onClick={setModalShow}
            />
          </div>
          <div>
            <div id="div-1">
              <p id="username" value={cricBuzzSeriesId}>
                CrickBuzz Series Id
              </p>
              <input
                id="crickbuzz-input"
                type="number"
                onChange={(e) => {
                  setCricBuzzSeriesId(e.target.value);
                }}
              />
            </div>
          </div>
          <button
            onClick={() => {
              setModalShow(false);
              setDisplayPopup(true);
              setModalShow2(true);
            }}
            id="next-btn"
          >
            Next
          </button>
        </Modal.Body>
      </Modal>
      {displayPopup && (
        <CreateSeriesAuto
          cricBuzzSeriesId={cricBuzzSeriesId}
          modalShow={modalShow2}
          setModalShow={() => setModalShow2(false)}
        />
      )}
    </div>
  );
};

export default EnterCrickBuzzId;
