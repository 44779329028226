import React from "react";
import "./Analytics.css";
import SideNav from "../SideNav";
import TopNav from "../TopNav";
import { useNavigate } from "react-router-dom";
import { ANALYTICS_API, MANAGER } from "../env";
import { getAdmin } from "../getAdmin";

const Analytics = () => {
  const navigate = useNavigate();

  let adminDetails = {};
  (async () => {
    adminDetails = await getAdmin();
    if (!(adminDetails.role & MANAGER)) {
      navigate("/");
    }
  })();

  let SendFileApi = ANALYTICS_API + "/api/users/fbleads";
  let DownloadUserTransfersApi =
    ANALYTICS_API + "/api/users/old-users-difference";
  let DownloadUserPredictionsApi =
    ANALYTICS_API + "/api/users/unused-predictions";
  let DownloadFirebaseUsersApi = ANALYTICS_API + "/api/users/firebase-users";

  const PostFile = async () => {
    let file = document.querySelector("input[type=file]").files[0];
    let input = new FormData();
    input.append("fbleads", file);

    const response = await fetch(SendFileApi, {
      method: "POST",
      headers: {
        mode: "cors",
        token: localStorage.getItem("token"),
      },
      body: input,
    });

    if (response.status !== 200) {
      alert("Invalid File");
    }

    const blob = await response.blob();

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "refinedFbLeads.csv"; // Specify the desired file name with .csv extension
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
    return;
  };

  const DownloadUserTransfers = async () => {
    const response = await fetch(DownloadUserTransfersApi);

    const blob = await response.blob();

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "oldUsersDifference.csv"; // Specify the desired file name with .csv extension
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  const DownloadUserPredictions = async () => {
    const response = await fetch(DownloadUserPredictionsApi);
    const blob = await response.blob();

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "unusedPredictions.csv"; // Specify the desired file name with .csv extension
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  const DownloadFirebaseUsers = async () => {
    const response = await fetch(DownloadFirebaseUsersApi);
    const blob = await response.blob();

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "firebaseUsers.csv"; // Specify the desired file name with .csv extension
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  return (
    <div className="Analytics-div">
      <SideNav />
      <div>
        <TopNav />

        <div id="Analytics-inputs">
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "baseline",
                width: "30vw",
                marginBottom: "2em",
              }}
            >
              <h3>Leads</h3>
            </div>
            <div style={{ display: "flex" }}>
              <div>
                <p id="username">Upload file</p>
                <div id="file-button">
                  <input type="file" name="uploadfile" id="file-input" />
                </div>
              </div>
              <button onClick={PostFile} id="send-file-btn">
                Send
              </button>
            </div>
          </div>
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "baseline",
                width: "30vw",
                marginTop: "2em",
                marginBottom: "2em",
              }}
            >
              <h3>Old User Transfer</h3>
            </div>
            <div
              style={{ display: "flex", alignItems: "baseline", width: "30vw" }}
            >
              <button onClick={DownloadUserTransfers} id="download-file-btn">
                Download File
              </button>
            </div>
          </div>
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "baseline",
                width: "30vw",
                marginTop: "2em",
                marginBottom: "2em",
              }}
            >
              <h3>Unused Predictions</h3>
            </div>
            <div
              style={{ display: "flex", alignItems: "baseline", width: "30vw" }}
            >
              <button onClick={DownloadUserPredictions} id="download-file-btn">
                Download File
              </button>
            </div>
          </div>
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "baseline",
                width: "30vw",
                marginTop: "2em",
                marginBottom: "2em",
              }}
            >
              <h3>Firebase Users</h3>
            </div>
            <div
              style={{ display: "flex", alignItems: "baseline", width: "30vw" }}
            >
              <button onClick={DownloadFirebaseUsers} id="download-file-btn">
                Download File
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
