import React from "react";
import SideNav from "./SideNav";
import TopNav from "./TopNav";
import "./Wallet.css";

const Wallet = () => {
  return (
    <div className="Wallet-div">
      <SideNav />
      <div style={{ width: "87svw" }}>
        <TopNav />
      </div>
    </div>
  );
};

export default Wallet;
