import React from "react";
import SideNav from "../SideNav";
import TopNav from "../TopNav";
import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { BiEdit } from "react-icons/bi";
import { AiOutlineDelete } from "react-icons/ai";
import CreateReadMatches from "./CreateReadMatches";
import EditReadMatches from "./EditReadMatches";
import DeleteMatches from "../Matches/DeleteMatches";
import { SERVER_API } from "../env";
import { getAdmin } from "../getAdmin";

const ReadMatches = () => {
  let adminDetails = {};
  (async () => {
    adminDetails = await getAdmin();
  })();

  const navigate = useNavigate();
  const location = useLocation();
  const [matches, setMatches] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);
  const [open, setOpen] = useState(false);
  const [individualMatch, setIndividualMatch] = useState({});

  let getMatchesApi =
    SERVER_API + `/api/series/${localStorage.getItem("seriesId")}/matches`;

  const ReadMatches = async () => {
    const response = await fetch(getMatchesApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    setMatches(result);
  };
  useEffect(() => {
    ReadMatches();
  }, [open, modalShow, modalShow2, individualMatch]);

  const handleNavigate = async (id) => {
    localStorage.setItem("matchId", id);
    navigate("/SelectMatch");
  };
  const handleEdit = (id) => {
    localStorage.setItem("matchId", id);
    setModalShow2(true);
  };
  const handleDelete = (id) => {
    localStorage.setItem("matchId", id);
    setOpen(true);
  };
  return (
    <div className="Series-div">
      <SideNav />
      <div style={{ width: "87svw" }}>
        <TopNav />

        <div id="tabs-div">
          <div
            style={{
              width: "17vw",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <button onClick={() => navigate("/ReadTeams")} id="match-btn">
              Teams
            </button>
            <button
              id="verify-btn"
              style={
                location.pathname === "/ReadMatches"
                  ? { border: "1px solid red", color: "red" }
                  : null
              }
            >
              Matches
            </button>
          </div>

          <div>
            <button onClick={() => setModalShow(true)} id="matches-btn">
              Create Matches
            </button>
          </div>
        </div>
        <div style={{ overflowY: "scroll", height: "80vh" }}>
          <table
            style={{
              width: " 85vw",
              marginLeft: "1em",
            }}
          >
            <thead id="thead" style={{ position: "sticky" }}>
              <tr>
                <th>Match No</th>
                <th>Start Date</th>
                <th>Team A</th>
                <th>Team B</th>
                <th>TeamA Score</th>
                <th>TeamB Score</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {matches?.map((data) => {
                return (
                  <tr
                    id="tRow"
                    key={data._id}
                    style={{
                      borderBottom: "1px solid lightgray",
                      height: "6vh",
                    }}
                  >
                    <td onClick={() => handleNavigate(data._id)}>
                      {data.matchNumber}
                    </td>
                    <td onClick={() => handleNavigate(data._id)}>
                      {getDateFromTimeStamp(data.startDate)}
                    </td>
                    <td onClick={() => handleNavigate(data._id)}>
                      {data.teamA}
                    </td>
                    <td onClick={() => handleNavigate(data._id)}>
                      {data.teamB}
                    </td>
                    <td onClick={() => handleNavigate(data._id)}>
                      Score:
                      {data.teamAScore.score.length === 0
                        ? "0/0"
                        : data.teamAScore.score}
                      <br />
                      Overs:
                      {data.teamAScore.overs.length === 0
                        ? "0/0"
                        : data.teamAScore.overs}
                    </td>
                    <td onClick={() => handleNavigate(data._id)}>
                      Score:
                      {data.teamBScore.score.length === 0
                        ? "0/0"
                        : data.teamBScore.score}
                      <br />
                      Overs:
                      {data.teamBScore.overs.length === 0
                        ? "0/0"
                        : data.teamBScore.overs}
                    </td>
                    <td id="Action-div">
                      <div id="Action-icons">
                        <BiEdit
                          onClick={() => {
                            handleEdit(data._id);
                            setIndividualMatch(data);
                          }}
                        />
                        <AiOutlineDelete
                          onClick={() => handleDelete(data._id)}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <CreateReadMatches
        modalShow={modalShow}
        setModalShow={() => setModalShow(false)}
      />
      <EditReadMatches
        matches={individualMatch}
        modalShow={modalShow2}
        setModalShow={() => setModalShow2(false)}
      />
      <DeleteMatches show={open} onHide={() => setOpen(false)} />
    </div>
  );
};

function getDateFromTimeStamp(timeStamp) {
  return new Date(timeStamp * 1000).toLocaleDateString("en-IN", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
}
export default ReadMatches;
