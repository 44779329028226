import React from "react";
import SideNav from "../SideNav";
import TopNav from "../TopNav";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { useState, useEffect } from "react";
import Published from "../Matches/Published";
import Cancel from "../Matches/Cancel";
import Verify from "../Matches/Verify";
import { SERVER_API } from "../env";
import CalRank2 from "./CalRank2";
import { getAdmin } from "../getAdmin";
import { PREDICTION_API } from "../env";

const VerifyContest = () => {
  let adminDetails = {};
  (async () => {
    adminDetails = await getAdmin();
  })();

  const navigate = useNavigate();
  const location = useLocation();
  const [verified, setVerified] = useState([]);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [error, setError] = useState("");
  const [error2, setError2] = useState("");
  const [error3, setError3] = useState("");
  const [error4, setError4] = useState("");

  let SelectPredictionContestApi =
    PREDICTION_API +
    `/api/prediction-contests/${localStorage.getItem("predictionContestId")}`;
  let PublishPredictionContestApi =
    PREDICTION_API +
    `/api/prediction-contests/${localStorage.getItem(
      "predictionContestId"
    )}/publish`;
  let CancelContestApi =
    SERVER_API + `/api/contests/${localStorage.getItem("contestId")}/cancel`;
  let VerifyContestApi =
    PREDICTION_API +
    `/api/prediction-contests/${localStorage.getItem(
      "predictionContestId"
    )}/verify`;
  let CalRankApi =
    SERVER_API +
    `/api/contests/${localStorage.getItem("contestId")}/rank/calculate`;
  let CalRewardsApi =
    SERVER_API +
    `/api/contests/${localStorage.getItem("contestId")}/rewards/calculate`;

  const CalRank = async () => {
    const response = await fetch(CalRankApi, {
      method: "PATCH",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      setOpen2(true);
    }
    if (response.status === 400) {
      setError4(result.message);
    }
  };

  const CheckContests = async () => {
    const response = await fetch(SelectPredictionContestApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    setVerified(result);
  };

  const PublishContest = async () => {
    const response = await fetch(PublishPredictionContestApi, {
      method: "PATCH",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      setOpen(true);
    }
    if (response.status === 400) {
      setError2(result.message);
    }
  };

  const CancelContest = async () => {
    const response = await fetch(CancelContestApi, {
      method: "PATCH",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      setOpen3(true);
    }
    if (response.ststus === 400) {
      setError2(result.message);
    }
  };

  const VerifyContest = async () => {
    const response = await fetch(VerifyContestApi, {
      method: "PATCH",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      setOpen4(true);
    }
    if (response.ststus === 400) {
      setError3(result.message);
    }
  };
  const CalRewards = async () => {
    const response = await fetch(CalRewardsApi, {
      method: "PATCH",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      setOpen(true);
    }
    if (response.status === 400) {
      setError(result.message);
    }
    console.log(result);
  };

  useEffect(() => {
    CheckContests();
  }, []);
  return (
    <div className="selectContest-div">
      <SideNav />
      <div style={{ width: "87svw" }}>
        <TopNav />

        <div id="main-tabs">
          <button onClick={PublishContest} id="publish-btn">
            Publish
          </button>
          <button onClick={CalRank} id="close-btn">
            Cal.Rank
          </button>
          <button onClick={CancelContest} id="Cancel-btn">
            Cancel
          </button>
        </div>

        <div id="tabs-div">
          <div
            style={{
              width: "17vw",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <button
              onClick={() => navigate("/SelectPredictionContest")}
              id="match-btn"
            >
              Contest
            </button>
            <button
              id="verify-btn"
              style={
                location.pathname === "/VerifyContest"
                  ? { border: "1px solid red", color: "red" }
                  : null
              }
            >
              Verified by
            </button>
          </div>

          <div
            style={{
              width: "21vw",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <button onClick={CalRewards} id="matches-btn">
              Cal.Rewards
            </button>
            <button onClick={VerifyContest} id="matches-btn">
              Verify
            </button>
          </div>
        </div>

        <table
          style={{
            width: " 85vw",
            marginLeft: "1em",
          }}
        >
          <thead id="thead">
            <tr>
              <th>Email</th>
              <th>Hash</th>
            </tr>
          </thead>
          <tbody>
            {verified?.verifiedBy?.map((data) => {
              return (
                <tr
                  style={{
                    borderBottom: "1px solid lightgray",
                    height: "6vh",
                  }}
                >
                  <td>{data.email}</td>
                  <td>{data.hash}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div id="bottom-btn">
          <button id="show-all-btn" onClick={() => navigate("/Leaderboard")}>
            Go To Leaderboard <HiOutlineArrowNarrowRight color="white" />
          </button>
        </div>
        <p style={{ color: "gray" }}>{error}</p>
        <p style={{ color: "gray" }}>{error2}</p>
        <p style={{ color: "gray" }}>{error3}</p>
        <p style={{ color: "gray" }}>{error4}</p>
      </div>
      <Published show={open} onHide={() => setOpen(false)} />
      <CalRank2 show={open2} onHide={() => setOpen2(false)} />
      <Cancel show={open3} onHide={() => setOpen3(false)} />
      <Verify show={open4} onHide={() => setOpen4(false)} />
    </div>
  );
};

export default VerifyContest;
