import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { LIVE_PREDICTION_API } from "../env";

const DeleteLivePredictionContest = ({ show, onHide }) => {
  const [error, setError] = useState("");
  let DeleteLPCApi =
    LIVE_PREDICTION_API +
    `/api/live-predictions/${localStorage.getItem("livePredictionContestId")}`;

  const DeleteLPC = async () => {
    const response = await fetch(DeleteLPCApi, {
      method: "DELETE",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application",
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      onHide();
    } else {
      setError(result.message);
    }
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={onHide}
        style={{
          backgroundColor: "black-transperant",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        size="md"
        centered
      >
        <Modal.Body
          style={{
            height: "30vh",
          }}
        >
          <div
            style={{
              height: "25vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h2>Delete</h2>
            <p>Are you sure you want to delete</p>
            <div id="buttons-div">
              <button onClick={onHide} id="cancel-btn3">
                Cancel
              </button>
              <button onClick={DeleteLPC} id="delete-btn">
                Delete
              </button>
            </div>
            <p id="error">{error}</p>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default DeleteLivePredictionContest;
