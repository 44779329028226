import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import "./ForgotPass.css";
import SentLink from "./SentLink";
import { SERVER_API } from "./env";

const ForgotPass = ({ show, onHide }) => {
  const [email, setEmail] = useState("");
  const [open, setOpen] = useState(false);

  let ForgotPassApi = SERVER_API + "/api/admins/password/forgot";

  const handleClick = async (e) => {
    e.preventDefault();
    let input = { email };
    const response = await fetch(ForgotPassApi, {
      method: "PATCH",
      headers: {
        mode: "cors",
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(input),
    });
    let result = await response.json();
    if (response.status === 200) {
      setOpen(true);
    }
    console.log(result);
  };
  return (
    <div>
      <Modal
        show={show}
        onHide={onHide}
        style={{ backgroundColor: "black-transperant" }}
        size="md"
        centered
      >
        <Modal.Body
          style={{
            height: "50vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div className="text-div">
            <h3 id="forgot-password">Forgot your password</h3>
            <Form.Label style={{ width: "20vw", marginTop: "1em" }}>
              Please enter email address you're using for your account bellow
              and we will send you a reset password link
            </Form.Label>
          </div>
          <div className="fogotPass-div">
            <div id="div-1">
              <p id="email">Enter your email</p>
              <input
                onChange={(e) => setEmail(e.target.value)}
                id="fst-input"
                type="text"
                value={email}
              />
            </div>
            <button onClick={handleClick} id="button">
              Send reset link
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <SentLink show={open} onHide={() => setOpen(false)} />
    </div>
  );
};

export default ForgotPass;
