import React from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import { SERVER_API } from "../env";

const ChangePass = ({ show, onHide }) => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState("");

  let ChangePasswordApi = SERVER_API + "/api/admins/password";

  const ChangePassword = async (e) => {
    e.preventDefault();
    let input = { oldPassword, newPassword };
    const response = await fetch(ChangePasswordApi, {
      method: "PATCH",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(input),
    });
    let result = await response.json();
    if (response.status === 200) {
      onHide();
    } else {
      setError(result.message);
    }
  };
  return (
    <div>
      <Modal
        show={show}
        onHide={onHide}
        style={{
          backgroundColor: "black-transperant",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        size="md"
        centered
      >
        <Modal.Body
          style={{
            height: "50vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div>
            <div style={{ display: "flex" }}>
              <h4 style={{ color: "red" }}>Change your password</h4>
            </div>
            <div className="otp-inputs">
              <div id="div-1">
                <p id="otp-email">Enter your email</p>
                <input id="fst-input" type="text" />
              </div>
              <div id="div-2">
                <p id="old-password">Enter old password</p>
                <input
                  onChange={(E) => setOldPassword(E.target.value)}
                  id="sec-input"
                  type="text"
                  value={oldPassword}
                />
              </div>
              <div id="div-3">
                <p id="new-password">Enter new password</p>
                <input
                  onChange={(E) => setNewPassword(E.target.value)}
                  id="sec-input"
                  type="text"
                  value={newPassword}
                />
              </div>
            </div>
            <p id="error">{error}</p>
            <button onClick={ChangePassword} id="button">
              Submit
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ChangePass;
