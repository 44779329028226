import React from "react";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import "./PredictQuestions.css";
import { useNavigate } from "react-router-dom";

const SelectPredictOption = ({ show, onHide }) => {
  const navigate = useNavigate();
  return (
    <div>
      <Modal
        show={show}
        onHide={onHide}
        style={{
          backgroundColor: "black-transperant",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        size="md"
        centered
      >
        <Modal.Body
          style={{
            height: "25vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              height: "15vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <button
              onClick={() => navigate("/PredictQuestions")}
              id="option-btn"
            >
              Auto
            </button>
            <button
              onClick={() => navigate("/PredictQuestionsManual")}
              id="option-btn"
            >
              Manual
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SelectPredictOption;
