import React from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import "./SentLink.css";

const SentLink = ({ show, onHide }) => {
  return (
    <div>
      <Modal
        show={show}
        onHide={onHide}
        style={{ backgroundColor: "black-transperant" }}
        size="md"
        centered
      >
        <Modal.Body
          style={{
            height: "50vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h2 id="reset-heading">Sent reset link</h2>
          <Form.Label style={{ marginTop: "1em" }}>
            Please check your email
          </Form.Label>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SentLink;
