import React, { useState, useEffect } from "react";
import { FANTASY_API, MANAGER } from "../env";
import SideNav from "../SideNav";
import TopNav from "../TopNav";
import CalRank2 from "./CalRank2";
import Accordion from "react-bootstrap/Accordion";
import { getAdmin } from "../getAdmin";

const FantasyLeaderboard = () => {
  const [toggle, setToggle] = useState(1);
  const [leaderboard, setLeaderboard] = useState([]);
  const [flexibleRewards, setFlexibleRewards] = useState([]);
  const [fixedRewards, setFixedRewards] = useState([]);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const [flexible, setFlexible] = useState(false);
  const [fixed, setFixed] = useState(false);
  const [adminDisplay, setAdminDisplay] = useState(false);

  let adminDetails = {};
  (async () => {
    adminDetails = await getAdmin();
    if (adminDetails.role & MANAGER) {
      setAdminDisplay(true);
    } else {
      setAdminDisplay(false);
    }
  })();

  // const [id, setId] = useState("");
  let contestId = window.location.pathname.split("/");
  let id = contestId.pop();
  // setId(contestId);

  useEffect(() => {
    GetLeaderboard(id);
  }, []);

  let RankCalApi = FANTASY_API + `/api/contests/${id}/rank/calculate`;
  let GetFlexibleRewardsApi =
    FANTASY_API + `/api/contests/${id}/rewards/breakup?type=flexible`;
  let GetFixedRewardsApi =
    FANTASY_API + `/api/contests/${id}/rewards/breakup?type=fixed`;

  const GetLeaderboard = async (id) => {
    const response = await fetch(
      FANTASY_API + `/api/contests/${id}/leaderboard`,
      {
        method: "GET",
        headers: {
          mode: "cors",
          Accept: "application/json",
          token: localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      setLeaderboard(result);
    } else {
      setError(result.message);
    }
  };

  const RankCal = async () => {
    const response = await fetch(RankCalApi, {
      method: "PATCH",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      setOpen(true);
    } else {
      setError(result.message);
    }
  };

  const GetFlexibleRewardsBreakup = async () => {
    const response = await fetch(GetFlexibleRewardsApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    setFlexibleRewards(result);
    setFlexible(true);
    setFixed(false);
  };

  const GetFixedRewardBreakup = async () => {
    const response = await fetch(GetFixedRewardsApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    setFixedRewards(result);
    setFixed(true);
    setFlexible(false);
  };

  function OptionSeclection() {
    var selectedOption = document.getElementById("verify-btn").value;
    switch (selectedOption) {
      case "flexible":
        GetFlexibleRewardsBreakup();
        break;

      case "fixed":
        GetFixedRewardBreakup();
        break;
    }
  }

  return (
    <div style={{ display: "flex" }}>
      <SideNav />
      <div>
        <TopNav />
        <div id="tabs-div">
          <div
            style={{
              width: "23vw",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <button
              onClick={() => setToggle(1)}
              style={
                toggle === 1
                  ? { border: "1px solid red", color: "red", width: "11vw" }
                  : { width: "11vw" }
              }
              id="match-btn"
            >
              Leaderboard
            </button>
            <select
              onClick={() => setToggle(2)}
              style={
                toggle === 2
                  ? { border: "1px solid red", color: "red", width: "11vw" }
                  : { width: "11vw" }
              }
              id="verify-btn"
              onChange={() => OptionSeclection()}
            >
              <option value="">Reward Breakup</option>
              <option value="flexible">Flexible</option>
              <option value="fixed">Fixed</option>
            </select>
          </div>
          <div>
            <button onClick={RankCal} id="matches-btn">
              Rank Cal.
            </button>
          </div>
        </div>

        {toggle === 1 ? (
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "baseline",
                marginLeft: "1em",
              }}
            >
              <p>No of Bots : {leaderboard.noOfBots}</p>
              <p>Total Spots : {leaderboard.totalSpots}</p>
            </div>
            <div style={{ height: "70vh", overflowY: "scroll" }}>
              <table id="admins-table">
                <thead id="thead" style={{ position: "sticky" }}>
                  <tr>
                    <th>Username</th>
                    <th>Score</th>
                    <th>Rank</th>
                  </tr>
                </thead>
                <tbody>
                  {leaderboard.usersRank?.map((rewards) => {
                    return (
                      <tr
                        style={{
                          borderBottom: "1px solid lightgray",
                          height: "6vh",
                          backgroundColor:
                            rewards.isBot && adminDisplay
                              ? "rgb(230, 193, 186)"
                              : "",
                        }}
                      >
                        <td>{rewards.userName}</td>
                        <td>{rewards.score}</td>
                        <td>{rewards.rank}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        ) : null}

        {toggle === 2 ? (
          <div id="predictions-main-div">
            {flexible
              ? flexibleRewards.map((symbol) => {
                  return (
                    <Accordion>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          Symbol : {symbol.symbol}, Total :{" "}
                          {roundToNDecimal(symbol.total) || 0}
                        </Accordion.Header>
                        <Accordion.Body>
                          <table
                            style={{
                              width: " 80vw",
                              marginLeft: "1em",
                            }}
                          >
                            <thead id="thead">
                              <tr>
                                <th>Rank</th>
                                <th>Amount</th>
                                <th>Count</th>
                              </tr>
                            </thead>
                            <tbody>
                              {symbol.distribution?.map(
                                (distribution, index) => {
                                  return (
                                    <tr
                                      key={distribution.amount}
                                      style={{
                                        borderBottom: "1px solid lightgray",
                                        height: "6vh",
                                      }}
                                    >
                                      <td>{index + 1}</td>
                                      <td>
                                        {roundToNDecimal(distribution.amount) ||
                                          0}
                                      </td>
                                      <td>
                                        {roundToNDecimal(distribution.count) ||
                                          0}
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </table>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  );
                })
              : null}

            {fixed
              ? fixedRewards.map((symbol) => {
                  return (
                    <Accordion>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          Symbol : {symbol.symbol}, Total :{" "}
                          {roundToNDecimal(symbol.total) || 0}
                        </Accordion.Header>
                        <Accordion.Body>
                          <table
                            style={{
                              width: " 80vw",
                              marginLeft: "1em",
                            }}
                          >
                            <thead id="thead">
                              <tr>
                                <th>Rank</th>
                                <th>Amount</th>
                                <th>Count</th>
                              </tr>
                            </thead>
                            <tbody>
                              {symbol.distribution?.map(
                                (distribution, index) => {
                                  return (
                                    <tr
                                      key={distribution.amount}
                                      style={{
                                        borderBottom: "1px solid lightgray",
                                        height: "6vh",
                                      }}
                                    >
                                      <td>{index + 1}</td>
                                      <td>
                                        {roundToNDecimal(distribution.amount) ||
                                          0}
                                      </td>
                                      <td>
                                        {roundToNDecimal(distribution.count) ||
                                          0}
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </table>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  );
                })
              : null}
          </div>
        ) : null}

        <p style={{ color: "gray" }}>{error}</p>
      </div>
      <CalRank2 show={open} onHide={() => setOpen(false)} />
    </div>
  );
};

function roundToNDecimal(number, decimal = 4) {
  return Math.round(number * Math.pow(10, decimal)) / Math.pow(10, decimal);
}

export default FantasyLeaderboard;

// const GetLoserRewards = async () => {
//   const response = await fetch(SelectFantasyDetailsApi, {
//     method: "GET",
//     headers: {
//       mode: "cors",
//       Accept: "application/json",
//       token: localStorage.getItem("token"),
//       "Content-Type": "application/json",
//     },
//   });
//   let result = await response.json();
//   setLoserRewards(result);
// };

/* <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "baseline",
                marginLeft: ".5em",
              }}
            >
              <h6 style={{ fontWeight: "bold" }}>Loser Rewards : </h6>
              <p>Symbol : {loserRewards.loserReward?.symbol}</p>
              <p>Rewards : {loserRewards.loserReward?.looserRewardPerPerson}</p>
              <p>
                Contest Token In USDT :{" "}
                {loserRewards.loserReward?.contestTokenPriceInUSD}
              </p>
    </div> */
