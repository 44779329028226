import React, { useState } from "react";
import "./SideNav.css";
import logo from "../Assets/cc-logo.png";
import { RxDashboard } from "react-icons/rx";
import { RiAdminLine } from "react-icons/ri";
import { FiUsers } from "react-icons/fi";
import { PiUsersThreeDuotone } from "react-icons/pi";
import { IoIosAddCircleOutline } from "react-icons/io";
import { PiMicrosoftTeamsLogo } from "react-icons/pi";
import { BiCricketBall } from "react-icons/bi";
import { LuGamepad2 } from "react-icons/lu";
import { RiSwapBoxLine } from "react-icons/ri";
import { PiHandCoinsFill } from "react-icons/pi";
import { MdSportsCricket } from "react-icons/md";
import { TbReportAnalytics } from "react-icons/tb";
import { FaCoins } from "react-icons/fa";
import { LiaRobotSolid } from "react-icons/lia";
import { BiMobile } from "react-icons/bi";
import { BsArrowRepeat } from "react-icons/bs";
import { useNavigate, useLocation } from "react-router-dom";
import { MANAGER } from "./env";
import { getAdmin } from "./getAdmin";
import { RiFolderTransferLine } from "react-icons/ri";
import Dropdown from "react-bootstrap/Dropdown";
import { IoMdArrowDropdown } from "react-icons/io";
import { BiSolidOffer } from "react-icons/bi";
import { TbTournament } from "react-icons/tb";
import { RiCoupon3Line } from "react-icons/ri";

const SideNav = () => {
  const [adminDisplay, setAdminDisplay] = useState(false);

  let adminDetails = {};
  (async () => {
    adminDetails = await getAdmin();
    if (adminDetails.role & MANAGER) {
      setAdminDisplay(true);
    } else {
      setAdminDisplay(false);
    }
  })();

  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="sideNav">
      <img className="cc-logo" src={logo} alt="cc-logo" />
      <div className="nav-list">
        <div id="icons-div">
          <RxDashboard color={location.pathname === "/" ? "red" : "white"} />
          <RiAdminLine
            color={location.pathname === "/Admin" ? "red" : "white"}
          />
          <FiUsers color={location.pathname === "/Users" ? "red" : "white"} />
          <PiUsersThreeDuotone
            color={location.pathname === "/Players" ? "red" : "white"}
          />
          <BiSolidOffer
            color={location.pathname === "/Offers" ? "red" : "white"}
          />
          <IoIosAddCircleOutline
            color={location.pathname === "/Series" ? "red" : "white"}
          />
          <PiMicrosoftTeamsLogo
            color={location.pathname === "/Teams" ? "red" : "white"}
          />
          <BiCricketBall
            color={
              location.pathname === "/Matches" ||
              location.pathname === "/UpcomingMatches" ||
              location.pathname === "/LiveMatches" ||
              location.pathname === "/CompletedMatches"
                ? "red"
                : "white"
            }
          />
          <LuGamepad2
            color={
              location.pathname === "/PredictionContest" ||
              location.pathname === "/LivePredictionContest" ||
              location.pathname === "/FantasyContest"
                ? "red"
                : "white"
            }
          />
          <TbTournament
            color={
              location.pathname === "/Tournaments" ||
              location.pathname === "/SelectTournament" ||
              location.pathname === "/TournamentsLeaderboard"
                ? "red"
                : "white"
            }
          />
          <PiHandCoinsFill
            color={
              location.pathname === "/SeriesStaking" ||
              location.pathname === "/UpcomingSeries" ||
              location.pathname === "/LiveSeries" ||
              location.pathname === "/CompletedSeries"
                ? "red"
                : "white"
            }
            style={{ display: adminDisplay ? "block" : "none" }}
          />
          <MdSportsCricket
            color={
              location.pathname === "/OGCricket" ||
              location.pathname === "/Sessions"
                ? "red"
                : "white"
            }
          />
          <TbReportAnalytics
            color={location.pathname === "/Analytics" ? "red" : "white"}
            style={{ display: adminDisplay ? "block" : "none" }}
          />
          <FaCoins
            color={location.pathname === "/TokenDistribution" ? "red" : "white"}
            style={{ display: adminDisplay ? "block" : "none" }}
          />
          <BiMobile
            color={location.pathname === "/IphoneLeaderboard" ? "red" : "white"}
            style={{ display: adminDisplay ? "block" : "none" }}
          />
          <LiaRobotSolid
            color={location.pathname === "/Bots" ? "red" : "white"}
            style={{ display: adminDisplay ? "block" : "none" }}
          />
          <RiSwapBoxLine
            color={location.pathname === "/Swapped" ? "red" : "white"}
            style={{ display: adminDisplay ? "block" : "none" }}
          />
          <BsArrowRepeat
            color={location.pathname === "/CronJobs" ? "red" : "white"}
            style={{ display: adminDisplay ? "block" : "none" }}
          />
          <RiFolderTransferLine
            color={location.pathname === "/Transactions" ? " red " : "white"}
            style={{ display: adminDisplay ? "block" : "none" }}
          />
          <RiCoupon3Line
            color={location.pathname === "/GenerateCode" ? " red " : "white"}
            style={{ display: adminDisplay ? "block" : "none" }}
          />
        </div>
        <div id="nav-options">
          <p
            onClick={() => navigate("/")}
            style={location.pathname === "/" ? { color: "red" } : null}
          >
            Dashboard
          </p>
          <p
            onClick={() => navigate("/Admin")}
            style={location.pathname === "/Admin" ? { color: "red" } : null}
          >
            Admin
          </p>

          <p
            onClick={() => navigate("/Users")}
            style={location.pathname === "/Users" ? { color: "red" } : null}
          >
            Users
          </p>
          <p
            onClick={() => navigate("/Players")}
            style={location.pathname === "/Players" ? { color: "red" } : null}
          >
            Players
          </p>
          <p
            style={location.pathname === "/Offers" ? { color: "red" } : null}
            onClick={() => navigate("/Offers")}
          >
            Offers
          </p>
          <p
            onClick={() => navigate("/Series")}
            style={location.pathname === "/Series" ? { color: "red" } : null}
          >
            Series
          </p>
          <p
            onClick={() => navigate("/Teams")}
            style={location.pathname === "/Teams" ? { color: "red" } : null}
          >
            Teams
          </p>
          <p
            onClick={() => navigate("/LiveMatches")}
            style={
              location.pathname === "/Matches" ||
              location.pathname === "/UpcomingMatches" ||
              location.pathname === "/LiveMatches" ||
              location.pathname === "/CompletedMatches"
                ? { color: "red" }
                : null
            }
          >
            Matches
          </p>
          <p>
            <Dropdown>
              <Dropdown.Toggle
                style={
                  location.pathname === "/PredictionContest" ||
                  location.pathname === "/LivePredictionContest" ||
                  location.pathname === "/FantasyContest"
                    ? { color: "red" }
                    : null
                }
              >
                Contest
                <IoMdArrowDropdown />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <p
                  style={
                    location.pathname === "/PredictionContest"
                      ? { color: "red" }
                      : null
                  }
                  onClick={() => navigate("/PredictionContest")}
                >
                  Predictions
                </p>
                <p
                  style={
                    location.pathname === "/LivePredictionContest"
                      ? { color: "red" }
                      : null
                  }
                  onClick={() => navigate("/LivePredictionContest")}
                >
                  Sessions
                </p>
                <p
                  style={
                    location.pathname === "/FantasyContest"
                      ? { color: "red" }
                      : null
                  }
                  onClick={() => navigate("/FantasyContest")}
                >
                  Fantasy
                </p>
              </Dropdown.Menu>
            </Dropdown>
          </p>
          <p
            style={
              location.pathname === "/Tournaments" ||
              location.pathname === "/SelectTournament" ||
              location.pathname === "/TournamentsLeaderboard"
                ? { color: "red" }
                : null
            }
            onClick={() => navigate("/Tournaments")}
          >
            Tournaments
          </p>

          <p
            onClick={() => navigate("/SeriesStaking")}
            style={{
              display: adminDisplay ? adminDisplay : "none",
              color:
                location.pathname === "/SeriesStaking" ||
                location.pathname === "/UpcomingSeries" ||
                location.pathname === "/LiveSeries" ||
                location.pathname === "/CompletedSeries"
                  ? "red"
                  : null,
            }}
          >
            Series Staking
          </p>
          <p
            onClick={() => navigate("/OGCricket")}
            style={
              location.pathname === "/OGCricket" ||
              location.pathname === "/Sessions"
                ? {
                    color: "red",
                  }
                : null
            }
          >
            OG Cricket
          </p>
          <p
            onClick={() => navigate("/Analytics")}
            style={{
              display: adminDisplay ? adminDisplay : "none",
              color: location.pathname === "/Analytics" ? "red" : "white",
            }}
          >
            Analytics
          </p>
          <p
            onClick={() => navigate("/TokenDistribution")}
            style={{
              display: adminDisplay ? adminDisplay : "none",
              color:
                location.pathname === "/TokenDistribution" ? "red" : "white",
            }}
          >
            Token Distribution
          </p>
          <p
            onClick={() => navigate("/IphoneLeaderboard")}
            style={{
              display: adminDisplay ? adminDisplay : "none",
              color:
                location.pathname === "/IphoneLeaderboard" ? "red" : "white",
            }}
          >
            iPhone Leaderboard
          </p>
          <p
            onClick={() => navigate("/Bots")}
            color={location.pathname === "/Bots" ? "red" : "white"}
            style={{
              display: adminDisplay ? adminDisplay : "none",
              color: location.pathname === "/Bots" ? "red" : "white",
            }}
          >
            Bots
          </p>
          <p
            onClick={() => navigate("/Swapped")}
            style={{
              display: adminDisplay ? adminDisplay : "none",
              color: location.pathname === "/Swapped" ? "red" : "white",
            }}
          >
            Swaped
          </p>
          <p
            onClick={() => navigate("/CronJobs")}
            style={{
              display: adminDisplay ? adminDisplay : "none",
              color: location.pathname === "/CronJobs" ? "red" : "white",
            }}
          >
            Cron Jobs
          </p>
          <p
            onClick={() => navigate("/Transactions")}
            style={{
              display: adminDisplay ? adminDisplay : "none",
              color: location.pathname === "/Transactions" ? "red" : "white",
            }}
          >
            Transactions
          </p>
          <p
            onClick={() => navigate("/GenerateCode")}
            style={{
              display: adminDisplay ? adminDisplay : "none",
              color: location.pathname === "/GenerateCode" ? "red" : "white",
            }}
          >
            Generate Code
          </p>
        </div>
      </div>
    </div>
  );
};

export default SideNav;
