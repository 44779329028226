import React from "react";
import { PREDICTION_API } from "../env";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";

const DeletePredictionContest = ({ show, onHide, id }) => {
  const [error, setError] = useState("");

  let DelContestApi =
    PREDICTION_API +
    `/api/prediction-contests/${localStorage.getItem("predictionContestId")}`;

  const DelPredictionContest = async () => {
    const response = await fetch(DelContestApi, {
      method: "DELETE",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      onHide();
    }
    setError(result.message);
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={onHide}
        style={{
          backgroundColor: "black-transperant",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        size="md"
        centered
      >
        <Modal.Body
          style={{
            height: "30vh",
          }}
        >
          <div
            style={{
              height: "25vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h2>Delete</h2>
            <p>Are you sure you want to delete</p>
            <div id="buttons-div">
              <button onClick={onHide} id="cancel-btn3">
                Cancel
              </button>
              <button onClick={DelPredictionContest} id="delete-btn">
                Delete
              </button>
            </div>
            <p id="error">{error}</p>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default DeletePredictionContest;
