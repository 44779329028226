import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { RxCross1 } from "react-icons/rx";
import { BOT_API } from "../env";
import "./CreateBots.css";

const AddRemoveUser = ({ show, onHide }) => {
  const [userName, setUserName] = useState("");
  const [addUser, setAddUser] = useState(true);
  const [removeUser, setRemoveUser] = useState(false);
  const [error, setError] = useState("");

  let AddAndRemoveUserApi = BOT_API + "/api/bots/user";

  const AddorDeleteUser = async () => {
    let input = { userName };
    const response = await fetch(AddAndRemoveUserApi, {
      method: addUser ? "POST" : "DELETE",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(input),
    });
    let result = await response.json();
    if (response.status === 200) {
      onHide();
    } else {
      setError(result.message);
    }
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={onHide}
        style={{
          backgroundColor: "black-transperant",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        size="md"
        centered
      >
        <Modal.Body
          style={{
            height: "40vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div id="head-div">
            <h4>Add/Remove Users</h4>
            <RxCross1 color="black" onClick={onHide} />
          </div>
          <div
            style={{
              height: "30vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                height: "11vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                marginTop: "2em",
              }}
            >
              <div id="div-1">
                <p id="otp-email">Username</p>
                <input
                  onChange={(e) => setUserName(e.target.value)}
                  id="fst-input"
                  value={userName}
                />
              </div>
              <div
                style={{
                  width: "10vw",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ display: "flex", alignitems: "center" }}>
                  <input
                    type="checkbox"
                    onChange={(e) => setAddUser(e.target.checked)}
                    style={{
                      height: "1.5em",
                      width: "1.5em",
                    }}
                    checked={addUser}
                  />
                  &nbsp;
                  <label for="checkbox1">Add</label>
                </div>

                <div style={{ display: "flex", alignitems: "center" }}>
                  <input
                    type="checkbox"
                    onChange={(e) => setRemoveUser(e.target.checked)}
                    style={{
                      height: "1.5em",
                      width: "1.5em",
                    }}
                    checked={removeUser}
                  />
                  &nbsp;
                  <lable for="checkbox2">Remove</lable>
                </div>
              </div>
            </div>
            <div
              style={{
                width: "20vw",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <button id="remove-btn" onClick={onHide}>
                Cancel
              </button>
              <button onClick={AddorDeleteUser} id="okay-btn">
                Submit
              </button>
            </div>
            <p id="error">{error}</p>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddRemoveUser;
