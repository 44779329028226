import React, { useState, useEffect } from "react";
import TopNav from "./TopNav";
import SideNav from "./SideNav";
import GenerateCodeModal from "./GenerateCodeModal";
import { SERVER_API, MANAGER } from "./env";
import { getAdmin } from "./getAdmin";

const GenerateCode = () => {
  const [adminDisplay, setAdminDisplay] = useState(false);
  const [openGenerateCode, setOpenGenerateCode] = useState(false);
  const [analysis, setAnalysis] = useState({});
  const [couponList, setCouponList] = useState([]);

  let GetExistingCouponsApi = SERVER_API + "/api/coupon/";
  let GetCouponAnalysisApi = SERVER_API + "/api/coupon/analysis";

  let adminDetails = {};
  (async () => {
    adminDetails = await getAdmin();
    if (adminDetails.role & MANAGER) {
      setAdminDisplay(true);
    } else {
      setAdminDisplay(false);
    }
  })();

  const GetExistingCoupons = async () => {
    const response = await fetch(GetExistingCouponsApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "aplication/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    setCouponList(result);
  };

  const GetCouponAnalysis = async () => {
    const response = await fetch(GetCouponAnalysisApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "aplication/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    setAnalysis(result);
  };

  useEffect(() => {
    GetExistingCoupons();
    GetCouponAnalysis();
  }, []);

  return (
    <div style={{ display: "flex" }}>
      <SideNav />
      <div>
        <TopNav />
        <div style={{ height: "10vh" }}>
          <button
            onClick={() => setOpenGenerateCode(true)}
            id="create-teams-btn"
          >
            Generate Code
          </button>
        </div>
        <table id="admins-table">
          <thead id="thead">
            <tr>
              <th>Total Coupones</th>
              <th>Claimed Coupones</th>
              <th>Total Rewards</th>
              <th>Claimed Rewards</th>
              <th>Expired Coupones</th>
            </tr>
          </thead>
          <tbody>
            <tr
              id="tRow"
              style={{
                borderBottom: "1px solid lightgray",
                height: "6vh",
              }}
            >
              <td>{analysis.totalCoupons}</td>
              <td>{analysis.claimedCoupons}</td>
              <td>{analysis.totalRewards}</td>
              <td>{analysis.claimedRewards}</td>
              <td>{analysis.expiredCoupons}</td>
            </tr>
          </tbody>
        </table>
        {/* {adminDisplay ? (
          <div style={{ height: "60vh", overflowY: "scroll", margin: "" }}>
            <table id="matches-table">
              <thead
                id="thead"
                style={{ position: "sticky", padding: "1px 0px 0px 0px" }}
              >
                <tr>
                  <th>_id</th>
                  <th>Code</th>
                  <th>Reward Symbol</th>
                  <th>Reward Amount</th>
                </tr>
              </thead>
              <tbody>
                {couponList.map((data) => {
                  return (
                    <tr
                      style={{
                        height: "6vh",
                        borderBottom: "1px solid lightgray",
                      }}
                      key={data._id}
                    >
                      <td>{data._id}</td>
                      <td>{data.code}</td>
                      <td>{data.rewardSymbol}</td>
                      <td>{data.rewardAmount}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : null} */}
      </div>
      <GenerateCodeModal
        show={openGenerateCode}
        onHide={() => setOpenGenerateCode(false)}
      />
    </div>
  );
};

export default GenerateCode;
