import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { RxCross1 } from "react-icons/rx";
import { SERVER_API } from "../env";
import { MdAdd } from "react-icons/md";
import { AiOutlineMinus } from "react-icons/ai";

const CreateTournamentsReward = ({
  modalShow,
  setModalShow,
  tournamentName,
  startDate,
  endDate,
  contestType,
  symbol,
  total,
  rewardType,
  rules,
  cancelled,
  dateChecked,
  seriesIds,
  seriesIdsList,
}) => {
  const [error, setError] = useState("");
  const [inputFields, setInputFields] = useState([
    { percentage: "", count: "" },
  ]);

  let CreateTournamentsapi = SERVER_API + "/api/tournaments";

  const PostTournaments = async () => {
    let input = {
      tournamentName,
      startDate: convertDateToTimeStamp(startDate),
      endDate: convertDateToTimeStamp(endDate),
      contestType,
      reward: { symbol: symbol, total: total, distribution: inputFields },
      rewardType,
      rules,
      cancelled,
    };
    if(!dateChecked){
      delete input.startDate;
      delete input.endDate;
    }
    if(seriesIds){
      if(seriesIdsList.length) input.seriesIds = seriesIdsList
    }
    if(!rules.length){
      input.rules = " ";
    }
    const response = await fetch(CreateTournamentsapi, {
      method: "POST",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(input),
    });
    let result = await response.json();
    if (response.status === 200) {
      window.location.reload();
    } else {
      setError(result.message);
    }
  };

  const handleAddField = () => {
    setInputFields([...inputFields, { percentage: "", count: "" }]); // Add an empty input field to the array
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const onChangeVal = [...inputFields];
    onChangeVal[index][name] = value;
    setInputFields(onChangeVal);
  };

  const handleRemoveField = (index) => {
    const newInputFields = [...inputFields];
    newInputFields.splice(index, 1);
    setInputFields(newInputFields);
  };

  return (
    <div>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body
          style={{
            height: "60vh",
          }}
        >
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h4>Create Reward Breakup</h4>
              <RxCross1 color="black" onClick={setModalShow} />
            </div>

            <div
              style={{
                height: "50vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                paddingTop: "2em",
              }}
            >
              <div style={{ height: "40vh", overflowY: "scroll" }}>
                <div id="modal-inputs">
                  {inputFields.map((input, index) => (
                    <div key={index} id="inputs-div">
                      <p id="amount">Amount(%)</p>
                      <input
                        id="amount-input"
                        name="percentage"
                        type="number"
                        value={input.percentage}
                        onChange={(e) => handleChange(e, index)}
                      />
                      <div>
                        <p id="count">Count</p>
                        <input
                          id="amount-input"
                          name="count"
                          type="number"
                          value={input.count}
                          onChange={(e) => handleChange(e, index)}
                        />
                      </div>

                      <button
                        style={{
                          border: "1px solid red",
                          borderRadius: "5px",
                          backgroundColor: "white",
                          height: "6vh",
                          width: "3vw",
                        }}
                        onClick={handleAddField}
                      >
                        <MdAdd style={{ color: "red" }} />
                      </button>

                      <button
                        onClick={() => handleRemoveField(index)}
                        disabled={index === 0}
                        style={{
                          border: "1px solid red",
                          borderRadius: "5px",
                          backgroundColor: "white",
                          height: "6vh",
                          width: "3vw",
                        }}
                      >
                        <AiOutlineMinus style={{ color: "red" }} />
                      </button>
                    </div>
                  ))}
                </div>
              </div>
              <p id="error">{error}</p>
              <div>
                <button
                  onClick={PostTournaments}
                  id="update-btn2"
                  style={{ marginRight: "0px" }}
                >
                  Create
                </button>
                <button onClick={setModalShow} id="cancel-btn2">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CreateTournamentsReward;

function convertDateToTimeStamp(dateString) {
  const date = new Date(dateString);
  return date.getTime() / 1000;
}