import React from "react";
import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { RxCross1 } from "react-icons/rx";
import { SERVER_API } from "../env";

const CreateMatches2 = ({ modalShow, setModalShow }) => {
  const [seriesId, setSeriesId] = useState("");
  const [matchNumber, setMatchNumber] = useState("");
  const [isHotMatch, setIsHotMatch] = useState("");
  const [teamAId, setTeamAId] = useState("");
  const [teamBId, setTeamBId] = useState("");
  const [cricBuzzMatchId, setCricBuzzMatchId] = useState("");
  const [seriesData, setSeriesData] = useState([]);
  const [teams, setTeams] = useState(["Select"]);
  const [error, setError] = useState("");

  let CreateMatchesApi = SERVER_API + "/api/matches";
  let SeriesApi = SERVER_API + "/api/series";
  let teamsApi = SERVER_API + "/api/teams";

  const CheckTeams = async (id) => {
    if (id) {
      teamsApi = `${SERVER_API}/api/series/${id}/teams`;
    }

    const response = await fetch(teamsApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    let temp = [{ teamName: "Select" }];
    setTeams([...temp, ...result]);
    setTeamAId(teams[0]?._id);
    setTeamBId(teams[0]?._id);
    teamsApi = SERVER_API + "/api/teams";
  };

  const CreateMatches = async (e) => {
    e.preventDefault();
    let input = {
      seriesId: seriesId,
      matchNumber,
      teamAId: teamAId,
      teamBId: teamBId,
      isHotMatch,
      cricBuzzMatchId,
    };
    const response = await fetch(CreateMatchesApi, {
      method: "POST",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(input),
    });
    let result = await response.json();
    if (response.status === 201) {
      setModalShow(false);
    }
    setError(result.message);
  };
  const CheckSeries = async () => {
    const response = await fetch(SeriesApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    console.log(result);
    setSeriesData([{ seriesName: "Select" }, ...result]);
    setSeriesId(seriesData[0]?._id);
    return seriesData[0]?._id;
  };
  useEffect(() => {
    CheckSeries();
    CheckTeams();
  }, []);

  return (
    <div>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body
          style={{
            height: "60vh",
          }}
        >
          <div
            style={{
              height: "55vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h4>Create Matches</h4>
              <RxCross1 color="black" onClick={setModalShow} />
            </div>
            <div
              style={{
                height: "35vh",
                display: "flex",
                justifyContent: "space-around",
                paddingTop: "2em",
              }}
            >
              <div
                style={{
                  height: "25vh",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <p id="username">Series Name</p>
                <select
                  id="select"
                  onChange={(E) => {
                    setSeriesId(E.target.value);
                    CheckTeams(E.target.value);
                  }}
                  value={seriesId}
                >
                  {seriesData?.map((data) => {
                    return (
                      <option key={data._id} value={data._id}>
                        {`${data.seriesName}, ${data.typeOfSeries || ""}`}
                      </option>
                    );
                  })}
                </select>

                <div id="div-2">
                  <p id="username">Match number</p>
                  <input
                    onChange={(E) => setMatchNumber(E.target.value)}
                    id="fst-input"
                    type="text"
                    value={matchNumber}
                  />
                </div>
                <div id="div-2">
                  <p id="username">Is hot match</p>
                  <select
                    onChange={(E) => setIsHotMatch(E.target.value)}
                    id="fst-input"
                    type="text"
                    value={isHotMatch}
                  >
                    <option value="select">Select</option>
                    <option value={true}>True</option>
                    <option value={false}>False</option>
                  </select>
                </div>
              </div>
              <div
                style={{
                  height: "25vh",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <p id="username">TeamA Id</p>
                  <select
                    id="select"
                    onChange={(E) => setTeamAId(E.target.value)}
                    value={teamAId}
                  >
                    {teams?.map((data) => {
                      return (
                        <option key={data._id} value={data._id}>
                          {data.teamName}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div>
                  <p id="username">TeamB Id</p>
                  <select
                    id="select"
                    onChange={(E) => setTeamBId(E.target.value)}
                    value={teamBId}
                  >
                    {teams?.map((data) => {
                      return (
                        <option key={data._id} value={data._id}>
                          {data.teamName}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div id="div-2">
                  <p id="username">CricBuzz Match Id</p>
                  <input
                    onChange={(e) => setCricBuzzMatchId(e.target.value)}
                    type="number"
                    id="fst-input"
                    value={cricBuzzMatchId}
                  />
                </div>
              </div>
            </div>
            <p id="error">{error}</p>
            <div>
              <button onClick={CreateMatches} id="update-btn2">
                Create
              </button>
              <button onClick={setModalShow} id="cancel-btn2">
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CreateMatches2;
