import React, { useState, useEffect } from "react";
import { SERVER_API } from "../env";
import SideNav from "./../SideNav";
import TopNav from "./../TopNav";
import { useNavigate } from "react-router-dom";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import CalRewards2 from "../Contests/CalRewards2";
import Verify from "../Matches/Verify";
import { BsCheckCircle } from "react-icons/bs";
import { RxCrossCircled } from "react-icons/rx";
import SettleContest from "./../Contests/SettleContest";

const SelectTournament = () => {
  const navigate = useNavigate();
  const [tournamentList, setTournamentList] = useState({});
  const [verified, setVerified] = useState([]);
  const [toggle, setToggle] = useState(1);
  const [error, setError] = useState("");
  const [openSuccessMessage, setOpenSuccessMessage] = useState(false);
  const [openVerified, setOpenVerified] = useState(false);
  const [openSettle, setOpenSettle] = useState(false);
  const [displayData, setDisplayData] = useState(false);

  let GetTournamentDetailsApi =
    SERVER_API + `/api/tournaments/${localStorage.getItem("tournamentId")}`;
  let RewardCalApi =
    SERVER_API +
    `/api/tournaments/${localStorage.getItem(
      "tournamentId"
    )}/rewards/calculate`;
  let VerifyTournamentApi =
    SERVER_API +
    `/api/tournaments/${localStorage.getItem("tournamentId")}/verify`;
  let SettleTournamentApi =
    SERVER_API +
    `/api/tournaments/${localStorage.getItem("tournamentId")}/settle`;

  const GetTournamentDetails = async () => {
    const response = await fetch(GetTournamentDetailsApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    setTournamentList(result);
    setVerified(result);
  };

  const RewardCal = async () => {
    const response = await fetch(RewardCalApi, {
      method: "PATCH",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      setOpenSuccessMessage(true);
    } else {
      setError(result.message);
    }
  };

  const VerifyTournament = async () => {
    const response = await fetch(VerifyTournamentApi, {
      method: "PATCH",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      setOpenVerified(true);
    } else {
      setError(result.message);
    }
  };

  const SettleTournament = async () => {
    const response = await fetch(SettleTournamentApi, {
      method: "PATCH",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      setOpenSettle(true);
      setDisplayData(true);
    } else {
      setError(result.message);
    }
  };

  const handleNavigate = (id) => {
    localStorage.setItem("tournamentId", id);
    navigate("/TournamentsLeaderboard");
  };

  useEffect(() => {
    GetTournamentDetails();
  }, []);

  return (
    <div style={{ display: "flex" }}>
      <SideNav />
      <div>
        <TopNav />
        <div id="tabs-div">
          <div
            style={{
              width: "17vw",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <button
              onClick={() => setToggle(1)}
              style={
                toggle === 1 ? { border: "1px solid red", color: "red" } : null
              }
              id="match-btn"
            >
              Tournaments
            </button>
            <button
              onClick={() => setToggle(2)}
              style={
                toggle === 2 ? { border: "1px solid red", color: "red" } : null
              }
              id="verify-btn"
            >
              Verified by
            </button>
          </div>
          <div id="main-tabs2">
            <button onClick={RewardCal} id="publish-btn">
              Reward Cal.
            </button>
            <button onClick={SettleTournament} id="matches-btn">
              Settle
            </button>
            <button onClick={VerifyTournament} id="Cancel-btn">
              Verify
            </button>
          </div>
        </div>
        {toggle === 1 ? (
          <table id="teams-table">
            <thead id="thead" style={{ position: "sticky" }}>
              <tr>
                <th>Tournament Name</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Contest Type</th>
                <th>Rules</th>
                <th>Reward Type</th>
                <th>Rewards Settle</th>
                <th>Is Verified</th>
              </tr>
            </thead>
            <tbody>
              <tr
                style={{
                  borderBottom: "1px solid lightgray",
                  height: "6vh",
                }}
              >
                <td>{tournamentList.tournamentName}</td>
                <td>{getDateFromTimeStamp(tournamentList.startDate)}</td>
                <td>{getDateFromTimeStamp(tournamentList.endDate)}</td>
                <td>{tournamentList.contestType}</td>
                <td>{tournamentList.rules}</td>
                <td>{tournamentList.rewardType}</td>
                <td>
                  {tournamentList.rewardsSettled === true ? (
                    <BsCheckCircle color={"green"} />
                  ) : (
                    <RxCrossCircled color={"red"} />
                  )}
                </td>
                <td>
                  {tournamentList.verified === true ? (
                    <BsCheckCircle color={"green"} />
                  ) : (
                    <RxCrossCircled color={"red"} />
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        ) : null}

        {toggle === 2 ? (
          <table id="teams-table">
            <thead id="thead" style={{ position: "sticky" }}>
              <tr>
                <th>Email</th>
                <th>Hash</th>
              </tr>
            </thead>
            <tbody>
              {verified?.verifiedBy?.map((data) => {
                return (
                  <tr
                    style={{
                      borderBottom: "1px solid lightgray",
                      height: "6vh",
                    }}
                  >
                    <td>{data.email}</td>
                    <td>{data.hash}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : null}

        <div id="bottom-btn">
          <button
            onClick={() => handleNavigate(tournamentList._id)}
            id="leaderboard-btn"
          >
            Go To Leaderboard <HiOutlineArrowNarrowRight color="white" />
          </button>
        </div>
        <p id="error" style={{ color: "gray" }}>
          {error}
        </p>
      </div>
      <Verify show={openVerified} onHide={() => setOpenVerified(false)} />
      <CalRewards2
        show={openSuccessMessage}
        onHide={() => setOpenSuccessMessage(false)}
      />
      <SettleContest
        displayData={displayData}
        show={openSettle}
        onHide={() => setOpenSettle(false)}
      />
    </div>
  );
};

export default SelectTournament;

function getDateFromTimeStamp(timeStamp) {
  return new Date(timeStamp * 1000).toLocaleDateString("en-IN", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
}
