import React, { useState, useEffect } from "react";
import { FANTASY_API } from "../env";
import { useNavigate } from "react-router-dom";
import { BsCheckCircle } from "react-icons/bs";
import { RxCrossCircled } from "react-icons/rx";
import { AiOutlineDelete } from "react-icons/ai";
import DeleteFantasyContest from "../Contests/DeleteFantasyContest";

const Fantasy = (createFantasyContest) => {
  const navigate = useNavigate();
  const [fantasyContest, setFantasyContest] = useState([]);
  const [fantasyId, setFantasyId] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [error, setError] = useState("");

  let FantasyContestApi =
    FANTASY_API +
    `/api/matches/${localStorage.getItem("matchId")}/fantasy-contests`;

  const GetFantasyContests = async () => {
    const response = await fetch(FantasyContestApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      setFantasyContest(result);
    } else {
      setError(result.message);
    }
  };

  const handleDelete = (id) => {
    setFantasyId(id);
    setOpenDelete(true);
  };

  const handleNavigate = (id) => {
    navigate(`/FantasyContest/${id}`);
  };

  useEffect(() => {
    GetFantasyContests();
  }, []);

  return (
    <div>
      <table id="contests-table">
        <thead id="thead">
          <tr>
            <th>Contest Name</th>
            <th>Match No</th>
            <th>Match Name</th>
            <th>Series</th>
            <th>Start Date</th>
            <th>Entry Fee</th>
            <th>Max User Teams</th>
            <th>Max Slots</th>
            <th>Users In Contest</th>
            <th>Ball Price In USDT</th>
            <th>Published</th>
            <th>Is Verified</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {fantasyContest.map((fantasy) => {
            return (
              <tr
                style={{
                  borderBottom: "1px solid lightgray",
                  height: "6vh",
                }}
                key={fantasy._id}
              >
                <td onClick={() => handleNavigate(fantasy._id)}>
                  {fantasy.contestName}
                </td>
                <td onClick={() => handleNavigate(fantasy._id)}>
                  {fantasy.matchNumber}
                </td>
                <td onClick={() => handleNavigate(fantasy._id)}>
                  {fantasy.match}
                </td>
                <td onClick={() => handleNavigate(fantasy._id)}>
                  {fantasy.series}
                </td>
                <td onClick={() => handleNavigate(fantasy._id)}>
                  {getDateFromTimeStamp(fantasy.startDate)}
                </td>
                <td onClick={() => handleNavigate(fantasy._id)}>
                  {fantasy.entryFee}
                </td>
                <td onClick={() => handleNavigate(fantasy._id)}>
                  {fantasy.maxUserTeams}
                </td>
                <td onClick={() => handleNavigate(fantasy._id)}>
                  {fantasy.maxSlots}
                </td>
                <td onClick={() => handleNavigate(fantasy._id)}>
                  {fantasy.usersInContest}
                </td>
                <td onClick={() => handleNavigate(fantasy._id)}>
                  {fantasy.ballPriceInUSD}
                </td>
                <td>
                  {fantasy.published === true ? (
                    <BsCheckCircle color="green" />
                  ) : (
                    <RxCrossCircled color="red" />
                  )}
                </td>
                <td>
                  {fantasy.isVerified === true ? (
                    <BsCheckCircle color="green" />
                  ) : (
                    <RxCrossCircled color="red" />
                  )}
                </td>
                <td>
                  <AiOutlineDelete onClick={() => handleDelete(fantasy._id)} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <p id="error" style={{ color: "gray", marginTop: "2em" }}>
        {error}
      </p>
      <DeleteFantasyContest
        id={fantasyId}
        show={openDelete}
        onHide={() => setOpenDelete(false)}
      />
    </div>
  );
};

function getDateFromTimeStamp(timeStamp) {
  return new Date(timeStamp * 1000).toLocaleDateString("en-IN", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
}
export default Fantasy;
