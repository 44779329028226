import React, { useState, useEffect } from "react";
import { ANALYTICS_API, MANAGER } from "./env";
import SideNav from "./SideNav";
import TopNav from "./TopNav";
import { useNavigate } from "react-router-dom";
import { getAdmin } from "./getAdmin";

const Swapped = () => {
  const navigate = useNavigate();
  const [swapList, setSwapList] = useState([]);

  // (() => {
  //   let adminRole = localStorage.getItem("adminRole");
  //   if (!(adminRole & MANAGER)) {
  //     navigate("/Login");
  //   }
  // })();

  let adminDetails = {};
  (async () => {
    adminDetails = await getAdmin();
    if (!(adminDetails.role & MANAGER)) {
      navigate("/");
    }
  })();

  let SwappedApi = ANALYTICS_API + "/api/transactions/swap";

  const GetSwapList = async () => {
    const response = await fetch(SwappedApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-type": "application/json",
      },
    });
    let result = await response.json();
    setSwapList(result);
  };
  useEffect(() => {
    GetSwapList();
  }, []);
  return (
    <div style={{ display: "flex" }}>
      <SideNav />
      <div>
        <TopNav />
        <div
          style={{
            height: "7vh",
            display: "flex",
            alignItems: "center",
            marginLeft: "1em",
          }}
        >
          <span
            style={{
              fontWeight: "bold",
            }}
          >
            Swapped Users Count :
          </span>
          &nbsp;
          <span>{swapList.length}</span>
        </div>
        <div style={{ height: "75vh", overflowY: "scroll" }}>
          <table
            style={{
              width: "85vw",
              marginLeft: "1em",
              cursor: "pointer",
            }}
          >
            <thead id="thead" style={{ position: "sticky" }}>
              <tr>
                <th>Username</th>
                <th>Available BALL</th>
                <th>Available BUSD</th>
                <th>Available USDT</th>
                <th>BALL Swap Out</th>
                <th>BALL Swap In</th>
                <th>USDT Swap Out</th>
                <th>USDT Swap In</th>
              </tr>
            </thead>
            <tbody>
              {swapList.map((data) => {
                return (
                  <tr
                    key={data._id}
                    style={{
                      borderBottom: "1px solid lightgray",
                      height: "6vh",
                      backgroundColor:
                        data.type === "admin" ? "rgb(230, 193, 186)" : "null",
                    }}
                  >
                    <td>{data.userName}</td>
                    <td>{roundToNDecimal(data.BALL_Avail, 4) || 0}</td>
                    <td>{roundToNDecimal(data.BUSD_Avail, 4) || 0}</td>
                    <td>{roundToNDecimal(data.USDT_Avail, 4) || 0}</td>
                    <td>{roundToNDecimal(data.BALL_swap_out, 4) || 0}</td>
                    <td>{roundToNDecimal(data.BALL_swap_in, 4) || 0}</td>
                    <td>{roundToNDecimal(data.USDT_swap_out, 4) || 0}</td>
                    <td>{roundToNDecimal(data.USDT_swap_in, 4) || 0}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Swapped;

function roundToNDecimal(number, decimal = 3) {
  return Math.round(number * Math.pow(10, decimal)) / Math.pow(10, decimal);
}
