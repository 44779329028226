import React from "react";
import Modal from "react-bootstrap/Modal";
import "./CreateAdmin.css";
import { RxCross1 } from "react-icons/rx";
import { useState } from "react";
import { SERVER_API } from "../env";

const CreateAdmin = ({ modalShow, setModalShow }) => {
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [error, setError] = useState("");

  let CreateAdminApi = SERVER_API + "/api/admins";

  const CreateAdmin = async (e) => {
    e.preventDefault();
    let input = { userName, email, role };
    const response = await fetch(CreateAdminApi, {
      method: "POST",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(input),
    });
    let result = await response.json();
    if (response.status === 201) {
      setModalShow(false);
    }
    setError(result.message);
  };
  return (
    <div>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body
          style={{
            height: "50vh",
          }}
        >
          <div
            style={{
              height: "40vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h4>Create Admin</h4>
              <RxCross1 color="black" onClick={setModalShow} />
            </div>
            <div id="modal-main-div">
              <div id="modal-left-div">
                <div id="div-1">
                  <p id="username">Username</p>
                  <input
                    onChange={(E) => setUserName(E.target.value)}
                    id="fst-input"
                    type="text"
                    value={userName}
                  />
                </div>
                <div id="div-2">
                  <p id="username">Role</p>
                  <input
                    onChange={(E) => setRole(E.target.value)}
                    id="fst-input"
                    type="number"
                    min="1"
                    value={role}
                  />
                </div>
              </div>
              <div>
                <div id="div-2">
                  <p id="username">Email</p>
                  <input
                    onChange={(E) => setEmail(E.target.value)}
                    id="fst-input"
                    type="text"
                    value={email}
                  />
                </div>
              </div>
            </div>
            <p id="error">{error}</p>
            <div>
              <button onClick={CreateAdmin} id="update-btn2">
                Create
              </button>
              <button onClick={setModalShow} id="cancel-btn2">
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CreateAdmin;
