import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { SERVER_API } from "../env";
import { RxCross1 } from "react-icons/rx";

const EditOffers = ({ modalShow, setModalShow, id, offersList }) => {
  const [error, setError] = useState("");
  const [offerId, setOfferId] = useState("");
  const [newTitle, setNewTitle] = useState("");
  const [expiryTime, setExpiryTime] = useState("");

  function formatDateString(timestamp) {
    timestamp = timestamp * 1000;

    let dateString = new Date(timestamp).toLocaleString("en-IN", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });

    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const parts = dateString.split(",");

    let date = parts[0] || "";
    let time = parts[1]?.trim() || "";

    date = date.split("/");
    time = time.split(" ");

    let period = time[0];
    time = time[0]?.split(":");

    time = time[0] + ":" + time[1];

    return `${date[2]}-${date[1]}-${date[0]}T${time}`;
  }

  let UpdateOffersApi = SERVER_API + "/api/offers";

  const UpdateOffers = async () => {
    let input = {
      offerId: `${id}`,
      newTitle: newTitle || offersList.title,
      expiryTime:
        convertDateToTimestamp(expiryTime) ||
        convertDateToTimestamp(offersList?.expiryTime),
    };
    const response = await fetch(UpdateOffersApi, {
      method: "PATCH",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(input),
    });
    let result = await response.json();
    if (response.status === 200 || response.status === 201) {
      setModalShow(false);
    } else {
      setError(result.message);
    }
  };

  return (
    <div>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body
          style={{
            height: "40vh",
          }}
        >
          <div
            style={{
              height: "35vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h4>Edit offers</h4>
              <RxCross1 onClick={setModalShow} color="black" />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <div
                style={{
                  height: "15vh",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  marginTop: "1em",
                }}
              >
                <div id="div-2">
                  <p id="username">OfferId</p>
                  <input
                    onChange={(e) => setOfferId(e.target.value)}
                    id="fst-input"
                    type="text"
                    value={id}
                  />
                </div>
                <div id="div-2">
                  <p id="username">New Title</p>
                  <input
                    onChange={(e) => setNewTitle(e.target.value)}
                    id="fst-input"
                    type="text"
                    defaultValue={offersList.title}
                  />
                </div>
              </div>
              <div
                style={{
                  height: "15vh",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  marginTop: "1em",
                }}
              >
                <div id="div-2">
                  <p id="username">End date</p>
                  <input
                    onChange={(E) => setExpiryTime(E.target.value)}
                    id="fst-input"
                    type="datetime-local"
                    defaultValue={formatDateString(offersList?.expiryTime)}
                  />
                </div>
              </div>
            </div>
            <p id="error">{error}</p>
            <div>
              <button id="update-btn2" onClick={UpdateOffers}>
                Update
              </button>
              <button onClick={setModalShow} id="cancel-btn2">
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EditOffers;

function convertDateToTimestamp(dateString) {
  const date = new Date(dateString);
  return date.getTime() / 1000;
}
