import { SERVER_API } from "./env";

export const getAdmin = async () => {
  try {
    let admin = await fetch(
      SERVER_API + "/api/admins/" + localStorage.getItem("adminId"),
      {
        method: "GET",
        headers: {
          mode: "cors",
          token: localStorage.getItem("token"),
          Accept: "application/json",
        },
      }
    );

    if (admin.status !== 200) {
      localStorage.setItem("token", "");
      window.location.assign(window.location.origin + "/Login");
      return;
    }
    return admin.json();
  } catch (e) {
    localStorage.setItem("token", "");
    window.location.assign(window.location.origin + "/Login");
    return;
  }
};
