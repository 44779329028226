import React from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import OTP from "./Components/OTP";
import Login from "./Components/Login";
import ForgotPass from "./Components/ForgotPass";
import Dashboard from "./Components/Dashboard/Dashboard";
import Admin from "./Components/Admins/Admin";
import Context from "./Context";
import { useState } from "react";
import Wallet from "./Components/Wallet";
import Series from "./Components/Series/Series";
import Teams from "./Components/Teams/Teams";
import Matches from "./Components/Matches/Matches";
import UpcomingMatches from "./Components/Matches/UpcomingMatches";
import LiveMatches from "./Components/Matches/LiveMatches";
import CompletedMatches from "./Components/Matches/CompletedMatches";
import SelectMatch from "./Components/Matches/SelectMatch";
import VerifyMatch from "./Components/Matches/VerifyMatch";
import ShowQuestions from "./Components/Matches/ShowQuestions";
import SeriesStaking from "./Components/SeriesStaking/SeriesStaking";
import Contests from "./Components/Contests/Contests";
import SelectPredictionContest from "./Components/Contests/SelectPredictionContest";
import VerifyContest from "./Components/Contests/VerifyContest";
import Leaderboard from "./Components/Contests/Leaderboard";
import Rewards from "./Components/Contests/Rewards";
import ReadTeams from "./Components/Series/ReadTeams";
import ReadMatches from "./Components/Series/ReadMatches";
import SelectSeries from "./Components/SeriesStaking/SelectSeries";
import UpcomingSeries from "./Components/SeriesStaking/UpcomingSeries";
import LiveSeries from "./Components/SeriesStaking/LiveSeries";
import CompletedSeries from "./Components/SeriesStaking/CompletedSeries";
import UpcomingContests from "./Components/Contests/UpcomingContests";
import LiveContests from "./Components/Contests/LiveContests";
import CompletedContests from "./Components/Contests/CompletedContests";
import OGCricket from "./Components/OG/OGCricket";
import Sessions from "./Components/OG/Sessions";
import Analytics from "./Components/Analytics/Analytics";
import PredictQuestions from "./Components/Matches/PredictQuestions";
import TokenDistribution from "./Components/Token Distribution/TokenDistribution";
import Bots from "./Components/Bots/Bots";
import ContestsInMatch from "./Components/Matches/ContestsInMatch";
import IPhoneLeaderboard from "./Components/IPhoneLeaderboard";
import Swapped from "./Components/Swapped";
import Users from "./Components/Users/Users";
import CronJobs from "./Components/CronJobs";
import Testing from "./Components/Testing";
import Transactions from "./Components/Transactions";
import Players from "./Components/Players/Players";
import PredictionContest from "./Components/Contests/PredictionContest";
import LivePredictionContest from "./Components/Contests/LivePredictionContest";
import FantasyContest from "./Components/Contests/FantasyContest";
import SamplePredictions from "./Components/Matches/SamplePredictions";
import SelectLivePredictionContest from "./Components/Contests/SelectLivePredictions";
import SelectFantasyContest from "./Components/Contests/SelectFantasyContest";
import FantasyLeaderboard from "./Components/Contests/FantasyLeaderboard";
import Scorecard from "./Components/Matches/Scorecard";
import SelectTeam from "./Components/Teams/SelectTeam";
import GetNewPlayers from "./Components/Teams/GetNewPlayers";
import ShowQuestionsDirect from "./Components/Matches/ShowQuestionsDirect";
import Offers from "./Components/Offers/Offers";
import Tournaments from "./Components/Tournaments/Tournaments";
import SelectTournament from "./Components/Tournaments/SelectTournament";
import TournamentsLeaderboard from "./Components/Tournaments/TournamentsLeaderboard";
import GenerateCode from "./Components/GenerateCode";
import PredictQuestionsManual from "./Components/Matches/PredictQuestionsManual";

function App() {
  const [userData, setUserData] = useState({});
  const [sideNav, setSideNav] = useState(false);
  const [allContests, setAllContests] = useState([]);
  const [individualSeries, setIndividualSeries] = useState({});
  const [individualContest, setIndividualContest] = useState({});

  return (
    <Context.Provider
      value={{
        userData,
        setUserData,
        sideNav,
        setSideNav,
        allContests,
        setAllContests,
        individualSeries,
        setIndividualSeries,
        individualContest,
        setIndividualContest,
      }}
    >
      <div className="App">
        {sideNav ? <sideNav /> : null}
        <Routes>
          <Route path="/Login" element={<Login />} />
          <Route path="/OTP" element={<OTP />} />
          <Route path="/ForgotPass" element={<ForgotPass />} />
          <Route path="/" element={<Dashboard />} />
          <Route path="/Admin" element={<Admin />} />
          <Route path="/Wallet" element={<Wallet />} />
          <Route path="/Series" element={<Series />} />
          <Route path="/Teams" element={<Teams />} />
          <Route path="Matches" element={<Matches />} />
          <Route path="/UpcomingMatches" element={<UpcomingMatches />} />
          <Route path="/LiveMatches" element={<LiveMatches />} />
          <Route path="/CompletedMatches" element={<CompletedMatches />} />
          <Route path="/SelectMatch" element={<SelectMatch />} />
          <Route path="/VerifyMatch" element={<VerifyMatch />} />
          <Route path="/ShowQuestions" element={<ShowQuestions />} />
          <Route path="/SeriesStaking" element={<SeriesStaking />} />
          <Route path="/Contests" element={<Contests />} />
          <Route
            path="/SelectPredictionContest"
            element={<SelectPredictionContest />}
          />
          <Route path="/VerifyContest" element={<VerifyContest />} />
          <Route path="/Leaderboard" element={<Leaderboard />} />
          <Route path="/Rewards" element={<Rewards />} />
          <Route path="/ReadTeams" element={<ReadTeams />} />
          <Route path="/ReadMatches" element={<ReadMatches />} />
          <Route path="/SelectSeries" element={<SelectSeries />} />
          <Route path="/UpcomingSeries" element={<UpcomingSeries />} />
          <Route path="/LiveSeries" element={<LiveSeries />} />
          <Route path="/CompletedSeries" element={<CompletedSeries />} />
          <Route path="/UpcomingContests" element={<UpcomingContests />} />
          <Route path="/LiveContests" element={<LiveContests />} />
          <Route path="/CompletedContests" element={<CompletedContests />} />
          <Route path="/OGCricket" element={<OGCricket />} />
          <Route path="/Sessions" element={<Sessions />} />
          <Route path="/Analytics" element={<Analytics />} />
          <Route path="/PredictQuestions" element={<PredictQuestions />} />
          <Route path="/ContestsInMatch" element={<ContestsInMatch />} />
          <Route path="/TokenDistribution" element={<TokenDistribution />} />
          <Route path="/Bots" element={<Bots />} />
          <Route path="/IphoneLeaderboard" element={<IPhoneLeaderboard />} />
          <Route path="/Swapped" element={<Swapped />} />
          <Route path="/Users" element={<Users />} />
          <Route path="/CronJobs" element={<CronJobs />} />
          <Route path="/Testing" element={<Testing />} />
          <Route path="/Transactions" element={<Transactions />} />
          <Route path="/Players" element={<Players />} />
          <Route path="/PredictionContest" element={<PredictionContest />} />
          <Route path="/FantasyContest/" element={<FantasyContest />} />
          <Route path="/FantasyContest/*" element={<SelectFantasyContest />} />
          <Route path="/SamplePredictions" element={<SamplePredictions />} />
          <Route path="/SelectTeam" element={<SelectTeam />} />
          <Route path="/Offers" element={<Offers />} />
          <Route path="/Tournaments" element={<Tournaments />} />
          <Route path="/SelectTournament" element={<SelectTournament />} />
          <Route
            path="/TournamentsLeaderboard"
            element={<TournamentsLeaderboard />}
          />
          <Route
            path="/FantasyLeaderboard/*"
            element={<FantasyLeaderboard />}
          />
          <Route
            path="/SelectLivePredictions"
            element={<SelectLivePredictionContest />}
          />
          <Route
            path="/LivePredictionContest"
            element={<LivePredictionContest />}
          />
          <Route path="/Scorecard" element={<Scorecard />} />
          <Route path="/GetNewPlayers" element={<GetNewPlayers />} />
          <Route
            path="/ShowQuestionsDirect"
            element={<ShowQuestionsDirect />}
          />
          <Route path="/GenerateCode" element={<GenerateCode />} />
          <Route
            path="/PredictQuestionsManual"
            element={<PredictQuestionsManual />}
          />
        </Routes>
      </div>
    </Context.Provider>
  );
}

export default App;
