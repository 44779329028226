// let SERVER_API = "http://192.168.29.135:3000"; //development
// let SERVER_API = "https://18.61.23.83:3000"; //development
// let SERVER_API = "https://dev.server.admin.cryptocricket.io:3000"; //development
// let SERVER_API = "http://18.60.59.48:3000"; //development
// let PREDICTION_API = "http://18.60.59.48:3001";
// let PREDICTION_API = "http://192.168.29.135:3001";
let PREDICTION_API = "https://prod.server.admin.cryptocricket.io:3001";
let LIVE_PREDICTION_API = "https://prod.server.admin.cryptocricket.io:3002";
// let LIVE_PREDICTION_API = "http://192.168.29.135:3002";
// let SERVER_API = "http://192.168.0.121:3000";
// let SERVER_API = "http://192.168.29.17:3040";
// let FANTASY_API = "http://192.168.29.135:3003";
let FANTASY_API = "https://prod.server.admin.cryptocricket.io:3003";
// let SERVER_API = "http://192.168.0.100:3000"; //development
// let SERVER_API = "http://192.168.29.17:3001"; //development
let SERVER_API = "https://prod.server.admin.cryptocricket.io:3000"; //production

// let ANALYTICS_API = "http://192.168.29.122:5000"; //development
// let ANALYTICS_API = "http://18.60.148.161:5000"; //production
let ANALYTICS_API = "https://prod.server.admin.cryptocricket.io:3004"; //production

// let BOT_API = "https://api.bots.cryptocricket.io";
let BOT_API = "https://prod.server.admin.cryptocricket.io:3005";
// let BOT_API = "http://192.168.29.122:3005";

const OWNER = 128;
const MANAGER = 64;
const ACCOUNTANT = 32;
const SCORER = 16;
const MARKETING = 2;
const SUPPORT = 1;

export {
  SERVER_API,
  ANALYTICS_API,
  BOT_API,
  PREDICTION_API,
  LIVE_PREDICTION_API,
  FANTASY_API,
  OWNER,
  MANAGER,
  ACCOUNTANT,
  SCORER,
  MARKETING,
  SUPPORT,
};
