import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { FANTASY_API } from "../env";
import { RxCross1 } from "react-icons/rx";

const CreateFantasyConfig = ({ show, onHide }) => {
  const [maxUserTeams, setMaxUserTeams] = useState("");
  const [error, setError] = useState("");

  let CreateFantasyConfigApi = FANTASY_API + "/api/matches/config";

  const PostFantasyConfig = async () => {
    let input = { matchId: `${localStorage.getItem("matchId")}`, maxUserTeams };
    const response = await fetch(CreateFantasyConfigApi, {
      method: "POST",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(input),
    });
    let result = await response.json();
    if (response.status === 200) {
      onHide();
    } else {
      setError(result.message);
    }
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={onHide}
        style={{
          backgroundColor: "black-transperant",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        size="md"
        centered
      >
        <Modal.Body
          style={{
            height: "35vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div id="head-div">
            <h4>Create Fantasy Config</h4>
            <RxCross1 color="black" onClick={onHide} />
          </div>
          <div
            style={{
              height: "10vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              paddingTop: "2em",
            }}
          >
            {/* <div id="div-1">
              <p id="old-password">Select Match</p>
              <select
                id="sec-input"
                onChange={(E) => setMatchId(E.target.value)}
                value={matchId}
              >
                {matchesList?.map((data) => {
                  return (
                    <option key={data._id} value={data._id}>
                      {data.matchNumber}
                    </option>
                  );
                })}
              </select>
            </div> */}

            <div id="div-2">
              <p id="old-password">Max User Teams</p>
              <input
                onChange={(e) => setMaxUserTeams(e.target.value)}
                type="number"
                id="sec-input"
                value={maxUserTeams}
              />
            </div>
          </div>
          <button onClick={PostFantasyConfig} id="button">
            Create
          </button>
          <p id="error">{error}</p>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CreateFantasyConfig;
