import React from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useState } from "react";

const ContestsTabs = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div
      style={{
        width: "30vw",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <button
        onClick={() => navigate("/Contests")}
        id="all-btn"
        style={
          location.pathname === "/Contests"
            ? { border: "1px solid red", color: "red" }
            : null
        }
      >
        All
      </button>
      <button
        onClick={() => navigate("/UpcomingContests")}
        id="upcoming-btn"
        style={
          location.pathname === "/UpcomingContests"
            ? { border: "1px solid red", color: "red" }
            : null
        }
      >
        Upcoming
      </button>
      <button
        onClick={() => navigate("/LiveContests")}
        id="live-btn"
        style={
          location.pathname === "/LiveContests"
            ? { border: "1px solid red", color: "red" }
            : null
        }
      >
        Live
      </button>
      <button
        onClick={() => navigate("/CompletedContests")}
        id="completed-btn"
        style={
          location.pathname === "/CompletedContests"
            ? { border: "1px solid red", color: "red" }
            : null
        }
      >
        Completed
      </button>
    </div>
  );
};

export default ContestsTabs;
