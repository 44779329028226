import React, { useState, useEffect } from "react";
import SideNav from "./../SideNav";
import "./OGCricket.css";
import TopNav from "./../TopNav";
import AddSession from "./AddSession";
import { SERVER_API } from "./../env";
import { BiEdit } from "react-icons/bi";
import EditScore from "./EditScore";
import { BsCheckCircle } from "react-icons/bs";
import { RxCrossCircled } from "react-icons/rx";
import { getAdmin } from "../getAdmin";

const Sessions = () => {
  let adminDetails = {};
  (async () => {
    adminDetails = await getAdmin();
  })();

  const [open, setOpen] = useState(false);
  const [sessionsList, setSessionList] = useState([]);
  const [matchDetails, setMatchDetails] = useState([]);
  const [open2, setOpen2] = useState(false);

  let SessionsListApi =
    SERVER_API + `/api/og/sessions/${localStorage.getItem("matchInstanceId")}`;

  const GetSessions = async () => {
    const response = await fetch(SessionsListApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();

    setSessionList(result.matchSessions);
    setMatchDetails(result.matchDetails);
  };

  const handleClick = (id) => {
    localStorage.setItem("sessionId", id);
    setOpen2(true);
  };

  useEffect(() => {
    GetSessions();
  }, [open, open2]);

  return (
    <div className="session-div">
      <SideNav />
      <div>
        <TopNav />
        <div id="tab-options">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "baseline",
            }}
          >
            <div id="match-details">
              <p style={{ color: "red" }}>{matchDetails.seriesName}</p>

              <p
                style={{
                  fontWeight: "bold",
                  paddingLeft: "1em",
                }}
              >
                {matchDetails.teamAName} vs {matchDetails.teamBName}
              </p>
            </div>
            <div>
              <p>Match No : {matchDetails.matchName}</p>
            </div>
          </div>
          <button id="add-sessions-btn" onClick={() => setOpen(true)}>
            Add Sessions
          </button>
        </div>

        <table id="matches-table">
          <thead id="thead">
            <tr>
              <th>
                {Sessions}
                {/* {sessionsList?.map((data) => {
                  return (
                    <button id="innings-btn">
                      INNINGS {""}
                      {data.innings}
                    </button>
                  );
                })} */}
                Innings
              </th>
              <th>Sessions</th>
              <th>Our Prediction</th>
              <th>Score</th>
              <th>Result</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {sessionsList?.map((data) => {
              return (
                <tr
                  style={{
                    borderBottom: "1px solid lightgray",
                    height: "6vh",
                  }}
                >
                  <td>{data.innings}</td>
                  <td>
                    {data.sessionStart} - {data.sessionEnd}
                  </td>
                  <td>
                    {data.scoreDirection} {""}
                    {data.predictionScore}
                  </td>
                  <td>
                    {data.actualScore} - {data.wickets}
                  </td>
                  <td>
                    {data.result === true ? (
                      <BsCheckCircle color={"green"} />
                    ) : (
                      <RxCrossCircled color={"red"} />
                    )}
                  </td>
                  <td>
                    <BiEdit onClick={() => handleClick(data._id)} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <AddSession show={open} onHide={() => setOpen(false)} />
      <EditScore show={open2} onHide={() => setOpen2(false)} />
    </div>
  );
};

export default Sessions;
