import React, { useEffect } from "react";
import SideNav from "../SideNav";
import "./Dashboard.css";
import TopNav from "../TopNav";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/Login");
    }
  }, []);
  return (
    <div className="dashboard-div">
      <SideNav />
      <div style={{ width: "87svw" }}>
        <TopNav />
      </div>
    </div>
  );
};

export default Dashboard;
