import React from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import { SERVER_API } from "../env";

const TransferOwnership = ({ show, onHide }) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  let TransferApi = SERVER_API + "/api/admins/transferownership";

  const TransferOwnership = async (e) => {
    e.preventDefault();
    let input = { email };
    const response = await fetch(TransferApi, {
      method: "PATCH",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(input),
    });
    let result = await response.json();
    if (response.status === 200) {
      onHide();
    }
    setError(result.message);
  };
  return (
    <div>
      <Modal
        show={show}
        onHide={onHide}
        style={{ backgroundColor: "black-transperant" }}
        size="md"
        centered
      >
        <Modal.Body
          style={{
            height: "40vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="up-div">
            <h4 id="update-heading">Transfer ownership</h4>
            <Form.Label></Form.Label>

            <div id="div-1">
              <p id="username">Username</p>
              <input
                onChange={(E) => setEmail(E.target.value)}
                id="fst-input"
                type="text"
                value={email}
              />
            </div>
            <p id="error">{error}</p>
            <div className="btn-div">
              <button onClick={onHide} id="cancel-btn">
                Cancel
              </button>
              <button onClick={TransferOwnership} id="update-btn">
                Update
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default TransferOwnership;
