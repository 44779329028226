import React, { useState, useEffect } from "react";
import { LIVE_PREDICTION_API } from "../env";
import SideNav from "./../SideNav";
import TopNav from "./../TopNav";
import Published from "../Matches/Published";
import SettleContest from "./SettleContest";
import Cancel from "../Matches/Cancel";
import Verify from "./../Matches/Verify";
import CalRewards2 from "./CalRewards2";
import { BsCheckCircle } from "react-icons/bs";
import { RxCrossCircled } from "react-icons/rx";

const SelectLivePredictionContest = () => {
  const [livePredictions, setLivePredictions] = useState({});
  const [toggle, setToggle] = useState(1);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [error, setError] = useState("");
  const [displayData, setDisplayData] = useState(false);
  const [users, setUsers] = useState([]);

  let GetLivePredictionsApi =
    LIVE_PREDICTION_API +
    `/api/live-predictions/${localStorage.getItem("livePredictionContestId")}`;

  let PublishLPCApi =
    LIVE_PREDICTION_API +
    `/api/live-predictions/${localStorage.getItem(
      "livePredictionContestId"
    )}/publish`;

  let SettleLPCApi =
    LIVE_PREDICTION_API +
    `/api/live-predictions/${localStorage.getItem(
      "livePredictionContestId"
    )}/settle`;

  let CancelLPCApi =
    LIVE_PREDICTION_API +
    `/api/live-predictions/${localStorage.getItem(
      "livePredictionContestId"
    )}/cancel`;

  let VerifyLPCApi =
    LIVE_PREDICTION_API +
    `/api/live-predictions/${localStorage.getItem(
      "livePredictionContestId"
    )}/verify`;

  let CalRewardsApi =
    LIVE_PREDICTION_API +
    `/api/live-predictions/${localStorage.getItem(
      "livePredictionContestId"
    )}/rewards/calculate`;
  let GetUsersApi =
    LIVE_PREDICTION_API +
    `/api/live-predictions/${localStorage.getItem(
      "livePredictionContestId"
    )}/users`;

  const GetUsers = async () => {
    const response = await fetch(GetUsersApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    setUsers(result);
  };

  const GetLivePredictions = async () => {
    const response = await fetch(GetLivePredictionsApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    setLivePredictions(result);
  };

  const PublishLPC = async () => {
    const response = await fetch(PublishLPCApi, {
      method: "PATCH",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      setOpen(true);
    } else {
      setError(result.message);
    }
  };

  const SettleLPC = async () => {
    const response = await fetch(SettleLPCApi, {
      method: "PATCH",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      setOpen2(true);
      setDisplayData(true);
    } else {
      setError(result.message);
    }
  };

  const CancelPC = async () => {
    const response = await fetch(CancelLPCApi, {
      method: "PATCH",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      setOpen3(true);
    } else {
      setError(result.message);
    }
  };

  const VerifyLPC = async () => {
    const response = await fetch(VerifyLPCApi, {
      method: "PATCH",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      setOpen4(true);
    } else {
      setError(result.message);
    }
  };

  const CalRewards = async () => {
    const response = await fetch(CalRewardsApi, {
      method: "PATCH",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      setOpen5(true);
    } else {
      setError(result.message);
    }
  };

  useEffect(() => {
    GetLivePredictions();
    GetUsers();
  }, []);
  return (
    <div style={{ display: "flex" }}>
      <SideNav />
      <div style={{ width: "87svw" }}>
        <TopNav />
        <div id="select-match-tabs">
          <div style={{ display: "flex" }}>
            <h5>Match No : </h5>
            &nbsp;
            <p style={{ display: "flex", alignItems: "center" }}>
              {livePredictions.matchNumber} ,&nbsp;{livePredictions.teamAName}{" "}
              vs {livePredictions.teamBName}
            </p>
          </div>
          <div id="main-tabs2">
            <button onClick={PublishLPC} id="publish-btn">
              Publish
            </button>
            <button onClick={SettleLPC} id="matches-btn">
              Settle
            </button>
            <button onClick={CancelPC} id="Cancel-btn">
              Cancel
            </button>
          </div>
        </div>

        <div id="tabs-div">
          <div
            style={{
              width: "26vw",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <button
              onClick={() => setToggle(1)}
              style={
                toggle === 1 ? { border: "1px solid red", color: "red" } : null
              }
              id="live-prediction-btn"
            >
              Sessions
            </button>
            <button
              onClick={() => setToggle(2)}
              style={
                toggle === 2 ? { border: "1px solid red", color: "red" } : null
              }
              id="verify-btn"
            >
              Verified by
            </button>
            <button
              onClick={() => setToggle(3)}
              style={
                toggle === 3 ? { border: "1px solid red", color: "red" } : null
              }
              id="verify-btn"
            >
              Users
            </button>
          </div>

          <div
            style={{
              width: "21vw",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <button onClick={CalRewards} id="matches-btn">
              Cal.Rewards
            </button>

            <button onClick={VerifyLPC} id="matches-btn">
              Verify
            </button>
          </div>
        </div>
        {toggle === 1 ? (
          <table
            style={{
              width: " 85vw",
              marginLeft: "1em",
            }}
          >
            <thead id="thead">
              <tr>
                <th>Published</th>
                <th>Cancelled</th>
                <th>Rewards Cal.</th>
                <th>Rewards Settle</th>
                <th>Published</th>
                <th>Verified</th>
              </tr>
            </thead>
            <tbody>
              <tr
                style={{
                  borderBottom: "1px solid lightgray",
                  height: "6vh",
                }}
              >
                <td>{livePredictions.published?.toString()}</td>
                <td>{livePredictions.cancelled?.toString()}</td>
                <td>{livePredictions.rewardsCalculated?.toString()}</td>
                <td>{livePredictions.rewardsSettled?.toString()}</td>
                <td>
                  {livePredictions.published === true ? (
                    <BsCheckCircle color="green" />
                  ) : (
                    <RxCrossCircled color="red" />
                  )}
                </td>
                <td>
                  {livePredictions.isVerified === true ? (
                    <BsCheckCircle color="green" />
                  ) : (
                    <RxCrossCircled color="red" />
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        ) : null}
        {toggle === 2 ? (
          <table
            style={{
              width: " 85vw",
              marginLeft: "1em",
            }}
          >
            <thead id="thead">
              <tr>
                <th>Email</th>
                <th>hash</th>
              </tr>
            </thead>
            <tbody>
              {livePredictions.verifiedBy?.map((data) => {
                return (
                  <tr
                    style={{
                      borderBottom: "1px solid lightgray",
                      height: "6vh",
                    }}
                  >
                    <td>{data.email}</td>
                    <td>{data.hash}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : null}
        {toggle === 3 ? (
          <table
            style={{
              width: " 85vw",
              marginLeft: "1em",
            }}
          >
            <thead id="thead">
              <tr>
                <th>Username</th>
                <th>Answer</th>
              </tr>
            </thead>
            <tbody>
              {users.map((data) => {
                return (
                  <tr
                    key={data._id}
                    style={{
                      height: "6vh",
                      borderBottom: "1px solid lightgray",
                    }}
                  >
                    <td>{data.userName}</td>
                    <td>{data.answer}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : null}
        <p style={{ color: "gray", marginTop: "5em " }}>{error}</p>
      </div>
      <Published show={open} onHide={() => setOpen(false)} />
      <SettleContest
        displayData={displayData}
        show={open2}
        onHide={() => setOpen2(false)}
      />
      <Cancel show={open3} onHide={() => setOpen3(false)} />
      <Verify show={open4} onHide={() => setOpen4(false)} />
      <CalRewards2 show={open5} onHide={() => setOpen5(false)} />
    </div>
  );
};

export default SelectLivePredictionContest;
