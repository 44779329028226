import React from "react";
import Modal from "react-bootstrap/Modal";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { useLocation } from "react-router-dom";

const SubmitQues = ({ show, onHide }) => {
  const location = useLocation();
  return (
    <div>
      <Modal
        show={show}
        onHide={onHide}
        style={{
          backgroundColor: "black-transperant",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        size="md"
        centered
      >
        {location.pathname === "/SelectMatch" ? (
          <Modal.Body
            style={{
              height: "25vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "2em",
            }}
          >
            <BsFillCheckCircleFill
              style={{ height: "6vh", width: "8vw", color: "green" }}
            />
            <h2>Reset Successfully</h2>
          </Modal.Body>
        ) : (
          <Modal.Body
            style={{
              height: "25vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "2em",
            }}
          >
            <BsFillCheckCircleFill
              style={{ height: "6vh", width: "8vw", color: "green" }}
            />
            <h2>Successfully Submited</h2>
          </Modal.Body>
        )}
      </Modal>
    </div>
  );
};

export default SubmitQues;
