import React, { useEffect, useState } from "react";
import { MANAGER } from "../env";
import SideNav from "./../SideNav";
import TopNav from "./../TopNav";
import Dropdown from "react-bootstrap/Dropdown";
import { FaSort } from "react-icons/fa";
import "./../TopNav.css";
import { RiArrowDropDownFill } from "react-icons/ri";

let adminDisplay = false;
(() => {
  let adminRole = localStorage.getItem("adminRole");

  if (adminRole & MANAGER) {
    adminDisplay = true;
  } else {
    adminDisplay = false;
  }
})();

const Users = () => {
  const [usersList, setUsersList] = useState([]);
  const [isAscending, setIsAscending] = useState(true);
  const [BALLDropdown, setBALLDropdown] = useState([]);
  const [BUSDDropdown, setBUSDDropdown] = useState([]);
  const [USDTDropdown, setUSDTDropdown] = useState([]);

  let successFullReferrals = 0;
  let isAddBotsCount = 0;
  let GetUsersApi = "https://prod.server.admin.cryptocricket.io:3004/api/users";

  const GetUsers = async () => {
    const response = await fetch(GetUsersApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();

    let BALLDropState = [...BALLDropdown];
    let BUSDDropState = [...BUSDDropdown];
    let USDTDropState = [...USDTDropdown];

    for (let i = 0; i < result.length; ++i) {
      BALLDropState.push(false);
      BUSDDropState.push(false);
      USDTDropState.push(false);
      let user = result[i];

      for (let j = 0; j < user.wallet.length; ++j) {
        let wallet = user.wallet[j];

        result[i][`${wallet.symbol}_Balance`] =
          wallet.available + (wallet.bonus || 0) + (wallet.frozen || 0);
      }
    }

    setBALLDropdown(BALLDropState);
    setBUSDDropdown(BUSDDropState);
    setUSDTDropdown(USDTDropState);

    setUsersList(result);
  };

  const sortByPrediction = () => {
    const sortedData = [...usersList];
    setIsAscending(!isAscending);

    if (isAscending) {
      sortedData
        .sort((a, b) => a.userPredictionContests - b.userPredictionContests)
        .reverse();
    } else {
      sortedData.sort(
        (a, b) => a.userPredictionContests - b.userPredictionContests
      );
    }
    setUsersList(sortedData);
  };

  const sortByLiveContests = () => {
    const sortedData = [...usersList];
    setIsAscending(!isAscending);

    if (isAscending) {
      sortedData
        .sort(
          (a, b) => a.userLivePredictionMatches - b.userLivePredictionMatches
        )
        .reverse();
    } else {
      sortedData.sort(
        (a, b) => a.userLivePredictionMatches - b.userLivePredictionMatches
      );
    }

    setUsersList(sortedData);
  };

  const sortByFantasy = () => {
    const sortedData = [...usersList];
    setIsAscending(!isAscending);

    if (isAscending) {
      sortedData
        .sort((a, b) => a.userFantasyContests - b.userFantasyContests)
        .reverse();
    } else {
      sortedData.sort((a, b) => a.userFantasyContests - b.userFantasyContests);
    }

    setUsersList(sortedData);
  };

  const sortByBALL = async () => {
    const sortedData = [...usersList];
    setIsAscending(!isAscending);

    if (isAscending) {
      sortedData.sort((a, b) => a.BALL_Balance - b.BALL_Balance).reverse();
    } else {
      sortedData.sort((a, b) => a.BALL_Balance - b.BALL_Balance);
    }
    setUsersList(sortedData);
  };

  const sortByBUSD = () => {
    const sortedData = [...usersList];
    setIsAscending(!isAscending);

    if (isAscending) {
      sortedData.sort((a, b) => a.BUSD_Balance - b.BUSD_Balance).reverse();
    } else {
      sortedData.sort((a, b) => a.BUSD_Balance - b.BUSD_Balance);
    }
    setUsersList(sortedData);
  };

  const sortByUSDT = () => {
    const sortedData = [...usersList];
    setIsAscending(!isAscending);

    if (isAscending) {
      sortedData.sort((a, b) => a.USDT_Balance - b.USDT_Balance).reverse();
    } else {
      sortedData.sort((a, b) => a.USDT_Balance - b.USDT_Balance);
    }
    setUsersList(sortedData);
  };

  useEffect(() => {
    GetUsers();
  }, []);

  return (
    <div style={{ display: "flex" }}>
      <SideNav style={{ position: "sticky" }} />
      <div>
        <TopNav />
        <div
          style={{
            height: "10vh",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "baseline",
              justifyContent: "center",
              marginLeft: "1em",
            }}
          >
            <div>
              <span
                style={{
                  fontWeight: "bold",
                }}
              >
                No of Users :
              </span>
              &nbsp;
              <span>{usersList.length}</span>
            </div>
            <div>
              <span style={{ fontWeight: "bold" }}>
                Successful Referrals :{" "}
              </span>
              <span id="sfr"></span>
            </div>
            <div style={{ display: adminDisplay ? "flex" : "none" }}>
              <span style={{ fontWeight: "bold" }}>Added Bots : </span>
              <span id="addedBotsCount"></span>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingRight: "1em",
              display: adminDisplay ? "flex" : "none",
            }}
          >
            <div
              style={{
                height: "1em",
                width: "1em",
                backgroundColor: "orange",
              }}
            ></div>
            &nbsp;
            <span>Admin</span> &nbsp;
            <div
              style={{
                height: "1em",
                width: "1em",
                backgroundColor: "rgb(230, 193, 186)",
              }}
            ></div>
            &nbsp;
            <span>Bots</span>
            &nbsp;
            <div
              style={{
                height: "1em",
                width: "1em",
                backgroundColor: "yellow",
              }}
            ></div>
            &nbsp;
            <span>Added Bots</span>
            &nbsp;
          </div>
        </div>

        <div
          style={{
            height: "75vh",
            width: "85vw",
            overflowY: "scroll",
            overflowX: "scroll",
            marginLeft: "1em",
          }}
        >
          <table
            style={{
              width: "85vw",
              cursor: "pointer",
            }}
          >
            <thead id="thead" style={{ position: "sticky", zIndex: "20000" }}>
              <tr>
                <th>Username</th>
                <th>Pub Key</th>
                <th>Referred By</th>
                <th>Total Referals</th>
                <th>Succesfull Refferal</th>
                <th>Is Active</th>
                <th onClick={sortByPrediction}>
                  No of Predictions
                  <FaSort style={{ height: "2vh", width: "2vw" }} />
                </th>
                <th onClick={sortByLiveContests}>
                  No of Sessions{" "}
                  <FaSort style={{ height: "2vh", width: "2vw" }} />
                </th>
                <th onClick={sortByFantasy}>
                  No of Fantasy{" "}
                  <FaSort style={{ height: "2vh", width: "2vw" }} />
                </th>
                <th onClick={sortByBALL}>
                  Total BALL
                  <FaSort style={{ height: "2vh", width: "2vw" }} />
                </th>
                <th onClick={sortByBUSD}>
                  Total BUSD
                  <FaSort style={{ height: "2vh", width: "2vw" }} />
                </th>
                <th onClick={sortByUSDT}>
                  Total USDT
                  <FaSort style={{ height: "2vh", width: "2vw" }} />
                </th>
              </tr>
            </thead>
            <tbody style={{ zIndex: "-100" }}>
              {usersList.map((data, index) => {
                if (data.successFullReferrals) {
                  successFullReferrals += data.successFullReferrals;
                }
                if (index === usersList.length - 1) {
                  document.getElementById("sfr").innerText =
                    successFullReferrals;
                }
                if (data.iAB) {
                  ++isAddBotsCount;
                }
                if (index === usersList.length - 1) {
                  document.querySelector("#addedBotsCount").innerHTML =
                    isAddBotsCount;
                }
                return (
                  <>
                    <tr
                      key={data._id}
                      style={{
                        borderBottom: "1px solid lightgray",
                        height: "6vh",
                        backgroundColor: setColour(data),
                      }}
                    >
                      <td>{data.userName || "-"}</td>
                      <td>{data.pubKey || "-"}</td>
                      <td>{data.referredBy || "-"}</td>
                      <td>{data.totalReferals || 0}</td>
                      <td>{data.succesfullReferals || 0}</td>
                      <td>{data.isActive.toString()}</td>
                      <td>{data.userPredictionContests}</td>
                      <td>{data.userLivePredictionMatches}</td>
                      <td>{data.userFantasyContests}</td>
                      <td
                        onClick={() => {
                          let BALLDropData = [...BALLDropdown];
                          BALLDropData[index] = !BALLDropData[index];

                          setBALLDropdown(BALLDropData);
                        }}
                        style={{
                          position: "relative",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            paddingLeft: "1em",
                          }}
                        >
                          {data.BALL_Balance.toFixed(3)}
                          <RiArrowDropDownFill />
                        </div>

                        {BALLDropdown[index] ? (
                          <div
                            style={{
                              height: "15vh",
                              width: "15vw",
                              backgroundColor: "white",
                              boxShadow: "0px 0px 4px gray",
                              borderRadius: "5px",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              position: "absolute",
                              zIndex: "1000",
                            }}
                          >
                            <div style={{ display: "flex" }}>
                              <h5>Available : </h5>
                              <p>
                                {(() => {
                                  for (
                                    let i = 0;
                                    i < data?.wallet?.length;
                                    ++i
                                  ) {
                                    let value = data.wallet[i];
                                    if (value.symbol === "BALL") {
                                      let total = value.available;

                                      return total.toFixed(3);
                                    }
                                  }
                                })()}
                              </p>
                            </div>
                            <div style={{ display: "flex" }}>
                              <h5>Frozen : </h5>
                              <p>
                                {(() => {
                                  for (
                                    let i = 0;
                                    i < data?.wallet?.length;
                                    ++i
                                  ) {
                                    let value = data.wallet[i];
                                    if (value.symbol === "BALL") {
                                      let total = value.frozen;

                                      return total.toFixed(3);
                                    }
                                  }
                                })()}
                              </p>
                            </div>
                            <div style={{ display: "flex" }}>
                              <h5>Bonus : </h5>
                              <p>
                                {(() => {
                                  for (
                                    let i = 0;
                                    i < data?.wallet?.length;
                                    ++i
                                  ) {
                                    let value = data.wallet[i];
                                    if (value.symbol === "BALL") {
                                      let total = value.bonus;
                                      return total.toFixed(3);
                                    }
                                  }
                                })()}
                              </p>
                            </div>
                          </div>
                        ) : null}
                      </td>
                      {/* <td>
                        <Dropdown
                          style={{
                            zIndex: "-1",
                            position: "inherit",
                          }}
                          className="d-inline mx-2"
                          autoClose="outside"
                        >
                          <Dropdown.Toggle
                            style={{
                              color: "black",
                            }}
                            id="dropdown-autoclose-outside"
                          >
                            {data.BALL_Balance.toFixed(3)}
                            <RiArrowDropDownFill />
                          </Dropdown.Toggle>
                          <Dropdown.Menu
                            style={{
                              background: "none",
                              border: "none",
                              height: "5vh",
                              color: "black",
                            }}
                          >
                            <div
                              style={{
                                height: "15vh",
                                width: "15vw",
                                backgroundColor: "white",
                                boxShadow: "0px 0px 4px gray",
                                borderRadius: "5px",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div style={{ display: "flex" }}>
                                <h5>Available : </h5>
                                <p>
                                  {(() => {
                                    for (
                                      let i = 0;
                                      i < data?.wallet?.length;
                                      ++i
                                    ) {
                                      let value = data.wallet[i];
                                      if (value.symbol === "BALL") {
                                        let total = value.available;

                                        return total.toFixed(3);
                                      }
                                    }
                                  })()}
                                </p>
                              </div>
                              <div style={{ display: "flex" }}>
                                <h5>Frozen : </h5>
                                <p>
                                  {(() => {
                                    for (
                                      let i = 0;
                                      i < data?.wallet?.length;
                                      ++i
                                    ) {
                                      let value = data.wallet[i];
                                      if (value.symbol === "BALL") {
                                        let total = value.frozen;

                                        return total.toFixed(3);
                                      }
                                    }
                                  })()}
                                </p>
                              </div>
                              <div style={{ display: "flex" }}>
                                <h5>Bonus : </h5>
                                <p>
                                  {(() => {
                                    for (
                                      let i = 0;
                                      i < data?.wallet?.length;
                                      ++i
                                    ) {
                                      let value = data.wallet[i];
                                      if (value.symbol === "BALL") {
                                        let total = value.bonus;
                                        return total.toFixed(3);
                                      }
                                    }
                                  })()}
                                </p>
                              </div>
                            </div>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td> */}
                      <td
                        onClick={() => {
                          let BUSDDropData = [...BUSDDropdown];
                          BUSDDropData[index] = !BUSDDropData[index];

                          setBUSDDropdown(BUSDDropData);
                        }}
                        style={{
                          position: "relative",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            paddingLeft: "1em",
                          }}
                        >
                          {data.BUSD_Balance.toFixed(3)}
                          <RiArrowDropDownFill />
                        </div>

                        {BUSDDropdown[index] ? (
                          <div
                            style={{
                              height: "15vh",
                              width: "15vw",
                              backgroundColor: "white",
                              boxShadow: "0px 0px 4px gray",
                              borderRadius: "5px",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              position: "absolute",
                              zIndex: "1000",
                              marginLeft: "-5em",
                            }}
                          >
                            <div style={{ display: "flex" }}>
                              <h5>Available : </h5>
                              <p>
                                {(() => {
                                  for (
                                    let i = 0;
                                    i < data?.wallet?.length;
                                    ++i
                                  ) {
                                    let value = data.wallet[i];
                                    if (value.symbol === "BUSD") {
                                      let total = value.available;

                                      return total.toFixed(3);
                                    }
                                  }
                                })()}
                              </p>
                            </div>
                            <div style={{ display: "flex" }}>
                              <h5>Frozen : </h5>
                              <p>
                                {(() => {
                                  for (
                                    let i = 0;
                                    i < data?.wallet?.length;
                                    ++i
                                  ) {
                                    let value = data.wallet[i];
                                    if (value.symbol === "BUSD") {
                                      let total = value.frozen;

                                      return total.toFixed(3);
                                    }
                                  }
                                })()}
                              </p>
                            </div>
                            <div style={{ display: "flex" }}>
                              <h5>Bonus : </h5>
                              <p>
                                {(() => {
                                  for (
                                    let i = 0;
                                    i < data?.wallet?.length;
                                    ++i
                                  ) {
                                    let value = data.wallet[i];
                                    if (value.symbol === "BUSD") {
                                      let total = value.bonus;
                                      return total.toFixed(3);
                                    }
                                  }
                                })()}
                              </p>
                            </div>
                          </div>
                        ) : null}
                      </td>
                      <td
                        onClick={() => {
                          let USDTDropData = [...USDTDropdown];
                          USDTDropData[index] = !USDTDropData[index];

                          setUSDTDropdown(USDTDropData);
                        }}
                        style={{
                          position: "relative",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            paddingLeft: "1em",
                          }}
                        >
                          {data.USDT_Balance.toFixed(3)}
                          <RiArrowDropDownFill />
                        </div>

                        {USDTDropdown[index] ? (
                          <div
                            style={{
                              height: "15vh",
                              width: "15vw",
                              backgroundColor: "white",
                              boxShadow: "0px 0px 4px gray",
                              borderRadius: "5px",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              position: "absolute",
                              zIndex: "1000",
                              marginLeft: "-10em",
                            }}
                          >
                            <div style={{ display: "flex" }}>
                              <h5>Available : </h5>
                              <p>
                                {(() => {
                                  for (
                                    let i = 0;
                                    i < data?.wallet?.length;
                                    ++i
                                  ) {
                                    let value = data.wallet[i];
                                    if (value.symbol === "USDT") {
                                      let total = value.available;

                                      return total.toFixed(3);
                                    }
                                  }
                                })()}
                              </p>
                            </div>
                            <div style={{ display: "flex" }}>
                              <h5>Frozen : </h5>
                              <p>
                                {(() => {
                                  for (
                                    let i = 0;
                                    i < data?.wallet?.length;
                                    ++i
                                  ) {
                                    let value = data.wallet[i];
                                    if (value.symbol === "USDT") {
                                      let total = value.frozen;

                                      return total.toFixed(3);
                                    }
                                  }
                                })()}
                              </p>
                            </div>
                            <div style={{ display: "flex" }}>
                              <h5>Bonus : </h5>
                              <p>
                                {(() => {
                                  for (
                                    let i = 0;
                                    i < data?.wallet?.length;
                                    ++i
                                  ) {
                                    let value = data.wallet[i];
                                    if (value.symbol === "USDT") {
                                      let total = value.bonus;
                                      return total.toFixed(3);
                                    }
                                  }
                                })()}
                              </p>
                            </div>
                          </div>
                        ) : null}
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Users;

function openWallet(index) {
  document.querySelector("#" + index).style.display =
    document.querySelector("#" + index).style.display === "block"
      ? "none"
      : "block";
  return;
}
function setColour(data) {
  let colourCode = "";
  if (adminDisplay) {
    if (data.type === "admin") {
      return "orange";
    }
    if (data.iB) {
      colourCode = "rgb(230, 193, 186)";
    }
    if (data.iAB) {
      colourCode = "yellow";
    }
  }
  return colourCode;
}
