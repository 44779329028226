import React, { useState, useEffect } from "react";
import { LIVE_PREDICTION_API, SERVER_API } from "../env";
import Modal from "react-bootstrap/Modal";
import { RxCross1 } from "react-icons/rx";
import { useLocation } from "react-router-dom";
import { MdAdd } from "react-icons/md";
import { AiOutlineMinus } from "react-icons/ai";

const CreateLivePrediction = ({ modalShow, setModalShow }) => {
  const location = useLocation();
  const [matchId, setMatchId] = useState("");
  const [question, setQuestion] = useState("");
  const [inputField, setInputField] = useState([""]);
  const [endDate, setEndDate] = useState("");
  const [symbol, setSymbol] = useState("");
  const [entryFee, setEntryFee] = useState("");
  const [allMatches, setAllMatches] = useState([]);
  const [error, setError] = useState("");

  let LiveMatchesApi = SERVER_API + "/api/matches?status=live";
  let CreateLivePredictionApi = LIVE_PREDICTION_API + "/api/live-predictions";
  let UpcomingMatchesApi = SERVER_API + "/api/matches?status=upcoming";

  const handleAddField = () => {
    setInputField([...inputField, ""]); // Add an empty input field to the array
  };

  const handleRemoveField = (index) => {
    const newInputFields = [...inputField];
    newInputFields.splice(index, 1);
    setInputField(newInputFields);
  };

  const handleChange = (e, index) => {
    const { value } = e.target;
    const onChangeVal = [...inputField];
    onChangeVal[index] = value;
    setInputField(onChangeVal);
  };

  const GetRecMatches = async () => {
    let response = await fetch(UpcomingMatchesApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();

    let temp = [...result];

    response = await fetch(LiveMatchesApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    result = await response.json();
    temp = [...temp, ...result];
    setAllMatches(temp);
  };

  useEffect(() => {
    GetRecMatches();
  }, []);

  const PostLivePrediction = async () => {
    let input = {
      matchId: `${localStorage.getItem("matchId")}`,
      question,
      // options: options.split(","),
      options: inputField,
      endDate: convertDateToTimestamp(endDate),
      symbol: symbol.toUpperCase(),
      entryFee,
    };
    const response = await fetch(CreateLivePredictionApi, {
      method: "POST",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(input),
    });
    let result = await response.json();
    if (response.status === 201) {
      setModalShow(false);
    } else {
      setError(result.message);
    }
  };

  return (
    <div>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body
          style={{
            height: "60vh",
          }}
        >
          <div
            style={{
              height: "55vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h4>Create Live Session</h4>
              <RxCross1 onClick={setModalShow} color="black" />
            </div>
            <div
              style={{
                height: "40vh",
                display: "flex",
                justifyContent: "space-around",
                marginTop: "1em",
              }}
            >
              <div
                style={
                  location.pathname === "/LivePredictionContest"
                    ? {
                        height: "40vh",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        padding: "0.5em",
                      }
                    : {
                        height: "35vh",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        padding: "0.5em",
                      }
                }
              >
                {location.pathname === "/LivePredictionContest" ? (
                  <div>
                    <p id="username">Match Id</p>
                    <select
                      id="select"
                      onChange={(E) => {
                        setMatchId(E.target.value);
                      }}
                      value={matchId}
                    >
                      {allMatches?.map((data) => {
                        return (
                          <option key={data._id} value={data._id}>
                            {data.series} - {data.teamA} vs {data.teamB} -
                            {data.matchNumber}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                ) : null}

                <div id="div-2">
                  <p id="username">Question</p>
                  <input
                    onChange={(e) => setQuestion(e.target.value)}
                    id="fst-input"
                    type="text"
                    value={question}
                  />
                </div>
                <div id="div-2">
                  <p id="username">End Date</p>
                  <input
                    onChange={(e) => setEndDate(e.target.value)}
                    id="fst-input"
                    type="datetime-local"
                    value={endDate}
                  />
                </div>
                <div id="div-2">
                  <p id="username">Symbol</p>
                  <input
                    onChange={(e) => setSymbol(e.target.value)}
                    id="fst-input"
                    type="text"
                    value={symbol.toUpperCase()}
                  />
                </div>

                <div id="div-2">
                  <p id="username">Entry Fee</p>
                  <input
                    onChange={(e) => setEntryFee(e.target.value)}
                    id="fst-input"
                    type="number"
                    value={entryFee}
                  />
                </div>
              </div>

              <div
                style={{
                  height: "35vh",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    overflowY: "scroll",
                    position: "relative",
                    padding: "0.5em",
                  }}
                >
                  {inputField.map((data, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          width: "23vw",
                          height: "10vh",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        id="div-2"
                      >
                        <p id="username">Option {index + 1}</p>
                        <input
                          style={{ width: "16vw" }}
                          onChange={(e) => handleChange(e, index)}
                          id="fst-input"
                          type="text"
                          value={data}
                        />
                        <button
                          style={{
                            border: "1px solid red",
                            borderRadius: "5px",
                            backgroundColor: "white",
                            height: "6vh",
                            width: "3vw",
                          }}
                          onClick={handleAddField}
                        >
                          <MdAdd style={{ color: "red" }} />
                        </button>
                        <button
                          onClick={() => handleRemoveField(index)}
                          disabled={index === 0}
                          style={{
                            border: "1px solid red",
                            borderRadius: "5px",
                            backgroundColor: "white",
                            height: "6vh",
                            width: "3vw",
                          }}
                        >
                          <AiOutlineMinus style={{ color: "red" }} />
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <p id="error">{error}</p>

            <div>
              <button onClick={PostLivePrediction} id="update-btn2">
                Create
              </button>
              <button onClick={setModalShow} id="cancel-btn2">
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CreateLivePrediction;

function convertDateToTimestamp(date) {
  var dateTime = date.split("T");
  var date = dateTime[0].split("-");
  var dateStr = `${date[1]}-${date[2]}-${date[0]}`.concat(" ");
  var timeStamp = Date.parse(dateStr.concat(dateTime[1]));
  return timeStamp / 1000;
}
