import React from "react";
import { PREDICTION_API } from "./../env";
import { useState, useEffect } from "react";
import "./Matches.css";
import { useNavigate } from "react-router-dom";
import { BsCheckCircle } from "react-icons/bs";
import { RxCrossCircled } from "react-icons/rx";
import { getAdmin } from "../getAdmin";
import LivePrediction from "./LivePrediction";
import Fantasy from "./Fantasy";
import CreatePredictionConfig from "./CreatePredictionConfig";
import CreateLivePredictionConfig from "./CreateLivePredictionConfig";
import CreateFantasyConfig from "./CreateFantasyConfig";
import SideNav from "./../SideNav";
import TopNav from "./../TopNav";
import CreateLivePrediction from "../Contests/CreateLivePrediction";
import CreateFantasyContest from "./../Contests/CreateFantasyContest";
import CreateContests2 from "./../Contests/CreateContests";
import { AiOutlineDelete } from "react-icons/ai";
import DeletePredictionContest from "./../Contests/DeletePredictionContest";

const ContestsInMatch = () => {
  let adminDetails = {};
  (async () => {
    adminDetails = await getAdmin();
  })();

  const navigate = useNavigate();
  const [predictionContests, setPredictionContests] = useState([]);
  const [toggle, setToggle] = useState(1);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [openCreatePredictions, setOpenCreatePredictions] = useState(false);
  const [openCreateLivePrediction, setOpenCreateLivePrediction] =
    useState(false);
  const [createFantasyContest, setCreateFantasyContest] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [error, setError] = useState("");

  let PredictionContestsApi =
    PREDICTION_API +
    `/api/matches/${localStorage.getItem("matchId")}/prediction-contests`;

  const GetPredictionContests = async () => {
    const response = await fetch(PredictionContestsApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      setPredictionContests(result);
    } else {
      setError(result.message);
    }
  };

  const handleNavigate = async (id) => {
    localStorage.setItem("predictionContestId", id);
    navigate("/SelectPredictionContest");
  };

  function changeTab(toggleVal) {
    setToggle(toggleVal);

    // Get the current URL
    var url = window.location.href;

    var searchParams = new URLSearchParams(window.location.search);

    searchParams.set("toggle", toggleVal);

    var newUrl = url.split("?")[0] + "?" + searchParams.toString();

    window.history.pushState(null, "", newUrl);
  }
  useEffect(() => {
    GetPredictionContests();
    let tabVal = window.location.search?.split("?");
    if (tabVal.length >= 2) {
      tabVal = tabVal[1]?.split("=");
      tabVal = parseInt(tabVal[1]) || 1;
      setToggle(tabVal);
    }
  }, [
    open,
    open2,
    open3,
    openCreatePredictions,
    openCreateLivePrediction,
    createFantasyContest,
  ]);

  const handleDelete = (id) => {
    localStorage.setItem("predictionContestId", id);
    setOpenDelete(true);
  };

  return (
    <div style={{ display: "flex" }}>
      <SideNav />
      <div>
        <TopNav />
        <div>
          <div id="tabs-div">
            <div
              style={{
                width: "25vw",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <button
                onClick={() => changeTab(1)}
                style={
                  toggle === 1
                    ? { border: "1px solid red", color: "red" }
                    : null
                }
                id="match-btn"
              >
                Prediction
              </button>
              <button
                onClick={() => changeTab(2)}
                style={
                  toggle === 2
                    ? { border: "1px solid red", color: "red" }
                    : null
                }
                id="live-prediction-btn"
              >
                LivePrediction
              </button>
              <button
                onClick={() => changeTab(3)}
                style={
                  toggle === 3
                    ? { border: "1px solid red", color: "red" }
                    : null
                }
                id="match-btn"
              >
                Fantasy
              </button>
            </div>
            <div>
              {toggle === 1 ? (
                <div id="toggle-btn">
                  <button onClick={() => setOpen(true)} id="create-btn">
                    Create Config
                  </button>
                  <button
                    onClick={() => setOpenCreatePredictions(true)}
                    id="create-prediction-btn"
                  >
                    Create Prediction
                  </button>
                </div>
              ) : null}
              {toggle === 2 ? (
                <div id="toggle-btn">
                  <button onClick={() => setOpen2(true)} id="create-btn">
                    Create Config
                  </button>
                  <button
                    onClick={() => setOpenCreateLivePrediction(true)}
                    id="create-prediction-btn"
                  >
                    Create LivePrediction
                  </button>
                </div>
              ) : null}
              {toggle === 3 ? (
                <div
                  style={{
                    width: "21vw",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <button onClick={() => setOpen3(true)} id="create-btn">
                    Create Config
                  </button>
                  <button
                    onClick={() => setCreateFantasyContest(true)}
                    id="create-btn"
                  >
                    Create Fantasy
                  </button>
                </div>
              ) : null}
            </div>
          </div>
          <div style={{ height: "75vh", overflowY: "scroll" }}>
            {toggle === 1 ? (
              <table id="contests-table">
                <thead id="thead" style={{ position: "sticky" }}>
                  <tr>
                    <th>Contest Name</th>
                    <th>Series</th>
                    <th>Start Date</th>
                    <th>Entry Fee</th>
                    <th>Users In Contests</th>
                    <th>Min Users Required</th>
                    <th>Commission</th>
                    <th>Settled</th>
                    <th>Is Verified</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {predictionContests?.map((data) => {
                    return (
                      <tr
                        key={data._id}
                        style={{
                          borderBottom: "1px solid lightgray",
                          height: "6vh",
                        }}
                      >
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.contestName}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.series}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {getDateFromTimeStamp(data.startDate)}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.entryFee}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.usersInContests}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.minimumUsersRequired || 0}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.commission}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.rewardsSettled?.toString()}
                        </td>
                        <td>
                          {data.isVerified === true ? (
                            <BsCheckCircle color={"green"} />
                          ) : (
                            <RxCrossCircled color={"red"} />
                          )}
                        </td>
                        <td>
                          <AiOutlineDelete
                            onClick={() => handleDelete(data._id)}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : null}
            {toggle === 2 ? (
              <div>
                <LivePrediction />
              </div>
            ) : null}
            {toggle === 3 ? (
              <div>
                <Fantasy />
              </div>
            ) : null}
          </div>
          <p id="error" style={{ color: "gray", marginTop: "2em" }}>
            {error}
          </p>
        </div>
        <CreatePredictionConfig show={open} onHide={() => setOpen(false)} />
        <CreateLivePredictionConfig
          show={open2}
          onHide={() => setOpen2(false)}
        />
        <CreateFantasyConfig show={open3} onHide={() => setOpen3(false)} />
        <CreateContests2
          modalShow={openCreatePredictions}
          setModalShow={() => setOpenCreatePredictions(false)}
        />
        <CreateLivePrediction
          modalShow={openCreateLivePrediction}
          setModalShow={() => setOpenCreateLivePrediction(false)}
        />
        <CreateFantasyContest
          modalShow={createFantasyContest}
          setModalShow={() => setCreateFantasyContest(false)}
        />
      </div>
      <DeletePredictionContest
        show={openDelete}
        onHide={() => setOpenDelete(false)}
      />
    </div>
  );
};

function getDateFromTimeStamp(timeStamp) {
  return new Date(timeStamp * 1000).toLocaleDateString("en-IN", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
}

export default ContestsInMatch;
