import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { RxCross1 } from "react-icons/rx";
import "./OGCricket.css";
import { SERVER_API } from "./../env";

const AddSession = ({ show, onHide }) => {
  const [sessionStart, setSessionStart] = useState("");
  const [sessionEnd, setSessionEnd] = useState("");
  const [innings, setInnings] = useState("");
  const [predictionScore, setPredictionScore] = useState("");
  const [scoreDirection, setScoreDirection] = useState("");
  const [error, setError] = useState("");

  let AddSessionApi = SERVER_API + "/api/og/session";

  const PostSession = async () => {
    let input = {
      matchInstanceId: localStorage.getItem("matchInstanceId"),
      sessionStart,
      sessionEnd,
      innings,
      predictionScore,
      scoreDirection,
    };
    const response = await fetch(AddSessionApi, {
      method: "POST",
      headers: {
        mode: "cors",
        // Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(input),
    });
    let result = await response.json();
    if (response.status === 201) {
      onHide();
    } else {
      setError(result.message);
    }
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={onHide}
        style={{
          backgroundColor: "black-transperant",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        size="md"
        centered
      >
        <Modal.Body
          style={{
            height: "60vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div id="modal-top-div">
            <RxCross1
              style={{ color: "black", float: "right" }}
              onClick={onHide}
            />
          </div>

          <div className="add-session-inputs">
            <div id="div-1">
              <p id="otp-email">Innings</p>
              <input
                onChange={(E) => setInnings(E.target.value)}
                id="fst-input"
                type="text"
                value={innings}
              />
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div id="div-1">
                <p id="otp-email">Start</p>
                <input
                  onChange={(E) => setSessionStart(E.target.value)}
                  id="start-input"
                  type="number"
                  value={sessionStart}
                />
              </div>
              <div id="div-1">
                <p id="otp-email">End</p>
                <input
                  onChange={(E) => setSessionEnd(E.target.value)}
                  id="start-input"
                  type="number"
                  value={sessionEnd}
                />
              </div>
            </div>
            <div id="div-2">
              <p id="old-password">Our Prediction</p>
              <input
                onChange={(E) => setPredictionScore(E.target.value)}
                id="sec-input"
                type="number"
                value={predictionScore}
              />
            </div>
            <div id="div-3">
              <p id="new-password">Select Range</p>
              <select
                onChange={(E) => setScoreDirection(E.target.value)}
                id="sec-input"
                value={scoreDirection}
              >
                <option value="select">Select</option>
                <option value="above">above</option>
                <option value="below">below</option>
              </select>
            </div>
          </div>
          <p id="error">{error}</p>

          <div
            style={{
              width: "20vw",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <button onClick={onHide} className="add-session-btn">
              Cancel
            </button>
            <button onClick={PostSession} className="add-session-btn">
              Add
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddSession;
