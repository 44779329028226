import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SERVER_API } from "../env";
import SideNav from "./../SideNav";
import TopNav from "./../TopNav";
import CreateTournament from "./CreateTournament";
import "./Tournaments.css";
import { FiEdit } from "react-icons/fi";
import { BiEdit } from "react-icons/bi";
import EditTournament from "./EditTournament";
import { AiOutlineDelete } from "react-icons/ai";
import DeleteTournament from "./DeleteTournament";

const Tournaments = () => {
  const navigate = useNavigate();
  const [tournamentList, setTournamentList] = useState([]);
  const [openCreate, setOpenCreate] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [toggle, setToggle] = useState(1);
  const [individualData, setIndividualData] = useState({});

  let GetTournamentApi = SERVER_API + "/api/tournaments";

  const GetTournaments = async () => {
    try {
      const response = await fetch(GetTournamentApi, {
        method: "GET",
        headers: {
          mode: "cors",
          Accept: "application/json",
          token: localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      });
      let result = await response.json();
      setTournamentList(result);
    } catch (e) {
      console.log("error in get tournaments", e);
    }
  };

  const handleNavigate = (id) => {
    localStorage.setItem("tournamentId", id);
    navigate("/SelectTournament");
  };

  const handleEdit = (id) => {
    localStorage.setItem("tournamentId", id);
    setOpenEdit(true);
  };

  const handleDelete = (id) => {
    localStorage.setItem("tournamentId", id);
    setOpenDelete(true);
  };

  useEffect(() => {
    GetTournaments();
  }, [openEdit, openCreate, openDelete]);

  return (
    <div style={{ display: "flex" }}>
      <SideNav />
      <div>
        <TopNav />
        <div id="tabs-div">
          <div
            style={{
              width: "17vw",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <button
              onClick={() => setToggle(1)}
              style={
                toggle === 1 ? { border: "1px solid red", color: "red" } : null
              }
              id="match-btn"
            >
              Active
            </button>
            <button
              onClick={() => setToggle(2)}
              style={
                toggle === 2 ? { border: "1px solid red", color: "red" } : null
              }
              id="verify-btn"
            >
              Completed
            </button>
          </div>
          <div>
            <button
              onClick={() => setOpenCreate(true)}
              id="create-tournaments-btn"
            >
              Create Tournaments
            </button>
          </div>
        </div>
        <div style={{ overflowY: "scroll", height: "75vh" }}>
          <table id="teams-table">
            <thead id="thead" style={{ position: "sticky" }}>
              <tr>
                <th>Tournament Name</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Contest Type</th>
                <th>Reward Type</th>
                <th>Rules</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {toggle === 1
                ? tournamentList
                    .filter((value) => {
                      if (value.rewardsSettled === false) {
                        return value;
                      }
                    })
                    .map((data) => {
                      console.log(data);
                      return (
                        <tr
                          style={{
                            borderBottom: "1px solid lightgray",
                            height: "6vh",
                          }}
                        >
                          <td onClick={() => handleNavigate(data._id)}>
                            {data.tournamentName}
                          </td>
                          <td onClick={() => handleNavigate(data._id)}>
                            {getDateFromTimeStamp(data.startDate)}
                          </td>
                          <td onClick={() => handleNavigate(data._id)}>
                            {getDateFromTimeStamp(data.endDate)}
                          </td>
                          <td onClick={() => handleNavigate(data._id)}>
                            {data.contestType}
                          </td>
                          <td onClick={() => handleNavigate(data._id)}>
                            {data.rewardType}
                          </td>
                          <td onClick={() => handleNavigate(data._id)}>
                            {data.rules}
                          </td>
                          <td>
                            <td id="Action-div">
                              <div id="Action-icons">
                                <BiEdit
                                  onClick={() => {
                                    handleEdit(data._id);
                                    setIndividualData(data);
                                  }}
                                />
                                <AiOutlineDelete
                                  onClick={() => handleDelete(data._id)}
                                />
                              </div>
                            </td>
                          </td>
                        </tr>
                      );
                    })
                : null}

              {toggle === 2
                ? tournamentList
                    .filter((value) => {
                      if (value.rewardsSettled === true) {
                        return value;
                      }
                    })
                    .map((data) => {
                      console.log(data);
                      return (
                        <tr
                          style={{
                            borderBottom: "1px solid lightgray",
                            height: "6vh",
                          }}
                        >
                          <td onClick={() => handleNavigate(data._id)}>
                            {data.tournamentName}
                          </td>
                          <td onClick={() => handleNavigate(data._id)}>
                            {getDateFromTimeStamp(data.startDate)}
                          </td>
                          <td onClick={() => handleNavigate(data._id)}>
                            {getDateFromTimeStamp(data.endDate)}
                          </td>
                          <td onClick={() => handleNavigate(data._id)}>
                            {data.contestType}
                          </td>
                          <td onClick={() => handleNavigate(data._id)}>
                            {data.rewardType}
                          </td>
                          <td onClick={() => handleNavigate(data._id)}>
                            {data.rules}
                          </td>
                          <td id="Action-div">
                            <div id="Action-icons">
                              <BiEdit
                                onClick={() => {
                                  handleEdit(data._id);
                                  setIndividualData(data);
                                }}
                              />
                              <AiOutlineDelete />
                            </div>
                          </td>
                        </tr>
                      );
                    })
                : null}
            </tbody>
          </table>
        </div>
      </div>
      <CreateTournament
        modalShow={openCreate}
        setModalShow={() => setOpenCreate(false)}
      />
      <EditTournament
        tournamentList={individualData}
        modalShow={openEdit}
        setModalShow={() => setOpenEdit(false)}
      />
      <DeleteTournament show={openDelete} onHide={() => setOpenDelete(false)} />
    </div>
  );
};

export default Tournaments;

function getDateFromTimeStamp(timeStamp) {
  return new Date(timeStamp * 1000).toLocaleDateString("en-IN", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
}
