import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { RxCross1 } from "react-icons/rx";
import { SERVER_API } from "../env";

const CreateSeriesStake = ({ show, onHide }) => {
  const [seriesId, setSeriesId] = useState("");
  const [seriesData, setSeriesData] = useState([]);
  const [lastDateToStake, setLastDateToStake] = useState("");
  const [error, setError] = useState("");
  const [maxAmount, setMaxAmount] = useState("");

  let CreateSeriesStakeApi = SERVER_API + "/api/series-stakes";
  let GetSeriesApi = SERVER_API + "/api/series?status=upcoming";

  const GetSeries = async () => {
    const response = await fetch(GetSeriesApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    setSeriesData([{ seriesName: "Select" }, ...result]);
    setSeriesId(seriesData[0]?._id);
    return seriesData[0]?._id;
  };

  const PostSeriesStake = async () => {
    let input = {
      seriesId,
      maxAmount,
      lastDateToStake: convertDateToTimestamp(lastDateToStake),
    };
    const response = await fetch(CreateSeriesStakeApi, {
      method: "POST",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(input),
    });
    let result = await response.json();
    if (response.status === 200) {
      onHide();
    } else {
      setError(result.message);
    }
  };

  useEffect(() => {
    GetSeries();
  }, []);

  return (
    <div>
      <Modal
        show={show}
        onHide={onHide}
        style={{
          backgroundColor: "black-transperant",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        size="md"
        centered
      >
        <Modal.Body
          style={{
            height: "55vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div id="modal-top-div">
            <RxCross1
              style={{ color: "black", float: "right" }}
              onClick={onHide}
            />
            <h4>Create Series Stake</h4>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "45vh",
              justifyContent: "space-between",
              marginTop: "2em",
            }}
          >
            <div id="div-1">
              <p id="otp-email">Series Id</p>
              <select
                onChange={(E) => setSeriesId(E.target.value)}
                id="select"
                value={seriesId}
              >
                {seriesData?.map((data) => {
                  return (
                    <option key={data._id} value={data._id}>
                      {data.seriesName}
                    </option>
                  );
                })}
              </select>
            </div>
            <div id="div-2">
              <p id="username">Last date to Stake</p>
              <input
                onChange={(E) => setLastDateToStake(E.target.value)}
                id="fst-input"
                type="datetime-local"
                value={lastDateToStake}
              />
            </div>

            <div id="div-2">
              <p id="username">Max Amount</p>
              <input
                onChange={(E) => setMaxAmount(E.target.value)}
                type="number"
                id="fst-input"
                value={maxAmount}
              />
            </div>
            <div
              style={{
                width: "20vw",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <button onClick={onHide} className="add-session-btn">
                Cancel
              </button>
              <button onClick={PostSeriesStake} className="add-session-btn">
                Create
              </button>
            </div>
          </div>
          <p id="error">{error}</p>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CreateSeriesStake;

function convertDateToTimestamp(date) {
  var dateTime = date.split("T");
  var date = dateTime[0].split("-");
  var dateStr = `${date[1]}-${date[2]}-${date[0]}`.concat(" ");
  var timeStamp = Date.parse(dateStr.concat(dateTime[1]));
  return timeStamp / 1000;
}
