import React from "react";
import Modal from "react-bootstrap/Modal";
import { RxCross1 } from "react-icons/rx";
import { useState, useEffect } from "react";
import { SERVER_API } from "../env";
import "./OGCricket.css";

const CreatePrediction = ({ show, onHide }) => {
  const [seriesList, setSeriesList] = useState([]);
  const [seriesId, setSeriesId] = useState("");
  const [matchId, setMatchId] = useState("");
  const [MatchesList, setMatchesList] = useState([]);
  const [error, setError] = useState("");

  let SeriesApi = SERVER_API + "/api/series";
  let MatchesApi = SERVER_API + "/api/matches";
  let CreatePredictionApi = SERVER_API + "/api/og/match-instance";

  const PostPrediction = async () => {
    let input = { seriesId, matchId };
    const response = await fetch(CreatePredictionApi, {
      method: "POST",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(input),
    });
    let result = await response.json();
    if (response.status === 200) {
      onHide();
      window.location.reload();
    } else {
      setError(result.message);
    }
  };

  const GetSeries = async () => {
    const response = await fetch(SeriesApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    setSeriesList([{ seriesName: "Select" }, ...result]);
  };

  const GetMatches = async (id) => {
    if (id) {
      MatchesApi = `${SERVER_API}/api/series/${id}/matches`;
    }
    const response = await fetch(MatchesApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    setMatchesList([{ matchNumber: "Select" }, ...result]);
  };

  useEffect(() => {
    GetSeries();
    GetMatches();
  }, []);
  return (
    <div>
      <Modal
        show={show}
        onHide={onHide}
        style={{
          backgroundColor: "black-transperant",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        size="md"
        centered
      >
        <Modal.Body
          style={{
            height: "50vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div id="head-div">
            <h4>Create Prediction</h4>
            <RxCross1 color="black" onClick={onHide} />
          </div>
          <div className="prediction-inputs-div">
            <div id="div-1">
              <p id="otp-email">Select Series</p>
              <select
                onChange={(E) => {
                  setSeriesId(E.target.value);
                  GetMatches(E.target.value);
                }}
                id="select"
                value={seriesId}
              >
                {seriesList?.map((data) => {
                  return (
                    <option key={data._id} value={data._id}>
                      {data.seriesName}
                    </option>
                  );
                })}
              </select>
            </div>

            <div id="div-2">
              <p id="old-password">Select Match</p>
              <select
                id="sec-input"
                onChange={(E) => setMatchId(E.target.value)}
                value={matchId}
              >
                {MatchesList?.map((data) => {
                  return (
                    <option key={data._id} value={data._id}>
                      {data.matchNumber}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <p id="error">{error}</p>
          <button onClick={PostPrediction} id="button">
            Create
          </button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CreatePrediction;
