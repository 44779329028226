import React, { useState, useEffect } from "react";
import { LIVE_PREDICTION_API } from "../env";
import SideNav from "./../SideNav";
import TopNav from "./../TopNav";
import { useNavigate } from "react-router-dom";
import CreateLivePrediction from "./CreateLivePrediction";
import CreateLivePredictionConfig from "./../Matches/CreateLivePredictionConfig";
import { BiEdit } from "react-icons/bi";
import { AiOutlineDelete } from "react-icons/ai";
import DeleteLivePredictionContest from "./DeleteLivePredictionContest";
import EditLivePredictionAnswer from "./../Matches/EditLivePredictionAnswer";

const LivePredictionContest = () => {
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(1);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [livePredictionContests, setLivePredictionContests] = useState([]);
  const [individualData, setIndividualData] = useState({});
  const [modalShow2, setModalShow2] = useState(false);
  const [error, setError] = useState("");

  let LivePredictionApi = LIVE_PREDICTION_API + "/api/live-predictions";

  const GetLivePredictions = async () => {
    const response = await fetch(LivePredictionApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      setLivePredictionContests(result);
    } else {
      setError(result.message);
    }
  };

  const handleNavigate = async (id) => {
    localStorage.setItem("livePredictionContestId", id);
    navigate("/SelectLivePredictions");
  };

  const handleEdit = (id) => {
    localStorage.setItem("livePredictionContestId", id);
    setModalShow2(true);
  };

  const handleDelete = (id) => {
    localStorage.setItem("livePredictionContestId", id);
    setOpen2(true);
  };

  useEffect(() => {
    GetLivePredictions();
  }, [modalShow, modalShow2, individualData]);
  return (
    <div style={{ display: "flex" }}>
      <SideNav />
      <div>
        <TopNav />
        <div id="tabs-div">
          <div
            style={{
              width: "30vw",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <button
              id="all-btn"
              style={
                toggle === 1 ? { border: "1px solid red", color: "red" } : null
              }
              onClick={() => setToggle(1)}
            >
              All
            </button>
            <button
              id="upcoming-btn"
              onClick={() => setToggle(2)}
              style={
                toggle === 2 ? { border: "1px solid red", color: "red" } : null
              }
            >
              Upcoming
            </button>
            <button
              id="live-btn"
              onClick={() => setToggle(3)}
              style={
                toggle === 3 ? { border: "1px solid red", color: "red" } : null
              }
            >
              Live
            </button>
            <button
              id="completed-btn"
              onClick={() => setToggle(4)}
              style={
                toggle === 4 ? { border: "1px solid red", color: "red" } : null
              }
            >
              Completed
            </button>
          </div>
          <div>
            <button
              onClick={() => setOpen(true)}
              style={{ marginRight: "1em" }}
              id="matches-btn"
            >
              Create Config
            </button>
            <button onClick={() => setModalShow(true)} id="matches-btn">
              Create Sessions
            </button>
          </div>
        </div>
        <div style={{ height: "75vh", overflowY: "scroll" }}>
          <table id="contests-table">
            <thead id="thead" style={{ position: "sticky" }}>
              <tr>
                <th>Questions</th>
                <th>Options</th>
                <th>Answer</th>
                <th>End Date</th>
                <th>Entry Fee</th>
                <th>Symbol</th>
                <th>Action</th>
              </tr>
            </thead>
            {toggle === 1 ? (
              <tbody>
                {livePredictionContests.map((data) => {
                  return (
                    <tr
                      key={data._id}
                      style={{
                        borderBottom: "1px solid lightgray",
                        height: "6vh",
                      }}
                    >
                      <td onClick={() => handleNavigate(data._id)}>
                        {data.question}
                      </td>
                      <td onClick={() => handleNavigate(data._id)}>
                        {convertArrayToString(data.options)}
                      </td>
                      <td onClick={() => handleNavigate(data._id)}>
                        {data.answer}
                      </td>
                      <td onClick={() => handleNavigate(data._id)}>
                        {getDateFromTimeStamp(data.endDate)}
                      </td>
                      <td onClick={() => handleNavigate(data._id)}>
                        {data.entryFee}
                      </td>
                      <td onClick={() => handleNavigate(data._id)}>
                        {data.symbol}
                      </td>
                      <td id="Action-div">
                        <div id="Action-icons">
                          <BiEdit
                            onClick={() => {
                              handleEdit(data._id);
                              setIndividualData(data);
                            }}
                          />
                          <AiOutlineDelete
                            onClick={() => handleDelete(data._id)}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            ) : null}

            {toggle === 2 ? (
              <tbody>
                {livePredictionContests
                  .filter?.((value) => {
                    if (value.endDate > parseInt(Date.now() / 1000)) {
                      return value;
                    }
                  })
                  .map((data) => {
                    return (
                      <tr
                        key={data._id}
                        style={{
                          borderBottom: "1px solid lightgray",
                          height: "6vh",
                        }}
                      >
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.question}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {convertArrayToString(data.options)}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.answer}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {getDateFromTimeStamp(data.endDate)}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.entryFee}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.symbol}
                        </td>
                        <td id="Action-div">
                          <div id="Action-icons">
                            <BiEdit
                              onClick={() => {
                                handleEdit(data._id);
                                setIndividualData(data);
                              }}
                            />
                            <AiOutlineDelete
                              onClick={() => handleDelete(data._id)}
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            ) : null}

            {toggle === 3 ? (
              <tbody>
                {livePredictionContests
                  .filter?.((value) => {
                    if (
                      value.endDate < parseInt(Date.now() / 1000) &&
                      value.rewardsSettled === false &&
                      value.cancelled === false
                    ) {
                      return value;
                    }
                  })
                  .map((data) => {
                    return (
                      <tr
                        key={data._id}
                        style={{
                          borderBottom: "1px solid lightgray",
                          height: "6vh",
                        }}
                      >
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.question}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {convertArrayToString(data.options)}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.answer}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {getDateFromTimeStamp(data.endDate)}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.entryFee}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.symbol}
                        </td>
                        <td id="Action-div">
                          <div id="Action-icons">
                            <BiEdit
                              onClick={() => {
                                handleEdit(data._id);
                                setIndividualData(data);
                              }}
                            />
                            <AiOutlineDelete
                              onClick={() => handleDelete(data._id)}
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            ) : null}

            {toggle === 4 ? (
              <tbody>
                {livePredictionContests
                  .filter?.((value) => {
                    if (
                      value.cancelled === true ||
                      value.rewardsSettled === true
                    ) {
                      return value;
                    }
                  })
                  .map((data) => {
                    return (
                      <tr
                        key={data._id}
                        style={{
                          borderBottom: "1px solid lightgray",
                          height: "6vh",
                        }}
                      >
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.question}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {convertArrayToString(data.options)}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.answer}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {getDateFromTimeStamp(data.endDate)}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.entryFee}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.symbol}
                        </td>
                        <td id="Action-div">
                          <div id="Action-icons">
                            <BiEdit
                              onClick={() => {
                                handleEdit(data._id);
                                setIndividualData(data);
                              }}
                            />
                            <AiOutlineDelete
                              onClick={() => handleDelete(data._id)}
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            ) : null}
          </table>
        </div>
      </div>
      <p id="error">{error}</p>
      <CreateLivePrediction
        modalShow={modalShow}
        setModalShow={() => setModalShow(false)}
      />
      <CreateLivePredictionConfig show={open} onHide={() => setOpen(false)} />
      <EditLivePredictionAnswer
        livePredictionContests={individualData}
        modalShow={modalShow2}
        setModalShow={() => setModalShow2(false)}
      />
      <DeleteLivePredictionContest
        show={open2}
        onHide={() => setOpen2(false)}
      />
    </div>
  );
};

function getDateFromTimeStamp(timeStamp) {
  return new Date(timeStamp * 1000).toLocaleDateString("en-IN", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
}

export default LivePredictionContest;

function convertArrayToString(arr) {
  let out = "";

  for (let i = 0; i < arr.length; ++i) {
    out += arr[i] + ", ";
  }

  return out;
}
