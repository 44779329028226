import React from "react";
import SideNav from "../SideNav";
import TopNav from "../TopNav";
import "./SelectMatch.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Published from "./Published";
import Close from "./Close";
import Cancel from "./Cancel";
import Verify from "./Verify";
import CalScore2 from "./CalScore2";
import { BOT_API, SERVER_API, MANAGER, PREDICTION_API } from "../env";
import { BsCheckCircle } from "react-icons/bs";
import { RxCrossCircled } from "react-icons/rx";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { getAdmin } from "../getAdmin";
import CreatePredictionConfig from "./CreatePredictionConfig";
import Form from "react-bootstrap/Form";
import CreateLivePredictionConfig from "./CreateLivePredictionConfig";
import CreateFantasyConfig from "./CreateFantasyConfig";
import { FiEdit } from "react-icons/fi";
import EditConfigs from "./EditConfigs";
import Scorecard from "./Scorecard";
import VerifyMatch from "./VerifyMatch";
import SubmitQues from "./SubmitQues";
import LineupResponse from "./LineupResponse";

const SelectMatch = () => {
  const [adminDisplay, setAdminDisplay] = useState(false);
  const [openEditConfigs, setOpenEditConfigs] = useState(false);
  const [configId, setConfigId] = useState("");
  const [individualConfigData, setIndividualConfigData] = useState("");

  let adminDetails = {};
  (async () => {
    adminDetails = await getAdmin();
    if (adminDetails.role & MANAGER) {
      setAdminDisplay(true);
    } else {
      setAdminDisplay(false);
    }
  })();

  const navigate = useNavigate();
  const [allMatches, setAllMatches] = useState({});
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [openPredictionConfig, setOpenPredictionConfig] = useState(false);
  const [openLivePredictionConfig, setOpenLivePredictionConfig] =
    useState(false);
  const [openFantasyConfig, setOpenFantasyConfig] = useState(false);
  const [error, setError] = useState("");
  const [error2, setError2] = useState("");
  const [error3, setError3] = useState("");
  const [predConfig, setPredConfig] = useState(false);
  const [fantConfig, setFantConfig] = useState(false);
  const [liveConfig, setLiveConfig] = useState(false);
  const [toggle, setToggle] = useState(1);
  const [scorecardExist, setScorecardExist] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [individualMatch, setIndividualMatch] = useState({});
  const [scorecard, setScorecard] = useState([]);
  const [openScorecardResponse, setOpenScorecardResponse] = useState(false);
  const [displayResponse, setDisplayResponse] = useState(false);
  const [openSuccessPost, setOpenSuccessPost] = useState(false);

  let PublishMatchApi =
    SERVER_API + `/api/matches/${localStorage.getItem("matchId")}/publish`;
  let CloseMatchApi =
    SERVER_API + `/api/matches/${localStorage.getItem("matchId")}/close`;
  let CancelMatchApi =
    SERVER_API + `/api/matches/${localStorage.getItem("matchId")}/cancel`;
  let VerifyMatchApi =
    SERVER_API + `/api/matches/${localStorage.getItem("matchId")}/verify`;

  let SelectMatchApi =
    SERVER_API + `/api/matches/${localStorage.getItem("matchId")}`;
  let CalScoreApi =
    PREDICTION_API +
    `/api/matches/${localStorage.getItem("matchId")}/score/calculate`;
  let MatchConfigsApi =
    SERVER_API + `/api/matches/${localStorage.getItem("matchId")}/configs`;
  let CreateScorecardApi =
    SERVER_API + `/api/scorecard/${localStorage.getItem("matchId")}`;
  let ResetScorecardApi =
    SERVER_API + `/api/scorecard/${localStorage.getItem("matchId")}`;
  let PushNotificationsApi = SERVER_API + "/api/notification";
  let UpdateMatchTeamsApi =
    BOT_API +
    `/api/fantasy/update-match-teams/${localStorage.getItem("matchId")}`;

  const CreateScorecard = async () => {
    const response = await fetch(CreateScorecardApi, {
      method: "POST",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 201) {
      window.location.reload();
    } else {
      setError(result.message);
    }
  };

  const ResetScorecard = async () => {
    const response = await fetch(ResetScorecardApi, {
      method: "PATCH",
      headers: {
        mode: "cors",
        Accept: "applicaion/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      setScorecard(result);
      setOpenSuccess(true);
    } else {
      setError(result.message);
    }
  };

  const GetMatchConfigs = async () => {
    const response = await fetch(MatchConfigsApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Contest-Type": "application/json",
      },
    });
    let result = await response.json();

    result.forEach((config) => {
      if (config.type === "prediction") setPredConfig(config);
      if (config.type === "livePrediction") setLiveConfig(config);
      if (config.type === "fantasy") setFantConfig(config);
    });
  };

  const CalScore = async () => {
    const response = await fetch(CalScoreApi, {
      method: "PATCH",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      setOpen5(true);
    } else {
      setError2(result.message);
    }
  };

  const GetMatchDetails = async () => {
    const response = await fetch(SelectMatchApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    setAllMatches(result);
  };

  const PublishMatch = async (e) => {
    e.preventDefault();
    const response = await fetch(PublishMatchApi, {
      method: "PATCH",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      setOpen(true);
    } else {
      setError2(result.message);
    }
  };

  const CloseMatch = async (e) => {
    e.preventDefault();
    const response = await fetch(CloseMatchApi, {
      method: "PATCH",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      setOpen2(true);
    }
    if (response.status === 400) {
      setError(result.message);
    }
  };

  const CancelMatch = async (e) => {
    e.preventDefault();
    const response = await fetch(CancelMatchApi, {
      method: "PATCH",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      setOpen3(true);
    }
    if (response.ststus === 400) {
      setError2(result.message);
    }
  };

  const VerifiedMatch = async (e) => {
    e.preventDefault();
    const response = await fetch(VerifyMatchApi, {
      method: "PATCH",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      setOpen4(true);
    } else {
      setError3(result.message);
    }
  };

  const handleEdit = (id) => {
    setOpenEditConfigs(true);
    setConfigId(id);
  };

  const UpdateMatchTeams = async () => {
    const response = await fetch(UpdateMatchTeamsApi, {
      method: "POST",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      setOpen5(true);
      setOpenSuccessPost(true);
    } else {
      setError(result.message);
    }
  };

  const PostNotifications = async () => {
    let input = {
      title: "Lineup",
      body: `${allMatches.teamAName} vs ${allMatches.teamBName} : Lineup ready!`,
    };
    const response = await fetch(PushNotificationsApi, {
      method: "POST",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(input),
    });
    let result = await response.json();
    if (response.status === 200) {
      setAllMatches(result);
      alert("Notification Sended");
    } else {
      alert(result.message);
    }
  };

  let GetLineupApi =
    SERVER_API + `/api/scorecard/lineup/${localStorage.getItem("matchId")}`;

  const GetAndUpdateLineup = async () => {
    const response = await fetch(GetLineupApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });

    let result = await response.json();
    if (response.status === 200 && result.response === "") {
      alert("Successful");
    } else if (response.status === 200) {
      setOpenScorecardResponse(true);
      setDisplayResponse(result.response);
    } else {
      setError(result.message);
    }

    setScorecardExist(true);
    setScorecard(result.scorecard);
  };

  function changeTab(toggleVal) {
    setToggle(toggleVal);

    // Get the current URL
    var url = window.location.href;

    var searchParams = new URLSearchParams(window.location.search);

    searchParams.set("toggle", toggleVal);

    var newUrl = url.split("?")[0] + "?" + searchParams.toString();

    window.history.pushState(null, "", newUrl);
  }

  useEffect(() => {
    GetMatchDetails();
    GetMatchConfigs();
    let tabVal = window.location.search?.split("?");
    if (tabVal.length >= 2) {
      tabVal = tabVal[1]?.split("=");
      tabVal = parseInt(tabVal[1]) || 1;
      setToggle(tabVal);
    }
  }, [openPredictionConfig, openLivePredictionConfig, openFantasyConfig]);

  return (
    <div className="Matches-div">
      <SideNav />
      <div style={{ width: "87svw" }}>
        <TopNav />
        {toggle === 1 || toggle === 2 ? (
          <div id="select-match-tabs">
            <div
              style={{
                height: "5vh",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  height: "3vh",
                  display: "flex",
                }}
              >
                <h5>Series Name :</h5>
                &nbsp;
                <p
                  style={{
                    height: "3vh",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {allMatches.seriesDetails?.seriesName},
                  {allMatches.seriesDetails?.typeOfSeries}
                </p>
              </div>
              <div style={{ display: "flex" }}>
                <h5>Match No : </h5>
                &nbsp;
                <p
                  style={{
                    height: "3vh",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {allMatches.matchNumber}, &nbsp;
                  {allMatches.teamAName} vs {allMatches.teamBName}
                </p>
              </div>
            </div>
            <div id="main-tabs2">
              <button onClick={PublishMatch} id="publish-btn">
                Publish
              </button>
              <button onClick={CloseMatch} id="close-btn">
                Close
              </button>
              <button onClick={CancelMatch} id="Cancel-btn">
                Cancel
              </button>
            </div>
          </div>
        ) : null}

        <div id="tabs-div">
          <div
            style={{
              width: "25vw",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <button
              id="match-btn"
              onClick={() => changeTab(1)}
              style={
                toggle === 1 ? { border: "1px solid red", color: "red" } : null
              }
            >
              Match
            </button>
            <button
              id="verify-btn"
              onClick={() => changeTab(2)}
              style={
                toggle === 2 ? { border: "1px solid red", color: "red" } : null
              }
            >
              Verified by
            </button>
            <button
              onClick={() => changeTab(3)}
              style={
                toggle === 3 ? { border: "1px solid red", color: "red" } : null
              }
              id="verify-btn"
            >
              Scorecard
            </button>
          </div>

          {toggle === 1 || toggle === 2 ? (
            <div
              style={
                adminDisplay
                  ? {
                      width: "32vw",
                      display: "flex",
                      justifyContent: "space-between",
                    }
                  : {
                      width: "21vw",
                      display: "flex",
                      justifyContent: "space-between",
                    }
              }
            >
              <button
                style={{
                  display: adminDisplay ? "block" : "none",
                }}
                id="score-btn"
                onClick={UpdateMatchTeams}
              >
                Update Bot Teams
              </button>
              <button onClick={CalScore} id="score-btn">
                Pred.Score.Cal
              </button>
              <button onClick={VerifiedMatch} id="verify-btn2">
                Verify
              </button>
            </div>
          ) : null}

          {toggle === 3 ? (
            !scorecardExist ? (
              <button onClick={CreateScorecard} id="score-btn">
                Create Scorecard
              </button>
            ) : (
              <div id="matches-nav-div">
                <button onClick={GetAndUpdateLineup} id="score-btn">
                  Get Linup
                </button>
                <button onClick={PostNotifications} id="push-notifications">
                  Push Notifications
                </button>
                <button onClick={ResetScorecard} id="score-btn">
                  Reset Scorecard
                </button>
              </div>
            )
          ) : null}
        </div>

        {/* perticular match details */}
        <div>
          {toggle === 1 ? (
            <table
              style={{
                width: " 85vw",
                marginLeft: "1em",
              }}
            >
              <thead id="thead">
                <tr>
                  <th>match No</th>
                  <th>CricBuzz Id</th>
                  <th>Published</th>
                  <th>Completed</th>
                  <th>Cancelled</th>
                  <th>Is hot match</th>
                  <th>Is Verified</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  style={{
                    borderBottom: "1px solid lightgray",
                    height: "6vh",
                  }}
                >
                  <td>{allMatches?.matchNumber}</td>
                  <td>{allMatches?.cricBuzzMatchId}</td>
                  <td>{allMatches?.published?.toString()}</td>
                  <td>{allMatches?.completed?.toString()}</td>
                  <td>{allMatches?.cancelled?.toString()}</td>
                  <td>{allMatches?.isHotMatch?.toString()}</td>
                  <td>
                    {allMatches.isVerified === true ? (
                      <BsCheckCircle color={"green"} />
                    ) : (
                      <RxCrossCircled color={"red"} />
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          ) : null}
          {toggle === 2 ? <VerifyMatch /> : null}
          {toggle === 3 ? (
            <Scorecard
              setScorecardExist={setScorecardExist}
              scorecard={scorecard}
              setScorecard={setScorecard}
            />
          ) : null}

          {toggle === 1 || toggle === 2 ? (
            <div id="bottom-btn">
              <button
                onClick={() => navigate("/SamplePredictions")}
                className="sample-prediction-btn"
                style={{
                  marginRight: "1em",
                  display: adminDisplay ? "block" : "none",
                }}
              >
                Sample Predictions <HiOutlineArrowNarrowRight color="white" />
              </button>
              <button
                onClick={() => navigate("/ContestsInMatch")}
                id="show-all-btn"
                style={{
                  marginRight: "1em",
                }}
              >
                Show Contests <HiOutlineArrowNarrowRight color="white" />
              </button>
              <button
                onClick={() => navigate("/ShowQuestionsDirect")}
                id="show-all-btn"
              >
                Show all questions <HiOutlineArrowNarrowRight color="white" />
              </button>
            </div>
          ) : null}

          {/* match configs data*/}
          {toggle === 1 ? (
            <div style={{ marginTop: "2em" }}>
              <h4 style={{ display: "flex", marginLeft: "1em" }}>
                Configs Data
              </h4>
              <table id="matches-table">
                <thead id="thead">
                  <th>Prediction Config</th>
                  <th>Live prediction Config</th>
                  <th>Fantasy Config</th>
                </thead>
                <tbody>
                  <tr
                    style={{
                      borderBottom: "1px solid lightgray",
                      height: "6vh",
                    }}
                  >
                    <td>
                      <Form>
                        <Form.Check
                          onClick={() => setOpenPredictionConfig(true)}
                          type="switch"
                          id="custom-switch"
                          checked={predConfig ? true : false}
                        />
                      </Form>
                    </td>
                    <td>
                      <Form>
                        <Form.Check
                          onClick={() => {
                            setOpenLivePredictionConfig(true);
                            setIndividualMatch(allMatches);
                          }}
                          type="switch"
                          id="custom-switch"
                          checked={liveConfig ? true : false}
                        />
                      </Form>
                    </td>
                    <td>
                      <Form>
                        <Form.Check
                          onClick={() => setOpenFantasyConfig(true)}
                          type="switch"
                          id="custom-switch"
                          checked={fantConfig ? true : false}
                        />
                      </Form>
                    </td>
                  </tr>
                  <tr>
                    {predConfig ? (
                      <td>
                        <div
                          style={{
                            boxShadow: "0px 0px 2px gray",
                            width: "20vw",
                            height: "20vh",
                            marginLeft: "5em",
                            marginTop: "1em",
                          }}
                        >
                          <p>
                            <p>
                              Max User Predictions :{" "}
                              {predConfig.maxUserPredictions}
                            </p>
                          </p>
                          <p>BALL Price In USD : {predConfig.ballPriceInUSD}</p>
                          <p>Type : {predConfig.type} </p>
                          <FiEdit
                            onClick={() => {
                              handleEdit(predConfig._id);
                              setIndividualConfigData(predConfig);
                            }}
                          />
                        </div>
                      </td>
                    ) : (
                      <td>
                        <div
                          style={{
                            width: "20vw",
                            height: "20vh",
                            marginLeft: "5em",
                            marginTop: "1em",
                          }}
                        ></div>
                      </td>
                    )}
                    {liveConfig ? (
                      <td>
                        <div
                          style={{
                            boxShadow: "0px 0px 2px gray",
                            width: "20vw",
                            height: "20vh",
                            marginLeft: "5em",
                            marginTop: "1em",
                          }}
                        >
                          <p>Max User Teams : {liveConfig.maxUserTeams}</p>
                          <p>BALL Price In USD : {liveConfig.ballPriceInUSD}</p>
                          <p>Type : {liveConfig.type} </p>
                        </div>
                      </td>
                    ) : (
                      <td>
                        <div
                          style={{
                            width: "20vw",
                            height: "20vh",
                            marginLeft: "5em",
                            marginTop: "1em",
                          }}
                        ></div>
                      </td>
                    )}
                    {fantConfig ? (
                      <td>
                        <div
                          style={{
                            boxShadow: "0px 0px 2px gray",
                            width: "20vw",
                            height: "20vh",
                            marginLeft: "5em",
                            marginTop: "1em",
                          }}
                        >
                          <p>
                            {fantConfig.maxUserPredictions
                              ? "Max User Predctions : "
                              : "Max User Teams :"}
                            {fantConfig.maxUserPredictions ||
                              fantConfig.maxUserTeams ||
                              0}
                          </p>
                          <p>BALL Price In USD : {fantConfig.ballPriceInUSD}</p>
                          <p>Type : {fantConfig.type} </p>

                          <FiEdit
                            onClick={() => {
                              handleEdit(fantConfig._id);
                              setIndividualConfigData(fantConfig);
                            }}
                          />
                        </div>
                      </td>
                    ) : (
                      <td>
                        <div
                          tyle={{
                            border: "1px solid gray",
                            width: "20vw",
                            height: "20vh",
                            marginLeft: "5em",
                            marginTop: "1em",
                          }}
                        ></div>
                      </td>
                    )}
                  </tr>
                </tbody>
              </table>
            </div>
          ) : null}
        </div>

        <p style={{ color: "gray" }}>{error}</p>
        <p style={{ color: "gray" }}>{error2}</p>
        <p style={{ color: "gray" }}>{error3}</p>
        {/* <p style={{ color: "gray" }}>{error4}</p> */}
      </div>
      <Published show={open} onHide={() => setOpen(false)} />
      <Close show={open2} onHide={() => setOpen2(false)} />
      <Cancel show={open3} onHide={() => setOpen3(false)} />
      <Verify show={open4} onHide={() => setOpen4(false)} />
      <CalScore2
        openSuccessPost={openSuccessPost}
        show={open5}
        onHide={() => setOpen5(false)}
      />
      <CreatePredictionConfig
        show={openPredictionConfig}
        onHide={() => setOpenPredictionConfig(false)}
      />
      <CreateLivePredictionConfig
        allMatches={individualMatch}
        show={openLivePredictionConfig}
        onHide={() => setOpenLivePredictionConfig(false)}
      />
      <CreateFantasyConfig
        show={openFantasyConfig}
        onHide={() => setOpenFantasyConfig(false)}
      />
      <EditConfigs
        id={configId}
        configData={individualConfigData}
        show={openEditConfigs}
        onHide={() => setOpenEditConfigs(false)}
      />
      <SubmitQues show={openSuccess} onHide={() => setOpenSuccess(false)} />
      <LineupResponse
        displayResponse={displayResponse}
        show={openScorecardResponse}
        onHide={() => setOpenScorecardResponse(false)}
      />
    </div>
  );
};

export default SelectMatch;
