import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { RxCross1 } from "react-icons/rx";
import { FANTASY_API, PREDICTION_API } from "../env";

const EditConfigs = ({ show, onHide, configData, id }) => {
  const [maxUserPredictions, setMaxUserPredictions] = useState("");
  const [maxUserTeams, setMaxUserTeams] = useState("");
  const [ballPriceInUSD, setBallPriceInUSD] = useState("");
  const [error, setError] = useState("");

  let UpdateFantasyConfigApi = FANTASY_API + "/api/matches/config";
  let UpdatePredictionConfigApi = PREDICTION_API + `/api/matches/config/${id}`;

  const UpdateFantasyConfig = async () => {
    let input = {
      configId: id,
      maxUserPredictions: maxUserPredictions || configData.maxUserPredictions,
      maxUserTeams: maxUserTeams || configData.maxUserTeams,
      ballPriceInUSD: ballPriceInUSD || configData.ballPriceInUSD,
    };
    const response = await fetch(UpdateFantasyConfigApi, {
      method: "PATCH",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(input),
    });
    let result = await response.json();
    if (response.status === 200) {
      onHide();
    } else {
      setError(result.message);
    }
  };

  const UpdatePredictionConfig = async () => {
    let input = {
      maxUserPredictions: maxUserPredictions || configData.maxUserPredictions,
    };
    const response = await fetch(UpdatePredictionConfigApi, {
      method: "PATCH",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(input),
    });
    let result = await response.json();
    if (response.status === 200) {
      onHide();
    } else {
      setError(result.message);
    }
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={onHide}
        style={{
          backgroundColor: "black-transperant",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        size="md"
        centered
      >
        {configData.type === "prediction" ? (
          <Modal.Body
            style={{
              height: "35vh",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div id="head-div">
              <h4>Edit Prediction Config</h4>
              <RxCross1 color="black" onClick={onHide} />
            </div>
            <div
              style={{
                height: "10vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                paddingTop: "2em",
              }}
            >
              <div id="div-2">
                <p id="old-password">Max User Predictions</p>
                <input
                  onChange={(e) => setMaxUserPredictions(e.target.value)}
                  type="number"
                  id="sec-input"
                  defaultValue={configData.maxUserPredictions}
                />
              </div>
            </div>
            <p id="error">{error}</p>
            <button onClick={UpdatePredictionConfig} id="button">
              Update
            </button>
          </Modal.Body>
        ) : (
          <Modal.Body
            style={{
              height: "40vh",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div id="head-div">
              <h4>Edit Fantasy Config</h4>
              <RxCross1 color="black" onClick={onHide} />
            </div>
            <div
              style={{
                height: "20vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                paddingTop: "2em",
              }}
            >
              <div id="div-2">
                <p id="old-password">Max User Teams</p>
                <input
                  onChange={(e) => setMaxUserTeams(e.target.value)}
                  type="number"
                  id="sec-input"
                  defaultValue={configData.maxUserTeams}
                />
              </div>

              <div id="div-2">
                <p id="old-password">Ball Price In USD</p>
                <input
                  onChange={(e) => setBallPriceInUSD(e.target.value)}
                  type="number"
                  id="sec-input"
                  value={configData.ballPriceInUSD}
                />
              </div>
            </div>

            <p id="error">{error}</p>
            <button onClick={UpdateFantasyConfig} id="button">
              Update
            </button>
          </Modal.Body>
        )}
      </Modal>
    </div>
  );
};

export default EditConfigs;
