import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { RxCross1 } from "react-icons/rx";
import { SERVER_API } from "../env";
import "./Series.css";

const CreateSeriesAuto = ({ modalShow, setModalShow, cricBuzzSeriesId }) => {
  const [autoSeries, setAutoSeries] = useState([]);
  const [teams, setTeams] = useState([]);
  const [seriesName, setSeriesName] = useState("");
  const [error, setError] = useState("");

  let GetAutoCreateSeriesApi =
    SERVER_API + `/api/series/cric-details/${cricBuzzSeriesId}`;
  let CreateAutoSeriesApi = SERVER_API + "/api/series/";

  const GetAutoSeries = async () => {
    const response = await fetch(GetAutoCreateSeriesApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    setAutoSeries(result);
    setTeams(result.teamsDetails);
  };

  const CreateAutoSeries = async (selectedSeriesDetails) => {
    let input = {
      seriesName: seriesName || autoSeries.seriesName,
      cricBuzzSeriesId: autoSeries.cricBuzzSeriesId,
      teams: autoSeries.teams,
      typeOfSeries: selectedSeriesDetails.typeOfSeries,
      numberOfMatches: selectedSeriesDetails.numberOfMatches,
      startDate: selectedSeriesDetails.startDate,
      endDate: selectedSeriesDetails.endDate,
    };

    let formatedTeams = [];

    teams.forEach((team) => {
      formatedTeams.push({
        cricBuzzTeamId: team.teamId,
        teamName: team.teamName,
        displayName: team.teamSName,
      });
    });

    input.teamsDetails = formatedTeams;

    const response = await fetch(CreateAutoSeriesApi, {
      method: "POST",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(input),
    });
    let result = await response.json();
    if (response.status === 201) {
      setModalShow(false);
      window.location.reload();
    } else {
      setError(result.message);
    }
  };

  useEffect(() => {
    GetAutoSeries();
  }, []);

  const handleTeamsDetailsChange = (index, key, value) => {
    let temp = teams;

    temp[index][key] = value;

    setTeams(temp);
  };

  const handleClick = (type) => {
    CreateAutoSeries(type);
  };

  return (
    <div>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body
          style={{
            height: "75vh",
          }}
        >
          <div
            style={{
              height: "65vh",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h4>Create Series</h4>
              <RxCross1
                style={{ color: "black", cursor: "pointer" }}
                onClick={setModalShow}
              />
            </div>
            <div
              style={{
                marginTop: "2em",
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <div id="div-1">
                <p id="username">Series Name</p>
                <input
                  id="input"
                  type="text"
                  onChange={(e) => setSeriesName(e.target.value)}
                  defaultValue={autoSeries.seriesName}
                />
              </div>
              <div id="div-1">
                <p id="username">Crickbuzz Id</p>
                <input
                  id="input"
                  type="text"
                  value={autoSeries.cricBuzzSeriesId}
                />
              </div>
              <div id="div-1">
                <p id="username">Teams</p>
                <input id="input" type="text" defaultValue={autoSeries.teams} />
              </div>
            </div>

            <div style={{ marginTop: "1em" }}>
              <h5>Team Details</h5>
              <div
                style={{
                  overflowY: "scroll",
                  height: "20vh",
                }}
              >
                <table style={{ width: "49vw" }}>
                  <thead id="thead" style={{ position: "sticky" }}>
                    <tr>
                      <th>Team Id</th>
                      <th>Team Name</th>
                      <th>Display Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {teams?.map((team, index) => {
                      return (
                        <tr>
                          <td>
                            <input
                              type="text"
                              defaultValue={team.teamId}
                              id="table-input"
                              onChange={(e) => {
                                handleTeamsDetailsChange(
                                  index,
                                  "teamId",
                                  e.target.value
                                );
                              }}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              defaultValue={team.teamName}
                              id="table-input"
                              onChange={(e) => {
                                handleTeamsDetailsChange(
                                  index,
                                  "teamName",
                                  e.target.value
                                );
                              }}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              value={team.teamSName}
                              id="table-input"
                              onChange={(e) => {
                                handleTeamsDetailsChange(
                                  index,
                                  "teamSName",
                                  e.target.value
                                );
                              }}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>

            <div style={{ marginTop: "1em" }}>
              <h5>Select Type</h5>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                {autoSeries.dataOnFormats?.map((type, index) => {
                  return (
                    <div>
                      <p>Type : {type.typeOfSeries}</p>
                      <p>No of Matches : {type.numberOfMatches}</p>
                      <p>Start Date : {getDateFromTimeStamp(type.startDate)}</p>
                      <p>End Date : {getDateFromTimeStamp(type.endDate)}</p>
                      <button
                        id="create-auto-series"
                        onClick={() => handleClick(type, index)}
                        disabled={type.seriesExist === true ? true : false}
                        style={
                          type.seriesExist === true
                            ? { backgroundColor: "rgba(253, 75, 75, 0.493)" }
                            : null
                        }
                      >
                        Create
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <p id="error" style={{ marginTop: "1em" }}>
            {error}
          </p>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CreateSeriesAuto;

function getDateFromTimeStamp(timeStamp) {
  return new Date(timeStamp * 1000).toLocaleDateString("en-In", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
}
