import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { RxCross1 } from "react-icons/rx";
import { BOT_API } from "../env";

const AddSelectiveBot = ({ show, onHide }) => {
  const [botsList, setBotsList] = useState([]);
  const [botName, setBotName] = useState("");
  const [sampleId, setSampleId] = useState("");
  const [predictions, setPredictions] = useState([]);
  const [error, setError] = useState("");

  let BotsApi = BOT_API + "/api/bots";
  let SelectiveBotApi = BOT_API + "/api/contests/join/botName";
  let GetPredictionsApi =
    BOT_API + `/api/sample-predictions/${localStorage.getItem("matchId")}`;

  const GetPredictions = async () => {
    console.log(GetPredictionsApi);
    const response = await fetch(GetPredictionsApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();

    setPredictions(result._samplePredictionsCount);
  };

  const SelectiveBot = async () => {
    let input = {
      contestId: localStorage.getItem("predictionContestId"),
      botName,
    };
    if (!(sampleId == "" || !sampleId)) {
      input.sampleId = sampleId;
    }

    const response = await fetch(SelectiveBotApi, {
      method: "POST",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(input),
    });
    let result = await response.json();
    if (response.status === 200) {
      onHide();
    } else {
      setError(result.message);
    }
  };

  const GetBots = async () => {
    const response = await fetch(BotsApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    setBotsList(result);
  };

  useEffect(() => {
    GetBots();
    GetPredictions();
  }, []);
  return (
    <div>
      <Modal
        show={show}
        onHide={onHide}
        style={{
          backgroundColor: "black-transperant",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        size="md"
        centered
      >
        <Modal.Body
          style={{
            height: "40vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div id="head-div">
            <h4>Add Selective Bot</h4>
            <RxCross1 color="black" onClick={onHide} />
          </div>
          <div
            style={{
              height: "15vh",
              display: " flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "2em",
            }}
          >
            <div id="div-1">
              <p id="otp-email">Bot Name</p>
              <input
                id="fst-input"
                onChange={(E) => setBotName(E.target.value)}
                value={botName}
              />
            </div>

            <div id="div-1">
              <p id="otp-email">Sample Id</p>
              <select
                id="fst-input"
                onChange={(E) => setSampleId(E.target.value)}
                value={sampleId}
              >
                <option value="">Select</option>;
                {predictions?.map((data) => {
                  return <option value={data._id}>{data._id}</option>;
                })}
              </select>
            </div>
          </div>

          <button onClick={SelectiveBot} id="button">
            Submit
          </button>
          <p id="error">{error}</p>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddSelectiveBot;
