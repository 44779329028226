import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { RxCross1 } from "react-icons/rx";
import { LIVE_PREDICTION_API, SERVER_API } from "../env";

const CreateLivePredictionConfig = ({ show, onHide, allMatches }) => {
  const [matchId, setMatchId] = useState("");
  const [matchNumber, setMatchNumber] = useState("");
  const [matchesList, setMatchesList] = useState([]);
  const [error, setError] = useState("");

  let MatchesApi = SERVER_API + "/api/matches";
  let LivePredictionConfigApi = LIVE_PREDICTION_API + "/api/matches/config";

  const CreateConfig = async () => {
    let input = { matchId: `${localStorage.getItem("matchId")}` };
    const response = await fetch(LivePredictionConfigApi, {
      method: "POST",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(input),
    });
    let result = await response.json();
    if (response.status === 201) {
      onHide();
    } else {
      setError(result.message);
    }
  };

  const GetMatches = async () => {
    const response = await fetch(MatchesApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    setMatchesList([{ matchNumber: "Select" }, ...result]);
  };

  useEffect(() => {
    GetMatches();
  }, []);
  return (
    <div>
      <Modal
        show={show}
        onHide={onHide}
        style={{
          backgroundColor: "black-transperant",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        size="md"
        centered
      >
        <Modal.Body
          style={{
            height: "35vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div id="head-div">
            <h4>Create Live Predicion Config</h4>
            <RxCross1 color="black" onClick={onHide} />
          </div>
          <div
            style={{
              height: "10vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              paddingTop: "2em",
            }}
          >
            <div id="div-1">
              <p id="old-password">Select Match</p>
              <input
                type="text"
                id="sec-input"
                onChange={(e) => setMatchNumber(e.target.value)}
                value={allMatches?.matchNumber}
              />
            </div>
          </div>
          <button onClick={CreateConfig} id="button">
            Create
          </button>
          <p id="error">{error}</p>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CreateLivePredictionConfig;
