import React from "react";
import Modal from "react-bootstrap/Modal";
import { RxCross1 } from "react-icons/rx";
import { useState, useEffect } from "react";
import "./CreateTeams.css";
import { SERVER_API } from "../env";

const CreateTeams2 = ({ modalShow, setModalShow }) => {
  const [seriesId, setSeriesId] = useState("");
  const [teamName, setTeamName] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [cricBuzzTeamId, setCricBuzzTeamId] = useState("");
  const [seriesData, setSeriesData] = useState([]);
  const [error, setError] = useState("");
  let reqId = "";
  const [ids, setIds] = useState(reqId);

  let CreateTeamsApi = SERVER_API + "/api/teams";
  let SeriesApi = SERVER_API + "/api/series";
  let UploadLogoApi = SERVER_API + "/api/s3/logos/teams?resType=high";
  let UploadJerseyApi = SERVER_API + "/api/s3/jersey/teams?resType=high";

  const UploadLogo = async (reqId) => {
    let logo = document.querySelector(".uploadLogo").files[0];
    let input = new FormData();
    input.append("teamLogos", logo);
    input.append("ids", reqId);

    const response = await fetch(UploadLogoApi, {
      method: "POST",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
      },
      body: input,
    });
    let result = await response.json();
    if (response.status === 200) {
      setModalShow(false);
    }
  };

  const UploadJersey = async (reqId) => {
    let jersey = document.querySelector(".uploadJersey").files[0];
    let input = new FormData();
    input.append("teamJersey", jersey);
    input.append("ids", reqId);

    const response = await fetch(UploadJerseyApi, {
      method: "POST",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
      },
      body: input,
    });
    let result = await response.json();
    if (response.status === 200) {
      setModalShow(false);
    }
  };

  const CreateTeams = async (e) => {
    let input = { seriesId: seriesId, teamName, displayName, cricBuzzTeamId };
    const response = await fetch(CreateTeamsApi, {
      method: "POST",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(input),
    });
    let result = await response.json();
    if (response.status === 201) {
      await UploadLogo(result._id);
      await UploadJersey(result._id);
    }
    setError(result.message);
  };

  const CheckSeries = async () => {
    const response = await fetch(SeriesApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    setSeriesData(result);
    setSeriesId(result[0]?._id);
  };
  useEffect(() => {
    CheckSeries();
  }, []);

  return (
    <div>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body
          style={{
            height: "55vh",
          }}
        >
          <div
            style={{
              height: "50vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h4>Create Teams</h4>
              <RxCross1 color="black" onClick={setModalShow} />
            </div>
            <div
              style={{
                height: "30vh",
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <div
                style={{
                  height: "25vh",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <p id="username">Series Name</p>
                <select
                  id="select"
                  onChange={(E) => setSeriesId(E.target.value)}
                  value={seriesId}
                >
                  {seriesData?.map((data) => {
                    return (
                      <option key={data._id} value={data._id}>
                        {data.seriesName}
                      </option>
                    );
                  })}
                </select>
                <div id="div-2">
                  <p id="username">Display name</p>
                  <input
                    onChange={(E) => setDisplayName(E.target.value)}
                    id="fst-input"
                    type="text"
                    value={displayName}
                  />
                </div>
                <div id="div-2">
                  <p id="username">CrickBuzz Team Id</p>
                  <input
                    onChange={(E) => setCricBuzzTeamId(E.target.value)}
                    id="fst-input"
                    type="text"
                    value={cricBuzzTeamId}
                  />
                </div>
              </div>
              <div
                style={{
                  height: "25vh",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div id="div-2">
                  <p id="username">Team name</p>
                  <input
                    onChange={(E) => setTeamName(E.target.value)}
                    id="fst-input"
                    type="text"
                    value={teamName}
                  />
                </div>
                <div>
                  <p id="username">Upload Logo</p>
                  <div id="file-button">
                    <input
                      type="file"
                      name="uploadfile"
                      id="file-input"
                      className="uploadLogo"
                    />
                  </div>
                </div>
                <div>
                  <p id="username">Upload Jersey</p>
                  <div id="file-button">
                    <input
                      type="file"
                      name="uploadfile"
                      id="file-input"
                      className="uploadJersey"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <button
                onClick={async () => {
                  await CreateTeams();
                }}
                id="update-btn2"
              >
                Create
              </button>
              <button onClick={setModalShow} id="cancel-btn2">
                Cancel
              </button>
            </div>
            <p id="error">{error}</p>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CreateTeams2;
