import React, { useState, useEffect } from "react";
import "./OGCricket.css";
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { SERVER_API } from "../env";
import { getAdmin } from "../getAdmin";

const LivePreadictions = () => {
  let adminDisplay = {};
  (async () => {
    adminDisplay = await getAdmin();
  })();

  const navigate = useNavigate();
  const [matchesList, setMatchesList] = useState([]);

  let MatchIntancesApi = SERVER_API + "/api/og/match-instances";

  const GetMatchInstances = async () => {
    const response = await fetch(MatchIntancesApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application.json",
        token: localStorage.getItem("token"),
        "Content-Type": "application.json",
      },
    });
    let result = await response.json();
    setMatchesList(result);
  };

  const handleNavigate = (id) => {
    localStorage.setItem("matchInstanceId", id);
    navigate("/Sessions");
  };

  useEffect(() => {
    GetMatchInstances();
  }, []);
  return (
    <div>
      {matchesList
        .filter((data) => {
          if (data._id == "live") return data;
        })
        .map((data) => {
          return (
            <div key={data._id}>
              <div>
                {data.matchInstances?.map((matchInstance) => {
                  return (
                    <div
                      onClick={() => handleNavigate(matchInstance._id)}
                      className="All-Prediction-tab"
                    >
                      <div style={{ width: "15vw" }}>
                        {matchInstance.seriesName}
                      </div>
                      <div id="match-instance-div">
                        {matchInstance.teamAName} vs {matchInstance.teamBName}
                      </div>
                      <div id="WL-div">
                        W/L :{" "}
                        {matchInstance.winToLooseRatio
                          ? matchInstance.winToLooseRatio
                          : 0}
                      </div>
                      <IoIosArrowForward />
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default LivePreadictions;
