import React, { useState, useEffect } from "react";
import SideNav from "./../SideNav";
import TopNav from "./../TopNav";
import CreateBots from "./CreateBots";
import { BOT_API, MANAGER } from "../env";
import { useNavigate } from "react-router-dom";
import EnterUsersNames from "./EnterUsersNames";
import Form from "react-bootstrap/Form";
import "./CreateBots.css";
import AddRemoveUser from "./AddRemoveUser";
import { getAdmin } from "../getAdmin";
import { FaSort } from "react-icons/fa";

const Bots = () => {
  let adminDetails = {};
  (async () => {
    adminDetails = await getAdmin();
    if (!(adminDetails.role & MANAGER)) {
      navigate("/");
    }
  })();

  const navigate = useNavigate();
  const [botsList, setBotsList] = useState([]);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);

  let BotsApi = BOT_API + "/api/bots";

  const IsActive = async (botId, index, isActive) => {
    const response = await fetch(BOT_API + `/api/bots/status/${botId}`, {
      method: "PATCH",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    document.querySelector(`#botList_${index}_toggle #custom-switch`).checked =
      isActive;

    console.log(result);
  };

  const GetBots = async (symbol) => {
    if (symbol) {
      BotsApi = BotsApi + "?sortBy=" + symbol;
    }

    const response = await fetch(BotsApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    setBotsList(result);
  };

  useEffect(() => {
    GetBots();
  }, [open3]);

  return (
    <div style={{ display: "flex" }}>
      <SideNav />
      <div>
        <TopNav />
        <div style={{ height: "10vh" }}>
          <button onClick={() => setOpen2(true)} id="create-bots-btn">
            Create Bots
          </button>
          <button onClick={() => setOpen(true)} id="enter-username-btn">
            Enter Usernames
          </button>
          <button onClick={() => setOpen3(true)} id="add-remove-btn">
            Add/Remove User
          </button>
          <div
            style={{
              display: "flex",
              marginLeft: "1em",
              paddingTop: "2em",
            }}
          >
            <p style={{ fontWeight: "bolder" }}>No of Bots:</p>
            &nbsp;
            <p>{botsList.length}</p>
          </div>
        </div>
        <div style={{ overflowY: "scroll", height: "70vh" }}>
          <table id="bots-table">
            <thead id="thead" style={{ position: "sticky" }}>
              <tr>
                <th>Username</th>
                <th>Create Date</th>
                <th>Pub Key</th>
                <th>Mask</th>
                <th onClick={() => GetBots("BALL")}>
                  Avail BALL
                  <FaSort style={{ height: "2vh", width: "2vw" }} />
                </th>
                <th onClick={() => GetBots("BUSD")}>
                  Avail BUSD <FaSort style={{ height: "2vh", width: "2vw" }} />
                </th>
                <th onClick={() => GetBots("USDT")}>
                  Avail USDT{" "}
                  <FaSort
                    style={{
                      height: "2vh",
                      width: "2vw",
                    }}
                  />
                </th>
                <th>Is Active</th>
              </tr>
            </thead>
            <tbody id="botsList">
              {botsList.map((data, index) => {
                return (
                  <tr
                    style={{
                      borderBottom: "1px solid lightgray",
                      height: "6vh",
                    }}
                  >
                    <td>{data.userName}</td>
                    <td>{getDateFromTimeStamp(data.timestamp)}</td>
                    <td>{data.pubKey}</td>
                    <td>{data.mask}</td>
                    <td>{roundToNDecimal(data.BALL_Available, 4) || 0}</td>
                    <td>{roundToNDecimal(data.BUSD_Available, 4) || 0}</td>
                    <td>{roundToNDecimal(data.USDT_Available, 4) || 0}</td>
                    <td>
                      <Form id={`botList_${index}_toggle`}>
                        <Form.Check
                          onClick={() =>
                            IsActive(data._id, index, !data.isActive)
                          }
                          type="switch"
                          id="custom-switch"
                          checked={data.isActive}
                        />
                      </Form>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <EnterUsersNames show={open} onHide={() => setOpen(false)} />
      <CreateBots show={open2} onHide={() => setOpen2(false)} />
      <AddRemoveUser show={open3} onHide={() => setOpen3(false)} />
    </div>
  );
};

function getDateFromTimeStamp(timeStamp) {
  return new Date(timeStamp * 1000).toLocaleDateString("en-IN", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
}
export default Bots;

function roundToNDecimal(number, decimal = 3) {
  return Math.round(number * Math.pow(10, decimal)) / Math.pow(10, decimal);
}
