import React from "react";
import { useState, useEffect } from "react";
import { SERVER_API } from "../env";
import SideNav from "../SideNav";
import TopNav from "../TopNav";
import Accordion from "react-bootstrap/Accordion";

const TournamentsLeaderboard = () => {
  const [leaderboard, setLeaderboard] = useState([]);
  const [toggle, setToggle] = useState(1);
  const [reward, setReward] = useState([]);
  const [error, setError] = useState("");

  let GetLeaderboardApi =
    SERVER_API +
    `/api/tournaments/${localStorage.getItem("tournamentId")}/leaderboard `;
  let GetRewardsApi =
    SERVER_API +
    `/api/tournaments/${localStorage.getItem("tournamentId")}/reward`;
  let UpdateLeaderboardApi =
    SERVER_API +
    `/api/tournaments/${localStorage.getItem("tournamentId")}/leaderboard`;

  const UpdateLeaderboard = async () => {
    const response = await fetch(UpdateLeaderboardApi, {
      method: "PATCH",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    setLeaderboard(result.leaderboard);
    if (response.status === 200) {
      alert("Reset Successfully");
    } else {
      alert(result.message);
    }
  };

  const GetLeaderboard = async () => {
    const response = await fetch(GetLeaderboardApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    setLeaderboard(result);
  };

  const GetRewards = async () => {
    const response = await fetch(GetRewardsApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    setLeaderboard(result);
    setReward(result);
  };

  useEffect(() => {
    GetLeaderboard();
    GetRewards();
  }, []);

  return (
    <div style={{ display: "flex" }}>
      <SideNav />
      <div>
        <TopNav />
        <div id="tabs-div">
          <div
            style={{
              width: "23vw",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <button
              onClick={() => setToggle(1)}
              style={
                toggle === 1
                  ? { border: "1px solid red", color: "red", width: "11vw" }
                  : { width: "11vw" }
              }
              id="match-btn"
            >
              Leaderboard
            </button>
            <button
              onClick={() => setToggle(2)}
              style={
                toggle === 2
                  ? { border: "1px solid red", color: "red", width: "11vw" }
                  : { width: "11vw" }
              }
              id="verify-btn"
            >
              Reward Breakup
            </button>
          </div>
          <button id="matches-btn" onClick={UpdateLeaderboard}>
            Reset
          </button>
        </div>

        {toggle === 1 ? (
          <div style={{ overflowY: "scroll", height: "75vh" }}>
            <table id="admins-table">
              <thead id="thead" style={{ position: "sticky" }}>
                <tr>
                  <th>Username</th>
                  <th>Match Score</th>
                  <th>Total Score</th>
                  <th>Rank</th>
                </tr>
              </thead>
              <tbody>
                {leaderboard.map((data) => {
                  return (
                    <tr
                      style={{
                        borderBottom: "1px solid lightgray",
                        height: "6vh",
                      }}
                    >
                      <td>{data.user}</td>
                      <td>{data.matchScore}</td>
                      <td>{data.totalScore}</td>
                      <td>{data.rank}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : null}

        {toggle === 2 ? (
          <div id="predictions-main-div">
            {reward.map((symbol) => {
              return (
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Symbol : {symbol.symbol}, Total :{" "}
                      {roundToNDecimal(symbol.total) || 0}
                    </Accordion.Header>
                    <Accordion.Body>
                      <table
                        style={{
                          width: " 80vw",
                          marginLeft: "1em",
                        }}
                      >
                        <thead id="thead">
                          <tr>
                            <th>Rank</th>
                            <th>Amount</th>
                            <th>Count</th>
                          </tr>
                        </thead>
                        <tbody>
                          {symbol.distribution?.map((distribution, index) => {
                            return (
                              <tr
                                key={distribution.amount}
                                style={{
                                  borderBottom: "1px solid lightgray",
                                  height: "6vh",
                                }}
                              >
                                <td>{index + 1}</td>
                                <td>
                                  {roundToNDecimal(distribution.amount) || 0}
                                </td>
                                <td>
                                  {roundToNDecimal(distribution.count) || 0}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              );
            })}
          </div>
        ) : null}

        <p style={{ color: "gray" }}>{error}</p>
      </div>
    </div>
  );
};

export default TournamentsLeaderboard;

function roundToNDecimal(number, decimal = 4) {
  return Math.round(number * Math.pow(10, decimal)) / Math.pow(10, decimal);
}
