import React from "react";
import SideNav from "../SideNav";
import TopNav from "../TopNav";
import "./SeriesStaking.css";
import { useEffect, useState } from "react";
import SettleContest from "./../Contests/SettleContest";
import { SERVER_API } from "../env";
import { getAdmin } from "../getAdmin";

const SelectSeries = () => {
  let adminDetails = {};
  (async () => {
    adminDetails = await getAdmin();
  })();

  const [usersData, setUsersData] = useState([]);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");

  let UsersDetailsApi =
    SERVER_API + `/api/series-stakes/${localStorage.getItem("seriesId")}`;
  let SettleSeriesApi =
    SERVER_API +
    `/api/series-stakes/${localStorage.getItem("seriesId")}/settle`;

  const SettleSeries = async () => {
    const response = await fetch(SettleSeriesApi, {
      method: "PATCH",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      setOpen(true);
    }
    if (response.status === 400) {
      setError(result.message);
    }
  };

  const UsersDetails = async () => {
    const response = await fetch(UsersDetailsApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    setUsersData(result);
  };
  useEffect(() => {
    UsersDetails();
  }, []);
  return (
    <div className="Series-div">
      <SideNav />
      <div style={{ width: "87svw" }}>
        <TopNav />
        <div id="table-top-data">
          <div id="data-div">
            <p>No Of Users :&nbsp;{usersData[0]?.users?.length}</p>
            <p>Staked Amount :&nbsp;{usersData[0]?.stakedAmount}</p>
          </div>
          <div>
            <button onClick={SettleSeries} id="matches-btn">
              Settle
            </button>
          </div>
        </div>
        <div style={{ height: "75vh", overflowY: "scroll" }}>
          <table id="matches-table">
            <thead id="thead" style={{ position: "sticky" }}>
              <tr>
                <th>User Name</th>
                <th>Phone No</th>
                <th>Public Key</th>
                <th>Amount</th>
                <th>Mask</th>
              </tr>
            </thead>
            <tbody>
              {usersData[0]?.users?.map((data) => {
                return (
                  <>
                    <tr
                      key={data._id}
                      style={{
                        borderBottom: "1px solid lightgray",
                        height: "6vh",
                      }}
                    >
                      <td>{data.userName}</td>
                      <td>{data.phoneNumber}</td>
                      <td>{data.pubKey}</td>
                      <td>{data.amount}</td>
                      <td>{data.mask}</td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        </div>
        <div>
          <p style={{ color: "gray" }}>{error}</p>
        </div>
      </div>
      <SettleContest show={open} onHide={() => setOpen(false)} />
    </div>
  );
};

export default SelectSeries;
