import React from "react";
import Modal from "react-bootstrap/Modal";
import { RxCross1 } from "react-icons/rx";
import { BOT_API } from "../env";
import { useState } from "react";

const AddFantasySelectBot = ({ show, onHide, id }) => {
  const [userName, setUserName] = useState("");
  const [error, setError] = useState("");

  let AddBotNameApi =
    BOT_API + `/api/fantasy/join-contest-name/${id}/${userName}`;

  const AddBotName = async () => {
    const response = await fetch(AddBotNameApi, {
      method: "POST",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      onHide();
    } else {
      setError(result.message);
    }
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={onHide}
        style={{
          backgroundColor: "black-transperant",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        size="md"
        centered
      >
        <Modal.Body
          style={{
            height: "35vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div id="head-div">
            <h4>Add Bots</h4>
            <RxCross1 color="black" onClick={onHide} />
          </div>
          <div
            style={{
              height: "6vh",
              display: " flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "2em",
            }}
          >
            <div id="div-1">
              <p id="otp-email">Enter Bot Name</p>
              <input
                onChange={(e) => setUserName(e.target.value)}
                id="fst-input"
                type="text"
                value={userName}
              />
            </div>
          </div>

          <button onClick={AddBotName} id="button">
            Submit
          </button>
          <p id="error">{error}</p>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddFantasySelectBot;
