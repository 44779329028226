import React from "react";
import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { RxCross1 } from "react-icons/rx";
import { SERVER_API } from "../env";

const EditReadMatches = ({ modalShow, setModalShow, matches }) => {
  const [teamAScore, setTeamAScore] = useState("");
  const [teamBScore, setTeamBScore] = useState("");
  const [teamAOvers, setTeamAOvers] = useState("");
  const [teamBOvers, setTeamBOvers] = useState("");
  const [isHotMatch, setIsHotMatch] = useState(false);
  const [comments, setComments] = useState("");
  const [matchNumber, setMatchNumber] = useState("");
  const [maximumUserPredictions, setMaximumUserPredictions] = useState("");
  const [teamAId, setTeamAId] = useState("");
  const [teamBId, setTeamBId] = useState("");
  const [teams, setTeams] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [error, setError] = useState("");

  let SeriesApi = SERVER_API + "/api/series";
  let UpdateMatchesApi =
    SERVER_API + `/api/matches/${localStorage.getItem("matchId")}`;

  let getTeamsApi =
    SERVER_API + `/api/series/${localStorage.getItem("seriesId")}/teams`;

  function formatDateString(timestamp) {
    timestamp = timestamp * 1000;

    let dateString = new Date(timestamp).toLocaleString("en-IN", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });

    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const parts = dateString.split(",");

    let date = parts[0] || "";
    let time = parts[1]?.trim() || "";

    date = date.split("/");
    time = time.split(" ");

    let period = time[0];
    time = time[0]?.split(":");

    time = time[0] + ":" + time[1];

    return `${date[2]}-${date[1]}-${date[0]}T${time}`;
  }

  const ReadTeams = async () => {
    const response = await fetch(getTeamsApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    setTeams(result);
  };

  const UpdateMatches = async (e) => {
    e.preventDefault();

    let input = generateUpdateMatchBody(
      teamAId || matches.teamAId,
      teamBId || matches.teamBId,
      matchNumber || matches.matchNumber,
      convertDateToTimestamp(startDate) ||
        convertDateToTimestamp(matches.startDate),
      teamAScore || matches.teamAScore,
      teamAOvers || matches.teamAOvers,
      teamBScore || matches.teamBScore,
      teamBOvers || matches.teamBOvers,
      maximumUserPredictions || matches.maximumUserPredictions,
      isHotMatch,
      comments
    );

    const response = await fetch(UpdateMatchesApi, {
      method: "PATCH",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-type": "application/json",
      },
      body: JSON.stringify(input),
    });
    let result = await response.json();
    setMatchNumber("");
    setStartDate();
    setTeamAScore("");
    setTeamBScore("");
    setTeamAOvers("");
    setTeamBOvers("");
    setMaximumUserPredictions();
    setIsHotMatch(false);
    setComments("");
    if (response.status === 200) {
      setModalShow(false);
    }
    setError(result.message);
  };

  useEffect(() => {
    ReadTeams();
  }, []);
  return (
    <div>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body
          style={{
            height: "80vh",
          }}
        >
          <div
            style={{
              height: "75vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h4>Edit Matches</h4>
              <RxCross1 color="black" onClick={setModalShow} />
            </div>
            <div
              style={{
                height: "55vh",
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <div
                style={{
                  height: "55vh",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div id="div-2">
                  <p id="username">Start date</p>
                  <input
                    onChange={(E) => setStartDate(E.target.value)}
                    id="fst-input"
                    type="datetime-local"
                    defaultValue={formatDateString(matches.startDate)}
                  />
                </div>

                <div id="div-2">
                  <p id="username">Match number</p>
                  <input
                    onChange={(E) => setMatchNumber(E.target.value)}
                    id="fst-input"
                    type="text"
                    defaultValue={matches?.matchNumber}
                  />
                </div>
                <div id="div-2">
                  <p id="username">TeamA Score</p>
                  <input
                    onChange={(e) => setTeamAScore(e.target.value)}
                    id="fst-input"
                    type="text"
                    defaultValue={matches.teamAScore?.score}
                  />
                </div>
                <div id="div-2">
                  <p id="username">TeamB Score</p>
                  <input
                    onChange={(e) => setTeamBScore(e.target.value)}
                    id="fst-input"
                    type="text"
                    defaultValue={matches.teamBScore?.score}
                  />
                </div>
                <div id="div-2">
                  <p id="username">Is Hot Match</p>
                  <select
                    id="select"
                    onChange={(E) => setIsHotMatch(E.target.value)}
                    value={isHotMatch}
                  >
                    <option value="select">Select</option>
                    <option value="true">True</option>
                    <option value="false">False</option>
                  </select>
                </div>
                <div id="div-2">
                  <p id="username">Comments</p>
                  <input
                    onChange={(e) => setComments(e.target.value)}
                    id="fst-input"
                    type="text"
                    value={comments}
                  />
                </div>
              </div>
              <div
                style={{
                  height: "45vh",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <p id="username">TeamA Id</p>
                  <select
                    id="select"
                    onChange={(E) => setTeamAId(E.target.value)}
                    value={matches.teamAId}
                  >
                    {teams?.map((data) => {
                      return <option value={data._id}>{data.teamName}</option>;
                    })}
                  </select>
                </div>
                <div>
                  <p id="username">TeamB Id</p>
                  <select
                    id="select"
                    onChange={(E) => setTeamBId(E.target.value)}
                    value={matches.teamBId}
                  >
                    {teams?.map((data) => {
                      return <option value={data._id}>{data.teamName}</option>;
                    })}
                  </select>
                </div>

                <div id="div-2">
                  <p id="username">TeamA Overs</p>
                  <input
                    onChange={(e) => setTeamAOvers(e.target.value)}
                    id="fst-input"
                    type="text"
                    defaultValue={matches.teamAScore?.overs}
                  />
                </div>
                <div id="div-2">
                  <p id="username">TeamB Overs</p>
                  <input
                    onChange={(e) => setTeamBOvers(e.target.value)}
                    id="fst-input"
                    type="text"
                    defaultValue={matches.teamBScore?.overs}
                  />
                </div>
                <div id="div-2">
                  <p id="username">Max User Predictions</p>
                  <input
                    onChange={(E) =>
                      setMaximumUserPredictions(parseInt(E.target.value))
                    }
                    id="fst-input"
                    type="text"
                    defaultValue={matches?.maximumUserPredictions}
                  />
                </div>
              </div>
            </div>
            <p id="error">{error}</p>
            <div>
              <button onClick={UpdateMatches} id="update-btn2">
                Update
              </button>
              <button onClick={setModalShow} id="cancel-btn2">
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EditReadMatches;

function convertDateToTimestamp(date) {
  //date - yyyy/mm/ddThh:mm;
  if (!date || typeof date == "number") {
    return "";
  }
  var dateTime = date.split("T");
  var date = dateTime[0].split("-");
  var dateStr = `${date[1]}-${date[2]}-${date[0]}`.concat(" ");
  var timestamp = Date.parse(dateStr.concat(dateTime[1]));
  return timestamp / 1000;
}

function generateUpdateMatchBody(
  teamAId,
  teambId,
  matchNumber,
  startDate,
  teamAScore,
  teamAOvers,
  teamBScore,
  teamBOvers,
  maximumUserPredictions,
  isHotMatch,
  comments
) {
  let out = {
    // teamAScore: { score: "", overs: "" },
    // teamBScore: { score: "", overs: "" },
  };

  if (matchNumber?.length) {
    out.matchNumber = matchNumber;
  }

  if (parseInt(startDate) > 0) {
    out.startDate = startDate;
  }

  if (teamAScore?.length) {
    out.teamAScore = { score: "", overs: "" };
    out.teamAScore.score = teamAScore;
  }

  if (teamAOvers?.length) {
    out.teamAScore.overs = teamAOvers;
  }

  if (teamBScore?.length) {
    out.teamBScore = { score: "", overs: "" };
    out.teamBScore.score = teamBScore;
  }

  if (teamBOvers?.length) {
    out.teamBScore.overs = teamBOvers;
  }

  if (parseInt(maximumUserPredictions)) {
    out.maximumUserPredictions = maximumUserPredictions;
  }

  if (comments?.length) {
    out.comments = comments;
  }

  out.isHotMatch = isHotMatch;

  return out;
}
