import React from "react";

const Testing = () => {
  return (
    <>
      <div>
        <button onClick={getData}>button</button>
      </div>
      <div id="output"></div>
    </>
  );
};

export default Testing;

async function getData() {
  const response = await fetch("https://smartai.finance:3000/users", {
    method: "GET",
    headers: {
      mode: "cors",
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  let result = await response.json();

  document.getElementById("output").innerText = result;
  return;
}
