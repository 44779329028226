import React, { useEffect, useState } from "react";
import { SERVER_API, MANAGER } from "./env";
import SideNav from "./SideNav";
import TopNav from "./TopNav";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import { getAdmin } from "./getAdmin";

const CronJobs = () => {
  const navigate = useNavigate();
  const [contestStatus, setContestStatus] = useState();
  const [referralStatus, setReferralStatus] = useState();
  const [livePriceStatus, setLivePriceStatus] = useState();

  // (() => {
  //   let adminRole = localStorage.getItem("adminRole");
  //   if (!(adminRole & MANAGER)) {
  //     navigate("/Login");
  //   }
  // })();

  let adminDetails = {};
  (async () => {
    adminDetails = await getAdmin();
    if (!(adminDetails.role & MANAGER)) {
      navigate("/");
    }
  })();

  let contestApi = SERVER_API + "/api/cron/contest";
  let referalsApi = SERVER_API + "/api/cron/referals";
  let currencyApi = SERVER_API + "/api/cron/currency";

  const GetContestStatus = async () => {
    const response = await fetch(contestApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    setContestStatus(result.status);
  };

  const UpdateContestStatus = async () => {
    const response = await fetch(contestApi, {
      method: "PATCH",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        Content_Type: "application/json",
      },
    });
    let result = await response.json();

    setContestStatus(result.status);
  };

  const GetReferralStatus = async () => {
    const response = await fetch(referalsApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    setReferralStatus(result.status);
  };

  const UpdateReferralStatus = async () => {
    const response = await fetch(referalsApi, {
      method: "PATCH",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    setReferralStatus(result.status);
  };

  const GetLivePrice = async () => {
    const response = await fetch(currencyApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    setLivePriceStatus(result.status);
  };

  const UpdateLivePrice = async () => {
    const response = await fetch(currencyApi, {
      method: "PATCH",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    setLivePriceStatus(result.status);
  };

  useEffect(() => {
    GetContestStatus();
    GetReferralStatus();
    GetLivePrice();
  }, []);
  return (
    <div style={{ display: "flex" }}>
      <SideNav />
      <div>
        <TopNav />
        <table
          style={{
            width: "85vw",
            marginLeft: "1em",
            cursor: "pointer",
            marginTop: "5em",
          }}
        >
          <thead id="thead">
            <tr>
              <th>Name</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr
              style={{
                borderBottom: "1px solid lightgray",
                height: "6vh",
              }}
            >
              <td>Contest</td>
              <td>
                <Form id={`contestStatus_toggle`}>
                  <Form.Check
                    onClick={() => UpdateContestStatus(!contestStatus)}
                    type="switch"
                    id="custom-switch"
                    checked={contestStatus}
                  />
                </Form>
              </td>
            </tr>
            <tr
              style={{
                borderBottom: "1px solid lightgray",
                height: "6vh",
              }}
            >
              <td>Referrals</td>
              <td>
                <Form id={`referralStatus_toggle`}>
                  <Form.Check
                    onClick={() => UpdateReferralStatus(!referralStatus)}
                    type="switch"
                    id="custom-switch"
                    checked={referralStatus}
                  />
                </Form>
              </td>
            </tr>
            <tr
              style={{
                borderBottom: "1px solid lightgray",
                height: "6vh",
              }}
            >
              <td>Live Price</td>
              <td>
                <Form id={`livePriceStatus_toggle`}>
                  <Form.Check
                    onClick={() => UpdateLivePrice(!livePriceStatus)}
                    type="switch"
                    id="custom-switch"
                    checked={livePriceStatus}
                  />
                </Form>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CronJobs;
