import React from "react";
import Modal from "react-bootstrap/Modal";
import { BsFillCheckCircleFill } from "react-icons/bs";

const CalScore2 = ({ show, onHide, openSuccessPost }) => {
  return (
    <div>
      <Modal
        show={show}
        onHide={onHide}
        style={{
          backgroundColor: "black-transperant",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        size="md"
        centered
      >
        {openSuccessPost ? (
          <Modal.Body
            style={{
              height: "25vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "2em",
            }}
          >
            <BsFillCheckCircleFill
              style={{ height: "6vh", width: "8vw", color: "green" }}
            />
            <h2>Successfully Posted</h2>
          </Modal.Body>
        ) : (
          <Modal.Body
            style={{
              height: "25vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "2em",
            }}
          >
            <BsFillCheckCircleFill
              style={{ height: "6vh", width: "8vw", color: "green" }}
            />
            <h2>Successfully Caluculated</h2>
          </Modal.Body>
        )}
      </Modal>
    </div>
  );
};

export default CalScore2;
