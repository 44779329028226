import React from "react";
import Modal from "react-bootstrap/Modal";
import { RxCross1 } from "react-icons/rx";
import { useState } from "react";
import { SERVER_API } from "../env";

const EditSeries = ({ modalShow, setModalShow, seriesData }) => {
  const [seriesName, setSeriesName] = useState("");
  const [typeOfSeries, setTypeOfSeries] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [matches, setMatches] = useState("");
  const [teams, setTeams] = useState("");
  const [error, setError] = useState("");
  const [comments, setComments] = useState("");

  let UpdateSeriesApi =
    SERVER_API + `/api/series/${localStorage.getItem("seriesId")}`;

  function formatDateString(timestamp) {
    timestamp = timestamp * 1000;

    let dateString = new Date(timestamp).toLocaleString("en-IN", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });

    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const parts = dateString.split(",");

    let date = parts[0] || "";
    let time = parts[1]?.trim() || "";

    date = date.split("/");
    time = time.split(" ");

    let period = time[0];
    time = time[0]?.split(":");

    time = time[0] + ":" + time[1];

    return `${date[2]}-${date[1]}-${date[0]}T${time}`;
  }

  const UpdateSeries = async (e) => {
    e.preventDefault();

    let input = {
      seriesName: seriesName || seriesData.seriesName,
      typeOfSeries: typeOfSeries || seriesData.typeOfSeries,
      startDate:
        convertDateToTimestamp(startDate) ||
        convertDateToTimestamp(seriesData.startDate),
      endDate:
        convertDateToTimestamp(endDate) ||
        convertDateToTimestamp(seriesData.endDate),
      teams: teams || secondPart,
      comments,
    };
    const response = await fetch(UpdateSeriesApi, {
      method: "PATCH",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(input),
    });
    let result = await response.json();
    if (response.status === 200) {
      setModalShow(false);
    }
    setError(result.message);
  };

  let str = seriesData.teams;

  let parts = str?.split("/");

  let secondPart = parts?.[1];
  console.log(secondPart);

  return (
    <div>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body
          style={{
            height: "60vh",
          }}
        >
          <div
            style={{
              height: "55vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h4>Edit Series</h4>
              <RxCross1
                style={{ color: "black", cursor: "pointer" }}
                onClick={setModalShow}
              />
            </div>
            <div
              style={{
                height: "35vh",
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <div
                style={{
                  height: "35vh",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div id="div-1">
                  <p id="username">Series name</p>
                  <input
                    onChange={(E) => setSeriesName(E.target.value)}
                    id="fst-input"
                    type="text"
                    defaultValue={seriesData.seriesName}
                  />
                </div>

                <div id="div-2">
                  <p id="username">Start date</p>
                  <input
                    onChange={(E) => {
                      setStartDate(E.target.value);
                    }}
                    id="fst-input"
                    type="datetime-local"
                    defaultValue={formatDateString(seriesData.startDate)}
                  />
                </div>
                <div id="div-2">
                  <p id="username">End date</p>
                  <input
                    onChange={(E) => setEndDate(E.target.value)}
                    id="fst-input"
                    type="datetime-local"
                    defaultValue={formatDateString(seriesData.endDate)}
                  />
                </div>
                <div id="div-2">
                  <p id="username">Comments</p>
                  <input
                    onChange={(E) => setComments(E.target.value)}
                    id="fst-input"
                    type="text"
                    value={comments}
                  />
                </div>
              </div>
              <div
                style={{
                  height: "25vh",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <p id="username">Type of series</p>
                <select
                  id="select"
                  onChange={(E) => setTypeOfSeries(E.target.value)}
                  defaultValue={seriesData.typeOfSeries}
                >
                  <option value="select">select</option>
                  <option value="T20">T20</option>
                  <option value="ODI">ODI</option>
                </select>
                <div id="div-2">
                  <p id="username">No of Teams</p>
                  <input
                    onChange={(E) => setTeams(E.target.value)}
                    id="fst-input"
                    type="text"
                    defaultValue={secondPart}
                  />
                </div>
                <div id="div-2">
                  <p id="username">No of Matches</p>
                  <input
                    onChange={(E) => setMatches(E.target.value)}
                    id="fst-input"
                    type="text"
                    value={seriesData.matches}
                  />
                </div>
              </div>
            </div>
            <p id="error">{error}</p>
            <div>
              <button onClick={UpdateSeries} id="update-btn2">
                Update
              </button>
              <button onClick={setModalShow} id="cancel-btn2">
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EditSeries;

function convertDateToTimestamp(dateString) {
  const date = new Date(dateString);
  return date.getTime();
}
