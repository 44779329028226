import React, { useState } from "react";
import { SERVER_API } from "../env";
import { RxCross1 } from "react-icons/rx";
import Modal from "react-bootstrap/Modal";

const EditPlayers = ({ modalShow, setModalShow, players }) => {
  const [cricBuzzPlayerId, setCricBuzzPlayerId] = useState("");
  const [playerName, setPlayerName] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [error, setError] = useState("");

  let UpdateApi =
    SERVER_API + `/api/generic-players/${localStorage.getItem("playerId")}`;

  const UpdatePlayer = async () => {
    let input = {
      cricBuzzPlayerId: cricBuzzPlayerId || players.cricBuzzPlayerId,
      playerName: playerName || players.playerName,
      displayName: displayName || players.displayName,
    };
    const response = await fetch(UpdateApi, {
      method: "PATCH",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(input),
    });
    let result = await response.json();
    if (response.status === 201) {
      setModalShow(false);
    } else {
      setError(result.message);
    }
  };

  return (
    <div>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body
          style={{
            height: "45vh",
          }}
        >
          <div
            style={{
              height: "40vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h4>Update Players</h4>
              <RxCross1 color="black" onClick={setModalShow} />
            </div>
            <div
              style={{
                height: "30vh",
                display: "flex",
                justifyContent: "space-around",
                paddingTop: "2em",
              }}
            >
              <div
                style={{
                  height: "16vh",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div id="div-2">
                  <p id="username">CrickBuzz Player Id</p>
                  <input
                    type="number"
                    onChange={(e) => setCricBuzzPlayerId(e.target.value)}
                    id="fst-input"
                    defaultValue={players.cricBuzzPlayerId}
                  />
                </div>
                <div id="div-2">
                  <p id="username">Player Name</p>
                  <input
                    onChange={(e) => setPlayerName(e.target.value)}
                    type="text"
                    id="fst-input"
                    defaultValue={players.playerName}
                  />
                </div>
              </div>
              <div
                style={{
                  height: "16vh",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <p id="username">Display Name</p>
                  <input
                    onChange={(e) => setDisplayName(e.target.value)}
                    type="text"
                    id="fst-input"
                    defaultValue={players.displayName}
                  />
                </div>
              </div>
            </div>
            <p id="error">{error}</p>
            <div>
              <button onClick={UpdatePlayer} id="update-btn2">
                Update
              </button>
              <button onClick={setModalShow} id="cancel-btn2">
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EditPlayers;
