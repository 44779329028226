import React from "react";
import SideNav from "../SideNav";
import TopNav from "../TopNav";
import "./Teams.css";
import { BiEdit } from "react-icons/bi";
import { AiOutlineDelete } from "react-icons/ai";
import { useState, useEffect } from "react";
import CreateTeams from "./CreateTeams";
import EditTeams from "./EditTeams";
import DeleteTeams from "./DeleteTeams";
import { useNavigate } from "react-router-dom";
import { SERVER_API } from "../env";
import { getAdmin } from "../getAdmin";

const Teams = () => {
  let adminDetails = {};
  (async () => {
    adminDetails = await getAdmin();
  })();

  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);
  const [open, setOpen] = useState(false);
  const [teams, setTeams] = useState([]);
  const [individualTeam, setIndividualTeam] = useState({});
  const [error, setError] = useState("");

  let TeamsApi = SERVER_API + "/api/teams";

  const GetTeams = async () => {
    const response = await fetch(TeamsApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      setTeams(result);
    } else {
      setError(result.message);
    }
  };
  useEffect(() => {
    GetTeams();
    if (!localStorage.getItem("token")) navigate("/Login");
  }, [open, modalShow, modalShow2, individualTeam]);

  const handleNavigate = (id) => {
    localStorage.setItem("teamId", id);
    navigate("/SelectTeam");
  };

  const handleEdit = (id) => {
    localStorage.setItem("teamId", id);
    setModalShow2(true);
  };
  const handleDelete = (id) => {
    localStorage.setItem("teamId", id);
    setOpen(true);
  };
  return (
    <div className="Teams-div">
      <SideNav />
      <div style={{ width: "87svw" }}>
        <TopNav />
        <div style={{ height: "10vh" }}>
          <button onClick={() => setModalShow(true)} id="create-teams-btn">
            Create Teams
          </button>
        </div>
        <div style={{ overflowY: "scroll", height: "75vh" }}>
          <table id="teams-table">
            <thead id="thead" style={{ position: "sticky" }}>
              <tr>
                <th>Logo</th>
                <th>Jersey</th>
                <th>Team Name</th>
                <th>CrickBuzz TeamId</th>
                <th>Series Name</th>
                <th>Display Name</th>
                <th>Players</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {teams?.map((data) => {
                return (
                  <tr
                    id="tRow"
                    key={data._id}
                    style={{
                      borderBottom: "1px solid lightgray",
                      height: "6vh",
                    }}
                  >
                    <td>
                      <img
                        src={`https://s3.ap-south-2.amazonaws.com/production.cryptocricket/logos/${data._id}.png`}
                        // src={`https://s3.ap-south-2.amazonaws.com/production.cryptocricket.io/logos/${data._id}.png`}
                        id="logo"
                      />
                    </td>
                    <td>
                      <img
                        src={`https://s3.ap-south-2.amazonaws.com/production.cryptocricket/jersey/${data._id}.png`}
                        id="logo"
                      />
                    </td>
                    <td onClick={() => handleNavigate(data._id)}>
                      {data.teamName}
                    </td>
                    <td onClick={() => handleNavigate(data._id)}>
                      {data.cricBuzzTeamId}
                    </td>
                    <td onClick={() => handleNavigate(data._id)}>
                      {data.series},{data.typeOfSeries}
                    </td>
                    <td onClick={() => handleNavigate(data._id)}>
                      {data.displayName}
                    </td>
                    <td onClick={() => handleNavigate(data._id)}>
                      {data.players}
                    </td>
                    <td id="Action-div">
                      <div id="Action-icons">
                        <BiEdit
                          onClick={() => {
                            handleEdit(data._id);
                            setIndividualTeam(data);
                          }}
                        />
                        <AiOutlineDelete
                          onClick={() => handleDelete(data._id)}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <p id="error" style={{ color: "gray" }}>
          {error}
        </p>
      </div>
      <CreateTeams
        modalShow={modalShow}
        setModalShow={() => setModalShow(false)}
      />
      <EditTeams
        teams={individualTeam}
        modalShow={modalShow2}
        setModalShow={() => setModalShow2(false)}
      />
      <DeleteTeams show={open} onHide={() => setOpen(false)} />
    </div>
  );
};

export default Teams;
