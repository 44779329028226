import React from "react";
import Modal from "react-bootstrap/Modal";
import { RxCross1 } from "react-icons/rx";
import { useState } from "react";
import { SERVER_API } from "../env";

const EditTeams = ({ modalShow, setModalShow, teams }) => {
  const [displayName, setDisplayName] = useState("");
  const [teamName, setTeamName] = useState("");
  const [error, setError] = useState("");

  let EditTeamsApi =
    SERVER_API + `/api/teams/${localStorage.getItem("teamId")}`;
  let UploadLogoApi = SERVER_API + "/api/s3/logos/teams?resType=high";
  let UploadJerseyApi = SERVER_API + "/api/s3/jersey/teams";

  const UploadLogo = async (reqId) => {
    let logo = document.querySelector(".uploadLogo").files[0];
    let input = new FormData();
    input.append("teamLogos", logo);
    input.append("ids", reqId);

    const response = await fetch(UploadLogoApi, {
      method: "POST",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
      },
      body: input,
    });
    let result = await response.json();
    if (response.status === 200) {
      setModalShow(false);
    }
    console.log(result);
  };

  const UploadJersey = async (reqId) => {
    let jersey = document.querySelector(".uploadJersey").files[0];
    let input = new FormData();
    input.append("teamJersey", jersey);
    input.append("ids", reqId);

    const response = await fetch(UploadJerseyApi, {
      method: "POST",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
      },
      body: input,
    });
    let result = await response.json();
    if (response.status === 200) {
      setModalShow(false);
    }
  };

  const UpdateTeams = async (e) => {
    e.preventDefault();
    let input = {
      displayName: displayName || teams.displayName,
      teamName: teamName || teams.teamName,
    };
    const response = await fetch(EditTeamsApi, {
      method: "PATCH",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(input),
    });
    let result = await response.json();
    if (response.status === 200) {
      await UploadLogo(teams._id || result._id);
      await UploadJersey(teams._id || result._id);
      setModalShow(false);
    }
    if (response.status === 200) {
      setModalShow(false);
    }
    setError(result.message);
  };

  return (
    <div>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body
          style={{
            height: "50vh",
          }}
        >
          <div
            style={{
              height: "40vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h4>Edit Teams</h4>
              <RxCross1 color="black" onClick={setModalShow} />
            </div>
            <div
              style={{
                height: "20vh",
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <div
                style={{
                  height: "16vh",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div id="div-2">
                  <p id="username">Team name</p>
                  <input
                    onChange={(e) => setTeamName(e.target.value)}
                    id="fst-input"
                    type="text"
                    defaultValue={teams.teamName}
                  />
                </div>
                <div>
                  <p id="username">Upload Logo</p>
                  <div id="file-button">
                    <input
                      type="file"
                      name="uploadfile"
                      id="file-input"
                      className="uploadLogo"
                    />
                  </div>
                </div>
              </div>
              <div
                style={{
                  height: "16vh",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div id="div-2">
                  <p id="username">Display name</p>
                  <input
                    onChange={(E) => setDisplayName(E.target.value)}
                    id="fst-input"
                    type="text"
                    defaultValue={teams.displayName}
                  />
                </div>
                <div>
                  <p id="username">Upload Jersey</p>
                  <div id="file-button">
                    <input
                      type="file"
                      name="uploadfile"
                      id="file-input"
                      className="uploadJersey"
                    />
                  </div>
                </div>
              </div>
            </div>
            <p id="error">{error}</p>
            <div>
              <button onClick={UpdateTeams} id="update-btn2">
                Update
              </button>
              <button onClick={setModalShow} id="cancel-btn2">
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EditTeams;
