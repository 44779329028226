import React from "react";
import Modal from "react-bootstrap/Modal";
import { RxCross1 } from "react-icons/rx";
import { useState, useEffect } from "react";
import { SERVER_API } from "../env";

const EditReadTeams = ({ modalShow, setModalShow, teams }) => {
  const [displayName, setDisplayName] = useState("");
  const [teamName, setTeamName] = useState("");
  const [error, setError] = useState("");
  const [seriesData, setSeriesData] = useState([]);

  let EditTeamsApi =
    SERVER_API + `/api/teams/${localStorage.getItem("teamId")}`;
  let SeriesApi = SERVER_API + "/api/series";
  let UploadLogoApi = SERVER_API + "/api/s3/logos/teams?resType=high";

  const UploadLogo = async (reqId) => {
    let logo = document.querySelector("input[type=file]").files[0];
    let input = new FormData();
    input.append("teamLogos", logo);
    input.append("ids", reqId);

    const response = await fetch(UploadLogoApi, {
      method: "POST",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        // "Content-Type": "multipart/form-data; boundary=something",
      },
      body: input,
    });
    let result = await response.json();
    if (response.status === 200) {
      setModalShow(false);
    } else {
      setError(result.message);
    }
  };

  const UpdateTeams = async (e) => {
    e.preventDefault();
    let input = {
      displayName: displayName || teams.displayName,
      teamName: teamName || teams.teamName,
    };
    const response = await fetch(EditTeamsApi, {
      method: "PATCH",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(input),
    });
    let result = await response.json();
    if (response.status === 200) {
      await UploadLogo(teams._id || result._id);
    }
    setError(result.message);
  };

  const CheckSeries = async () => {
    const response = await fetch(SeriesApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    setSeriesData(result);
  };
  useEffect(() => {
    CheckSeries();
  }, []);
  return (
    <div>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body
          style={{
            height: "50vh",
          }}
        >
          <div
            style={{
              height: "40vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h4>Edit Teams</h4>
              <RxCross1 color="black" onClick={setModalShow} />
            </div>
            <div
              style={{
                height: "20vh",
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <div
                style={{
                  height: "16vh",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div id="div-2">
                  <p id="username">Team name</p>
                  <input
                    onChange={(e) => setTeamName(e.target.value)}
                    id="fst-input"
                    type="text"
                    defaultValue={teams.teamName}
                  />
                </div>
                <div>
                  <p id="username">Upload Logo</p>
                  <div id="file-button">
                    <input type="file" name="uploadfile" id="file-input" />
                  </div>
                </div>
              </div>
              <div>
                <div id="div-2">
                  <p id="username">Display name</p>
                  <input
                    onChange={(E) => setDisplayName(E.target.value)}
                    id="fst-input"
                    type="text"
                    defaultValue={teams.displayName}
                  />
                </div>
              </div>
            </div>
            <p id="error">{error}</p>
            <div>
              <button onClick={UpdateTeams} id="update-btn2">
                Update
              </button>
              <button onClick={setModalShow} id="cancel-btn2">
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EditReadTeams;
