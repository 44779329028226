import React, { useState, useEffect } from "react";
import SideNav from "../SideNav";
import TopNav from "../TopNav";
import { PREDICTION_API } from "../env";
import { BiEdit } from "react-icons/bi";
import { getAdmin } from "../getAdmin";
import EditAnswer from "./EditAnswer";

const ShowQuestionsDirect = () => {
  let adminDetails = {};
  (async () => {
    adminDetails = await getAdmin();
  })();
  const [showQuestions, setShowQuestions] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [questionsExist, setQuestionsExist] = useState(false);
  const [answersArray, setAnswersArray] = useState([]);
  const [updateQAnA, setUpdateQAnA] = useState({});
  const [questionIndex, setIndex] = useState(-1);
  const [error, setError] = useState("");
  const [toggle, setToggle] = useState(1);

  let ShowQuestionsApi =
    PREDICTION_API +
    `/api/matches/${localStorage.getItem("matchId")}/questions`;

  const ShowQuestionsExist = async () => {
    const response = await fetch(ShowQuestionsApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });

    let result = await response.json();
    if (response.status === 200) {
      if (result.length) {
        setQuestionsExist(true);
      }
      setShowQuestions(result);

      let tempAns = new Array();
      for (let i = 0; i < result.length; ++i) {
        tempAns.push(result[i].answer);
        result[i].index = i;
      }
      setAnswersArray(tempAns);
    } else {
      setError(result.message);
    }
  };

  useEffect(() => {
    ShowQuestionsExist();
  }, [modalShow]);

  let onAnswerChange = (answer, index) => {
    let temp = [...answersArray];
    temp[index] = answer;
    setAnswersArray(temp);
  };

  return (
    <div className="ShowQuestions-div">
      <SideNav />
      <div style={{ width: "87svw" }}>
        <TopNav />

        <div id="tabs-div">
          <div
            style={{
              width: "17vw",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <button
              id="match-btn"
              onClick={() => setToggle(1)}
              style={
                toggle === 1 ? { border: "1px solid red", color: "red" } : null
              }
            >
              Short
            </button>
            <button
              style={
                toggle === 2 ? { border: "1px solid red", color: "red" } : null
              }
              onClick={() => setToggle(2)}
              id="verify-btn"
            >
              MCQ
            </button>
          </div>
        </div>
        <div
          style={{
            height: "65vh",
          }}
        >
          {toggle === 1 ? (
            <table
              style={{
                width: " 85vw",
                marginLeft: "1em",
              }}
            >
              <thead id="thead">
                <tr style={{ position: "sticky" }}>
                  <th>Question</th>
                  <th>Points</th>
                  <th>Answer</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody className="t-body">
                {showQuestions
                  .filter((data) => data.type === "short")
                  .map((data, index) => {
                    return (
                      <tr
                        key={index}
                        style={{
                          borderBottom: "1px solid lightgray",
                          height: "6vh",
                          backgroundColor:
                            data.isCancelled === true
                              ? "rgb(247, 113, 113)"
                              : "null",
                        }}
                      >
                        <td>{data.question}</td>
                        <td>{data.points}</td>
                        <td>{data.answer}</td>
                        <td>
                          <BiEdit
                            onClick={() => {
                              setIndex(data.index);
                              setModalShow(true);
                              setUpdateQAnA({
                                question: data.question,
                                type: data.type,
                                points: data.points,
                              });
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          ) : null}
          <div>
            {toggle === 2 ? (
              <table
                style={{
                  width: " 85vw",
                  marginLeft: "1em",
                }}
              >
                <thead id="thead" style={{ position: "sticky", top: "0px" }}>
                  <tr>
                    <th>Question</th>
                    <th>Options</th>
                    <th>Points</th>
                    <th>Answer</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody className="t-body">
                  {showQuestions
                    .filter((data) => data.type === "mcq")
                    .map((data, index) => {
                      return (
                        <tr
                          key={index}
                          style={{
                            borderBottom: "1px solid lightgray",
                            height: "6vh",
                            backgroundColor:
                              data.isCancelled === true
                                ? "rgb(247, 113, 113)"
                                : "null",
                          }}
                        >
                          <td>{data.question}</td>
                          <td>{convertArrayToString(data.options)}</td>
                          <td>{data.points}</td>
                          <td>{data.answer}</td>

                          <td>
                            <BiEdit
                              onClick={() => {
                                setIndex(data.index);
                                setModalShow(true);
                                setUpdateQAnA({
                                  question: data.question,
                                  type: data.type,
                                  points: data.points,
                                  options: data.options,
                                });
                              }}
                            />

                            {/* <BiEdit onClick={() => setModalshow(true)} />
                        <AiOutlineDelete onClick={() => setOpen2(true)} /> */}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            ) : null}
          </div>
        </div>

        <p style={{ color: "gray" }}>{error}</p>
      </div>
      <EditAnswer
        index={questionIndex}
        answersArray={answersArray}
        onAnswerChange={onAnswerChange}
        updateQAnA={updateQAnA}
        modalShow={modalShow}
        setModalShow={() => setModalShow(false)}
      />
    </div>
  );
};

export default ShowQuestionsDirect;

function convertArrayToString(arr) {
  let out = "";

  for (let i = 0; i < arr.length; ++i) {
    out += arr[i] + ", ";
  }

  return out;
}
