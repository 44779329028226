import React from "react";
import SideNav from "../SideNav";
import TopNav from "../TopNav";
import "./Series.css";
import { BiEdit } from "react-icons/bi";
import { AiOutlineDelete } from "react-icons/ai";
import { useEffect } from "react";
import { useState, useContext } from "react";
import CreateSeries from "./CreateSeries";
import EditSeries from "./EditSeries";
import DeleteSeries from "./DeleteSeries";
import { useNavigate } from "react-router-dom";
import { SERVER_API } from "../env";
import Context from "../../Context";
import { getAdmin } from "../getAdmin";
import Dropdown from "react-bootstrap/Dropdown";
import { MdKeyboardArrowRight } from "react-icons/md";
import EnterCrickBuzzId from "./EnterCrickBuzzId";

const Series = () => {
  let adminDetails = {};
  (async () => {
    adminDetails = await getAdmin();
  })();

  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);
  const [modalShow3, setModalShow3] = useState(false);
  const [open, setOpen] = useState(false);
  const [seriesData, setSeriesData] = useState([]);
  const { individualSeries, setIndividualSeries } = useContext(Context);
  const [error, setError] = useState("");

  let SeriesApi = SERVER_API + "/api/series";

  const GetSeries = async () => {
    const response = await fetch(SeriesApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      setSeriesData(result);
    } else {
      setError(result.message);
    }
  };
  useEffect(() => {
    GetSeries();
    if (!localStorage.getItem("token")) navigate("/Login");
  }, [open, modalShow, modalShow2, individualSeries]);

  const handleNavigate = (id) => {
    localStorage.setItem("seriesId", id);
    navigate("/ReadTeams");
  };

  const handleEdit = (id) => {
    localStorage.setItem("seriesId", id);
    setModalShow2(true);
  };

  const handleDelete = (id) => {
    localStorage.setItem("seriesId", id);
    setOpen(true);
  };

  return (
    <div className="Series-div">
      <SideNav />
      <div>
        <TopNav />
        <div style={{ height: "10vh" }}>
          <Dropdown autoClose="outside">
            <Dropdown.Toggle
              id="create-teams-btn"
              style={{ backgroundColor: "red" }}
            >
              Create Series
            </Dropdown.Toggle>
            <Dropdown.Menu
              style={{
                backgroundColor: "white",
                color: "black",
                border: "1px solid gray",
              }}
            >
              <div
                onClick={() => setModalShow(true)}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  cursor: "pointer",
                }}
                id="manual"
              >
                <p style={{}}>Manual</p>
                <p>{MdKeyboardArrowRight()}</p>
              </div>
              <div
                onClick={() => setModalShow3(true)}
                id="auto"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  cursor: "pointer",
                }}
              >
                <p>Auto</p>
                <p>{MdKeyboardArrowRight()}</p>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <div style={{ overflowY: "scroll", height: "75vh" }}>
          <table id="admins-table">
            <thead id="thead" style={{ position: "sticky" }}>
              <tr>
                <th>Series Name</th>
                <th>CricBuzz Id</th>
                <th>Type of Series</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>No of Teams</th>
                <th>No of Matches</th>
                <th>Actions</th>
              </tr>
            </thead>

            <tbody>
              {seriesData?.map((data) => {
                return (
                  <tr
                    id="tRow"
                    key={data._id}
                    style={{
                      borderBottom: "1px solid lightgray",
                      height: "6vh",
                    }}
                  >
                    <td onClick={() => handleNavigate(data._id)}>
                      {data.seriesName}
                    </td>
                    <td onClick={() => handleNavigate(data._id)}>
                      {data.cricBuzzSeriesId || "-"}
                    </td>
                    <td onClick={() => handleNavigate(data._id)}>
                      {data.typeOfSeries}
                    </td>
                    <td onClick={() => handleNavigate(data._id)}>
                      {getDateFromTimeStamp(data.startDate)}
                    </td>
                    <td onClick={() => handleNavigate(data._id)}>
                      {getDateFromTimeStamp(data.endDate)}
                    </td>
                    <td onClick={() => handleNavigate(data._id)}>
                      {data.teams}
                    </td>
                    <td onClick={() => handleNavigate(data._id)}>
                      {data.matches}
                    </td>
                    {/* <td>
                    <Form>
                      <Form.Check
                        onClick={() => handleClick(data._id)}
                        type="switch"
                        id="custom-switch"
                      />
                    </Form>
                  </td> */}

                    <td id="Action-div">
                      <div id="Action-icons">
                        <BiEdit
                          onClick={() => {
                            handleEdit(data._id);
                            setIndividualSeries(data);
                          }}
                        />
                        <AiOutlineDelete
                          onClick={() => handleDelete(data._id)}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <p id="error" style={{ color: "gray" }}>
          {error}
        </p>
      </div>
      <CreateSeries
        modalShow={modalShow}
        setModalShow={() => setModalShow(false)}
      />
      <EnterCrickBuzzId
        modalShow={modalShow3}
        setModalShow={() => setModalShow3(false)}
      />
      <EditSeries
        seriesData={individualSeries}
        modalShow={modalShow2}
        setModalShow={() => setModalShow2(false)}
      />
      <DeleteSeries show={open} onHide={() => setOpen(false)} />
    </div>
  );
};

function getDateFromTimeStamp(timeStamp) {
  return new Date(timeStamp * 1000).toLocaleDateString("en-IN", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
}

export default Series;
