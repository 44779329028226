import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { RxCross1 } from "react-icons/rx";
import { LIVE_PREDICTION_API } from "../env";

const EditLivePredictionAnswer = ({
  modalShow,
  setModalShow,
  livePredictionContests,
}) => {
  const [error, setError] = useState(false);
  const [answer, setAnswer] = useState("");
  const [endDate, setEndDate] = useState("");
  const [dateEdit, setDateEdit] = useState(false);
  const [answerEdit, setAnswerEdit] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  function formatDateString(timestamp) {
    timestamp = timestamp * 1000;

    let dateString = new Date(timestamp).toLocaleString("en-IN", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });

    const parts = dateString.split(",");

    let date = parts[0] || "";
    let time = parts[1]?.trim() || "";

    date = date.split("/");
    time = time.split(" ");

    let period = time[0];
    time = time[0]?.split(":");

    time = time[0] + ":" + time[1];

    return `${date[2]}-${date[1]}-${date[0]}T${time}`;
  }

  const submit = async () => {
    if (answerEdit && dateEdit) {
      setError(true);
      setErrorMessage("You can only edit either date or answer");
    } else {
      var updateUrl;
      var bodyToSend;
      if (answerEdit) {
        updateUrl =
          LIVE_PREDICTION_API +
          `/api/live-predictions/${localStorage.getItem(
            "livePredictionContestId"
          )}/answer `;

        bodyToSend = { answer: answer };
      } else {
        updateUrl =
          LIVE_PREDICTION_API +
          `/api/live-predictions/${localStorage.getItem(
            "livePredictionContestId"
          )} `;

        bodyToSend = { endDate: convertDateToTimestamp(endDate) };
      }

      const response = await fetch(updateUrl, {
        method: "PATCH",
        headers: {
          mode: "cors",
          Accept: "application/json",
          token: localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bodyToSend),
      });

      let result = await response.json();
      if (response.status === 200) {
        setModalShow(false);
        window.location.reload();
      } else {
        setError(true);
        setErrorMessage(result.message);
      }
    }
  };

  return (
    <div>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body
          style={{
            height: "50vh",
          }}
        >
          <div
            style={{
              height: "40vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h4>Edit Answer</h4>
              <RxCross1 color="black" onClick={setModalShow} />
            </div>
            <div
              style={{
                height: "20vh",
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <div
                style={{
                  height: "16vh",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div id="div-2">
                  <p id="username">Answer</p>
                  <input
                    onChange={(e) => {
                      setAnswer(e.target.value);
                      if (e.target.value !== livePredictionContests?.answer) {
                        setAnswerEdit(true);
                      } else {
                        setAnswerEdit(true);
                      }
                    }}
                    id="fst-input"
                    type="text"
                    defaultValue={livePredictionContests?.answer}
                  />
                </div>
              </div>
              <div
                style={{
                  height: "16vh",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div id="div-2">
                  <p id="username">End Date</p>
                  <input
                    onChange={(e) => {
                      setEndDate(e.target.value);
                      if (e.target.value !== livePredictionContests?.endDate) {
                        setDateEdit(true);
                      } else {
                        setDateEdit(false);
                      }
                    }}
                    id="fst-input"
                    type="datetime-local"
                    defaultValue={formatDateString(
                      livePredictionContests?.endDate
                    )}
                  />
                </div>
              </div>
            </div>
            <p id="error">{error ? errorMessage : null}</p>
            <div>
              <button
                onClick={() => {
                  submit();
                }}
                id="update-btn2"
              >
                Update
              </button>
              <button onClick={setModalShow} id="cancel-btn2">
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

function convertDateToTimestamp(dateString) {
  const date = new Date(dateString);
  return date.getTime() / 1000;
}

export default EditLivePredictionAnswer;
