import React from "react";
import Modal from "react-bootstrap/Modal";

const LineupResponse = ({ show, onHide, displayResponse }) => {
  return (
    <div>
      <Modal
        show={show}
        onHide={onHide}
        style={{
          backgroundColor: "black-transperant",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        size="md"
        centered
      >
        <Modal.Body
          style={{
            height: "25vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "2em",
          }}
        >
          <h4>{displayResponse}</h4>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default LineupResponse;
