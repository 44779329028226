import React, { useState, useEffect } from "react";
import SideNav from "../SideNav";
import TopNav from "../TopNav";
import CreateFantasyConfig from "./../Matches/CreateFantasyConfig";
import { FANTASY_API } from "../env";
import CreateFantasyContest from "./CreateFantasyContest";
import { useNavigate } from "react-router-dom";
import { BsCheckCircle } from "react-icons/bs";
import { RxCrossCircled } from "react-icons/rx";
import { AiOutlineDelete } from "react-icons/ai";
import DeleteFantasyContest from "./DeleteFantasyContest";

const FantasyContest = () => {
  const navigate = useNavigate();
  const [fantasyContests, setFantasyContests] = useState([]);
  const [toggle, setToggle] = useState(1);
  const [open, setOpen] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [error, setError] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [fantasyId, setFantasyId] = useState("");

  let GetFantasyContestApi = FANTASY_API + "/api/contests";

  const GetFantasyContests = async () => {
    const response = await fetch(GetFantasyContestApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      setFantasyContests(result);
    } else {
      setError(result.message);
    }
  };

  const handleDelete = (id) => {
    setFantasyId(id);
    setOpenDelete(true);
  };

  const handleNavigate = (id) => {
    navigate(`/FantasyContest/${id}`);
  };

  useEffect(() => {
    GetFantasyContests();
  }, [modalShow]);

  return (
    <div style={{ display: "flex" }}>
      <SideNav />
      <div>
        <TopNav />
        <div id="tabs-div">
          <div
            style={{
              width: "30vw",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <button
              id="all-btn"
              style={
                toggle === 1 ? { border: "1px solid red", color: "red" } : null
              }
              onClick={() => setToggle(1)}
            >
              All
            </button>
            <button
              id="upcoming-btn"
              onClick={() => setToggle(2)}
              style={
                toggle === 2 ? { border: "1px solid red", color: "red" } : null
              }
            >
              Upcoming
            </button>
            <button
              id="live-btn"
              onClick={() => setToggle(3)}
              style={
                toggle === 3 ? { border: "1px solid red", color: "red" } : null
              }
            >
              Live
            </button>
            <button
              id="completed-btn"
              onClick={() => setToggle(4)}
              style={
                toggle === 4 ? { border: "1px solid red", color: "red" } : null
              }
            >
              Completed
            </button>
          </div>
          <div>
            <button
              onClick={() => setOpen(true)}
              style={{ marginRight: "1em" }}
              id="matches-btn"
            >
              Create Config
            </button>
            <button
              style={{ width: "14vw" }}
              onClick={() => setModalShow(true)}
              id="matches-btn"
            >
              Create Fantasy
            </button>
          </div>
        </div>
        <div style={{ height: "75vh", overflowY: "scroll" }}>
          <table id="contests-table">
            <thead id="thead" style={{ position: "sticky" }}>
              <tr>
                <th>Contest Name</th>
                <th>Match No</th>
                <th>Match Name</th>
                <th>Series</th>
                <th>Start Date</th>
                <th>Entry Fee</th>
                <th>Max User Teams</th>
                <th>Max Slots</th>
                <th>Users In Contest</th>
                <th>Ball Price In USDT</th>
                <th>Published</th>
                <th>Is Verified</th>
                <th>Action</th>
              </tr>
            </thead>
            {toggle === 1 ? (
              <tbody>
                {fantasyContests.map((data) => {
                  return (
                    <tr
                      style={{
                        borderBottom: "1px solid lightgray",
                        height: "6vh",
                      }}
                    >
                      <td onClick={() => handleNavigate(data._id)}>
                        {data.contestName}
                      </td>
                      <td onClick={() => handleNavigate(data._id)}>
                        {data.matchNumber}
                      </td>
                      <td onClick={() => handleNavigate(data._id)}>
                        {data.match}
                      </td>
                      <td>{data.series}</td>
                      <td onClick={() => handleNavigate(data._id)}>
                        {getDateFromTimeStamp(data.startDate)}
                      </td>
                      <td onClick={() => handleNavigate(data._id)}>
                        {data.entryFee}
                      </td>
                      <td onClick={() => handleNavigate(data._id)}>
                        {data.maxUserTeams}
                      </td>
                      <td onClick={() => handleNavigate(data._id)}>
                        {data.maxSlots}
                      </td>
                      <td onClick={() => handleNavigate(data._id)}>
                        {data.usersInContest}
                      </td>
                      <td onClick={() => handleNavigate(data._id)}>
                        {data.ballPriceInUSD}
                      </td>
                      <td>
                        {data.published === true ? (
                          <BsCheckCircle color="green" />
                        ) : (
                          <RxCrossCircled color="red" />
                        )}
                      </td>
                      <td>
                        {data.isVerified === true ? (
                          <BsCheckCircle color="green" />
                        ) : (
                          <RxCrossCircled color="red" />
                        )}
                      </td>
                      <td>
                        <AiOutlineDelete
                          onClick={() => handleDelete(data._id)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            ) : null}

            {toggle === 2 ? (
              <tbody>
                {fantasyContests
                  .filter?.((value) => {
                    if (value.startDate > parseInt(Date.now() / 1000)) {
                      return value;
                    }
                  })
                  .map((data) => {
                    return (
                      <tr
                        style={{
                          borderBottom: "1px solid lightgray",
                          height: "6vh",
                        }}
                      >
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.contestName}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.matchNumber}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.match}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.series}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {getDateFromTimeStamp(data.startDate)}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.entryFee}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.maxUserTeams}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.maxSlots}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.usersInContest}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.ballPriceInUSD}
                        </td>
                        <td>
                          {data.published === true ? (
                            <BsCheckCircle color={"green"} />
                          ) : (
                            <RxCrossCircled color={"red"} />
                          )}
                        </td>
                        <td>
                          {data.isVerified === true ? (
                            <BsCheckCircle color="green" />
                          ) : (
                            <RxCrossCircled color="red" />
                          )}
                        </td>
                        <td>
                          <AiOutlineDelete
                            onClick={() => handleDelete(data._id)}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            ) : null}

            {toggle === 3 ? (
              <tbody>
                {fantasyContests
                  .filter?.((value) => {
                    if (
                      value.startDate <= parseInt(Date.now() / 1000) &&
                      value.rewardsSettled === false &&
                      value.cancelled === false
                    ) {
                      return value;
                    }
                  })
                  .map((data) => {
                    return (
                      <tr
                        style={{
                          borderBottom: "1px solid lightgray",
                          height: "6vh",
                        }}
                      >
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.contestName}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.matchNumber}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.match}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.series}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {getDateFromTimeStamp(data.startDate)}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.entryFee}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.maxUserTeams}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.maxSlots}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.usersInContest}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.ballPriceInUSD}
                        </td>
                        <td>
                          {data.published === true ? (
                            <BsCheckCircle color="green" />
                          ) : (
                            <RxCrossCircled color="red" />
                          )}
                        </td>
                        <td>
                          {data.isVerified === true ? (
                            <BsCheckCircle color="green" />
                          ) : (
                            <RxCrossCircled color="red" />
                          )}
                        </td>
                        <td>
                          <AiOutlineDelete
                            onClick={() => handleDelete(data._id)}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            ) : null}

            {toggle === 4 ? (
              <tbody>
                {fantasyContests
                  .filter?.((value) => {
                    if (
                      value.rewardsSettled === true ||
                      value.cancelled === true
                    ) {
                      return value;
                    }
                  })
                  .map((data) => {
                    return (
                      <tr
                        style={{
                          borderBottom: "1px solid lightgray",
                          height: "6vh",
                        }}
                      >
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.contestName}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.matchNumber}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.match}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.series}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {getDateFromTimeStamp(data.startDate)}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.entryFee}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.maxUserTeams}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.maxSlots}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.usersInContest}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.ballPriceInUSD}
                        </td>
                        <td>
                          {data.published === true ? (
                            <BsCheckCircle color="green" />
                          ) : (
                            <RxCrossCircled color="red" />
                          )}
                        </td>
                        <td>
                          {data.isVerified === true ? (
                            <BsCheckCircle color="green" />
                          ) : (
                            <RxCrossCircled color="red" />
                          )}
                        </td>
                        <td>
                          <AiOutlineDelete
                            onClick={() => handleDelete(data._id)}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            ) : null}
          </table>
        </div>
        <p id="error" style={{ color: "gray" }}>
          {error}
        </p>
      </div>
      <DeleteFantasyContest
        id={fantasyId}
        show={openDelete}
        onHide={() => setOpenDelete(false)}
      />
      <CreateFantasyConfig show={open} onHide={() => setOpen(false)} />
      <CreateFantasyContest
        modalShow={modalShow}
        setModalShow={() => setModalShow(false)}
      />
    </div>
  );
};

export default FantasyContest;

function getDateFromTimeStamp(timeStamp) {
  return new Date(timeStamp * 1000).toLocaleDateString("en-In", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
}
