import React, { useState } from "react";
import { SERVER_API } from "../env";
import { RxCross1 } from "react-icons/rx";
import Modal from "react-bootstrap/Modal";

const EditTournament = ({ modalShow, setModalShow, tournamentList }) => {
  const [tournamentName, setTournamentName] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [rules, setRules] = useState("");
  const [contestType, setContestType] = useState("");
  const [symbol, setSymbol] = useState("");
  const [total, setTotal] = useState("");
  const [rewardType, setRewardType] = useState("");
  const [cancelled, setCancelled] = useState("");
  const [error, setError] = useState("");

  let UpdateTournamentApi =
    SERVER_API + `/api/tournaments/${localStorage.getItem("tournamentId")}`;

  function formatDateString(timestamp) {
    timestamp = timestamp * 1000;

    let dateString = new Date(timestamp).toLocaleString("en-IN", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });

    const parts = dateString.split(",");

    let date = parts[0] || "";
    let time = parts[1]?.trim() || "";

    date = date.split("/");
    time = time.split(" ");

    let period = time[0];
    time = time[0]?.split(":");

    time = time[0] + ":" + time[1];

    return `${date[2]}-${date[1]}-${date[0]}T${time}`;
  }

  const UpdateTournaments = async () => {
    let input = generateUpdateBody(
      convertDateToTimeStamp(startDate),
      convertDateToTimeStamp(endDate),
      rules
    );
    const response = await fetch(UpdateTournamentApi, {
      method: "PATCH",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(input),
    });
    let result = await response.json();
    setStartDate();
    setEndDate();
    setRules("");
    if (response.status === 200) {
      setModalShow(false);
    } else {
      setError(result.message);
    }
  };
  return (
    <div>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body
          style={{
            height: "60vh",
          }}
        >
          <div
            style={{
              height: "55vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h4>Edit Tournament</h4>
              <RxCross1 onClick={setModalShow} color="black" />
            </div>
            <div
              style={{
                height: "40vh",
                display: "flex",
                justifyContent: "space-around",
                marginTop: "1em",
              }}
            >
              <div
                style={{
                  height: "40vh",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <p id="username">Tournament Name</p>
                  <input
                    onChange={(e) => setTournamentName(e.target.value)}
                    id="fst-input"
                    type="text"
                    value={tournamentList.tournamentName}
                  />
                </div>

                <div id="div-2">
                  <p id="username">Start Date</p>
                  <input
                    onChange={(e) => setStartDate(e.target.value)}
                    id="fst-input"
                    type="datetime-local"
                    defaultValue={formatDateString(tournamentList?.startDate)}
                  />
                </div>
                <div id="div-2">
                  <p id="username">End Date</p>
                  <input
                    onChange={(e) => setEndDate(e.target.value)}
                    id="fst-input"
                    type="datetime-local"
                    defaultValue={formatDateString(tournamentList.endDate)}
                  />
                </div>
                <div id="div-2">
                  <p id="username">Contest Type</p>
                  <select
                    onChange={(e) => setContestType(e.target.value)}
                    id="fst-input"
                    type="text"
                    value={tournamentList.contestType}
                  >
                    <option value="select">Select</option>
                    <option value="prediction">Prediction</option>
                    <option value="live">Live</option>
                    <option value="fantasy">Fantasy</option>
                  </select>
                </div>
                <div id="div-2">
                  <p id="username">Reward Type</p>
                  <select
                    onChange={(e) => setRewardType(e.target.value)}
                    id="select"
                    type="text"
                    value={tournamentList.rewardType}
                  >
                    <option value="select">Select</option>
                    <option value="onchain">onchain</option>
                  </select>
                </div>
              </div>

              <div
                style={{
                  height: "30vh",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div id="div-2">
                  <p id="username">Reward Symbol</p>
                  <input
                    onChange={(e) => setSymbol(e.target.value)}
                    id="fst-input"
                    type="text"
                    value={tournamentList.symbol}
                  />
                </div>

                <div id="div-2">
                  <p id="username">Reward Total</p>
                  <input
                    onChange={(e) => setTotal(e.target.value)}
                    id="fst-input"
                    type="number"
                    value={tournamentList.total}
                  />
                </div>

                <div id="div-2">
                  <p id="username">Rules</p>
                  <input
                    onChange={(e) => setRules(e.target.value)}
                    id="fst-input"
                    type="text"
                    defaultValue={tournamentList.rules}
                  />
                </div>
                <div id="div-2">
                  <p id="username">Cancelled</p>
                  <select
                    onChange={(e) => setCancelled(e.target.value)}
                    id="fst-input"
                    type="text"
                    value={tournamentList.cancelled}
                  >
                    <option value="select">Selcet</option>
                    <option value="true">True</option>
                    <option value="false">False</option>
                  </select>
                </div>
              </div>
            </div>
            <p id="error">{error}</p>
            <div>
              <button id="update-btn2" onClick={UpdateTournaments}>
                Update
              </button>
              <button onClick={setModalShow} id="cancel-btn2">
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EditTournament;

function convertDateToTimeStamp(date) {
  //date - yyyy / mm / ddThh:mm;
  if (!date || typeof date == "number") {
    return "";
  }
  var dateTime = date.split("T");
  var date = dateTime[0].split("-");
  var dateStr = `${date[1]}-${date[2]}-${date[0]}`.concat(" ");
  var timestamp = Date.parse(dateStr.concat(dateTime[1]));
  return timestamp / 1000;
}

function generateUpdateBody(startDate, endDate, rules) {
  let out = {};

  if (parseInt(startDate) > 0) {
    out.startDate = startDate;
  }
  if (parseInt(endDate) > 0) {
    out.endDate = endDate;
  }
  if (rules?.length) {
    out.rules = rules;
  }

  return out;
}
