import React from "react";
import Modal from "react-bootstrap/Modal";
import { RxCross1 } from "react-icons/rx";
import { useState } from "react";
import "./CreateSeries.css";
import { SERVER_API } from "../env";

const CreateSeries2 = ({ modalShow, setModalShow }) => {
  const [seriesName, setSeriesName] = useState("");
  const [cricBuzzSeriesId, setCricBuzzSeriesId] = useState("");
  const [typeOfSeries, setTypeOfSeries] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [numberOfMatches, setNumberOfMatches] = useState("");
  const [teams, setTeams] = useState("");
  const [error, setError] = useState("");

  let CreateSeriesApi = SERVER_API + "/api/series";

  const CreateSeries = async (e) => {
    e.preventDefault();
    let input = {
      seriesName,
      cricBuzzSeriesId,
      typeOfSeries,
      startDate: convertDateToTimestamp(startDate),
      endDate: convertDateToTimestamp(endDate),
      numberOfMatches,
      teams,
    };
    const response = await fetch(CreateSeriesApi, {
      method: "POST",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(input),
    });
    let result = await response.json();
    if (response.status === 201) {
      setModalShow(false);
    }
    setError(result.message);
  };

  return (
    <div>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body
          style={{
            height: "60vh",
          }}
        >
          <div
            style={{
              height: "55vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h4>Create Series</h4>
              <RxCross1
                style={{ color: "black", cursor: "pointer" }}
                onClick={setModalShow}
              />
            </div>
            <div
              style={{
                height: "40vh",
                display: "flex",
                justifyContent: "space-around",
                marginTop: "1em",
              }}
            >
              <div
                style={{
                  height: "35vh",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div id="div-1">
                  <p id="username">SeriesName</p>
                  <input
                    onChange={(E) => setSeriesName(E.target.value)}
                    id="fst-input"
                    type="text"
                    value={seriesName}
                  />
                </div>
                <div id="div-1">
                  <p id="username">CricBuzz Series Id</p>
                  <input
                    onChange={(E) => setCricBuzzSeriesId(E.target.value)}
                    id="fst-input"
                    type="text"
                    value={cricBuzzSeriesId}
                  />
                </div>

                <div id="div-2">
                  <p id="username">Start date</p>
                  <input
                    onChange={(e) => {
                      console.log(e.target.value);
                      setStartDate(e.target.value);
                    }}
                    id="fst-input"
                    type="datetime-local"
                    value={startDate}
                  />
                </div>
                <div id="div-2">
                  <p id="username">End date</p>
                  <input
                    onChange={(E) => setEndDate(E.target.value)}
                    id="fst-input"
                    type="datetime-local"
                    value={endDate}
                  />
                </div>
              </div>
              <div
                style={{
                  height: "25vh",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <p id="username">Type of series</p>
                <select
                  id="select"
                  onChange={(E) => setTypeOfSeries(E.target.value)}
                  value={typeOfSeries}
                >
                  <option value="select">select</option>
                  <option value="T20">T20</option>
                  <option value="ODI">ODI</option>
                </select>
                <div id="div-2">
                  <p id="username">No of Teams</p>
                  <input
                    onChange={(E) => setTeams(E.target.value)}
                    id="fst-input"
                    type="number"
                    value={teams}
                  />
                </div>
                <div id="div-2">
                  <p id="username">No of Matches</p>
                  <input
                    onChange={(E) => setNumberOfMatches(E.target.value)}
                    id="fst-input"
                    type="number"
                    value={numberOfMatches}
                  />
                </div>
              </div>
            </div>
            <p id="error">{error}</p>
            <div>
              <button onClick={CreateSeries} id="update-btn2">
                Create
              </button>
              <button onClick={setModalShow} id="cancel-btn2">
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CreateSeries2;

function convertDateToTimestamp(date) {
  //date - yyyy / mm / dd:hh:mm;
  var dateTime = date.split("T");
  var date = dateTime[0].split("-");
  var dateStr = `${date[1]}-${date[2]}-${date[0]}`.concat(" ");
  var timeStamp = Date.parse(dateStr.concat(dateTime[1]));
  return timeStamp / 1000;
}
