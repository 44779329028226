import React, { useState, useEffect } from "react";
import SideNav from "./../SideNav";
import TopNav from "../TopNav";
import { SERVER_API } from "../env";
import { useNavigate } from "react-router-dom";
import { FaSort } from "react-icons/fa";

const SelectTeam = () => {
  const navigate = useNavigate();
  const [teamPlayers, setTeamPlayers] = useState([]);
  const [teamDetails, setTeamDetails] = useState({});
  const [error, setError] = useState("");

  let GetTeamPlayersApi =
    SERVER_API + `/api/team-players/${localStorage.getItem("teamId")}`;
  let GetTeamDetailsApi =
    SERVER_API + `/api/teams/${localStorage.getItem("teamId")}`;

  const GetTeamPlayers = async () => {
    const response = await fetch(GetTeamPlayersApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      setTeamPlayers(result);
    } else {
      setError(result.message);
    }
  };

  const GetTeamDetails = async () => {
    const response = await fetch(GetTeamDetailsApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    setTeamDetails(result);
  };

  const sortByDisplayName = () => {
    const sortedItems = [...teamPlayers];
    sortedItems.sort((a, b) => a.displayName.localeCompare(b.displayName));
    setTeamPlayers(sortedItems);
  };

  const sortByRole = () => {
    const sortedRole = [...teamPlayers];
    sortedRole.sort((a, b) => a.role.localeCompare(b.role));
    setTeamPlayers(sortedRole);
  };

  useEffect(() => {
    GetTeamPlayers();
    GetTeamDetails();
  }, []);
  return (
    <div style={{ display: "flex" }}>
      <SideNav />
      <div>
        <TopNav />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            height: "10vh",
            alignItems: "center",
            marginLeft: "1em",
          }}
        >
          <div style={{ display: "flex" }}>
            <p style={{ fontWeight: "bold" }}>Team Name :</p>&nbsp;
            <p>{teamDetails.teamName}</p>
          </div>
          <button
            onClick={() => navigate("/GetNewPlayers")}
            id="create-teams-btn2"
          >
            Get New Players
          </button>
        </div>

        <div style={{ overflowY: "scroll", height: "70vh" }}>
          <table id="teams-table">
            <thead id="thead" style={{ position: "sticky" }}>
              <tr>
                <th>CrickBuzz Player Id</th>
                <th>player Name</th>
                <th onClick={sortByDisplayName}>
                  Display Name{" "}
                  <FaSort
                    style={{
                      height: "2vh",
                      width: "2vw",
                    }}
                  />
                </th>
                <th>Series Fantasy Score</th>
                <th onClick={sortByRole}>
                  Role{" "}
                  <FaSort
                    style={{
                      height: "2vh",
                      width: "2vw",
                    }}
                  />
                </th>
                <th>Credits</th>
              </tr>
            </thead>
            <tbody>
              {teamPlayers.map((players) => {
                return (
                  <tr
                    style={{
                      borderBottom: "1px solid lightgray",
                      height: "6vh",
                    }}
                  >
                    <td>{players.cricBuzzPlayerId}</td>
                    <td>{players.playerName}</td>
                    <td>{players.displayName}</td>
                    <td>{players.seriesFantasyScore}</td>
                    <td>{players.role}</td>
                    <td>{players.credits}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <p id="error" style={{ color: "gray" }}>
          {error}
        </p>
      </div>
    </div>
  );
};

export default SelectTeam;
