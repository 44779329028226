import React from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import "./UpdateProfile.css";
import { useState } from "react";
import { SERVER_API } from "../env";

const UpdateProfile = ({ show, onHide }) => {
  const [userName, setUserName] = useState("");
  const [error, setError] = useState("");

  let UpdateProfileApi =
    SERVER_API + `/api/admins/${localStorage.getItem("adminId")}/profile`;

  const UpdateProfile = async (e) => {
    console.log(localStorage.getItem("token"));
    e.preventDefault();
    let input = { userName };
    const response = await fetch(UpdateProfileApi, {
      method: "PATCH",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(input),
    });
    let result = await response.json();
    if (response.status === 200) {
      onHide();
    }
    setError(result.message);
    localStorage.setItem("userName", result.userName);
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={onHide}
        style={{ backgroundColor: "black-transperant" }}
        size="md"
        centered
      >
        <Modal.Body
          style={{
            height: "40vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="up-div">
            <h4 id="update-heading">Update your profile</h4>
            <Form.Label></Form.Label>

            <div id="div-1">
              <p id="username">Username</p>
              <input
                onChange={(E) => setUserName(E.target.value)}
                id="fst-input"
                type="text"
                value={userName}
              />
            </div>
            <div className="btn-div">
              <button onClick={onHide} id="cancel-btn">
                Cancel
              </button>
              <button onClick={UpdateProfile} id="update-btn">
                Update
              </button>
            </div>
          </div>
          <p id="error-msg">{error}</p>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UpdateProfile;
