import React from "react";
import SideNav from "./../SideNav";
import TopNav from "../TopNav";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import CreateReadTeams from "./CreateReadTeams";
import { BiEdit } from "react-icons/bi";
import { AiOutlineDelete } from "react-icons/ai";
import EditReadTeams from "./EditReadTeams";
import DeleteTeams from "./../Teams/DeleteTeams";
import { SERVER_API } from "../env";
import { getAdmin } from "../getAdmin";

const ReadTeams = () => {
  let adminDetails = {};
  (async () => {
    adminDetails = await getAdmin();
  })();

  const navigate = useNavigate();
  const location = useLocation();
  const [teams, setTeams] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);
  const [open, setOpen] = useState(false);
  const [individualTeams, setIndividualTeams] = useState({});

  let getTeamsApi =
    SERVER_API + `/api/series/${localStorage.getItem("seriesId")}/teams`;

  const ReadTeams = async () => {
    const response = await fetch(getTeamsApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    setTeams(result);
  };
  useEffect(() => {
    ReadTeams();
  }, [open, modalShow, modalShow2, individualTeams]);

  const handleNavigate = (id) => {
    localStorage.setItem("teamId", id);
    navigate("/SelectTeam");
  };

  const handleEdit = (id) => {
    localStorage.setItem("teamId", id);
    setModalShow2(true);
  };
  const handleDelete = (id) => {
    localStorage.setItem("teamId", id);
    setOpen(true);
  };
  return (
    <div className="Series-div">
      <SideNav />
      <div style={{ width: "87svw" }}>
        <TopNav />

        <div id="tabs-div">
          <div
            style={{
              width: "17vw",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <button
              id="match-btn"
              style={
                location.pathname === "/ReadTeams"
                  ? { border: "1px solid red", color: "red" }
                  : null
              }
            >
              Teams
            </button>
            <button onClick={() => navigate("/ReadMatches")} id="verify-btn">
              Matches
            </button>
          </div>

          <div>
            <button onClick={() => setModalShow(true)} id="matches-btn">
              Create Teams
            </button>
          </div>
        </div>

        <table
          style={{
            width: " 85vw",
            marginLeft: "1em",
          }}
        >
          <thead id="thead">
            <tr>
              <th>Team Logo</th>
              <th>Team Name</th>
              <th>Display Name</th>
              <th>Players</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {teams?.map((data) => {
              return (
                <tr
                  id="tRow"
                  style={{
                    borderBottom: "1px solid lightgray",
                    height: "6vh",
                  }}
                  key={data._id}
                >
                  <td>
                    <img
                      src={`https://s3.ap-south-2.amazonaws.com/production.cryptocricket/logos/${data._id}.png`}
                      // src={`https://s3.ap-south-2.amazonaws.com/production.cryptocricket.io/logos/${data._id}.png`}
                      id="logo"
                    />
                  </td>
                  <td onClick={() => handleNavigate(data._id)}>
                    {data.teamName}
                  </td>
                  <td onClick={() => handleNavigate(data._id)}>
                    {data.displayName}
                  </td>
                  <td onClick={() => handleNavigate(data._id)}>
                    {data.players}
                  </td>
                  <td id="Action-div">
                    <div id="Action-icons">
                      <BiEdit
                        onClick={() => {
                          handleEdit(data._id);
                          setIndividualTeams(data);
                        }}
                      />
                      <AiOutlineDelete onClick={() => handleDelete(data._id)} />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <CreateReadTeams
        modalShow={modalShow}
        setModalShow={() => setModalShow(false)}
      />
      <EditReadTeams
        teams={individualTeams}
        modalShow={modalShow2}
        setModalShow={() => setModalShow2(false)}
      />
      <DeleteTeams show={open} onHide={() => setOpen(false)} />
    </div>
  );
};

export default ReadTeams;
