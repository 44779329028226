import React from "react";
import Modal from "react-bootstrap/Modal";
import { RxCross1 } from "react-icons/rx";
import { useState } from "react";
import { PREDICTION_API } from "../env";

const EditAnswer = ({
  index,
  answersArray,
  updateQAnA,
  modalShow,
  setModalShow,
  onAnswerChange,
}) => {
  const [answer, setAnswer] = useState("");
  const [comments, setComments] = useState("");
  const [error, setError] = useState("");

  let UpdateAnswerApi =
    PREDICTION_API +
    `/api/matches/${localStorage.getItem("matchId")}/questions/answers`;
  let CancelQuestionApi =
    PREDICTION_API +
    `/api/matches/${localStorage.getItem("matchId")}/questions/cancel?index=`;
  let RevertQuestionApi =
    PREDICTION_API +
    `/api/matches/${localStorage.getItem(
      "matchId"
    )}/questions/cancel/revert?index=`;

  const CancelQuestion = async (index) => {
    const response = await fetch(CancelQuestionApi + index, {
      method: "PATCH",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 400) {
      setError(result.message);
    }
  };

  const RevertQuestion = async (index) => {
    const response = await fetch(RevertQuestionApi + index, {
      method: "PATCH",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 400) {
      setError(result.message);
    }
  };

  const checkbox = document.getElementById("myCheckbox");

  checkbox?.addEventListener("change", function () {
    if (checkbox.checked) {
      CancelQuestion(index);
    } else {
      RevertQuestion(index);
    }
  });

  const UpdateAnswer = async () => {
    answersArray[index] = answer;
    console.log(answersArray);
    let body = { answers: answersArray };

    const response = await fetch(UpdateAnswerApi, {
      method: "PATCH",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    let result = await response.json();
    if (response.status === 200) {
      onAnswerChange(answer, index);
      setModalShow(false);
    } else {
      setError(result.message);
    }
  };

  return (
    <div>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body
          style={{
            height: "50vh",
          }}
        >
          <div
            style={{
              height: "40vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h4>Edit Answer</h4>
              <RxCross1 color="black" onClick={setModalShow} />
            </div>
            <div id="modal-main-div">
              <div id="modal-left-div" style={{ height: "10vh" }}>
                <div id="div-2">
                  <p id="username">Answer</p>
                  <input
                    id="fst-input"
                    type="number"
                    onChange={(e) => setAnswer(e.target.value)}
                    value={answer}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "22vw",
                    alignItems: "center",
                  }}
                >
                  <input
                    id="myCheckbox"
                    style={{ height: "1.5em", width: "1.5em" }}
                    type="checkbox"
                  />
                  &nbsp;
                  <lable id="lable">Do you want to cancel this question </lable>
                </div>
              </div>
              <div>
                <div id="div-2">
                  <p id="username">Comments</p>
                  <input
                    id="fst-input"
                    type="text"
                    onChange={(e) => setComments(e.target.value)}
                    value={comments}
                  />
                </div>
              </div>
            </div>
            <p id="error">{error}</p>
            <div>
              <button onClick={UpdateAnswer} id="update-btn2">
                Update
              </button>
              <button onClick={setModalShow} id="cancel-btn2">
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EditAnswer;
