import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { RxCross1 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import { PREDICTION_API } from "../env";

const CreatePredictionConfig = ({ show, onHide }) => {
  const navigate = useNavigate();
  const [maxUserPredictions, setMaxUserPredictions] = useState([]);

  return (
    <div>
      <Modal
        show={show}
        onHide={onHide}
        style={{
          backgroundColor: "black-transperant",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        size="md"
        centered
      >
        <Modal.Body
          style={{
            height: "35vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div id="head-div">
            <h4>Create Prediction Config</h4>
            <RxCross1 color="black" onClick={onHide} />
          </div>
          <div
            style={{
              height: "10vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              paddingTop: "2em",
            }}
          >
            <div id="div-2">
              <p id="old-password">Max User Predictions</p>
              <input
                onChange={(e) => setMaxUserPredictions(e.target.value)}
                type="number"
                id="sec-input"
                value={maxUserPredictions}
              />
            </div>
          </div>

          <button
            onClick={() =>
              navigate("/ShowQuestions", { state: maxUserPredictions })
            }
            id="create-config-btn2"
          >
            Add Questions
          </button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CreatePredictionConfig;
