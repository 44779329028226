import React, { useState, useEffect } from "react";
import SideNav from "./../SideNav";
import TopNav from "../TopNav";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import SettleContest from "./SettleContest";
import { SERVER_API, MANAGER, PREDICTION_API } from "../env";
import { getAdmin } from "../getAdmin";

const Leaderboard = () => {
  const [adminDisplay, setAdminDisplay] = useState(false);

  let adminDetails = {};
  (async () => {
    adminDetails = await getAdmin();
    if (adminDetails.role & MANAGER) {
      setAdminDisplay(true);
    } else {
      setAdminDisplay(false);
    }
  })();

  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const [leaderboard, setLeaderboard] = useState([]);
  const [botsCount, setBotsCount] = useState(0);

  let SettleApi =
    SERVER_API +
    `/api/contests/${localStorage.getItem("contestId")}/rewards/settle`;
  let GetLeaderboardApi =
    PREDICTION_API +
    `/api/prediction-contests/${localStorage.getItem(
      "predictionContestId"
    )}/leaderboard`;

  const RewardSettle = async (e) => {
    e.preventDefault();
    const response = await fetch(SettleApi, {
      method: "PATCH",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      setOpen(true);
    }
    if (response.status === 400) {
      setError(result.message);
    }
    console.log(result);
  };

  const GetLeaderboard = async () => {
    const response = await fetch(GetLeaderboardApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      setLeaderboard(result);
      setBotsCount(result.noOfBots);
    } else {
      setError(result.message);
    }
  };
  useEffect(() => {
    GetLeaderboard();
  }, []);
  return (
    <div className="selectContest-div">
      <SideNav />
      <div style={{ width: "87svw" }}>
        <TopNav />

        <div id="tabs-div">
          <div
            style={{
              width: "22vw",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <button
              id="match-btn"
              style={
                location.pathname === "/Leaderboard"
                  ? { border: "1px solid red", color: "red", width: "10vw" }
                  : null
              }
            >
              Leaderboard
            </button>
            <select
              onClick={() => navigate("/Rewards")}
              id="verify-btn"
              style={{ width: "11vw" }}
            >
              <option value="">Reward Breakup</option>
            </select>
          </div>

          {/* <div>
            <button onClick={RewardSettle} id="matches-btn">
              Settele Contest
            </button>
          </div> */}
        </div>
        <div
          style={{
            display: adminDisplay ? "flex" : "none",
            margin: "0 0 0 1.5em",
          }}
        >
          <p style={{ fontWeight: "bolder" }}>No of Bots : </p>&nbsp;
          <p> {botsCount}</p>
        </div>
        <div
          style={{
            overflowY: "scroll",
            height: "70vh",
          }}
        >
          <table
            style={{
              width: " 85vw",
              marginLeft: "1em",
            }}
          >
            <thead id="thead" style={{ position: "sticky" }}>
              <tr>
                <th>User Id</th>
                <th>tag</th>
                <th>Rank</th>
                <th>Score</th>
              </tr>
            </thead>
            <tbody>
              {leaderboard.usersRank?.map((data) => {
                return (
                  <tr
                    key={data._id}
                    style={{
                      borderBottom: "1px solid lightgray",
                      height: "6vh",
                      backgroundColor:
                        data.isBot && adminDisplay ? "rgb(230, 193, 186)" : "",
                    }}
                  >
                    <td>{data.userId}</td>
                    <td>{data.userName}</td>
                    <td>{data.rank}</td>
                    <td>{data.score}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <p style={{ color: "gray" }}>{error}</p>
      </div>
      <SettleContest show={open} onHide={() => setOpen(false)} />
    </div>
  );
};

export default Leaderboard;
