import React from "react";
import "./TopNav.css";
import { IoMdArrowDropdown } from "react-icons/io";
import { MdKeyboardArrowRight } from "react-icons/md";
import { useState } from "react";
import UpdateProfile from "./Dropdown/UpdateProfile";
import ChangePass from "./Dropdown/ChangePass";
import TransferOwnership from "./Dropdown/TransferOwnership";
import { useLocation } from "react-router-dom";
import Logout from "./Dropdown/Logout";

const TopNav = () => {
  const location = useLocation();
  const [dropdown, setDropdown] = useState();
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);

  return (
    <div className="TopNav">
      <div className="fst-div">
        {location.pathname === "/" ? <h4 id="heading">Dashboard</h4> : null}
        {location.pathname === "/Admin" ? <h4 id="heading">Admin</h4> : null}
        {location.pathname === "/Wallet" ? <h4 id="heading">Wallet</h4> : null}
        {location.pathname === "/Series" ? <h4 id="heading">Series</h4> : null}
        {location.pathname === "/Users" ? <h4 id="heading">Users</h4> : null}
        {location.pathname === "/UserWallet" ? (
          <h4 id="heading">Wallet</h4>
        ) : null}
        {location.pathname === "/ReadTeams" ||
        location.pathname === "/ReadMatches" ? (
          <h4 id="heading">Series</h4>
        ) : null}
        {location.pathname === "/Teams" ||
        location.pathname === "/SelectTeam" ||
        location.pathname === "/GetNewPlayers" ? (
          <h4 id="heading">Teams</h4>
        ) : null}
        {location.pathname === "/Matches" ? (
          <h4 id="heading">Matches</h4>
        ) : null}
        {location.pathname === "/UpcomingMatches" ||
        location.pathname === "/ShowQuestionsDirect" ? (
          <h4 id="heading">Matches</h4>
        ) : null}
        {location.pathname === "/LiveMatches" ? (
          <h4 id="heading">Matches</h4>
        ) : null}
        {location.pathname === "/CompletedMatches" ? (
          <h4 id="heading">Matches</h4>
        ) : null}
        {location.pathname === "/SelectMatch" ? (
          <h4 id="heading">Matches</h4>
        ) : null}
        {location.pathname === "/VerifyMatch" ? (
          <h4 id="heading">Matches</h4>
        ) : null}
        {location.pathname === "/ShowQuestions" ||
        location.pathname === "/PredictQuestions" ||
        location.pathname === "/PredictQuestionsManual" ||
        location.pathname === "/SamplePredictions" ||
        location.pathname === "/Scorecard" ||
        location.pathname === "/ContestsInMatch" ? (
          <h4 id="heading">Matches</h4>
        ) : null}
        {location.pathname === "/MCQ" ? <h4 id="heading">Matches</h4> : null}
        {location.pathname === "/SeriesStaking" ? (
          <h4 id="heading">Series Staking</h4>
        ) : null}
        {location.pathname === "/Contests" ? (
          <h4 id="heading">Contests</h4>
        ) : null}
        {location.pathname === "/Tournaments" ||
        location.pathname === "/SelectTournament" ||
        location.pathname === "/TournamentsLeaderboard" ? (
          <h4 id="heading">Tournaments</h4>
        ) : null}
        {location.pathname === "/SelectPredictionContest" ? (
          <h4 id="heading">Prediction Contests</h4>
        ) : null}
        {location.pathname === "/VerifyContest" ? (
          <h4 id="heading">Prediction Contests</h4>
        ) : null}
        {location.pathname === "/Leaderboard" ? (
          <h4 id="heading">Contests</h4>
        ) : null}
        {location.pathname === "/Rewards" ? (
          <h4 id="heading">Contests</h4>
        ) : null}
        {location.pathname === "/SelectSeries" ? (
          <h4 id="heading">Series Staking</h4>
        ) : null}
        {location.pathname === "/UpcomingSeries" ? (
          <h4 id="heading">Series Staking</h4>
        ) : null}
        {location.pathname === "/LiveSeries" ? (
          <h4 id="heading">Series Staking</h4>
        ) : null}
        {location.pathname === "/CompletedSeries" ? (
          <h4 id="heading">Series Staking</h4>
        ) : null}
        {location.pathname === "/UpcomingContests" ? (
          <h4 id="heading">Contests</h4>
        ) : null}
        {location.pathname === "/LiveContests" ? (
          <h4 id="heading">Contests</h4>
        ) : null}
        {location.pathname === "/CompletedContests" ? (
          <h4 id="heading">Contests</h4>
        ) : null}
        {location.pathname === "/OGCricket" ? (
          <h4 id="heading">OG Cricket</h4>
        ) : null}
        {location.pathname === "/Sessions" ? (
          <h4 id="heading">OG Cricket</h4>
        ) : null}
        {location.pathname === "/Analytics" ? (
          <h4 id="heading">Analytics</h4>
        ) : null}
        {location.pathname === "/TokenDistribution" ? (
          <h4 id="heading">Token Distribution</h4>
        ) : null}
        {location.pathname === "/Bots" ? <h4 id="heading">Bots</h4> : null}
        {location.pathname === "/IphoneLeaderboard" ? (
          <h4 id="heading">iPhone Leaderboard</h4>
        ) : null}
        {location.pathname === "/Swapped" ? (
          <h4 id="heading">Swapped</h4>
        ) : null}
        {location.pathname === "/CronJobs" ? (
          <h4 id="heading">Cron Jobs</h4>
        ) : null}
        {location.pathname === "/Transactions" ? (
          <h4 id="heading">Transactions</h4>
        ) : null}
        {location.pathname === "/Players" ? (
          <h4 id="heading">Players</h4>
        ) : null}
        {location.pathname === "/PredictionContest" ? (
          <h4 id="heading">Prediction</h4>
        ) : null}
        {location.pathname === "/LivePredictionContest" ||
        location.pathname === "/SelectLivePredictions" ? (
          <h4 id="heading">Sessions</h4>
        ) : null}
        {location.pathname === "/Offers" ? <h4 id="heading">Offers</h4> : null}
        {/.*\/Fantasy.*/.test(location.pathname) ? (
          <h4 id="heading">Fantasy Contest</h4>
        ) : null}
        {location.pathname === "/GenerateCode" ? (
          <h4 id="heading">Generate Code</h4>
        ) : null}

        <div onClick={() => setDropdown(!dropdown)} style={{ display: "flex" }}>
          <div id="profile-div">
            <h6 style={{ fontWeight: "bold" }}>
              {localStorage.getItem("userName")}
            </h6>
            <p>
              {localStorage.getItem("email")} {IoMdArrowDropdown()}
            </p>
          </div>
        </div>
      </div>
      {dropdown ? (
        <div id="dropdown-div">
          <div id="options">
            <div
              onClick={() => {
                setOpen(true);
              }}
              id="fst-option"
            >
              <p>Update Profile</p>
              <p> {MdKeyboardArrowRight()}</p>
            </div>
            <div onClick={() => setOpen2(true)} id="sec-option">
              <p>Change Password </p>
              <p>{MdKeyboardArrowRight()}</p>
            </div>
            <div onClick={() => setOpen3(true)} id="third-option">
              <p>Transfer Ownership</p>
              <p> {MdKeyboardArrowRight()}</p>
            </div>
            <div onClick={() => setOpen4(true)} id="third-option">
              <p>Logout</p>
              <p> {MdKeyboardArrowRight()}</p>
            </div>
          </div>
        </div>
      ) : null}
      <UpdateProfile show={open} onHide={() => setOpen(false)} />
      <ChangePass show={open2} onHide={() => setOpen2(false)} />
      <TransferOwnership show={open3} onHide={() => setOpen3(false)} />
      <Logout show={open4} onHide={() => setOpen4(false)} />
    </div>
  );
};

export default TopNav;
