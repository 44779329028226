import React from "react";
import SideNav from "../SideNav";
import "./Admin.css";
import { BiEdit } from "react-icons/bi";
import { AiOutlineDelete } from "react-icons/ai";
import { useState } from "react";
import { useEffect } from "react";
import TopNav from "../TopNav";
import CreateAdmin from "./CreateAdmin";
import EditAdmin from "./EditAdmin";
import DeleteAdmin from "./DeleteAdmin";
import { useNavigate } from "react-router-dom";
import { SERVER_API, MANAGER } from "../env";
import { IoFilterOutline } from "react-icons/io5";
import { CiSearch } from "react-icons/ci";
import Dropdown from "react-bootstrap/Dropdown";
import { getAdmin } from "../getAdmin";
import DeleteLogs from "./DeleteLogs";

const Admin = () => {
  const [showLogs, setShowLogs] = useState(false);

  let adminDetails = {};
  (async () => {
    adminDetails = await getAdmin();
    if (adminDetails.role & MANAGER) {
      setShowLogs(true);
    } else {
      setShowLogs(false);
    }
  })();

  const navigate = useNavigate();
  const [adminsData, setAdminsData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [toggle, setToggle] = useState(1);
  const [adminLogs, setAdminLogs] = useState([]);
  const [searchAdmin, setSearchAdmin] = useState("");
  const [searchApi, setSearchApi] = useState("");

  let AdminsApi = SERVER_API + "/api/admins";
  let AdminLogsApi = SERVER_API + "/api/logs";

  const GetLogs = async () => {
    const response = await fetch(AdminLogsApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "applcation/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    setAdminLogs(result);
  };

  const GetAdmins = async () => {
    const response = await fetch(AdminsApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    setAdminsData(result);
  };

  const getLogs = async () => {
    let queryString = "";

    if (document.getElementById("select-logs3").value) {
      queryString +=
        document.getElementById("select-logs3").value +
        "=" +
        (document.getElementById("select-order3").value != ""
          ? document.getElementById("select-order3").value
          : "-1") +
        "&";
    }

    const response = await fetch(AdminLogsApi + "?" + queryString, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    setAdminLogs(result);
  };

  useEffect(() => {
    GetAdmins();
    GetLogs();
    if (!localStorage.getItem("token")) navigate("/Login");
  }, [open, open2, modalShow, modalShow2]);

  const handleEdit = (id) => {
    localStorage.setItem("adminId", id);
    setModalShow2(true);
  };
  const handleDelete = (id) => {
    localStorage.setItem("adminId", id);
    setOpen(true);
  };
  return (
    <div className="Admin-div">
      <SideNav />
      <div style={{ width: "87svw" }}>
        <TopNav />
        <div id="tabs-div">
          <div
            style={{
              width: "17vw",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <button
              id="match-btn"
              onClick={() => setToggle(1)}
              style={
                toggle === 1 ? { border: "1px solid red", color: "red" } : null
              }
            >
              Admins
            </button>
            <div style={{ display: showLogs ? "block" : "none" }}>
              <button
                style={
                  toggle === 2
                    ? { border: "1px solid red", color: "red" }
                    : null
                }
                onClick={() => setToggle(2)}
                id="verify-btn"
              >
                Admin Logs
              </button>
            </div>
          </div>

          <div
            style={{
              width: toggle === 2 ? "28vw" : "10vw",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Dropdown autoClose="outside">
              <Dropdown.Toggle
                id="dropdown-autoclose-outside"
                className="dropdown"
              >
                {toggle === 2 ? (
                  <button
                    style={{
                      width: "5vw",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      border: "none",
                      backgroundColor: "white",
                    }}
                  >
                    <IoFilterOutline style={{ color: "black" }} />
                    Filter
                  </button>
                ) : null}
              </Dropdown.Toggle>
              <Dropdown.Menu style={{ border: "none", marginTop: "1em" }}>
                <div id="filter-dropdown">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <CiSearch
                        style={{
                          position: "absolute",
                        }}
                      />
                      <input
                        className="select-dropdown"
                        onChange={(e) => setSearchAdmin(e.target.value)}
                        id="select-order"
                        placeholder="search admin"
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <CiSearch
                        style={{
                          position: "absolute",
                        }}
                      />
                      <input
                        onChange={(e) => setSearchApi(e.target.value)}
                        className="select-dropdown"
                        id="select-order2"
                        placeholder="search api"
                      ></input>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <select className="date-dropdown" id="select-order3">
                      <option id="select-logs3" value="timestamp">
                        Timestamp
                      </option>
                      <option value="1">Ascending</option>
                      <option value="-1">Decending</option>
                    </select>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <button onClick={getLogs} id="get-btn">
                        Get
                      </button>
                    </div>
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
            {toggle === 2 ? (
              <button onClick={() => setOpen2(true)} id="create-btn">
                Clear Logs
              </button>
            ) : null}
            <button onClick={() => setModalShow(true)} id="create-btn">
              Create Admin
            </button>
          </div>
        </div>

        {toggle === 1 ? (
          <table id="admins-table">
            <thead id="thead" style={{ position: "sticky" }}>
              <tr>
                <th>Username</th>
                <th>Email</th>
                <th>Role</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {adminsData?.map((data) => {
                return (
                  <tr
                    id="tRow"
                    key={data._id}
                    style={{
                      borderBottom: "1px solid lightgray",
                      height: "6vh",
                    }}
                  >
                    <td>{data.userName}</td>
                    <td>{data.email}</td>
                    <td>{data.role}</td>
                    <td id="Action-div">
                      <div id="Action-icons">
                        <BiEdit onClick={() => handleEdit(data._id)} />
                        <AiOutlineDelete
                          onClick={() => handleDelete(data._id)}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : null}
        {toggle === 2 ? (
          <div style={{ height: "75vh", overflowY: "scroll" }}>
            {" "}
            <table id="admins-table">
              <thead id="thead" style={{ position: "sticky" }}>
                <tr>
                  <th>Admin Name</th>
                  <th>TimeStamp</th>
                  <th>Api</th>
                </tr>
              </thead>
              <tbody>
                {adminLogs
                  .filter((value) => {
                    if (searchAdmin === "" && searchApi === "") {
                      return value;
                    } else if (
                      value.adminName
                        .toLowerCase()
                        .includes(searchAdmin.toLowerCase()) &&
                      value.api.toLowerCase().includes(searchApi.toLowerCase())
                    ) {
                      return value;
                    }
                  })
                  .map((data) => {
                    return (
                      <tr
                        style={{
                          borderBottom: "1px solid lightgray",
                          height: "6vh",
                        }}
                      >
                        <td>{data.adminName}</td>
                        <td>{getDateFromTimeStamp(data.timestamp)}</td>
                        <td>{data.api}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        ) : null}
      </div>
      <CreateAdmin
        modalShow={modalShow}
        setModalShow={() => setModalShow(false)}
      />
      <EditAdmin
        modalShow={modalShow2}
        setModalShow={() => setModalShow2(false)}
      />
      <DeleteAdmin show={open} onHide={() => setOpen(false)} />
      <DeleteLogs show={open2} onHide={() => setOpen2(false)} />
    </div>
  );
};

function getDateFromTimeStamp(timeStamp) {
  return new Date(timeStamp * 1000).toLocaleDateString("en-IN", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
}
export default Admin;
