import React from "react";
import SideNav from "./../SideNav";
import TopNav from "./../TopNav";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import "./UpcomingSeries.css";
import { SERVER_API } from "../env";
import CreateSeriesStake from "./CreateSeriesStake";
import { getAdmin } from "../getAdmin";

const UpcomingSeries = () => {
  let adminDetails = {};
  (async () => {
    adminDetails = await getAdmin();
  })();

  const navigate = useNavigate();
  const location = useLocation();
  const [seriesStaking, setSeriesStaking] = useState([]);
  const [open, setOpen] = useState(false);

  let UpcomingSeriesApi = SERVER_API + "/api/series-stakes?status=opentostake";

  const Upcoming = async () => {
    const response = await fetch(UpcomingSeriesApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    setSeriesStaking(result);
  };

  const handleNavigate = (id) => {
    localStorage.setItem("seriesId", id);
    navigate("/SelectSeries");
  };

  useEffect(() => {
    Upcoming();
  }, [open]);

  return (
    <div className="seriesStaking-div">
      <SideNav />
      <div style={{ width: "87svw" }}>
        <TopNav />
        <div id="tabs-div">
          <div
            style={{
              width: "31vw",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <button
              id="all-btn"
              onClick={() => navigate("/SeriesStaking")}
              style={
                location.pathname === "/SeriesStaking"
                  ? { border: "1px solid red", color: "red" }
                  : null
              }
            >
              All
            </button>
            <button
              id="upcoming-btn"
              style={
                location.pathname === "/UpcomingSeries"
                  ? { border: "1px solid red", color: "red", width: "9vw" }
                  : null
              }
            >
              Open to Stake
            </button>
            <button onClick={() => navigate("/LiveSeries")} id="live-btn">
              Ongoing
            </button>
            <button
              onClick={() => navigate("/CompletedSeries")}
              id="completed-btn"
            >
              Completed
            </button>
          </div>
          <button onClick={() => setOpen(true)} id="create-stake-btn">
            Create SeriesStake
          </button>
        </div>
        <table id="matches-table">
          <thead id="thead">
            <tr>
              <th>Series Name</th>
              <th>Last date to Stake</th>
              <th>Last date to UnStake</th>
            </tr>
          </thead>
          <tbody>
            {seriesStaking?.map((data) => {
              return (
                <tr
                  id="tRow"
                  key={data._id}
                  style={{
                    borderBottom: "1px solid lightgray",
                    height: "6vh",
                  }}
                >
                  <td onClick={() => handleNavigate(data._id)}>
                    {data.seriesName}
                  </td>

                  <td onClick={() => handleNavigate(data._id)}>
                    {getDateFromTimeStamp(data.lastDateToStake)}
                  </td>
                  <td onClick={() => handleNavigate(data._id)}>
                    {getDateFromTimeStamp(data.lastDateToUnStake)}
                  </td>
                  <td></td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <CreateSeriesStake show={open} onHide={() => setOpen(false)} />
    </div>
  );
};

function getDateFromTimeStamp(timeStamp) {
  return new Date(timeStamp * 1000).toLocaleDateString("en-IN", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
}
export default UpcomingSeries;
