import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { RxCross1 } from "react-icons/rx";
import { BOT_API } from "../env";

const EnterUsersNames = ({ show, onHide }) => {
  const [error, setError] = useState("");
  let UserNameApi = BOT_API + "/api/bots/names";

  const PostUserNames = async () => {
    let file = document.querySelector("input[type=file]").files[0];
    let input = new FormData();
    input.append("botNames", file);
    const response = await fetch(UserNameApi, {
      method: "POST",
      headers: {
        mode: "cors",
        token: localStorage.getItem("token"),
      },
      body: input,
    });
    let result = await response.json();
    if (response.status === 200) {
      onHide();
    } else {
      setError(result.message);
    }
  };
  return (
    <div>
      <Modal
        show={show}
        onHide={onHide}
        style={{
          backgroundColor: "black-transperant",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        size="md"
        centered
      >
        <Modal.Body
          style={{
            height: "40vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div id="head-div">
            <h4>Create Bot Names</h4>
            <RxCross1 color="black" onClick={onHide} />
          </div>
          <div
            style={{
              height: "15vh",
              display: " flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "3em",
            }}
          >
            <div>
              <div>
                <p id="username">Username</p>
                <div id="file-button">
                  <input type="file" name="uploadfile" id="bots-file-input" />
                </div>
              </div>
            </div>
            <button id="button" onClick={PostUserNames}>
              send
            </button>
          </div>
          <p id="error">{error}</p>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EnterUsersNames;
