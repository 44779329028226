import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { RxCross1 } from "react-icons/rx";
import { BOT_API } from "../env";
import SuccessPredict from "./../Matches/SuccessPredict";

const AddBots = ({ show, onHide }) => {
  const [count, setCount] = useState(1);
  const [open2, setOpen2] = useState(false);
  const [error, setError] = useState("");
  const [allowRepetition, setAllowRepetition] = useState(false);

  let AddBotsApi = BOT_API + "/api/contests/join";

  const userNames = [
    "rgvrgv",
    "hmanas",
    "gakash123",
    "ravi99",
    "indhu",
    "lakshmi",
    "srinu98",
    "harsha",
    "sbhanu",
    "rajuraju",
    "sunny11",
    "kiran99",
    "ramu29",
    "riteshks",
    "ramvikas",
    "vikasvarma",
    "jkapil",
    "jaswanth",
    "rohitk11",
    "ramesh143",
    "krish678",
    "vivek32",
    "ramaraju",
    "prudhvi56",
    "sandhya143",
    "saiteja99",
    "sunil",
    "ramu1999",
    "amitkumar4",
    "gaurav54",
    "bhavani21f",
    "rahul987",
    "ssr98",
    "jaganraj",
    "bujji143",
    "priya000",
    "sunil20",
    "apple65",
    "jay69",
    "sanjana6",
    "nagaraj99",
    "giri4",
    "suribabu11",
    "venkat6543",
    "srinu741",
    "shresta98",
    "vikramraj",
    "ramchand55",
    "koushik65",
    "rudra555",
    "amitraj12",
    "ranjank45",
    "kapilr99",
    "nehara56",
    "rahulr123",
    "nitya8",
    "vikram1234",
    "akash98r",
    "sanjayk9",
    "amits27",
    "kartikp6",
    "ranjita55",
    "sonalk87",
    "divya99s",
    "rajrishi6",
    "sonuram45",
    "vinodr9",
    "sonalir11",
    "neelamk98",
    "anurag123",
    "rahulkc",
    "nidhir456",
    "shreyas34",
    "priyankam1",
    "aryanp99",
    "kunal789",
    "anusha123",
    "rashmik7",
    "rajeevr34",
    "vikasr12",
    "akshayj45",
    "soniraju6",
    "neha11c",
    "rahulb99",
    "neerajg12",
    "amitk7",
    "soniyam1",
    "ranjanv99",
    "rajeshs6",
    "aman345r",
    "nikitak12",
    "rituk45",
    "rishabh9",
    "neha99j",
    "sonamr11",
    "vikaskm",
    "anjali123",
    "sonipr45",
    "nikhilk99",
    "rajvir786",
  ];

  const BotsCount = async () => {
    let input = {
      count: parseInt(count),
      contestId: localStorage.getItem("predictionContestId"),
      allowRepetition,
    };

    let response = await fetch(AddBotsApi, {
      method: "POST",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(input),
    });
    let result = await response.json();
    if (response.status === 200) {
      onHide();
      setOpen2(true);
    } else {
      setError(result.message);
    }
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={onHide}
        style={{
          backgroundColor: "black-transperant",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        size="md"
        centered
      >
        <Modal.Body
          style={{
            height: "40vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div id="head-div">
            <h4>Add Bots</h4>
            <RxCross1 color="black" onClick={onHide} />
          </div>
          <div
            style={{
              height: "10vh",
              display: " flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "2em",
            }}
          >
            <div id="div-1">
              <p id="otp-email">Bots to enter</p>
              <input
                onChange={(e) => setCount(e.target.value)}
                id="fst-input"
                value={count}
              />
            </div>
            <div
              style={{
                display: "flex",
                width: "20vw",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <input
                style={{ height: "1.5em", width: "1.5em" }}
                onClick={(e) => setAllowRepetition(e.target.checked)}
                type="checkbox"
                value={allowRepetition}
              />
              <lable id="lable">Do you want to repeat bot names</lable>
            </div>
          </div>

          <button id="button" onClick={BotsCount}>
            Submit
          </button>
          <p id="error">{error}</p>
        </Modal.Body>
      </Modal>
      <SuccessPredict show={open2} onHide={() => setOpen2(false)} />
    </div>
  );
};

export default AddBots;
