import React from "react";
import SideNav from "../SideNav";
import TopNav from "../TopNav";
import "./Matches.css";
import { useNavigate } from "react-router-dom";
import CreateMatches from "./CreateMatches";
import { useState, useEffect } from "react";
import { BiEdit } from "react-icons/bi";
import { AiOutlineDelete } from "react-icons/ai";
import EditMatches from "./EditMatches";
import DeleteMatches from "./DeleteMatches";
import { useLocation } from "react-router-dom";
import { SERVER_API, BOT_API, MANAGER } from "../env";
import { BsCheckCircle } from "react-icons/bs";
import { RxCrossCircled } from "react-icons/rx";
import { getAdmin } from "../getAdmin";

const Matches = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [modalShow, setModalShow] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);
  const [open, setOpen] = useState(false);
  const [allMatches, setAllMatches] = useState([]);
  const [individualMatch, setIndividualMatch] = useState({});
  const [adminDisplay, setAdminDisplay] = useState(false);
  const [error, setError] = useState("");

  let adminDetails = {};
  (async () => {
    adminDetails = await getAdmin();
    if (adminDetails.role & MANAGER) {
      setAdminDisplay(true);
    } else {
      setAdminDisplay(false);
    }
  })();

  let MatchesApi = SERVER_API + "/api/matches";

  const GetMatches = async () => {
    const response = await fetch(MatchesApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      setAllMatches(result);
    } else {
      setError(result.message);
    }
  };

  useEffect(() => {
    GetMatches();
    if (!localStorage.getItem("token")) navigate("/Login");
  }, [open, modalShow, modalShow2, individualMatch]);

  const handleNavigate = (id) => {
    localStorage.setItem("matchId", id);
    navigate("/SelectMatch");
  };
  const handleEdit = (id) => {
    localStorage.setItem("matchId", id);
    setModalShow2(true);
  };
  const handleDelete = (id) => {
    localStorage.setItem("matchId", id);
    setOpen(true);
  };
  return (
    <div className="Matches-div">
      <SideNav />
      <div style={{ width: "87svw" }}>
        <TopNav />
        <div id="tabs-div">
          <div
            style={{
              width: "30vw",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <button
              onClick={() => navigate("/Matches")}
              id="all-btn"
              style={
                location.pathname === "/Matches"
                  ? { border: "1px solid red", color: "red" }
                  : null
              }
            >
              All
            </button>
            <button
              onClick={() => navigate("/UpcomingMatches")}
              id="upcoming-btn"
            >
              Upcoming
            </button>
            <button onClick={() => navigate("/LiveMatches")} id="live-btn">
              Live
            </button>
            <button
              onClick={() => navigate("/CompletedMatches")}
              id="completed-btn"
            >
              Completed
            </button>
          </div>

          <button onClick={() => setModalShow(true)} id="matches-btn">
            Create Matches
          </button>
        </div>
        <div style={{ overflowY: "scroll", height: "75vh" }}>
          <table id="matches-table">
            <thead id="thead" style={{ position: "sticky" }}>
              <tr>
                <th>Match No</th>
                <th>CricBuzz Id</th>
                <th>Series</th>
                <th>Start Date</th>
                <th>Team A</th>
                <th>Team B</th>
                <th>TeamA Score</th>
                <th>TeamB Score</th>
                <th>Is Verified</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {allMatches?.map((data) => {
                return (
                  <>
                    <tr
                      id="tRow"
                      key={data._id}
                      style={{
                        borderBottom: "1px solid lightgray",
                        height: "6vh",
                      }}
                    >
                      <td onClick={() => handleNavigate(data._id)}>
                        {data.matchNumber}
                      </td>
                      <td onClick={() => handleNavigate(data._id)}>
                        {data.cricBuzzMatchId}
                      </td>
                      <td onClick={() => handleNavigate(data._id)}>
                        {data.series}
                      </td>
                      <td onClick={() => handleNavigate(data._id)}>
                        {getDateFromTimeStamp(data.startDate)}
                      </td>
                      <td onClick={() => handleNavigate(data._id)}>
                        {data.teamA}
                      </td>
                      <td onClick={() => handleNavigate(data._id)}>
                        {data.teamB}
                      </td>
                      <td onClick={() => handleNavigate(data._id)}>
                        Score:
                        {data?.teamAScore.score.length === 0
                          ? "0/0"
                          : data?.teamAScore.score}
                        <br />
                        Overs:
                        {data?.teamAScore.overs.length === 0
                          ? "0/0"
                          : data?.teamAScore.overs}
                      </td>
                      <td onClick={() => handleNavigate(data._id)}>
                        Score:
                        {data?.teamBScore.score.length === 0
                          ? "0/0"
                          : data?.teamBScore.score}
                        <br />
                        Overs:
                        {data?.teamBScore.overs.length === 0
                          ? "0/0"
                          : data?.teamBScore.overs}
                      </td>

                      <td>
                        {data.isVerified === true ? (
                          <BsCheckCircle color={"green"} />
                        ) : (
                          <RxCrossCircled color={"red"} />
                        )}
                      </td>
                      <td id="Action-div">
                        <div id="Action-icons">
                          <BiEdit
                            onClick={() => {
                              handleEdit(data._id);
                              setIndividualMatch(data);
                            }}
                          />
                          <AiOutlineDelete
                            onClick={() => handleDelete(data._id)}
                          />
                        </div>
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        </div>
        <p id="error">{error}</p>
      </div>
      <CreateMatches
        modalShow={modalShow}
        setModalShow={() => setModalShow(false)}
      />
      <EditMatches
        allMatches={individualMatch}
        modalShow={modalShow2}
        setModalShow={() => setModalShow2(false)}
      />
      <DeleteMatches show={open} onHide={() => setOpen(false)} />
    </div>
  );
};

function getDateFromTimeStamp(timeStamp) {
  // return new Date(timeStamp * 1000).toLocaleDateString("en-IN", {
  return new Date(timeStamp * 1000).toLocaleString("en-IN", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
}
export default Matches;
