import React, { useState, useEffect } from "react";
import SideNav from "./../SideNav";
import TopNav from "./../TopNav";
import { useNavigate } from "react-router-dom";
import { BOT_API, PREDICTION_API } from "../env";
import "./PredictQuestions.css";
import Accordion from "react-bootstrap/Accordion";
import { AiOutlineDelete } from "react-icons/ai";
import DeleteInstance from "./DeleteInstance";
import { getAdmin } from "../getAdmin";
import SelectPredictOption from "./SelectPredictOption";

const SamplePredictions = () => {
  const navigate = useNavigate();
  const [showQuestions, setShowQuestions] = useState([]);
  const [predictions, setPredictions] = useState([]);
  const [open, setOpen] = useState(false);
  const [toDeleteSampleId, setToDeleteSampleId] = useState("");
  const [openOptions, setOpenOptions] = useState(false);

  let adminDetails = {};
  (async () => {
    adminDetails = await getAdmin();
  })();

  let GetPredictionsApi =
    BOT_API + `/api/sample-predictions/${localStorage.getItem("matchId")}`;

  let ShowQuestionsApi =
    PREDICTION_API +
    `/api/matches/${localStorage.getItem("matchId")}/questions`;
  let EditSampleAnswerApi = BOT_API + "/api/sample-predictions";

  const UpdateAnswers = async (id, answers) => {
    let input = {
      sampleId: id,
      answers,
    };
    const response = await fetch(EditSampleAnswerApi, {
      method: "PATCH",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(input),
    });
    let result = await response.json();
  };

  const ShowQuestions = async () => {
    const response = await fetch(ShowQuestionsApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();

    if (response.status === 200) {
      setShowQuestions(result);
    }
  };

  const GetPredictions = async () => {
    const response = await fetch(GetPredictionsApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    setPredictions(result._samplePredictionsCount);
  };

  const handleInputChange = (index, newValue, answers) => {
    // const updatedArr = [...answers];
    // updatedArr[index] = newValue;
    // setAnswers(updatedArr);
    // console.log(answers);

    answers[index] = newValue;
    return answers;
  };

  const handleDelete = (id) => {
    setToDeleteSampleId(id);
    setOpen(true);
  };

  useEffect(() => {
    GetPredictions();
    ShowQuestions();
  }, [open]);

  return (
    <div style={{ display: "flex" }}>
      <SideNav />
      <div style={{ width: "87svw" }}>
        <TopNav />

        <button onClick={() => setOpenOptions(true)} id="predict-ques-btn">
          Predict questions
        </button>

        <div id="predictions-main-div">
          {predictions?.map((data) => {
            // setAnswers(data.answers);
            let answers = data.answers;
            return (
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    {data._id}
                    <AiOutlineDelete
                      onClick={() => handleDelete(data._id)}
                      style={{
                        position: "absolute",
                        right: "3em",
                        color: "gray",
                      }}
                    />
                  </Accordion.Header>
                  <Accordion.Body>
                    <div
                      style={{
                        overflowY: "scroll",
                        height: "60vh",
                      }}
                    >
                      <table
                        style={{
                          width: " 80vw",
                          marginLeft: "1em",
                        }}
                      >
                        <thead
                          id="thead"
                          style={{ position: "sticky", top: "0px" }}
                        >
                          <tr>
                            <th>Question</th>
                            <th>Answers</th>
                          </tr>
                        </thead>

                        <tbody className="t-body">
                          {showQuestions?.map((questions, index) => {
                            return (
                              <tr
                                key={index}
                                style={{
                                  borderBottom: "1px solid lightgray",
                                  height: "6vh",
                                }}
                              >
                                <td>{questions.question}</td>
                                <td
                                  contentEditable={
                                    data.answers[index] === -1 ? false : true
                                  }
                                  onBlur={(E) => {
                                    answers = handleInputChange(
                                      index,
                                      parseFloat(E.target.innerText),
                                      answers
                                    );
                                  }}
                                  value={answers[index]}
                                >
                                  {answers[index]}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <button
                      onClick={() => UpdateAnswers(data._id, answers)}
                      id="submit-btn"
                    >
                      Submit
                    </button>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            );
          })}
        </div>
      </div>
      <DeleteInstance
        show={open}
        onHide={() => setOpen(false)}
        sampleId={toDeleteSampleId}
      />
      <SelectPredictOption
        show={openOptions}
        onHide={() => setOpenOptions(false)}
      />
    </div>
  );
};

export default SamplePredictions;
