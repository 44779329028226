import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import SideNav from "./../SideNav";
import TopNav from "../TopNav";
import "./TokenDistribution.css";
import SuccessDistribution from "./SuccessDistribution";
import { SERVER_API, MANAGER } from "../env";
import { getAdmin } from "../getAdmin";

const TokenDistribution = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [userId, setUserId] = useState("");
  const [symbol, setSymbol] = useState("");
  const [amount, setAmount] = useState("");
  const [walletFieldToAdd, setWalletFieldToAdd] = useState("");
  const [purpose, setPurpose] = useState("");
  const [error, setError] = useState("");

  let adminDetails = {};
  (async () => {
    adminDetails = await getAdmin();
    if (!(adminDetails.role & MANAGER)) {
      navigate("/");
    }
  })();

  let DistributionApi = SERVER_API + "/api/users/token";

  const PostDistributions = async () => {
    let input = { userId, symbol, amount, walletFieldToAdd, purpose };
    const response = await fetch(DistributionApi, {
      method: "POST",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(input),
    });
    let result = await response.json();
    if (response.status === 201) {
      setOpen(true);
    } else {
      setError(result.message);
    }
  };

  return (
    <div className="distribution-div">
      <SideNav />
      <div>
        <TopNav />
        <div className="distribution-inputs-div">
          <div
            style={{
              display: "flex",
              width: "45vw",
              justifyContent: "space-between",
            }}
          >
            <div id="left-inputs">
              <div id="div-1">
                <p id="username">User Id</p>
                <input
                  onChange={(E) => setUserId(E.target.value)}
                  id="fst-input"
                  type="text"
                  value={userId}
                />
              </div>
              <div id="div-1">
                <p id="username">Symbol</p>
                <select
                  onChange={(E) => setSymbol(E.target.value)}
                  id="fst-input"
                  type="text"
                  value={symbol}
                >
                  <option value="select">Select</option>
                  <option value="BALL">BALL</option>
                </select>
              </div>
              <div id="div-1">
                <p id="username">Amount</p>
                <input
                  onChange={(E) => setAmount(E.target.value)}
                  id="fst-input"
                  type="text"
                  value={amount}
                />
              </div>
            </div>
            <div id="right-inputs">
              <div id="div-1">
                <p id="username">Wallet</p>
                <select
                  onChange={(E) => setWalletFieldToAdd(E.target.value)}
                  id="fst-input"
                  type="text"
                  value={walletFieldToAdd}
                >
                  <option value="select">Select</option>
                  <option value="bonus">Game</option>
                  <option value="available">Available</option>
                </select>
              </div>
              <div id="div-1">
                <p id="username">Purpous</p>
                <select
                  onChange={(E) => setPurpose(E.target.value)}
                  id="fst-input"
                  type="text"
                  value={purpose}
                >
                  <option value="select">Select</option>
                  <option value="twitter_contest">Twitter Contest</option>
                  <option value="scorer">Scorer</option>
                  <option value="iphone_contest">iPhone Contest</option>
                  <option value="airdrop - offchain">Airdrop-offchain</option>
                  <option value="others">Oters</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <p id="error">{error}</p>
        <button onClick={PostDistributions} id="submit-btn">
          Submit
        </button>
      </div>
      <SuccessDistribution show={open} onHide={() => setOpen(false)} />
    </div>
  );
};

export default TokenDistribution;
