import "./Login.css";
import OTP from "./OTP";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ForgotPass from "./ForgotPass";
import { SERVER_API } from "./env";
import { BiShow } from "react-icons/bi";
import { BiHide } from "react-icons/bi";

const Login = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [display, setDisplay] = useState(true);

  let LoginApi = SERVER_API + "/api/admins/login";

  const handleSubmit = async (e) => {
    e.preventDefault();
    let input = { email, password };
    const response = await fetch(LoginApi, {
      method: "POST",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(input),
    });
    let result = await response.json();
    if (response.status === 200) {
      localStorage.setItem("token", response.headers.get("token"));
      localStorage.setItem("adminRole", result.role);

      if (result.forceChangePassword === true) {
        setOpen(true);
      }
      if (result.forceChangePassword === false) {
        localStorage.setItem("adminId", result._id);
        localStorage.setItem("email", result.email);
        localStorage.setItem("userName", result.userName);
        navigate("/");
      }
    }
    setError(result.message);
  };

  function myFunction() {
    setDisplay(!display);
    var input = document.getElementById("myInput");
    if (input.type === "password") {
      input.type = "text";
    } else {
      input.type = "password";
    }
  }

  return (
    <div className="main-div">
      <h1>Welcome</h1>
      <div className="inputs-div">
        <div id="div-1">
          <p id="username">Username</p>
          <input
            onChange={(E) => setEmail(E.target.value)}
            id="fst-input"
            type="text"
            value={email}
          />
        </div>
        <div id="div-2">
          <p id="password">Password</p>
          <input
            onChange={(E) => setPassword(E.target.value)}
            className="sec-input"
            type="password"
            value={password}
            id="myInput"
          />
          {display ? (
            <BiHide
              onClick={myFunction}
              id="togglePassword"
              style={{
                position: "absolute",
                marginLeft: "-2em",
                marginTop: "1em",
              }}
            />
          ) : (
            <BiShow
              onClick={myFunction}
              id="togglePassword"
              style={{
                position: "absolute",
                marginLeft: "-2em",
                marginTop: "1em",
              }}
            />
          )}
        </div>
      </div>
      <p onClick={() => setOpen2(true)} id="FP">
        Forgot Password
      </p>
      <button onClick={handleSubmit} id="button">
        Login
      </button>
      <p id="error-msg">{error}</p>
      <OTP show={open} onHide={() => setOpen(false)} />
      <ForgotPass show={open2} onHide={() => setOpen2(false)} />
    </div>
  );
};

export default Login;
