import React from "react";
import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { RxCross1 } from "react-icons/rx";
import { SERVER_API } from "../env";
import "./Matches.css";

const EditMatches = ({ modalShow, setModalShow, allMatches }) => {
  const [matchNumber, setMatchNumber] = useState("");
  const [maximumUserPredictions, setMaximumUserPredictions] = useState();
  const [isHotMatch, setIsHotMatch] = useState(false);
  const [comments, setComments] = useState("");
  const [series, setSeries] = useState("");
  const [teamAId, setTeamAId] = useState("");
  const [teamBId, setTeamBId] = useState("");
  const [teamAScore, setTeamAScore] = useState("");
  const [teamBScore, setTeamBScore] = useState("");
  const [teamAOvers, setTeamAOvers] = useState("");
  const [teamBOvers, setTeamBOvers] = useState("");
  const [seriesData, setSeriesData] = useState([]);
  const [teams, setTeams] = useState([]);
  const [startDate, setStartDate] = useState();
  const [error, setError] = useState("");

  let SeriesApi = SERVER_API + "/api/series";
  let UpdateMatchesApi =
    SERVER_API + `/api/matches/${localStorage.getItem("matchId")}`;
  let teamsApi = SERVER_API + "/api/teams";

  function formatDateString(timestamp) {
    timestamp = timestamp * 1000;

    let dateString = new Date(timestamp).toLocaleString("en-IN", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });

    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const parts = dateString.split(",");

    let date = parts[0] || "";
    let time = parts[1]?.trim() || "";

    date = date.split("/");
    time = time.split(" ");

    let period = time[0];
    time = time[0]?.split(":");

    time = time[0] + ":" + time[1];

    return `${date[2]}-${date[1]}-${date[0]}T${time}`;
  }

  const CheckTeams = async () => {
    const response = await fetch(teamsApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    setTeams(result);
  };

  const UpdateMatches = async (e) => {
    e.preventDefault();

    let input = generateUpdateMatchBody(
      series || allMatches.series,
      teamAId || allMatches.teamAId,
      teamBId || allMatches.teamBId,
      matchNumber || allMatches.matchNumber,
      convertDateToTimestamp(startDate) ||
        convertDateToTimestamp(allMatches.startDate),
      teamAScore || allMatches.teamAScore.score,
      teamAOvers || allMatches.teamAScore.overs,
      teamBScore || allMatches.teamBScore.score,
      teamBOvers || allMatches.teamBScore.overs,
      maximumUserPredictions || allMatches.maximumUserPredictions,
      isHotMatch,
      comments
    );
    const response = await fetch(UpdateMatchesApi, {
      method: "PATCH",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-type": "application/json",
      },
      body: JSON.stringify(input),
    });
    let result = await response.json();
    setMatchNumber("");
    setStartDate();
    setTeamAScore("");
    setTeamBScore("");
    setTeamAOvers("");
    setTeamBOvers("");
    setMaximumUserPredictions();
    setIsHotMatch(false);
    setComments("");
    if (response.status === 200) {
      setModalShow(false);
    } else {
      setError(result.message);
    }
  };

  const CheckSeries = async () => {
    const response = await fetch(SeriesApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    setSeriesData(result);
  };
  useEffect(() => {
    CheckSeries();
    CheckTeams();
  }, []);
  return (
    <div>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body
          style={{
            height: "80vh",
          }}
        >
          <div
            style={{
              height: "75vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h4>Edit Matches</h4>
              <RxCross1 color="black" onClick={setModalShow} />
            </div>
            <div
              style={{
                height: "55vh",
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <div
                style={{
                  height: "55vh",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <p id="username">Series Name</p>
                <input
                  id="select-series"
                  onChange={(E) => setSeries(E.target.value)}
                  Value={allMatches?.series}
                />
                {/* {seriesData?.map((data) => {
                    return <option value={data._id}>{data.seriesName}</option>;
                  })} */}

                <div>
                  <p id="username">TeamA Id</p>
                  <select
                    id="select"
                    onChange={(E) => setTeamAId(E.target.value)}
                    value={allMatches?.teamAId}
                  >
                    {teams?.map((data) => {
                      return (
                        <option key={data._id} value={data._id}>
                          {data.teamName}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div id="div-2">
                  <p id="username">Match number</p>
                  <input
                    onChange={(E) => setMatchNumber(E.target.value)}
                    id="fst-input"
                    type="text"
                    defaultValue={allMatches?.matchNumber}
                  />
                </div>
                <div id="div-2">
                  <p id="username">TeamA Score</p>
                  <input
                    onChange={(e) => setTeamAScore(e.target.value)}
                    id="fst-input"
                    type="text"
                    defaultValue={allMatches?.teamAScore?.score}
                  />
                </div>
                <div id="div-2">
                  <p id="username">TeamB Score</p>
                  <input
                    onChange={(e) => setTeamBScore(e.target.value)}
                    id="fst-input"
                    type="text"
                    defaultValue={allMatches?.teamBScore?.score}
                  />
                </div>
                <div id="div-2">
                  <p id="username">Is Hot Match</p>
                  <select
                    id="select"
                    onChange={(E) => setIsHotMatch(E.target.value)}
                    value={isHotMatch}
                  >
                    <option value="select">Select</option>
                    <option value="true">True</option>
                    <option value="false">False</option>
                  </select>
                </div>
              </div>
              <div
                style={{
                  height: "55vh",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div id="div-2">
                  <p id="username">Start date</p>
                  <input
                    onChange={(E) => setStartDate(E.target.value)}
                    id="fst-input"
                    type="datetime-local"
                    defaultValue={formatDateString(allMatches?.startDate)}
                  />
                </div>

                <div>
                  <p id="username">TeamB Id</p>
                  <select
                    id="select"
                    onChange={(E) => setTeamBId(E.target.value)}
                    value={allMatches?.teamBId}
                  >
                    {teams?.map((data) => {
                      return (
                        <option key={data._id} value={data._id}>
                          {data.teamName}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div id="div-2">
                  <p id="username">Max User Predictions</p>
                  <input
                    onChange={(E) =>
                      setMaximumUserPredictions(parseInt(E.target.value))
                    }
                    id="fst-input"
                    type="text"
                    defaultValue={allMatches?.maximumUserPredictions}
                  />
                </div>
                <div id="div-2">
                  <p id="username">TeamA Overs</p>
                  <input
                    onChange={(e) => setTeamAOvers(e.target.value)}
                    id="fst-input"
                    type="text"
                    defaultValue={allMatches?.teamAScore?.overs}
                  />
                </div>
                <div id="div-2">
                  <p id="username">TeamB Overs</p>
                  <input
                    onChange={(e) => setTeamBOvers(e.target.value)}
                    id="fst-input"
                    type="text"
                    defaultValue={allMatches?.teamBScore?.overs}
                  />
                </div>
                <div id="div-2">
                  <p id="username">Comments</p>
                  <input
                    onChange={(e) => setComments(e.target.value)}
                    id="fst-input"
                    type="text"
                    value={comments}
                  />
                </div>
              </div>
            </div>
            <p id="error">{error}</p>
            <div>
              <button onClick={UpdateMatches} id="update-btn2">
                Update
              </button>
              <button onClick={setModalShow} id="cancel-btn2">
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EditMatches;

function convertDateToTimestamp(date) {
  // date - yyyy / mm / dd;
  // var myDate = date.split("-");
  // var newDate = new Date(myDate[0], myDate[1] - 1, myDate[2]);
  // return newDate.getTime() / 1000;

  //date - yyyy / mm / ddThh:mm;
  if (!date || typeof date == "number") {
    return "";
  }
  var dateTime = date.split("T");
  var date = dateTime[0].split("-");
  var dateStr = `${date[1]}-${date[2]}-${date[0]}`.concat(" ");
  var timestamp = Date.parse(dateStr.concat(dateTime[1]));
  return timestamp / 1000;
}

function generateUpdateMatchBody(
  series,
  teamAId,
  teamBId,
  matchNumber,
  startDate,
  teamAScore,
  teamAOvers,
  teamBScore,
  teamBOvers,
  maximumUserPredictions,
  isHotMatch,
  comments
) {
  let out = {
    teamAScore: { score: "", overs: "" },
    teamBScore: { score: "", overs: "" },
  };

  let teamA = false,
    teamB = false;

  if (matchNumber?.length) {
    out.matchNumber = matchNumber;
  }

  if (parseInt(startDate) > 0) {
    out.startDate = startDate;
  }

  if (teamAScore?.length) {
    teamA = true;
    out.teamAScore.score = teamAScore;
  }

  if (teamAOvers?.length) {
    teamA = true;
    out.teamAScore.overs = teamAOvers;
  }

  if (teamBScore?.length) {
    teamB = true;
    out.teamBScore.score = teamBScore;
  }

  if (teamBOvers?.length) {
    teamB = true;
    out.teamBScore.overs = teamBOvers;
  }

  if (parseInt(maximumUserPredictions)) {
    out.maximumUserPredictions = maximumUserPredictions;
  }

  if (comments?.length) {
    out.comments = comments;
  }

  out.isHotMatch = isHotMatch;

  if (!teamA) {
    delete out.teamAScore;
  }
  if (!teamB) {
    delete out.teamBScore;
  }

  return out;
}
