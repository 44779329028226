import React from "react";
import { useState, useEffect } from "react";
import { SERVER_API } from "../env";
import { getAdmin } from "../getAdmin";

const VerifyMatch = () => {
  let adminDetails = {};
  (async () => {
    adminDetails = await getAdmin();
  })();

  const [verified, setVerified] = useState([]);
  let MatchesApi =
    SERVER_API + `/api/matches/${localStorage.getItem("matchId")}`;

  const GetMatches = async () => {
    const response = await fetch(MatchesApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    setVerified(result);
  };

  useEffect(() => {
    GetMatches();
  }, []);
  return (
    <div>
      <table
        style={{
          width: " 85vw",
          marginLeft: "1em",
        }}
      >
        <thead id="thead">
          <tr>
            <th>Email</th>
            <th>Hash</th>
          </tr>
        </thead>
        <tbody>
          {verified?.verifiedBy?.map((data) => {
            return (
              <tr
                style={{
                  borderBottom: "1px solid lightgray",
                  height: "6vh",
                }}
              >
                <td>{data.email}</td>
                <td>{data.hash}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default VerifyMatch;
