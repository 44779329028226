import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { RxCross1 } from "react-icons/rx";
import { SERVER_API } from "../env";
import "./Offers.css";

const CreateOffer = ({ modalShow, setModalShow }) => {
  const [title, setTitle] = useState("");
  const [discription, setDiscription] = useState("");
  const [offerType, setOfferType] = useState("");
  const [rules, setRules] = useState("");
  const [trigger, setTrigger] = useState("");
  const [depositSymbol, setDepositeSymbol] = useState("");
  const [symbol, setSymbol] = useState("");
  const [rewardAmount, setRewardAmount] = useState("");
  const [rewardTransactionType, setRewardTransactionType] = useState("");
  const [startTime, setStartTime] = useState("");
  const [expiryTime, setExpiryTime] = useState("");
  const [seriesSpecific, setSeriesSpecific] = useState(false);
  const [seriesId, setSeriesId] = useState("");
  const [symbolSpecific, setSymbolSpecific] = useState("");
  const [contestSymbol, setContestSymbol] = useState("");
  const [seriesData, setSeriesData] = useState([]);
  const [error, setError] = useState("");

  let CreateOffersApi = SERVER_API + "/api/offers";

  const PostOffer = async () => {
    let input = {
      title,
      discription,
      offerType,
      rules,
      trigger,
      depositSymbol,
      symbol: symbol.toUpperCase(),
      rewardAmount,
      rewardTransactionType,
      startTime: convertDateToTimeStamp(startTime),
      expiryTime: convertDateToTimeStamp(expiryTime),
      seriesSpecific,
      seriesId,
      symbolSpecific,
      contestSymbol,
    };

    if (!input.startTime) {
      delete input.startTime;
    }

    if (input.offerType === "prediction_matches") {
      delete input.depositSymbol;
    }
    if (input.offerType === "fantasy_matches") {
      delete input.depositSymbol;
    }
    if (!discription.length) {
      delete input.discription;
    }

    const response = await fetch(CreateOffersApi, {
      method: "POST",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(input),
    });
    let result = await response.json();
    if (response.status === 201) {
      setModalShow(false);
    } else {
      setError(result.message);
    }
  };

  let SeriesApi = SERVER_API + "/api/series";

  const GetSeries = async () => {
    const response = await fetch(SeriesApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    setSeriesData(result);

    setSeriesData([{ seriesName: "Select" }, ...result]);
    setSeriesId(seriesData[0]?._id);
    return seriesData[0]?._id;
  };

  useEffect(() => {
    GetSeries();
  }, []);

  return (
    <div>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body
          style={{
            height: "98vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <h4>Create Offer</h4>
            <RxCross1 color="black" onClick={setModalShow} />
          </div>
          <div
            style={{
              height: "60vh",
              display: "flex",
              justifyContent: "space-around",
              paddingTop: "2em",
            }}
          >
            <div
              style={
                offerType === "prediction_matches" ||
                offerType === "fantasy_matches" ||
                offerType === "deposit"
                  ? {
                      height: "57vh",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }
                  : {
                      height: "40vh",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }
              }
            >
              <p id="username">Title</p>
              <input
                onChange={(e) => setTitle(e.target.value)}
                id="select"
                type="text"
                value={title}
              />
              <div id="div-2">
                <p id="username">Reward Symbol</p>
                <input
                  onChange={(e) => setSymbol(e.target.value)}
                  id="fst-input"
                  type="text"
                  value={symbol.toUpperCase()}
                />
              </div>

              <div id="div-2">
                <p id="username">Start Date</p>
                <input
                  onChange={(e) => setStartTime(e.target.value)}
                  id="fst-input"
                  type="datetime-local"
                  value={startTime}
                />
              </div>
              <div id="div-2">
                <p id="username">End Date</p>
                <input
                  onChange={(e) => setExpiryTime(e.target.value)}
                  id="fst-input"
                  type="datetime-local"
                  value={expiryTime}
                />
              </div>
              <div>
                <p id="username">Offer Type</p>
                <select
                  onChange={(e) => setOfferType(e.target.value)}
                  id="select"
                  type="text"
                  value={offerType}
                >
                  <option value="">Select</option>
                  <option value="deposit">Deposit</option>
                  <option value="prediction_matches">Prediction-Matches</option>
                  <option value="fantasy_matches">Fantasy-Matches</option>
                </select>
              </div>
              {offerType === "deposit" ? (
                <div id="div-2">
                  <p id="username">Deposite Symbol</p>
                  <input
                    onChange={(e) => setDepositeSymbol(e.target.value)}
                    id="fst-input"
                    type="text"
                    value={depositSymbol}
                  />
                </div>
              ) : null}
              {offerType === "prediction_matches" ||
              offerType === "fantasy_matches" ? (
                <div
                  style={
                    symbolSpecific === "true"
                      ? {
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          height: "14vh",
                        }
                      : null
                  }
                >
                  <div id="div-2">
                    <p id="username">Symbol Specific</p>
                    <select
                      onChange={(e) => setSymbolSpecific(e.target.value)}
                      id="fst-input"
                      type="text"
                      value={symbolSpecific}
                    >
                      <option value="">Select</option>
                      <option value={true}>True</option>
                      <option value={false}>False</option>
                    </select>
                  </div>
                  {symbolSpecific === "true" ? (
                    <div id="div-2">
                      <p id="username">Contest Symbol</p>
                      <select
                        onChange={(e) => setContestSymbol(e.target.value)}
                        id="fst-input"
                        type="text"
                        value={contestSymbol}
                      >
                        <option value="">Select</option>
                        <option value="USDT">USDT</option>
                        <option value="BALL">BALL</option>
                      </select>
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
            <div
              style={
                seriesSpecific === "true"
                  ? {
                      height: "48vh",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }
                  : {
                      height: "40vh",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }
              }
            >
              <div id="div-2">
                <p id="username">Rules</p>
                <input
                  onChange={(e) => setRules(e.target.value)}
                  id="fst-input"
                  type="text"
                  value={rules}
                />
              </div>
              <div>
                <p id="username">Trigger</p>
                <input
                  onChange={(e) => setTrigger(e.target.value)}
                  id="fst-input"
                  type="text"
                  value={trigger}
                />
              </div>
              <div id="div-2">
                <p id="username">Series Specific</p>
                <select
                  onChange={(e) => setSeriesSpecific(e.target.value)}
                  id="fst-input"
                  type="text"
                  value={seriesSpecific}
                >
                  <option value="">Select</option>
                  <option value={true}>True</option>
                  <option value={false}>False</option>
                </select>
              </div>
              {seriesSpecific === "true" ? (
                <div id="div-2">
                  <p id="username">Series Id</p>
                  <select
                    onChange={(e) => setSeriesId(e.target.value)}
                    id="fst-input"
                    type="text"
                    value={seriesId}
                  >
                    {seriesData.map((series) => {
                      return (
                        <option key={series._id} value={series._id}>
                          {series.seriesName}
                        </option>
                      );
                    })}
                  </select>
                </div>
              ) : null}

              <div id="div-2">
                <p id="username">Reward Amount</p>
                <input
                  onChange={(e) => setRewardAmount(e.target.value)}
                  id="fst-input"
                  type="text"
                  value={rewardAmount}
                />
              </div>
              <div id="div-2">
                <p id="username">Reward Transaction Type</p>
                <select
                  onChange={(e) => setRewardTransactionType(e.target.value)}
                  id="fst-input"
                  type="text"
                  value={rewardTransactionType}
                >
                  <option value="">Select</option>
                  <option value="onChain">onChain</option>
                  <option value="offChain">offChain</option>
                </select>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "3em",
            }}
          >
            <div>
              <p id="username">Description</p>
              <textarea
                onChange={(e) => setDiscription(e.target.value)}
                type="text"
                id="discription-input"
                value={discription}
              ></textarea>
            </div>
          </div>
          <p id="error">{error}</p>
          <div>
            <button onClick={PostOffer} id="update-btn2">
              Create
            </button>
            <button onClick={setModalShow} id="cancel-btn2">
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CreateOffer;

function convertDateToTimeStamp(dateString) {
  const date = new Date(dateString);
  return date.getTime() / 1000;
}
