import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SideNav from "../SideNav";
import "./PredictQuestions.css";
import TopNav from "./../TopNav";
import { BOT_API, PREDICTION_API, MANAGER } from "../env";
import SuccessPredict from "./SuccessPredict";

const PredictQuestionsManual = () => {
  const navigate = useNavigate();
  (() => {
    let adminRole = localStorage.getItem("adminRole");
    if (!(adminRole & MANAGER)) {
      navigate("/Matches");
    }
  })();

  const [showQuestions, setShowQuestions] = useState([]);
  const [open, setOpen] = useState(false);
  const [samplePredictionCount, setSamplePredictionCount] = useState();
  const [error, setError] = useState("");
  var [answers, setAnswers] = useState([
    -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
  ]);

  let GetAnswersApi =
    BOT_API +
    `/api/sample-predictions/generate-answers/${localStorage.getItem(
      "matchId"
    )}`;

  let PostQuestionsApi = BOT_API + "/api/sample-predictions/";

  let CountApi =
    BOT_API +
    `/api/sample-predictions/${localStorage.getItem("matchId")}?type=count`;

  const GetCount = async () => {
    const response = await fetch(CountApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    setSamplePredictionCount(result._samplePredictionsCount);
  };

  function updateAnswerAtIndex(index, answer, answers) {
    answer = parseInt(answer);
    if (isNaN(answer)) {
      return setError("Answer can only be intiger.");
    }

    answers[index] = answer;
    return answers;
  }

  const PostQuestions = async () => {
    let input = { matchId: localStorage.getItem("matchId"), answers };

    let mcqCount = 0,
      shortCount = 0;

    console.log(answers);

    for (let i = 0; i < showQuestions.length; ++i) {
      if (answers[i] != -1) {
        if (showQuestions[i].type == "short") {
          ++shortCount;
        } else {
          ++mcqCount;
        }
      }
    }

    console.log(mcqCount, shortCount);
    if (mcqCount != 5 || shortCount != 4) {
      setError(
        "You need to answer 4 short and 5 MCQ questions. Current stats => MCQ = " +
          mcqCount +
          " Short = " +
          shortCount
      );
      return;
    }

    const response = await fetch(PostQuestionsApi, {
      method: "POST",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(input),
    });
    let result = await response.json();
    if (response.status === 201) {
      setOpen(true);
      navigate("/SamplePredictions");
    } else {
      setError(result.message);
    }
  };

  const GetAnswers = async () => {
    console.log(GetAnswersApi);
    const response = await fetch(GetAnswersApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      setAnswers(result.answers);
    } else {
      setError(result.message);
    }
  };

  let ShowQuestionsApi =
    PREDICTION_API +
    `/api/matches/${localStorage.getItem("matchId")}/questions`;

  const ShowQuestions = async () => {
    const response = await fetch(ShowQuestionsApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      setShowQuestions(result);
    } else {
      setError(result.message);
    }
  };
  useEffect(() => {
    ShowQuestions();
    GetCount();
  }, []);

  return (
    <div className="Questions-div">
      <SideNav />
      <div style={{ width: "87svw" }}>
        <TopNav />
        <div id="table-top-data" style={{ height: "10vh" }}>
          <div>
            <p>Sample Prediction Count : {samplePredictionCount}</p>
          </div>
        </div>
        <div style={{ width: "87svw" }}>
          <div
            style={{
              overflowY: "scroll",
              height: "65vh",
            }}
          >
            <table
              style={{
                width: " 85vw",
                marginLeft: "1em",
              }}
            >
              <thead id="thead" style={{ position: "sticky", top: "0px" }}>
                <tr>
                  <th>Question</th>
                  <th>Options</th>
                  <th>Points</th>
                  <th>Answer</th>
                </tr>
              </thead>
              <tbody className="t-body">
                {showQuestions.map((data, index) => {
                  return (
                    <tr
                      key={index}
                      style={{
                        borderBottom: "1px solid lightgray",
                        height: "6vh",
                      }}
                    >
                      <td>{data.question}</td>
                      <td>{convertArrayToString(data.options)}</td>
                      <td>{data.points}</td>
                      <td
                        onBlur={(e) => {
                          answers = updateAnswerAtIndex(
                            index,
                            e.target.innerText,
                            answers
                          );
                          return;
                        }}
                        contentEditable={answers[index] ? true : false}
                      >
                        {answers[index]}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <button
            onClick={PostQuestions}
            id="match-btn"
            style={{
              backgroundColor: "red",
              border: "red",
              color: "white",
              width: "20vw",
              margin: "1em",
            }}
          >
            Submit Answers
          </button>
          <p id="error">{error}</p>
        </div>
      </div>
      <SuccessPredict show={open} onHide={() => setOpen(false)} />
    </div>
  );
};

export default PredictQuestionsManual;

function convertArrayToString(arr) {
  let out = "";

  for (let i = 0; i < arr?.length; ++i) {
    out += arr[i] + ", ";
  }

  return out;
}
