import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { RxCross1 } from "react-icons/rx";
import "./CreateBots.css";
import { BOT_API } from "../env";
import Spinner from "react-bootstrap/Spinner";

const CreateBots = ({ show, onHide }) => {
  const [modalShow, setModalShow] = useState(false);
  const [count, setCount] = useState("");
  const [error, setError] = useState("");
  const [error2, setError2] = useState("");
  const [showResp, setShowResp] = useState([]);

  let CreateBotsApi = BOT_API + "/api/bots";
  let PostFileApi = BOT_API + "/api/bots/mobile-bots";

  const PostMobileNumbers = async () => {
    document.getElementById("loading").style.display = "block";
    let file = document.querySelector("input[type=file]").files[0];
    let input = new FormData();
    input.append("mobileBot", file);
    const response = await fetch(PostFileApi, {
      method: "POST",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
      },
      body: input,
    });
    let result = await response.json();
    if (response.status === 200) {
      onHide();
      setModalShow(true);
      setShowResp(result);
    } else {
      setError2(result.message);
    }
    document.getElementById("loading").style.display = "none";
  };

  const PostBotsCount = async () => {
    let input = { count };
    const response = await fetch(CreateBotsApi, {
      method: "POST",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(input),
    });
    let result = await response.json();
    if (response.status === 200 || response.status === 201) {
      onHide();
    } else {
      setError(
        result.message + " after creating " + result.createdBotsCount + " bots."
      );
    }
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={onHide}
        style={{
          backgroundColor: "black-transperant",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        size="lg"
        centered
      >
        <Modal.Body
          style={{
            height: "40vh",

            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div id="title-div">
            <h4>Create Bots</h4>
            <RxCross1 color="black" onClick={onHide} />
          </div>

          <div
            style={{
              height: "18vh",
              display: " flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "2em",
            }}
          >
            <div
              style={{
                width: "30vw",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div id="div-1">
                <p id="otp-email">No of Bots</p>
                <input
                  type="number"
                  onChange={(E) => setCount(E.target.value)}
                  id="fst-input"
                  value={count}
                />
              </div>
              <button onClick={PostBotsCount} id="submit-btn">
                Submit
              </button>
            </div>
            <div
              style={{
                width: "30vw",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                <p id="username">Mobile Numbers</p>
                <div id="file-button">
                  <input type="file" name="uploadfile" id="bots-file-input" />
                </div>
              </div>
              <button onClick={PostMobileNumbers} id="submit-btn">
                Submit
              </button>
            </div>
          </div>
          <p id="error">{error}</p>
          <p id="error">{error2}</p>
          <Spinner
            animation="border"
            role="status"
            id="loading"
            style={{ display: "none" }}
          >
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Modal.Body>
      </Modal>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body
          style={{
            height: "60vh",
          }}
        >
          <div style={{ fontWeight: "bold" }}>
            {" "}
            <span>Signed Up Bots Count : {showResp.signedUpBotsCount}</span>
            <br />
            <span>ExistingUsers : {showResp.existingUsers}</span>
          </div>

          <div style={{ height: "50vh", overflowY: "scroll" }}>
            <table id="response-table">
              <thead id="thead" style={{ position: "sticky" }}>
                <tr>
                  <th>Username</th>
                  <th>Phone No</th>
                  <th>Message</th>
                  <th>Status Code</th>
                </tr>
              </thead>
              <tbody>
                {showResp?.responseArray?.map((data) => {
                  return (
                    <tr
                      style={{
                        borderBottom: "1px solid lightgray",
                        height: "1em",
                      }}
                    >
                      <td>{data.userName}</td>
                      <td>{data.phoneNumber}</td>
                      <td>{data.message}</td>
                      <td>{data.statusCode}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CreateBots;
