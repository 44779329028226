import React, { useState, useEffect } from "react";
import { ANALYTICS_API } from "./env";
import SideNav from "./SideNav";
import TopNav from "./TopNav";

const Transactions = () => {
  const [transactions, setTransactions] = useState([]);
  const [toggle, setToggle] = useState(1);

  let TransactionsApi = ANALYTICS_API + "/api/transactions/onchain-txn";

  const GetTransactions = async () => {
    const response = await fetch(TransactionsApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    setTransactions(result);
  };

  useEffect(() => {
    GetTransactions();
  }, []);
  return (
    <div style={{ display: "flex" }}>
      <SideNav />
      <div>
        <TopNav />
        <div
          style={{
            height: "10vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "25vw",
            marginLeft: "1em",
          }}
        >
          <button
            onClick={() => setToggle(1)}
            id="match-btn"
            style={
              toggle === 1 ? { border: "1px solid red", color: "red" } : null
            }
          >
            Deposite
          </button>
          <button
            onClick={() => setToggle(2)}
            id="match-btn"
            style={
              toggle === 2 ? { border: "1px solid red", color: "red" } : null
            }
          >
            Withdraw
          </button>
          <button
            onClick={() => setToggle(3)}
            id="match-btn"
            style={
              toggle === 3 ? { border: "1px solid red", color: "red" } : null
            }
          >
            Summary
          </button>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "baseline",
            marginLeft: "1em",
          }}
        >
          <div>
            <span style={{ fontWeight: "bold" }}>Total USDT Deposite :</span>
            <span>{transactions.depositAmount?.USDT}</span>
          </div>
          <div>
            <span style={{ fontWeight: "bold" }}>Total USDT Withdraw :</span>
            <span>{transactions.withdrawAmount?.USDT}</span>
          </div>
        </div>
        <div style={{ height: "70vh", overflowY: "scroll" }}>
          {toggle === 1 ? (
            <table id="admins-table">
              <thead id="thead" style={{ position: "sticky" }}>
                <tr>
                  <th>Username</th>
                  <th>Type</th>
                  <th>Symbol</th>
                  <th>Amount</th>
                  <th>Date</th>
                  <th>Transaction Hash</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {transactions.deposits?.map((data) => {
                  return (
                    <tr
                      key={data._id}
                      style={{
                        borderBottom: "1px solid lightgray",
                        height: "6vh",
                      }}
                    >
                      <td>{data.user}</td>
                      <td>{data.type}</td>
                      <td>{data.symbol}</td>
                      <td>{data.amount}</td>
                      <td>{getDateFromTimeStamp(data.timestamp)}</td>
                      <td>{data.transactionHash}</td>
                      <td>{data.status}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : null}
          {toggle === 2 ? (
            <table id="admins-table">
              <thead id="thead" style={{ position: "sticky" }}>
                <tr>
                  <th>Username</th>
                  <th>Type</th>
                  <th>Symbol</th>
                  <th>Amount</th>
                  <th>Date</th>
                  <th>Transaction Hash</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {transactions.withdraws?.map((data) => {
                  return (
                    <tr
                      key={data._id}
                      style={{
                        borderBottom: "1px solid lightgray",
                        height: "6vh",
                      }}
                    >
                      <td>{data.user}</td>
                      <td>{data.type}</td>
                      <td>{data.symbol}</td>
                      <td>{data.amount}</td>
                      <td>{getDateFromTimeStamp(data.timestamp)}</td>
                      <td>{data.transactionHash}</td>
                      <td>{data.status}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : null}
          {toggle === 3 ? (
            <div>
              <h5>Available Balance</h5>
              <table id="admins-table">
                <thead id="thead">
                  <tr>
                    <th>Asset</th>
                    <th>Avaialable</th>
                    <th>Frozen</th>
                    <th>Bonus</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {transactions.availableAssets?.map((data) => {
                    return (
                      <tr
                        key={data._id}
                        style={{
                          borderBottom: "1px solid lightgray",
                          height: "6vh",
                        }}
                      >
                        <td>{data._id}</td>
                        <td>{roundToNDecimal(data.available)}</td>
                        <td>{roundToNDecimal(data.frozen)}</td>
                        <td>{roundToNDecimal(data.bonus)}</td>
                        <td>{roundToNDecimal(data.total)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div style={{ paddingTop: "5em" }}>
                <table id="admins-table">
                  <thead id="thead">
                    <tr>
                      <th>Asset</th>
                      <th>Total Deposite</th>
                      <th>Balance Difference</th>
                      <th>Network Fee</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <p>BALL</p>
                        <p>BUSD</p>
                        <p>USDT</p>
                      </td>
                      <td>
                        <p>
                          {roundToNDecimal(transactions.depositAmount.BALL) ||
                            0}
                        </p>
                        <p>
                          {roundToNDecimal(transactions.depositAmount.BUSD) ||
                            0}
                        </p>
                        <p>
                          {roundToNDecimal(transactions.depositAmount.USDT) ||
                            0}
                        </p>
                      </td>
                      <td>
                        <p>
                          {roundToNDecimal(transactions.balanceDifference.BALL)}
                        </p>
                        <p>
                          {roundToNDecimal(
                            transactions.balanceDifference.BUSD
                          ) || 0}
                        </p>
                        <p>
                          {roundToNDecimal(
                            transactions.balanceDifference.USDT
                          ) || 0}
                        </p>
                      </td>
                      <td>
                        <p>
                          {roundToNDecimal(transactions.networkFee.BALL) || 0}
                        </p>
                        <p>
                          {roundToNDecimal(transactions.networkFee.BUSD) || 0}
                        </p>
                        <p>
                          {roundToNDecimal(transactions.networkFee.USDT) || 0}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Transactions;

function roundToNDecimal(number, decimal = 3) {
  return Math.round(number * Math.pow(10, decimal)) / Math.pow(10, decimal);
}

function getDateFromTimeStamp(timeStamp) {
  return new Date(timeStamp * 1000).toLocaleDateString("en-IN", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
}
