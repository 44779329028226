import React, { useState, useEffect } from "react";
import SideNav from "./../SideNav";
import TopNav from "../TopNav";
import AddedPlayersToTeam from "./AddedPlayersToTeam";
import { SERVER_API } from "../env";
import { useNavigate } from "react-router-dom";

const GetNewPlayers = () => {
  const navigate = useNavigate();
  const [newPlayers, setNewPlayers] = useState([]);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");

  let GetNewPlayersApi =
    SERVER_API +
    `/api/team-players/fetch-new/${localStorage.getItem("teamId")}`;
  let AddPlayersApi = SERVER_API + "/api/team-players/";

  const GetNewPlayers = async () => {
    const response = await fetch(GetNewPlayersApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      setNewPlayers(result);
    } else {
      setError(result.message);
    }
  };

  const editPlayersData = (index, key, value) => {
    let temp = newPlayers;

    temp[index][key] = value;

    setNewPlayers(temp);
  };

  const AddPlayers = async () => {
    let players = [];

    newPlayers.forEach((newPlayers) => {
      players.push({
        cricBuzzPlayerId: newPlayers.cricBuzzPlayerId,
        playerName: newPlayers.playerName,
        displayName: newPlayers.displayName,
        role: newPlayers.role,
        credits: newPlayers.credits,
        seriesFantasyScore: newPlayers.seriesFantasyScore,
      });
    });
    let input = { teamId: `${localStorage.getItem("teamId")}`, players };
    const response = await fetch(AddPlayersApi, {
      method: "POST",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(input),
    });
    let result = await response.json();
    if (response.status === 201) {
      navigate("/SelectTeam");
    } else {
      setError(result.message);
    }
  };

  useEffect(() => {
    GetNewPlayers();
  }, []);

  return (
    <div style={{ display: "flex" }}>
      <SideNav />
      <div>
        <TopNav />
        <div style={{ overflowY: "scroll", height: "70vh", marginTop: "2em" }}>
          <table id="teams-table">
            <thead id="thead" style={{ position: "sticky" }}>
              <tr>
                <th>CrickBuzz Player Id</th>
                <th>player Name</th>
                <th>Display Name</th>
                <th>Series Fantasy Score</th>
                <th>Role</th>
                <th>Credits</th>
                <th>In CricBuzz</th>
                <th>In DataBase</th>
              </tr>
            </thead>
            <tbody>
              {newPlayers.map((getNew, index) => {
                return (
                  <tr
                    style={{
                      borderBottom: "1px solid lightgray",
                      height: "6vh",
                    }}
                  >
                    <td>{getNew.cricBuzzPlayerId}</td>
                    <td
                      onBlur={(e) =>
                        editPlayersData(index, "playerName", e.target.innerText)
                      }
                      contentEditable
                    >
                      {getNew.playerName}
                    </td>
                    <td
                      onBlur={(e) =>
                        editPlayersData(
                          index,
                          "displayName",
                          e.target.innerText
                        )
                      }
                      style={
                        getNew.displayName.length >= 15
                          ? { color: "red" }
                          : null
                      }
                      contentEditable
                    >
                      {getNew.displayName}
                    </td>
                    <td
                      onBlur={(e) =>
                        editPlayersData(
                          index,
                          "seriesFantasyScore",
                          e.target.innerText
                        )
                      }
                      contentEditable
                    >
                      {getNew.seriesFantasyScore || 0}
                    </td>
                    <td>
                      <select
                        style={{ border: "none" }}
                        onChange={(e) =>
                          editPlayersData(index, "role", e.target.value)
                        }
                      >
                        <option value={getNew.role}>{getNew.role}</option>
                        <option value="batter">batter</option>
                        <option value="bowler">bowler</option>
                        <option value="wk">wk</option>
                        <option value="allrounder">allrounder</option>
                      </select>
                    </td>

                    <td
                      onBlur={(e) =>
                        editPlayersData(index, "credits", e.target.innerText)
                      }
                      contentEditable
                    >
                      {getNew.credits}
                    </td>

                    <td>{getNew.inCb.toString()}</td>
                    <td>{getNew.inDb.toString()}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <button
          id="match-btn"
          style={{
            backgroundColor: "red",
            border: "red",
            color: "white",
            width: "20vw",
            marginTop: "1em",
          }}
          onClick={AddPlayers}
        >
          Add Players
        </button>
        <p id="error" style={{ color: "gray" }}>
          {error}
        </p>
      </div>
      <AddedPlayersToTeam show={open} onHide={() => setOpen(false)} />
    </div>
  );
};

export default GetNewPlayers;
