import React, { useEffect, useState } from "react";
import { SERVER_API, MANAGER } from "./env";
import SideNav from "./SideNav";
import TopNav from "./TopNav";
import { useNavigate } from "react-router-dom";
import { getAdmin } from "./getAdmin";

const IPhoneLeaderboard = () => {
  const navigate = useNavigate();
  const [leaderboard, setLeaderboard] = useState([]);
  let botCountTemp = 0;
  let initialIndex = 0;
  let GetLeaderboardApi = SERVER_API + "/api/iphone-leaderboard";

  let adminDetails = {};
  (async () => {
    adminDetails = await getAdmin();
    if (!(adminDetails.role & MANAGER)) {
      navigate("/");
    }
  })();

  const GetLeaderboard = async () => {
    const response = await fetch(GetLeaderboardApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    setLeaderboard(result);
  };

  useEffect(() => {
    GetLeaderboard();
  }, []);
  let parent = (
    <div style={{ display: "flex" }}>
      <SideNav />
      <div>
        <TopNav />
        <div
          style={{
            height: "7vh",
            display: "flex",
            alignItems: "center",
            marginLeft: "1em",
          }}
        >
          <span
            style={{
              fontWeight: "bold",
            }}
          >
            Bots Count :
          </span>
          &nbsp;
          <span id="botCount"></span>
        </div>

        <div style={{ height: "75vh", overflowY: "scroll" }}>
          <table
            style={{
              width: "85vw",
              marginLeft: "1em",
              cursor: "pointer",
            }}
          >
            <thead id="thead" style={{ position: "sticky" }}>
              <tr>
                <th>Username</th>
                <th>Total Score</th>
                <th>Match Score</th>
                <th>Rank</th>
              </tr>
            </thead>
            <tbody>
              {leaderboard.leaderboard?.map((data, index) => {
                if (data.isBot) {
                  ++botCountTemp;
                }
                if (index == leaderboard.leaderboard.length - 1) {
                  document.querySelector("#botCount").innerHTML = botCountTemp;
                }
                return (
                  <tr
                    key={data._id}
                    style={{
                      borderBottom: "1px solid lightgray",
                      height: "6vh",
                      backgroundColor:
                        data.isBot === true ? "rgb(230, 193, 186)" : "",
                    }}
                  >
                    <td>{data.user}</td>
                    <td>{data.totalScore}</td>
                    <td>{data.matchScore}</td>
                    <td>{data.rank}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
  return parent;
};

export default IPhoneLeaderboard;
