import React, { useState, useEffect } from "react";
import { FANTASY_API, MANAGER } from "../env";
import SideNav from "../SideNav";
import TopNav from "../TopNav";
import Published from "../Matches/Published";
import Verify from "../Matches/Verify";
import Cancel from "../Matches/Cancel";
import SettleContest from "./SettleContest";
import CalRewards2 from "./CalRewards2";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { BsCheckCircle } from "react-icons/bs";
import { RxCrossCircled } from "react-icons/rx";
import { getAdmin } from "../getAdmin";
import AddFantasyBot from "./AddFantasyBot";
import AddFantasySelectBot from "./AddFantasySelectBot";

const SelectFantasyContest = () => {
  const navigate = useNavigate("");
  const [id, setId] = useState("");
  const [adminDisplay, setAdminDisplay] = useState(false);
  const [fantasyDetails, setFantasyDetails] = useState({});
  const [toggle, setToggle] = useState(1);
  const [open, setOpen] = useState(false);
  const [openContestVerified, setOpenContestVerified] = useState(false);
  const [openContestCancel, setOpenContestCancel] = useState(false);
  const [openContestSettle, setOpenContestSettle] = useState(false);
  const [openRewardsCal, setOpenRewardsCal] = useState(false);
  const [displayData, setDisplayData] = useState(false);
  const [openFantasyBot, setOpenFantasyBot] = useState(false);
  const [fantasyId, setFantasyId] = useState("");
  const [openSelectBot, setOpenSelectBot] = useState(false);
  const [error, setError] = useState("");

  let adminDetails = {};
  (async () => {
    adminDetails = await getAdmin();
    if (adminDetails.role & MANAGER) {
      setAdminDisplay(true);
    } else {
      setAdminDisplay(false);
    }
  })();

  useEffect(() => {
    let contestId = window.location.pathname.split("/");
    contestId = contestId.pop();
    setId(contestId);
  }, []);

  useEffect(() => {
    if (id) GetFantasyContestDetails(id);
  }, [id, openSelectBot, openFantasyBot]);

  let SelectFantasyDetailsApi = FANTASY_API + `/api/contests/${id}`;
  let PublishFantasyContestApi = FANTASY_API + `/api/contests/${id}/publish`;
  let VerifyFantasyContestApi = FANTASY_API + `/api/contests/${id}/verify`;
  let CancelFantasyContestApi = FANTASY_API + `/api/contests/${id}/cancel`;
  let SettleFantasyContestApi =
    FANTASY_API + `/api/contests/${id}/rewards/settle`;
  let CalFantasyRewardsApi =
    FANTASY_API + `/api/contests/${id}/rewards/calculate`;

  const PublishFantasyContest = async () => {
    const response = await fetch(PublishFantasyContestApi, {
      method: "PATCH",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      setOpen(true);
    } else {
      setError(result.message);
    }
  };

  const VerifyFantasyContest = async () => {
    const response = await fetch(VerifyFantasyContestApi, {
      method: "PATCH",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      setOpenContestVerified(true);
    } else {
      setError(result.message);
    }
  };

  const CancelFantasyContest = async () => {
    const response = await fetch(CancelFantasyContestApi, {
      method: "PATCH",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      setOpenContestCancel(true);
    } else {
      setError(result.message);
    }
  };

  const SettleFantasyContest = async () => {
    const response = await fetch(SettleFantasyContestApi, {
      method: "PATCH",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      setOpenContestSettle(true);
      setDisplayData(true);
    } else {
      setError(result.message);
    }
  };

  const CalFantasyRewards = async () => {
    const response = await fetch(CalFantasyRewardsApi, {
      method: "PATCH",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      setOpenRewardsCal(true);
    } else {
      setError(result.message);
    }
  };

  const GetFantasyContestDetails = async (id) => {
    const respose = await fetch(SelectFantasyDetailsApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });

    let result = await respose.json();
    setFantasyDetails(result);
    console.log("result", result);
  };

  const handleNavigate = (id) => {
    navigate(`/FantasyLeaderboard/${id}`);
  };

  const handleOpen = async (id) => {
    setFantasyId(id);
    setOpenFantasyBot(true);
  };

  const handleOpenSelectBot = async (id) => {
    setFantasyId(id);
    setOpenSelectBot(true);
  };

  return (
    <div style={{ display: "flex" }}>
      <SideNav />
      <div>
        <TopNav />
        <div id="select-match-tabs">
          <div style={{ display: "flex" }}>
            <h5>Match No : </h5>
            &nbsp;
            <p style={{ display: "flex", alignItems: "center" }}>
              {fantasyDetails.matchNumber}, &nbsp; {fantasyDetails.teamAName} vs{" "}
              {fantasyDetails.teamBName}
            </p>
          </div>
          <div id="main-tabs2">
            <button onClick={PublishFantasyContest} id="publish-btn">
              Publish
            </button>
            <button onClick={SettleFantasyContest} id="matches-btn">
              Settle
            </button>
            <button onClick={CancelFantasyContest} id="Cancel-btn">
              Cancel
            </button>
          </div>
        </div>
        <div id="tabs-div">
          <div
            style={{
              width: "17vw",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <button
              onClick={() => setToggle(1)}
              style={
                toggle === 1 ? { border: "1px solid red", color: "red" } : null
              }
              id="match-btn"
            >
              Contest
            </button>
            <button
              onClick={() => setToggle(2)}
              style={
                toggle === 2 ? { border: "1px solid red", color: "red" } : null
              }
              id="verify-btn"
            >
              Verified by
            </button>
          </div>

          <div
            style={{
              width: "21vw",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <button onClick={CalFantasyRewards} id="matches-btn">
              Cal.Rewards
            </button>
            <button onClick={VerifyFantasyContest} id="matches-btn">
              Verify
            </button>
            {/* <button id="matches-btn">Cal.Rank</button> */}
          </div>
        </div>
        {toggle === 1 ? (
          <div>
            <table id="admins-table">
              <thead id="thead">
                <tr>
                  <th>Entry Symbol</th>
                  <th>Entry Fee</th>
                  <th>Entry Type</th>
                  <th>Min Users Required</th>
                  <th>Max Entries</th>
                  <th>No Of Users</th>
                  <th>Commission</th>
                  {/* <th>Comments</th> */}
                  <th>Cancelled</th>
                  <th>Rewards Cal.</th>
                  <th>Rewards Set.</th>
                  <th>Is Hot</th>
                  <th>Published</th>
                  <th>Is Verified</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  style={{
                    borderBottom: "1px solid lightgray",
                    height: "6vh",
                  }}
                >
                  <td>{fantasyDetails?.entry?.symbol}</td>
                  <td>{fantasyDetails?.entry?.fee}</td>
                  <td>{fantasyDetails?.entry?.type}</td>
                  <td>{fantasyDetails?.minimumUsersRequired}</td>
                  <td>{fantasyDetails?.maxEntriesPerUser}</td>
                  <td>{fantasyDetails?.usersInContest}</td>
                  <td>{fantasyDetails?.commission}</td>
                  {/* <td>{fantasyDetails?.comments}</td> */}
                  <td>{fantasyDetails?.cancelled?.toString()}</td>
                  <td>{fantasyDetails?.rewardsCalculated?.toString()}</td>
                  <td>{fantasyDetails?.rewardsSettled?.toString()}</td>
                  <td>{fantasyDetails?.isHot?.toString()}</td>
                  <td>
                    {fantasyDetails.published === true ? (
                      <BsCheckCircle color="green" />
                    ) : (
                      <RxCrossCircled color="red" />
                    )}
                  </td>
                  <td>
                    {fantasyDetails.isVerified === true ? (
                      <BsCheckCircle color="green" />
                    ) : (
                      <RxCrossCircled color="red" />
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : null}
        {toggle === 2 ? (
          <table id="admins-table">
            <thead id="thead">
              <tr>
                <th>Email</th>
                <th>Hash</th>
              </tr>
            </thead>
            <tbody>
              {fantasyDetails.verifiedBy?.map((data) => {
                return (
                  <tr
                    style={{
                      borderBottom: "1px solid lightgray",
                      height: "6vh",
                    }}
                  >
                    <td>{data.email}</td>
                    <td>{data.hash}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : null}

        <div id="bottom-btn">
          <button
            onClick={() => handleOpenSelectBot(fantasyDetails._id)}
            style={{ display: adminDisplay ? "block" : "none" }}
            id="add-bots-btn"
          >
            Selective Bot
          </button>
          <button
            onClick={() => handleOpen(fantasyDetails._id)}
            style={{ display: adminDisplay ? "block" : "none" }}
            id="add-bots-btn"
          >
            Add Bots
          </button>
          <button
            onClick={() => handleNavigate(fantasyDetails._id)}
            id="leaderboard-btn"
          >
            Go To Leaderboard <HiOutlineArrowNarrowRight color="white" />
          </button>
        </div>
        <p style={{ color: "gray" }}>{error}</p>
      </div>
      <Published show={open} onHide={() => setOpen(false)} />
      <Verify
        show={openContestVerified}
        onHide={() => setOpenContestVerified(false)}
      />
      <Cancel
        show={openContestCancel}
        onHide={() => setOpenContestCancel(false)}
      />
      <SettleContest
        displayData={displayData}
        show={openContestSettle}
        onHide={() => setOpenContestSettle(false)}
      />
      <CalRewards2
        show={openRewardsCal}
        onHide={() => setOpenRewardsCal(false)}
      />
      <AddFantasyBot
        id={fantasyId}
        show={openFantasyBot}
        onHide={() => setOpenFantasyBot(false)}
      />
      <AddFantasySelectBot
        id={fantasyId}
        show={openSelectBot}
        onHide={() => setOpenSelectBot(false)}
      />
    </div>
  );
};

export default SelectFantasyContest;
