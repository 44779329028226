import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { RxCross1 } from "react-icons/rx";
import CreateTournamentsReward from "./CreateTournamentsReward";
import { SERVER_API } from "../env";
import { useEffect } from "react";

const CreateTournament = ({ modalShow, setModalShow }) => {
  const [openReward, setOpenReward] = useState(false);
  const [tournamentName, setTournamentName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [contestType, setContestType] = useState("");
  const [symbol, setSymbol] = useState("");
  const [total, setTotal] = useState("");
  const [rewardType, setRewardType] = useState("");
  const [rules, setRules] = useState("");
  const [cancelled, setCancelled] = useState("");
  const [dateChecked, setDateChecked] = useState(false);
  const [seriesIds, setSeriesIds] = useState(false);
  const [seriesIdsList, setSeriesIdsList] = useState([]);
  const [seriesData, setSeriesData] = useState([]);

  
  let SeriesApi = SERVER_API + "/api/series";

  const CheckSeries = async () => {
    const response = await fetch(SeriesApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    console.log(result);
    setSeriesData([...result]);
    return seriesData[0]?._id;
  };

  useEffect(() => {
    CheckSeries();
  }, []);

  return (
    <div>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h4>Create Tournament</h4>
              <RxCross1 onClick={setModalShow} color="black" />
            </div>
            <div><p><input type="checkbox" onChange={() =>{setDateChecked(!dateChecked)}} checked={dateChecked} /> <span>Date</span>&nbsp;&nbsp;
            <input type="checkbox" onChange={() =>{setSeriesIds(!seriesIds)}} checked={seriesIds} /> <span>Series Ids</span></p></div>
               
        
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  flexWrap:"wrap",
                  rowGap : "25px"
                }}
              >
                <div>
                  <p id="username">Tournament Name</p>
                  <input
                    onChange={(e) => setTournamentName(e.target.value)}
                    id="fst-input"
                    type="text"
                    value={tournamentName}
                  />
                </div>
               
                {dateChecked && <><div id="div-2">
                  <p id="username">Start Date</p>
                  <input
                    onChange={(e) => setStartDate(e.target.value)}
                    id="fst-input"
                    type="datetime-local"
                    value={startDate}
                  />
                  </div>
                  <div id="div-2">
                  <p id="username">End Date</p>
                  <input
                    onChange={(e) => setEndDate(e.target.value)}
                    id="fst-input"
                    type="datetime-local"
                    value={endDate}
                  />
                  </div>
                </> }
                
                <div id="div-2">
                  <p id="username">Contest Type</p>
                  <select
                    onChange={(e) => setContestType(e.target.value)}
                    id="fst-input"
                    type="text"
                  >
                    <option value="select">Select</option>
                    <option value="prediction">Prediction</option>
                    <option value="live">Live</option>
                    <option value="fantasy">Fantasy</option>
                  </select>
                </div>
                <div id="div-2">
                  <p id="username">Reward Type</p>
                  <select
                    onChange={(e) => setRewardType(e.target.value)}
                    id="fst-input"
                    type="text"
                    value={rewardType}
                  >
                    <option value="">Select</option>
                    <option value="onchain">onChain</option>
                    <option value="offchain">offChain</option>
                  </select>
                </div>

                <div id="div-2">
                  <p id="username">Reward Symbol</p>
                  <input
                    onChange={(e) => setSymbol(e.target.value)}
                    id="fst-input"
                    type="text"
                    value={symbol}
                  />
                </div>

                <div id="div-2">
                  <p id="username">Reward Total</p>
                  <input
                    onChange={(e) => setTotal(e.target.value)}
                    id="fst-input"
                    type="number"
                    value={total}
                  />
                </div>

                <div id="div-2">
                  <p id="username">Rules</p>
                  <input
                    onChange={(e) => setRules(e.target.value)}
                    id="fst-input"
                    type="text"
                    value={rules}
                  />
                </div>
                <div id="div-2">
                  <p id="username">Cancelled</p>
                  <select
                    onChange={(e) => setCancelled(e.target.value)}
                    id="fst-input"
                    type="text"
                    value={cancelled}
                  >
                    <option value="select">Selcet</option>
                    <option value="true">True</option>
                    <option value="false">False</option>
                  </select>
                </div>
              </div>

              {seriesIds && <><div id="div-2" style={{marginLeft : "30px"}}>
                  <h6>Select series</h6>
                  <div   style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  flexWrap:"wrap",
                  rowGap : "25px"
                }}>

                  {seriesData?.map((data) => {
                    return (
                      <p>
                      <input type="checkbox" defaultChecked={seriesIdsList.indexOf(data._id) >= 0 ? true : false} onChange={(e) => {
                        if(e.target.checked){
                          if(seriesIdsList.indexOf(data._id) == -1)
                          {
                            let temp = [...seriesIdsList, data._id];
                            setSeriesIdsList(temp);
                          }
                        }else{
                          let matchingIndex = seriesIdsList.indexOf(data._id);
                          if(matchingIndex >= 0)
                            {
                              let temp = [...seriesIdsList];
                              temp.splice(matchingIndex,1)
                              setSeriesIdsList(temp);
                            }
                        }
                      }} />
                      <span>{data.seriesName}</span>
                      </p>
                    );
                  })}
                </div>
                </div>
                </> }

            <div>
              <button
                onClick={() => {
                  setOpenReward(true);
                  setModalShow(true);
                }}
                id="update-btn2"
              >
                Create Reward
              </button>
              <button onClick={setModalShow} id="cancel-btn2">
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <CreateTournamentsReward
        tournamentName={tournamentName}
        startDate={startDate}
        endDate={endDate}
        contestType={contestType}
        symbol={symbol}
        total={total}
        rewardType={rewardType}
        rules={rules}
        cancelled={cancelled}
        modalShow={openReward}
        dateChecked={dateChecked}
        seriesIds={seriesIds}
        seriesIdsList={seriesIdsList}
        setModalShow={() => setOpenReward(false)}
      />
    </div>
  );
};

export default CreateTournament;