import React, { useState, useEffect } from "react";
import SideNav from "./../SideNav";
import TopNav from "./../TopNav";
import { BsCheckCircle } from "react-icons/bs";
import { RxCrossCircled } from "react-icons/rx";
import { PREDICTION_API } from "../env";
import { useNavigate } from "react-router-dom";
import CreatePredictionConfig from "./../Matches/CreatePredictionConfig";
import CreateContests2 from "./CreateContests";
import { AiOutlineDelete } from "react-icons/ai";
import DeletePredictionContest from "./DeletePredictionContest";

const PredictionContest = () => {
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(1);
  const [predictionContests, setPredictionContests] = useState([]);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [error, setError] = useState("");

  let PredictionContestsApi = PREDICTION_API + "/api/prediction-contests";

  const GetPredictions = async () => {
    const response = await fetch(PredictionContestsApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      setPredictionContests(result);
    } else {
      setError(result.message);
    }
  };

  const handleNavigate = async (id) => {
    localStorage.setItem("predictionContestId", id);
    navigate("/SelectPredictionContest");
  };

  const handleDelete = (id) => {
    localStorage.setItem("predictionContestId", id);
    setOpen2(true);
  };

  useEffect(() => {
    GetPredictions();
  }, [modalShow]);
  return (
    <div style={{ display: "flex" }}>
      <SideNav />
      <div>
        <TopNav />
        <div id="tabs-div">
          <div
            style={{
              width: "30vw",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <button
              id="all-btn"
              style={
                toggle === 1 ? { border: "1px solid red", color: "red" } : null
              }
              onClick={() => setToggle(1)}
            >
              All
            </button>
            <button
              id="upcoming-btn"
              onClick={() => setToggle(2)}
              style={
                toggle === 2 ? { border: "1px solid red", color: "red" } : null
              }
            >
              Upcoming
            </button>
            <button
              id="live-btn"
              onClick={() => setToggle(3)}
              style={
                toggle === 3 ? { border: "1px solid red", color: "red" } : null
              }
            >
              Live
            </button>
            <button
              id="completed-btn"
              onClick={() => setToggle(4)}
              style={
                toggle === 4 ? { border: "1px solid red", color: "red" } : null
              }
            >
              Completed
            </button>
          </div>
          <div>
            <button
              onClick={() => setOpen(true)}
              style={{ marginRight: "1em" }}
              id="matches-btn"
            >
              Create Config
            </button>
            <button
              onClick={() => setModalShow(true)}
              id="create-prediction-btn"
            >
              Create Prediction
            </button>
          </div>
        </div>
        <div style={{ height: "70vh", overflowY: "scroll" }}>
          <table id="contests-table">
            <thead id="thead" style={{ position: "sticky" }}>
              <tr>
                <th>Contest Name</th>
                <th>Series</th>
                <th>Start Date</th>
                <th>Entry Fee</th>
                <th>Max Slots</th>
                <th>Max Entries Per User</th>
                <th>Min Users Required</th>
                <th>Commission</th>
                <th>Settled</th>
                <th>Is Verified</th>
                <th>Actions</th>
              </tr>
            </thead>
            {toggle === 1 ? (
              <tbody>
                {predictionContests.map((data) => {
                  return (
                    <tr
                      key={data._id}
                      style={{
                        borderBottom: "1px solid lightgray",
                        height: "6vh",
                      }}
                    >
                      <td onClick={() => handleNavigate(data._id)}>
                        {data.contestName}
                      </td>
                      <td onClick={() => handleNavigate(data._id)}>
                        {data.series}
                      </td>
                      <td onClick={() => handleNavigate(data._id)}>
                        {getDateFromTimeStamp(data.startDate)}
                      </td>
                      <td onClick={() => handleNavigate(data._id)}>
                        {data.entryFee}
                      </td>
                      <td onClick={() => handleNavigate(data._id)}>
                        {data.maxSlots}
                      </td>
                      <td onClick={() => handleNavigate(data._id)}>
                        {data.maxEntriesPerUser}
                      </td>
                      <td onClick={() => handleNavigate(data._id)}>
                        {data.minimumUsersRequired || 0}
                      </td>
                      <td onClick={() => handleNavigate(data._id)}>
                        {data.commission}
                      </td>
                      <td onClick={() => handleNavigate(data._id)}>
                        {data.rewardsSettled?.toString()}
                      </td>
                      <td onClick={() => handleNavigate(data._id)}>
                        {data.isVerified === true ? (
                          <BsCheckCircle color={"green"} />
                        ) : (
                          <RxCrossCircled color={"red"} />
                        )}
                      </td>
                      <td>
                        <AiOutlineDelete
                          onClick={() => handleDelete(data._id)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            ) : null}
            {toggle === 2 ? (
              <tbody>
                {predictionContests
                  .filter?.((value) => {
                    if (value.startDate > parseInt(Date.now() / 1000)) {
                      return value;
                    }
                  })
                  .map((data) => {
                    return (
                      <tr
                        key={data._id}
                        style={{
                          borderBottom: "1px solid lightgray",
                          height: "6vh",
                        }}
                      >
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.contestName}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.series}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {getDateFromTimeStamp(data.startDate)}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.entryFee}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.maxSlots}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.maxEntriesPerUser}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.minimumUsersRequired}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.commission}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.rewardsSettled?.toString()}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.isVerified === true ? (
                            <BsCheckCircle color={"green"} />
                          ) : (
                            <RxCrossCircled color={"red"} />
                          )}
                        </td>
                        <td>
                          <AiOutlineDelete
                            onClick={() => handleDelete(data._id)}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            ) : null}
            {toggle === 3 ? (
              <tbody>
                {predictionContests
                  .filter?.((value) => {
                    if (
                      value.startDate <= parseInt(Date.now() / 1000) &&
                      value.cancelled === false &&
                      value.rewardsSettled === false
                    ) {
                      return value;
                    }
                  })
                  .map((data) => {
                    return (
                      <tr
                        key={data._id}
                        style={{
                          borderBottom: "1px solid lightgray",
                          height: "6vh",
                        }}
                      >
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.contestName}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.series}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {getDateFromTimeStamp(data.startDate)}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.entryFee}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.maxSlots}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.maxEntriesPerUser}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.minimumUsersRequired}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.commission}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.rewardsSettled?.toString()}
                        </td>
                        <td>
                          {data.isVerified === true ? (
                            <BsCheckCircle color={"green"} />
                          ) : (
                            <RxCrossCircled color={"red"} />
                          )}
                        </td>
                        <td>
                          <AiOutlineDelete
                            onClick={() => handleDelete(data._id)}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            ) : null}
            {toggle === 4 ? (
              <tbody>
                {predictionContests
                  .filter?.((value) => {
                    if (
                      value.cancelled === true ||
                      value.rewardsSettled === true
                    ) {
                      return value;
                    }
                  })
                  .map((data) => {
                    return (
                      <tr
                        key={data._id}
                        style={{
                          borderBottom: "1px solid lightgray",
                          height: "6vh",
                        }}
                      >
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.contestName}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.series}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {getDateFromTimeStamp(data.startDate)}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.entryFee}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.maxSlots}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.maxEntriesPerUser}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.minimumUsersRequired}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.commission}
                        </td>
                        <td onClick={() => handleNavigate(data._id)}>
                          {data.rewardsSettled?.toString()}
                        </td>
                        <td>
                          {data.isVerified === true ? (
                            <BsCheckCircle color={"green"} />
                          ) : (
                            <RxCrossCircled color={"red"} />
                          )}
                        </td>
                        <td>
                          <AiOutlineDelete
                            onClick={() => handleDelete(data._id)}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            ) : null}
          </table>
        </div>
        <p id="error" style={{ color: "gray" }}>
          {error}
        </p>
      </div>
      <CreatePredictionConfig show={open} onHide={() => setOpen(false)} />
      <CreateContests2
        modalShow={modalShow}
        setModalShow={() => setModalShow(false)}
      />
      <DeletePredictionContest show={open2} onHide={() => setOpen2(false)} />
    </div>
  );
};

function getDateFromTimeStamp(timeStamp) {
  return new Date(timeStamp * 1000).toLocaleDateString("en-IN", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
}

export default PredictionContest;
