import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { SERVER_API } from "./env";
import { LuCopy } from "react-icons/lu";

const GenerateCodeModal = ({ show, onHide }) => {
  const [displayCode, setDisplayCode] = useState(false);
  const [response, setResponse] = useState(false);
  const [rewardAmount, setRewardAmount] = useState("");
  const [rewardSymbol, setRewardSymbol] = useState("");

  let GenerateCodeApi = SERVER_API + "/api/coupon/new";

  const GenerateCode = async () => {
    let input = { rewardSymbol, rewardAmount };
    const response = await fetch(GenerateCodeApi, {
      method: "POST",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(input),
    });
    let result = await response.json();
    if (response.status === 201) {
      setResponse(result);
      setDisplayCode(true);
    }
  };

  function copyText() {
    var textToCopy = document.getElementById("textToCopy").innerText;
    var tempInput = document.createElement("input");
    tempInput.style.position = "absolute";
    tempInput.style.left = "-9999px";
    tempInput.value = textToCopy;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    alert("Text copied to clipboard: " + textToCopy);
  }

  return (
    <div>
      <Modal
        show={show}
        onHide={onHide}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body
          style={
            !displayCode
              ? {
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }
              : {
                  height: "20vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }
          }
        >
          {!displayCode ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div>
                <h4 id="update-heading">Generate Code</h4>
              </div>
              <div className="inputs-div" style={{ height: "20vh" }}>
                <div id="div-1">
                  <p id="username">Reward Amount</p>
                  <input
                    onChange={(E) => setRewardAmount(E.target.value)}
                    id="fst-input"
                    type="text"
                    value={rewardAmount}
                  />
                </div>
                <div id="div-2">
                  <p id="password">Reward Symbol</p>
                  <input
                    onChange={(E) => setRewardSymbol(E.target.value)}
                    id="sec-input"
                    type="text"
                    value={rewardSymbol}
                  />
                </div>
              </div>

              <button onClick={GenerateCode} id="button">
                Generate Coupon
              </button>
            </div>
          ) : null}

          {displayCode ? (
            <div
              style={{
                height: "6vh",
                width: "25vw",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                border: "1px solid gray",
                borderRadius: "5px",
                padding: "0px .5em 0px 1em",
              }}
            >
              <div id="textToCopy">{response.coupon}</div>
              <button
                style={{
                  height: "5vh",
                  width: "8vw",
                  borderRadius: "5px",
                  border: "none",
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }}
                onClick={copyText}
              >
                <LuCopy />
                Copy
              </button>
            </div>
          ) : null}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default GenerateCodeModal;
