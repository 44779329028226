import React from "react";
import Modal from "react-bootstrap/Modal";
import "./CreateAdmin.css";
import { RxCross1 } from "react-icons/rx";
import { useState } from "react";
import { SERVER_API } from "../env";

const EditAdmin = ({ modalShow, setModalShow }) => {
  const [userName, setUserName] = useState("");
  const [role, setRole] = useState("");
  const [error, setError] = useState("");

  let EditProfileApi =
    SERVER_API + `/api/admins/${localStorage.getItem("adminId")}/profile`;
  let ChangeRoleApi =
    SERVER_API + `/api/admins/${localStorage.getItem("adminId")}/role`;

  const ChangeRole = async () => {
    let input = { role };
    const response = await fetch(ChangeRoleApi, {
      method: "PATCH",
      headers: {
        mode: "cors",
        Accpet: "application/json",
        token: localStorage.getItem("token"),
        "Content-type": "application/json",
      },
      body: JSON.stringify(input),
    });
    let result = await response.json();
    if (response.status === 200) {
      setModalShow(false);
    } else {
      setError(result.message);
    }
  };

  const EditProfile = async () => {
    let input = { userName };
    const response = await fetch(EditProfileApi, {
      method: "PATCH",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(input),
    });
    let result = await response.json();
    if (response.status === 200) {
      setModalShow(false);
    } else {
      setError(result.message);
    }
  };

  return (
    <div>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body
          style={{
            height: "50vh",
          }}
        >
          <div
            style={{
              height: "40vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h4>Edit Admin</h4>
              <RxCross1 color="black" onClick={setModalShow} />
            </div>
            <div id="modal-main-div">
              <div id="modal-left-div">
                <div id="div-1">
                  <p id="username">Username</p>
                  <input
                    onChange={(E) => setUserName(E.target.value)}
                    id="fst-input"
                    type="text"
                    value={userName}
                  />
                </div>
              </div>
              <div>
                <div id="div-2">
                  <p id="username">Role</p>
                  <input
                    onChange={(e) => setRole(e.target.value)}
                    id="fst-input"
                    type="number"
                    value={role}
                  />
                </div>
              </div>
            </div>
            <p id="error">{error}</p>
            <div>
              <button
                onClick={() => {
                  EditProfile();
                  ChangeRole();
                }}
                id="update-btn2"
              >
                Update
              </button>
              <button onClick={setModalShow} id="cancel-btn2">
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EditAdmin;
