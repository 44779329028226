import React from "react";
import TopNav from "./../TopNav";
import SideNav from "../SideNav";
import ContestsTabs from "./ContestsTabs";
import Context from "../../Context";
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineDelete } from "react-icons/ai";
import { SERVER_API } from "../env";
import CreateContests from "./CreateContests";
import { getAdmin } from "./../getAdmin";

const CompletedContests = () => {
  let adminDetails = {};
  (async () => {
    adminDetails = await getAdmin();
  })();

  const navigate = useNavigate();
  const { allContests, setAllContests, setIndividualContest } =
    useContext(Context);
  const [open, setOpen] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  let ContestsApi = SERVER_API + "/api/contests";

  const GetContests = async () => {
    const response = await fetch(ContestsApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    setAllContests(result);
  };

  const handleNavigate = async (id) => {
    localStorage.setItem("contestId", id);
    navigate("/SelectContest");
  };
  useEffect(() => {
    GetContests();
  }, [open, modalShow]);
  return (
    <div className="contests-div">
      <SideNav />
      <div style={{ width: "87svw" }}>
        <TopNav />
        <div id="tabs-div">
          <ContestsTabs />
          <button onClick={() => setModalShow(true)} id="create-contest-btn">
            Create Contest
          </button>
        </div>
        <div style={{ height: "75vh", overflowY: "scroll" }}>
          <table id="contests-table">
            <thead id="thead" style={{ position: "sticky" }}>
              <tr>
                <th>Contest Name</th>
                <th>Match</th>
                <th>Match No</th>
                <th>Contests Type</th>
                <th>Series</th>
                <th>Start Date</th>
                <th>Max Participants</th>
                <th>Min Users Required</th>
                <th>Settled</th>
                <th>Cancelled</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {allContests
                .filter?.((value) => {
                  if (
                    value.cancelled === true ||
                    value.rewardsSettled === true
                  ) {
                    return value;
                  }
                })
                .map((data) => {
                  return (
                    <tr
                      id="tRow"
                      key={data._id}
                      onClick={() => setIndividualContest(data)}
                      style={{
                        borderBottom: "1px solid lightgray",
                        height: "6vh",
                      }}
                    >
                      <td onClick={() => handleNavigate(data._id)}>
                        {data.contestName}
                      </td>
                      <td onClick={() => handleNavigate(data._id)}>
                        {data.match}
                      </td>
                      <td onClick={() => handleNavigate(data._id)}>
                        {data.matchNumber}
                      </td>
                      <td onClick={() => handleNavigate(data._id)}>
                        {data.contestType}
                      </td>
                      <td onClick={() => handleNavigate(data._id)}>
                        {data.series}
                      </td>
                      <td onClick={() => handleNavigate(data._id)}>
                        {getDateFromTimeStamp(data.startDate)}
                      </td>
                      <td onClick={() => handleNavigate(data._id)}>
                        {data.maxParticipants}
                      </td>
                      <td onClick={() => handleNavigate(data._id)}>
                        {data.minimumUsersRequired}
                      </td>
                      <td onClick={() => handleNavigate(data._id)}>
                        {data.rewardsSettled?.toString()}
                      </td>
                      <td onClick={() => handleNavigate(data._id)}>
                        {data.cancelled?.toString()}
                      </td>
                      <td>
                        <div>
                          <AiOutlineDelete onClick={() => setOpen(true)} />
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
      <CreateContests
        modalShow={modalShow}
        setModalShow={() => setModalShow(false)}
      />
    </div>
  );
};

function getDateFromTimeStamp(timeStamp) {
  return new Date(timeStamp * 1000).toLocaleDateString("en-IN", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
}
export default CompletedContests;
