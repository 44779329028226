import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LIVE_PREDICTION_API } from "../env";
import { BiEdit } from "react-icons/bi";
import EditLivePredictionAnswer from "./EditLivePredictionAnswer";
import { AiOutlineDelete } from "react-icons/ai";
import DeleteLivePredictionContest from "../Contests/DeleteLivePredictionContest";

const LivePrediction = () => {
  const navigate = useNavigate();
  const [livePredictionContests, setLivePredictionContests] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [individualData, setIndividualData] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [error, setError] = useState("");

  let LivePredictionContestsApi =
    LIVE_PREDICTION_API +
    `/api/matches/${localStorage.getItem("matchId")}/live-predictions`;

  const GetLivePredictionContests = async () => {
    const response = await fetch(LivePredictionContestsApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      setLivePredictionContests(result);
    } else {
      setError(result.message);
    }
  };

  const handleNavigate = async (id) => {
    localStorage.setItem("livePredictionContestId", id);
    navigate("/SelectLivePredictions");
  };

  const handleEdit = (id) => {
    localStorage.setItem("livePredictionContestId", id);
    setModalShow(true);
  };

  const handleDelete = (id) => {
    localStorage.setItem(" ", id);
    setOpenDelete(true);
  };

  useEffect(() => {
    GetLivePredictionContests();
  }, [modalShow]);
  return (
    <div>
      <table id="contests-table">
        <thead id="thead">
          <tr>
            <th>Question</th>
            <th>Options</th>
            <th>Answer</th>
            <th>EndDate</th>
            <th>Entry Fee</th>
            <th>Symbol</th>
            <th>Published</th>
            <th>Cancelled</th>
            <th>Reward Cal.</th>
            <th>Reward Settle</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {livePredictionContests?.map((data) => {
            return (
              <tr
                key={data._id}
                style={{
                  borderBottom: "1px solid lightgray",
                  height: "6vh",
                }}
              >
                <td onClick={() => handleNavigate(data._id)}>
                  {data.question}
                </td>
                <td onClick={() => handleNavigate(data._id)}>
                  {convertArrayToString(data.options)}
                </td>
                <td onClick={() => handleNavigate(data._id)}>{data.answer}</td>
                <td onClick={() => handleNavigate(data._id)}>
                  {getDateFromTimeStamp(data.endDate)}
                </td>
                <td onClick={() => handleNavigate(data._id)}>
                  {data.entryFee}
                </td>
                <td onClick={() => handleNavigate(data._id)}>{data.symbol}</td>
                <td onClick={() => handleNavigate(data._id)}>
                  {data.published.toString()}
                </td>
                <td onClick={() => handleNavigate(data._id)}>
                  {data.cancelled.toString()}
                </td>
                <td onClick={() => handleNavigate(data._id)}>
                  {data.rewardsCalculated.toString()}
                </td>
                <td onClick={() => handleNavigate(data._id)}>
                  {data.rewardsSettled?.toString()}
                </td>
                <td id="Action-div">
                  <div id="Action-icons">
                    <BiEdit
                      onClick={() => {
                        handleEdit(data._id);
                        setIndividualData(data);
                      }}
                    />
                    <AiOutlineDelete onClick={() => handleDelete(data._id)} />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <p id="error" style={{ color: "gray", marginTop: "2em" }}>
        {error}
      </p>
      <EditLivePredictionAnswer
        livePredictionContests={individualData}
        modalShow={modalShow}
        setModalShow={() => setModalShow(false)}
      />
      <DeleteLivePredictionContest
        show={openDelete}
        onHide={() => setOpenDelete(false)}
      />
    </div>
  );
};

function getDateFromTimeStamp(timeStamp) {
  return new Date(timeStamp * 1000).toLocaleDateString("en-IN", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
}

export default LivePrediction;

function convertArrayToString(arr) {
  let out = "";

  for (let i = 0; i < arr.length; ++i) {
    out += arr[i] + ", ";
  }

  return out;
}
