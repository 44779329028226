import React, { useState, useEffect } from "react";
import { SERVER_API } from "../env";
import SideNav from "../SideNav";
import TopNav from "../TopNav";
import CreateOffer from "./CreateOffer";
import Accordion from "react-bootstrap/Accordion";
import { FiEdit } from "react-icons/fi";
import EditOffers from "./EditOffers";
import { AiOutlineDelete } from "react-icons/ai";
import DeleteOffers from "./DeleteOffers";

const Offers = () => {
  const [modalShow, setModalShow] = useState(false);
  const [offersList, setOffersList] = useState([]);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [offerId, setOfferId] = useState("");
  const [individualData, setIndividualData] = useState({});

  let GetOffersApi = SERVER_API + "/api/offers";

  const GetOffers = async () => {
    const response = await fetch(GetOffersApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    setOffersList(result);
  };

  const handleEdit = (id) => {
    setOpenEdit(true);
    setOfferId(id);
  };

  const handleDelete = (id) => {
    setOpenDelete(true);
    setOfferId(id);
  };

  useEffect(() => {
    GetOffers();
  }, [modalShow, openEdit, openDelete]);

  return (
    <div style={{ display: "flex" }}>
      <SideNav />
      <div>
        <TopNav />
        <div>
          <div style={{ height: "10vh" }}>
            <button onClick={() => setModalShow(true)} id="create-teams-btn">
              Create Offer
            </button>
          </div>
          <div
            style={{ overflowY: "scroll", height: "75vh" }}
            id="predictions-main-div"
          >
            {offersList.map((data) => {
              return (
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header style={{ display: "flex" }}>
                      <p style={{ fontWeight: "bold" }}>Title : </p>&nbsp;
                      <p>{data.title}</p>
                      <AiOutlineDelete
                        onClick={() => handleDelete(data._id)}
                        style={{
                          position: "absolute",
                          right: "3em",
                          color: "gray",
                        }}
                      />
                    </Accordion.Header>
                    <Accordion.Body>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "baseline",
                        }}
                      >
                        <div
                          style={{
                            width: "82vw",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            <p style={{ fontWeight: "bold" }}>Series Name :</p>
                            &nbsp;
                            <p>{data.seriesName}</p>
                          </div>

                          <FiEdit
                            onClick={() => {
                              handleEdit(data._id);
                              setIndividualData(data);
                            }}
                          />
                        </div>
                        <div style={{ display: "flex" }}>
                          <p style={{ fontWeight: "bold" }}>Offer Type : </p>
                          &nbsp;
                          <p>{data.offerType}</p>
                        </div>
                        <div style={{ display: "flex" }}>
                          <p style={{ fontWeight: "bold" }}>Rules : </p>&nbsp;
                          <p> {data.rules}</p>
                        </div>
                        <div style={{ display: "flex" }}>
                          <p style={{ fontWeight: "bold" }}>Trigger : </p>&nbsp;
                          <p> {data.trigger}</p>
                        </div>
                        <div style={{ display: "flex" }}>
                          <p style={{ fontWeight: "bold" }}>
                            Deposite Symbol :{" "}
                          </p>
                          &nbsp; <p>{data.depositSymbol}</p>
                        </div>
                        <div style={{ display: "flex" }}>
                          <p style={{ fontWeight: "bold" }}>Reward Symbol : </p>
                          &nbsp;
                          <p>{data.symbol}</p>
                        </div>
                        <div style={{ display: "flex" }}>
                          <p style={{ fontWeight: "bold" }}>
                            Contest Symbol :{" "}
                          </p>
                          &nbsp;
                          <p>{data.contestSymbol}</p>
                        </div>
                        <div style={{ display: "flex" }}>
                          <p style={{ fontWeight: "bold" }}>Reward Amount : </p>
                          &nbsp; <p>{data.rewardAmount}</p>
                        </div>
                        <div style={{ display: "flex" }}>
                          <p style={{ fontWeight: "bold" }}>
                            Reward Transaction Type :
                          </p>
                          &nbsp; <p>{data.rewardTransactionType}</p>
                        </div>
                        <div style={{ display: "flex" }}>
                          <p style={{ fontWeight: "bold" }}>Create Date :</p>{" "}
                          &nbsp;
                          <p>{getDateFromTimeStamp(data.timestamp)}</p>
                        </div>
                        <div style={{ display: "flex" }}>
                          <p style={{ fontWeight: "bold" }}>Start Date :</p>{" "}
                          &nbsp;
                          <p>{getDateFromTimeStamp(data.startTime)}</p>
                        </div>
                        <div style={{ display: "flex" }}>
                          <p style={{ fontWeight: "bold" }}>End Date : </p>
                          &nbsp; <p>{getDateFromTimeStamp(data.expiryTime)}</p>
                        </div>
                        <div style={{ display: "flex" }}>
                          <p style={{ fontWeight: "bold" }}>Discription : </p>
                          &nbsp;<p>{data.discription}</p>
                        </div>
                        <div style={{ display: "flex" }}>
                          <p style={{ fontWeight: "bold" }}>Status : </p>&nbsp;
                          <p>{data.status}</p>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              );
            })}
          </div>
        </div>
      </div>
      <CreateOffer
        modalShow={modalShow}
        setModalShow={() => setModalShow(false)}
      />
      <EditOffers
        offersList={individualData}
        id={offerId}
        modalShow={openEdit}
        setModalShow={() => setOpenEdit(false)}
      />
      <DeleteOffers
        id={offerId}
        show={openDelete}
        onHide={() => setOpenDelete(false)}
      />
    </div>
  );
};

export default Offers;

function getDateFromTimeStamp(timeStamp) {
  return new Date(timeStamp * 1000).toLocaleDateString("en-IN", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
}
