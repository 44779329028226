import React from "react";
import SideNav from "../SideNav";
import TopNav from "../TopNav";
import "./SelectContest.css";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Published from "../Matches/Published";
import Cancel from "../Matches/Cancel";
import Verify from "../Matches/Verify";
import { PREDICTION_API, MANAGER } from "../env";
import AddBots from "./AddBots";
import AddSelectiveBot from "./AddSelectiveBot";
import { BsCheckCircle } from "react-icons/bs";
import { RxCrossCircled } from "react-icons/rx";
import CalRewards2 from "./CalRewards2";
import { getAdmin } from "../getAdmin";
import SettleContest from "./SettleContest";

const SelectPredictionContest = () => {
  const [adminDisplay, setAdminDisplay] = useState(false);
  const [predictionContests, setPredictionContests] = useState({});
  const [displayData, setDisplayData] = useState(false);

  let adminDetails = {};
  (async () => {
    adminDetails = await getAdmin();
    if (adminDetails.role & MANAGER) {
      setAdminDisplay(true);
    } else {
      setAdminDisplay(false);
    }
  })();

  const navigate = useNavigate();
  const location = useLocation();

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [open6, setOpen6] = useState(false);
  const [open7, setOpen7] = useState(false);
  const [error, setError] = useState("");
  const [error2, setError2] = useState("");
  const [error3, setError3] = useState("");
  const [error4, setError4] = useState("");

  let PublishPredictionContestApi =
    PREDICTION_API +
    `/api/prediction-contests/${localStorage.getItem(
      "predictionContestId"
    )}/publish`;

  let CancelPredictionContestApi =
    PREDICTION_API +
    `/api/prediction-contests/${localStorage.getItem(
      "predictionContestId"
    )}/cancel`;

  let VerifyContestApi =
    PREDICTION_API +
    `/api/prediction-contests/${localStorage.getItem(
      "predictionContestId"
    )}/verify`;
  let SelectPredictionContestApi =
    PREDICTION_API +
    `/api/prediction-contests/${localStorage.getItem("predictionContestId")}`;

  let CalRewardsApi =
    PREDICTION_API +
    `/api/prediction-contests/${localStorage.getItem(
      "predictionContestId"
    )}/rewards/calculate`;

  let SettlePredictionContestApi =
    PREDICTION_API +
    `/api/prediction-contests/${localStorage.getItem(
      "predictionContestId"
    )}/rewards/settle`;

  const SettlePredictionContest = async () => {
    const response = await fetch(SettlePredictionContestApi, {
      method: "PATCH",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      setOpen2(true);
      setDisplayData(true);
    } else {
      setError4(result.message);
    }

    if (response.status === 409) {
      setOpen2(true);
    }
  };

  const SelectContest = async () => {
    const response = await fetch(SelectPredictionContestApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    setPredictionContests(result);
  };

  const PublishPredictionContest = async () => {
    const response = await fetch(PublishPredictionContestApi, {
      method: "PATCH",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      setOpen(true);
    }
    if (response.status === 400) {
      setError2(result.message);
    }
  };

  const CancelContest = async () => {
    const response = await fetch(CancelPredictionContestApi, {
      method: "PATCH",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      setOpen3(true);
    }
    if (response.ststus === 400) {
      setError2(result.message);
    }
  };

  const VerifyContest = async () => {
    const response = await fetch(VerifyContestApi, {
      method: "PATCH",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      setOpen4(true);
    } else {
      setError3(result.message);
    }
  };
  const CalRewards = async () => {
    const response = await fetch(CalRewardsApi, {
      method: "PATCH",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      setOpen7(true);
    }
    if (response.status === 400) {
      setError(result.message);
    }
  };

  useEffect(() => {
    SelectContest();
  }, [open5, open6]);

  return (
    <div className="selectContest-div">
      <SideNav />
      <div style={{ width: "87svw" }}>
        <TopNav />

        <div id="select-match-tabs">
          <div style={{ display: "flex" }}>
            <h5>Match No : </h5>
            &nbsp;
            <p
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {predictionContests.matchNumber}, &nbsp;{" "}
              {predictionContests.teamAName} vs {predictionContests.teamBName}
            </p>
          </div>
          <div id="main-tabs2">
            <button onClick={PublishPredictionContest} id="publish-btn">
              Publish
            </button>
            <button onClick={SettlePredictionContest} id="matches-btn">
              Settle
            </button>
            <button onClick={CancelContest} id="Cancel-btn">
              Cancel
            </button>
          </div>
        </div>

        <div id="tabs-div">
          <div
            style={{
              width: "17vw",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <button
              id="match-btn"
              style={
                location.pathname === "/SelectPredictionContest"
                  ? { border: "1px solid red", color: "red" }
                  : null
              }
            >
              Contest
            </button>
            <button onClick={() => navigate("/VerifyContest")} id="verify-btn">
              Verified by
            </button>
          </div>

          <div
            style={{
              width: "21vw",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <button onClick={CalRewards} id="matches-btn">
              Cal.Rewards
            </button>

            <button onClick={VerifyContest} id="matches-btn">
              Verify
            </button>
          </div>
        </div>

        <table
          style={{
            width: " 85vw",
            marginLeft: "1em",
          }}
        >
          <thead id="thead">
            <tr>
              <th>Contests Name</th>
              <th>Entry Fee</th>
              <th>Min Users Required</th>
              <th>Max Entries PerUser</th>
              <th>Users In Contest</th>
              <th>Commission</th>
              <th>Published</th>
              <th>Reward Cal</th>
              <th>Reward Set</th>
              <th>Cancelled</th>
              <th>Is Verified</th>
            </tr>
          </thead>
          <tbody>
            <tr
              style={{
                borderBottom: "1px solid lightgray",
                height: "6vh",
              }}
            >
              <td>{predictionContests?.contestName}</td>
              <td>
                {predictionContests?.entry?.fee +
                  " " +
                  predictionContests?.entry?.symbol}
              </td>
              <td>{predictionContests?.minimumUsersRequired}</td>
              <td>{predictionContests?.maxEntriesPerUser}</td>
              <td>{predictionContests?.usersInContest}</td>
              <td>{predictionContests?.commission}</td>
              <td>{predictionContests?.published?.toString()}</td>
              <td>{predictionContests?.rewardsCalculated?.toString()}</td>
              <td>{predictionContests?.rewardsSettled?.toString()}</td>
              <td>{predictionContests?.cancelled?.toString()}</td>
              <td>
                {predictionContests?.isVerified === true ? (
                  <BsCheckCircle color={"green"} />
                ) : (
                  <RxCrossCircled color={"red"} />
                )}
              </td>
            </tr>
          </tbody>
        </table>
        <div id="bottom-btn">
          <button
            style={{ display: adminDisplay ? "block" : "none" }}
            onClick={() => setOpen6(true)}
            id="add-bots-btn"
          >
            Selective Bot
          </button>
          <button
            onClick={() => setOpen5(true)}
            style={{ display: adminDisplay ? "block" : "none" }}
            id="add-bots-btn"
          >
            Add Bots
          </button>
          <button id="leaderboard-btn" onClick={() => navigate("/Leaderboard")}>
            Go To Leaderboard <HiOutlineArrowNarrowRight color="white" />
          </button>
        </div>
        <p style={{ color: "gray" }}>{error}</p>
        <p style={{ color: "gray" }}>{error2}</p>
        <p style={{ color: "gray" }}>{error3}</p>
        <p style={{ color: "gray" }}>{error4}</p>
      </div>
      <Published show={open} onHide={() => setOpen(false)} />
      <Cancel show={open3} onHide={() => setOpen3(false)} />
      <Verify show={open4} onHide={() => setOpen4(false)} />
      <AddBots show={open5} onHide={() => setOpen5(false)} />
      <AddSelectiveBot show={open6} onHide={() => setOpen6(false)} />
      <CalRewards2 show={open7} onHide={() => setOpen7(false)} />
      <SettleContest
        displayData={displayData}
        show={open2}
        onHide={() => setOpen2(false)}
      />
    </div>
  );
};

export default SelectPredictionContest;
