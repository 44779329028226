import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { SERVER_API } from "../env";
import { RxCross1 } from "react-icons/rx";

const EditScore = ({ show, onHide }) => {
  const [score, setScore] = useState("");
  const [wickets, setWickets] = useState("");
  const [error, setError] = useState("");

  let EditScoreApi = SERVER_API + "/api/og/session";
  const UpdateScore = async () => {
    let input = {
      sessionId: localStorage.getItem("sessionId"),
      score,
      wickets,
    };
    const response = await fetch(EditScoreApi, {
      method: "PATCH",
      headers: {
        mode: "cors",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(input),
    });
    let result = await response.json();
    if (response.status === 200) {
      onHide();
    } else {
      setError(result.message);
    }
  };
  return (
    <div>
      <Modal
        show={show}
        onHide={onHide}
        style={{
          backgroundColor: "black-transperant",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        size="md"
        centered
      >
        <Modal.Body
          style={{
            height: "50vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div id="head-div">
            <h4>Edit Score</h4>
            <RxCross1 color="black" onClick={onHide} />
          </div>
          <div className="prediction-inputs-div">
            <div id="div-1">
              <p id="otp-email">Score</p>
              <input
                onChange={(E) => setScore(E.target.value)}
                id="select"
                value={score}
              />
            </div>

            <div id="div-2">
              <p id="old-password">Wickets</p>
              <input
                onChange={(E) => setWickets(E.target.value)}
                id="sec-input"
                value={wickets}
              />
            </div>
          </div>
          <p id="error">{error}</p>
          <button onClick={UpdateScore} id="button">
            Update
          </button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EditScore;
