import React from "react";
import SideNav from "./../SideNav";
import TopNav from "../TopNav";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import CalRewards2 from "./CalRewards2";
import { PREDICTION_API } from "../env";
import { getAdmin } from "../getAdmin";
import Accordion from "react-bootstrap/Accordion";

const Rewards = () => {
  let adminDetails = {};
  (async () => {
    adminDetails = await getAdmin();
  })();

  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const [flexibleRewards, setFlexibleRewards] = useState([]);
  const [fixedRewards, setFixedRewards] = useState([]);
  const [toggle, setToggle] = useState(1);

  let FlexibleRewardsApi =
    PREDICTION_API +
    `/api/prediction-contests/${localStorage.getItem(
      "predictionContestId"
    )}/rewards/breakup?type=flexible`;

  let FixedRewardsApi =
    PREDICTION_API +
    `/api/prediction-contests/${localStorage.getItem(
      "predictionContestId"
    )}/rewards/breakup?type=fixed`;

  const FlexibleRewardBreakup = async () => {
    const response = await fetch(FlexibleRewardsApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      setFlexibleRewards(result);
    } else {
      setError(result.message);
    }
  };

  const FixedRewardBreakup = async () => {
    const response = await fetch(FixedRewardsApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      setFixedRewards(result);
    } else {
      setError(result.message);
    }
  };

  function handleOptionSelect() {
    var selectedOption = document.getElementById("mySelect").value;
    switch (selectedOption) {
      case "flexible":
        FlexibleRewardBreakup(setToggle(1));
        break;

      case "fixed":
        FixedRewardBreakup(setToggle(2));
        break;
    }
  }

  let x = 0,
    y = 0;

  return (
    <div className="selectContest-div">
      <SideNav />
      <div style={{ width: "87svw" }}>
        <TopNav />

        <div id="tabs-div">
          <div
            style={{
              width: "22vw",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <button
              id="match-btn"
              onClick={() => navigate("/Leaderboard")}
              style={{ width: "10vw" }}
            >
              Leaderboard
            </button>

            <select
              style={
                location.pathname === "/Rewards"
                  ? {
                      border: "1px solid red",
                      color: "red",
                      width: "11vw",
                      borderRadius: "5px",
                    }
                  : null
              }
              id="mySelect"
              onChange={() => handleOptionSelect()}
            >
              <option value="">Reward Breakup</option>
              <option value="flexible">Flexible</option>
              <option value="fixed">Fixed</option>
            </select>
          </div>
        </div>

        <div id="predictions-main-div">
          {toggle === 1
            ? flexibleRewards.map((symbol) => {
                return (
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        Symbol : {symbol.symbol}, Total : {symbol.total || 0}
                      </Accordion.Header>
                      <Accordion.Body>
                        <table
                          style={{
                            width: " 80vw",
                            marginLeft: "1em",
                          }}
                        >
                          <thead id="thead">
                            <tr>
                              <th>Rank</th>
                              <th>Amount</th>
                              <th>Count</th>
                            </tr>
                          </thead>
                          <tbody id="tbody">
                            {symbol?.distribution?.map((data, index) => {
                              {
                                x = y;
                                y += data.count;
                              }
                              return (
                                <tr
                                  style={{
                                    borderBottom: "1px solid lightgray",
                                    height: "6vh",
                                  }}
                                >
                                  {/* <td> 
                                    {index + 1 !== index + data.count
                                      ? index + 1 + " - " + (index + data.count)
                                      : index + 1}
                                  </td>
                                  <td>{data.amount || 0}</td>
                                  <td>{data.count || 0}</td> */}

                                  <td>
                                    {data.count !== 1
                                      ? x + 1 + " - " + (x + data.count)
                                      : x + 1}
                                  </td>
                                  <td>{data.amount || 0}</td>
                                  <td>{data.count || 0}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                );
              })
            : null}

          {toggle === 2
            ? fixedRewards.map((symbol) => {
                return (
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        Symbol : {symbol.symbol}, Total : {symbol.total || 0}
                      </Accordion.Header>
                      <Accordion.Body>
                        <table
                          style={{
                            width: " 80vw",
                            marginLeft: "1em",
                          }}
                        >
                          <thead id="thead">
                            <tr>
                              <th>Rank</th>
                              <th>Amount</th>
                              <th>Count</th>
                            </tr>
                          </thead>
                          <tbody id="tbody">
                            {symbol?.distribution?.map((data, index) => {
                              {
                                x = y;
                                y += data.count;
                              }
                              return (
                                <tr
                                  style={{
                                    borderBottom: "1px solid lightgray",
                                    height: "6vh",
                                  }}
                                >
                                  <td>
                                    {data.count !== 1
                                      ? x + 1 + " - " + (x + data.count)
                                      : x + 1}
                                  </td>
                                  <td>{data.amount || 0}</td>
                                  <td>{data.count || 0}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                );
              })
            : null}
        </div>

        <p style={{ color: "gray" }}>{error}</p>
      </div>
      <CalRewards2 show={open} onHide={() => setOpen(false)} />
    </div>
  );
};

export default Rewards;
