import React, { useState, useEffect } from "react";
import SideNav from "./../SideNav";
import TopNav from "./../TopNav";
import AddPlayers from "./AddPlayers";
import { SERVER_API } from "../env";
import { BiEdit } from "react-icons/bi";
import EditPlayers from "./EditPlayers";
import { getAdmin } from "./../getAdmin";

const Players = () => {
  const [modalShow, setModalShow] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);
  const [allPlayers, setPlayers] = useState([]);
  const [individualPlayer, setIndividualPlayer] = useState([]);

  let adminDetails = {};
  (async () => {
    adminDetails = await getAdmin();
  })();

  let GetAllPlayersApi = SERVER_API + "/api/generic-players";

  const GetPlayers = async () => {
    const response = await fetch(GetAllPlayersApi, {
      method: "GET",
      headers: {
        mode: "cors",
        Accept: "application/json",
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    setPlayers(result);
  };

  const handleEdit = (id) => {
    localStorage.setItem("playerId", id);
    setModalShow2(true);
  };

  useEffect(() => {
    GetPlayers();
  }, [modalShow, modalShow2, individualPlayer]);

  return (
    <div style={{ display: "flex" }}>
      <SideNav />
      <div>
        <TopNav />
        <div style={{ height: "10vh" }}>
          <button onClick={() => setModalShow(true)} id="create-teams-btn">
            Add Players
          </button>
        </div>
        <div style={{ height: "70vh", overflowY: "scroll" }}>
          <table id="admins-table">
            <thead id="thead" style={{ position: "sticky" }}>
              <tr>
                <th>CricBuzz PlayerId</th>
                <th>Player Name</th>
                <th>Display Name</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {allPlayers.map((data) => {
                return (
                  <tr
                    style={{
                      borderBottom: "1px solid lightgray",
                      height: "6vh",
                    }}
                  >
                    <td>{data.cricBuzzPlayerId}</td>
                    <td>{data.playerName}</td>
                    <td>{data.displayName}</td>
                    <td>
                      <BiEdit
                        onClick={() => {
                          handleEdit(data._id);
                          setIndividualPlayer(data);
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <AddPlayers
        modalShow={modalShow}
        setModalShow={() => setModalShow(false)}
      />
      <EditPlayers
        players={individualPlayer}
        modalShow={modalShow2}
        setModalShow={() => setModalShow2(false)}
      />
    </div>
  );
};

export default Players;
