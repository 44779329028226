import React from "react";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";

const Logout = ({ show, onHide }) => {
  const navigate = useNavigate();
  return (
    <div>
      <Modal
        show={show}
        onHide={onHide}
        style={{
          backgroundColor: "black-transperant",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        size="md"
        centered
      >
        <Modal.Body
          style={{
            height: "30vh",
          }}
        >
          <div
            style={{
              height: "20vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h2>Logout</h2>
            <p>Are you sure you want to Logout</p>
            <div id="buttons-div">
              <button onClick={onHide} id="cancel-btn3">
                Cancel
              </button>
              <button
                onClick={() => {
                  localStorage.setItem("token", "");
                  navigate("/Login");
                }}
                id="delete-btn"
              >
                Logout
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Logout;
